/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Stripe Checkout Session details
 * @export
 * @interface CreateSessionParamsDTO
 */
export interface CreateSessionParamsDTO {
    /**
     * 
     * @type {string}
     * @memberof CreateSessionParamsDTO
     */
    successUrl: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSessionParamsDTO
     */
    failureUrl: string;
}

export function CreateSessionParamsDTOFromJSON(json: any): CreateSessionParamsDTO {
    return CreateSessionParamsDTOFromJSONTyped(json, false);
}

export function CreateSessionParamsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateSessionParamsDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'successUrl': json['successUrl'],
        'failureUrl': json['failureUrl'],
    };
}

export function CreateSessionParamsDTOToJSON(value?: CreateSessionParamsDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'successUrl': value.successUrl,
        'failureUrl': value.failureUrl,
    };
}


