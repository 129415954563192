import { omitBy, isUndefined, isObject, mapValues } from 'lodash';

export function stripUndefinedProps<T extends object>(object: T): T {
  return omitBy(object, isUndefined) as T;
}

// The typing is not exactly precise. If the type has field that can be undefined
// but is not optional (like { a: number | undefined}), then in returned type this
// field should become optional. I'm not sure if it's possible to describe that in TS.
export function deeplyStripUndefinedProps<T>(input: T): T {
  if (isObject(input)) {
    return mapValues(omitBy(input, isUndefined), (prop) => deeplyStripUndefinedProps(prop)) as T;
  }

  return input;
}
