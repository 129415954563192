// neptune-core-ui
// eslint-disable-next-line no-restricted-imports
import { getCellData } from 'neptune-core-ui/components/table/helpers';


// Module
function simpleRenderer({data, dataKey, children}) {
  return getCellData({data, dataKey, children});
}


export default simpleRenderer;
