/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AvatarSourceEnum,
    AvatarSourceEnumFromJSON,
    AvatarSourceEnumFromJSONTyped,
    AvatarSourceEnumToJSON,
    OrganizationTraitsSetDTO,
    OrganizationTraitsSetDTOFromJSON,
    OrganizationTraitsSetDTOFromJSONTyped,
    OrganizationTraitsSetDTOToJSON,
    OrganizationTypeDTO,
    OrganizationTypeDTOFromJSON,
    OrganizationTypeDTOFromJSONTyped,
    OrganizationTypeDTOToJSON,
    PricingPlanDTO,
    PricingPlanDTOFromJSON,
    PricingPlanDTOFromJSONTyped,
    PricingPlanDTOToJSON,
    PricingPlanDefinitionDTO,
    PricingPlanDefinitionDTOFromJSON,
    PricingPlanDefinitionDTOFromJSONTyped,
    PricingPlanDefinitionDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface OrganizationDTO
 */
export interface OrganizationDTO {
    /**
     * 
     * @type {string}
     * @memberof OrganizationDTO
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDTO
     */
    paymentStatus: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDTO
     */
    avatarUrl: string;
    /**
     * 
     * @type {OrganizationTypeDTO}
     * @memberof OrganizationDTO
     */
    organizationType: OrganizationTypeDTO;
    /**
     * 
     * @type {AvatarSourceEnum}
     * @memberof OrganizationDTO
     */
    avatarSource: AvatarSourceEnum;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDTO
     */
    info?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDTO
     */
    id: string;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationDTO
     */
    limitsEnforced: boolean;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDTO
     */
    userflowIdentityHash: string;
    /**
     * 
     * @type {PricingPlanDTO}
     * @memberof OrganizationDTO
     */
    pricingPlan: PricingPlanDTO;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationDTO
     */
    supportsMonitoringHoursLimits: boolean;
    /**
     * 
     * @type {PricingPlanDefinitionDTO}
     * @memberof OrganizationDTO
     */
    pricingPlanDefinition: PricingPlanDefinitionDTO;
    /**
     * 
     * @type {Date}
     * @memberof OrganizationDTO
     */
    created: Date;
    /**
     * 
     * @type {OrganizationTraitsSetDTO}
     * @memberof OrganizationDTO
     */
    traits: OrganizationTraitsSetDTO;
}

export function OrganizationDTOFromJSON(json: any): OrganizationDTO {
    return OrganizationDTOFromJSONTyped(json, false);
}

export function OrganizationDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'paymentStatus': json['paymentStatus'],
        'avatarUrl': json['avatarUrl'],
        'organizationType': OrganizationTypeDTOFromJSON(json['organizationType']),
        'avatarSource': AvatarSourceEnumFromJSON(json['avatarSource']),
        'info': !exists(json, 'info') ? undefined : json['info'],
        'id': json['id'],
        'limitsEnforced': json['limitsEnforced'],
        'userflowIdentityHash': json['userflowIdentityHash'],
        'pricingPlan': PricingPlanDTOFromJSON(json['pricingPlan']),
        'supportsMonitoringHoursLimits': json['supportsMonitoringHoursLimits'],
        'pricingPlanDefinition': PricingPlanDefinitionDTOFromJSON(json['pricingPlanDefinition']),
        'created': (new Date(json['created'])),
        'traits': OrganizationTraitsSetDTOFromJSON(json['traits']),
    };
}

export function OrganizationDTOToJSON(value?: OrganizationDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'paymentStatus': value.paymentStatus,
        'avatarUrl': value.avatarUrl,
        'organizationType': OrganizationTypeDTOToJSON(value.organizationType),
        'avatarSource': AvatarSourceEnumToJSON(value.avatarSource),
        'info': value.info,
        'id': value.id,
        'limitsEnforced': value.limitsEnforced,
        'userflowIdentityHash': value.userflowIdentityHash,
        'pricingPlan': PricingPlanDTOToJSON(value.pricingPlan),
        'supportsMonitoringHoursLimits': value.supportsMonitoringHoursLimits,
        'pricingPlanDefinition': PricingPlanDefinitionDTOToJSON(value.pricingPlanDefinition),
        'created': (value.created.toISOString()),
        'traits': OrganizationTraitsSetDTOToJSON(value.traits),
    };
}


