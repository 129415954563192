import React from 'react';

import { markGroupsRe, collapseSiblingRe } from '@neptune/shared/common-domain';

type TextWithMatchingProps = {
  text: string;
};

export const TextWithMatching = ({ text }: TextWithMatchingProps) => {
  const pairs = [...text.replace(collapseSiblingRe, '').matchAll(markGroupsRe)]
    .filter(([, normal, matching]) => normal || matching)
    .map(([, normal, matching]) => ({ normal, matching }));

  return (
    <>
      {pairs.map(({ normal, matching }, index) => (
        <span key={index}>
          {normal}
          {matching && <mark>{matching}</mark>}
        </span>
      ))}
    </>
  );
};
