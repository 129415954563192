/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AssignIntOperation
 */
export interface AssignIntOperation {
    /**
     * 
     * @type {number}
     * @memberof AssignIntOperation
     */
    value: number;
}

export function AssignIntOperationFromJSON(json: any): AssignIntOperation {
    return AssignIntOperationFromJSONTyped(json, false);
}

export function AssignIntOperationFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssignIntOperation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'value': json['value'],
    };
}

export function AssignIntOperationToJSON(value?: AssignIntOperation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'value': value.value,
    };
}


