import React from 'react';

import { Layout } from '../layout/Layout';
import { LayoutRowProps } from '../layout-row/LayoutRow';
import { bemBlock } from '../../modules/bem';

import './CardSection.less';

const block = bemBlock('n-card-section');

export type CardSectionProps = {
  className?: string;
  children?: React.ReactNode;
};

export const CardSection: React.FC<
  CardSectionProps & Omit<LayoutRowProps<{}>, 'spacedChildren' | 'withPadding'>
> = ({ className, children, ...rest }) => {
  return (
    <Layout.Row
      className={block({ extra: className })}
      {...rest}
      withPadding="lg"
      spacedChildren="md"
      children={children}
    />
  );
};
