import { invert } from 'lodash';

export function isNotUndefined<T>(param: T | undefined): param is T {
  return param !== undefined;
}

export function invertMapTyped<Source extends string, Target extends string>(
  mapping: Partial<Record<Source, Target>>,
): Partial<Record<Target, Source>> {
  return invert(mapping) as Partial<Record<Target, Source>>;
}
