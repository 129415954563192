/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MonitoringTimeAndPackageHistoryPeriodDTO
 */
export interface MonitoringTimeAndPackageHistoryPeriodDTO {
    /**
     * 
     * @type {number}
     * @memberof MonitoringTimeAndPackageHistoryPeriodDTO
     */
    usedSeconds: number;
    /**
     * 
     * @type {number}
     * @memberof MonitoringTimeAndPackageHistoryPeriodDTO
     */
    topUpSeconds: number;
    /**
     * 
     * @type {Date}
     * @memberof MonitoringTimeAndPackageHistoryPeriodDTO
     */
    periodStart: Date;
    /**
     * 
     * @type {Date}
     * @memberof MonitoringTimeAndPackageHistoryPeriodDTO
     */
    periodEnd: Date;
}

export function MonitoringTimeAndPackageHistoryPeriodDTOFromJSON(json: any): MonitoringTimeAndPackageHistoryPeriodDTO {
    return MonitoringTimeAndPackageHistoryPeriodDTOFromJSONTyped(json, false);
}

export function MonitoringTimeAndPackageHistoryPeriodDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitoringTimeAndPackageHistoryPeriodDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'usedSeconds': json['usedSeconds'],
        'topUpSeconds': json['topUpSeconds'],
        'periodStart': (new Date(json['periodStart'])),
        'periodEnd': (new Date(json['periodEnd'])),
    };
}

export function MonitoringTimeAndPackageHistoryPeriodDTOToJSON(value?: MonitoringTimeAndPackageHistoryPeriodDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'usedSeconds': value.usedSeconds,
        'topUpSeconds': value.topUpSeconds,
        'periodStart': (value.periodStart.toISOString()),
        'periodEnd': (value.periodEnd.toISOString()),
    };
}


