/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AttributeTypeDTO,
    AttributeTypeDTOFromJSON,
    AttributeTypeDTOFromJSONTyped,
    AttributeTypeDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface LeaderboardSortParamsDTO
 */
export interface LeaderboardSortParamsDTO {
    /**
     * 
     * @type {Array<string>}
     * @memberof LeaderboardSortParamsDTO
     */
    sortBy?: Array<string>;
    /**
     * 
     * @type {Array<AttributeTypeDTO>}
     * @memberof LeaderboardSortParamsDTO
     */
    sortFieldType?: Array<AttributeTypeDTO>;
    /**
     * 
     * @type {Array<string>}
     * @memberof LeaderboardSortParamsDTO
     */
    sortFieldAggregationMode?: Array<LeaderboardSortParamsDTOSortFieldAggregationModeEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof LeaderboardSortParamsDTO
     */
    sortDirection?: Array<LeaderboardSortParamsDTOSortDirectionEnum>;
}

export function LeaderboardSortParamsDTOFromJSON(json: any): LeaderboardSortParamsDTO {
    return LeaderboardSortParamsDTOFromJSONTyped(json, false);
}

export function LeaderboardSortParamsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): LeaderboardSortParamsDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sortBy': !exists(json, 'sortBy') ? undefined : json['sortBy'],
        'sortFieldType': !exists(json, 'sortFieldType') ? undefined : ((json['sortFieldType'] as Array<any>).map(AttributeTypeDTOFromJSON)),
        'sortFieldAggregationMode': !exists(json, 'sortFieldAggregationMode') ? undefined : json['sortFieldAggregationMode'],
        'sortDirection': !exists(json, 'sortDirection') ? undefined : json['sortDirection'],
    };
}

export function LeaderboardSortParamsDTOToJSON(value?: LeaderboardSortParamsDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sortBy': value.sortBy,
        'sortFieldType': value.sortFieldType === undefined ? undefined : ((value.sortFieldType as Array<any>).map(AttributeTypeDTOToJSON)),
        'sortFieldAggregationMode': value.sortFieldAggregationMode,
        'sortDirection': value.sortDirection,
    };
}

/**
* @export
* @enum {string}
*/
export enum LeaderboardSortParamsDTOSortFieldAggregationModeEnum {
    Last = 'last',
    Min = 'min',
    Max = 'max',
    Average = 'average',
    Variance = 'variance',
    Auto = 'auto'
}
/**
* @export
* @enum {string}
*/
export enum LeaderboardSortParamsDTOSortDirectionEnum {
    Ascending = 'ascending',
    Descending = 'descending'
}


