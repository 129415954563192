/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExperimentTypeDTO,
    ExperimentTypeDTOFromJSON,
    ExperimentTypeDTOFromJSONTyped,
    ExperimentTypeDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface Experiment
 */
export interface Experiment {
    /**
     * 
     * @type {string}
     * @memberof Experiment
     */
    customId?: string;
    /**
     * 
     * @type {string}
     * @memberof Experiment
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Experiment
     */
    organizationId: string;
    /**
     * 
     * @type {string}
     * @memberof Experiment
     */
    organizationName: string;
    /**
     * 
     * @type {string}
     * @memberof Experiment
     */
    parentId: string;
    /**
     * 
     * @type {string}
     * @memberof Experiment
     */
    projectId: string;
    /**
     * 
     * @type {string}
     * @memberof Experiment
     */
    projectName: string;
    /**
     * 
     * @type {string}
     * @memberof Experiment
     */
    shortId: string;
    /**
     * 
     * @type {boolean}
     * @memberof Experiment
     */
    trashed: boolean;
    /**
     * 
     * @type {ExperimentTypeDTO}
     * @memberof Experiment
     */
    type: ExperimentTypeDTO;
}

export function ExperimentFromJSON(json: any): Experiment {
    return ExperimentFromJSONTyped(json, false);
}

export function ExperimentFromJSONTyped(json: any, ignoreDiscriminator: boolean): Experiment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'customId': !exists(json, 'customId') ? undefined : json['customId'],
        'id': json['id'],
        'organizationId': json['organizationId'],
        'organizationName': json['organizationName'],
        'parentId': json['parentId'],
        'projectId': json['projectId'],
        'projectName': json['projectName'],
        'shortId': json['shortId'],
        'trashed': json['trashed'],
        'type': ExperimentTypeDTOFromJSON(json['type']),
    };
}

export function ExperimentToJSON(value?: Experiment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'customId': value.customId,
        'id': value.id,
        'organizationId': value.organizationId,
        'organizationName': value.organizationName,
        'parentId': value.parentId,
        'projectId': value.projectId,
        'projectName': value.projectName,
        'shortId': value.shortId,
        'trashed': value.trashed,
        'type': ExperimentTypeDTOToJSON(value.type),
    };
}


