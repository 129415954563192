/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ProjectChartFiltersDTO,
    ProjectChartFiltersDTOFromJSON,
    ProjectChartFiltersDTOFromJSONTyped,
    ProjectChartFiltersDTOToJSON,
    ProjectChartMetricDTO,
    ProjectChartMetricDTOFromJSON,
    ProjectChartMetricDTOFromJSONTyped,
    ProjectChartMetricDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface ProjectChartDTO
 */
export interface ProjectChartDTO {
    /**
     * 
     * @type {ProjectChartFiltersDTO}
     * @memberof ProjectChartDTO
     */
    filters: ProjectChartFiltersDTO;
    /**
     * Chart id
     * @type {string}
     * @memberof ProjectChartDTO
     */
    id: string;
    /**
     * Chart metrics
     * @type {Array<ProjectChartMetricDTO>}
     * @memberof ProjectChartDTO
     */
    metrics: Array<ProjectChartMetricDTO>;
    /**
     * Chart name
     * @type {string}
     * @memberof ProjectChartDTO
     */
    name: string;
}

export function ProjectChartDTOFromJSON(json: any): ProjectChartDTO {
    return ProjectChartDTOFromJSONTyped(json, false);
}

export function ProjectChartDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectChartDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'filters': ProjectChartFiltersDTOFromJSON(json['filters']),
        'id': json['id'],
        'metrics': ((json['metrics'] as Array<any>).map(ProjectChartMetricDTOFromJSON)),
        'name': json['name'],
    };
}

export function ProjectChartDTOToJSON(value?: ProjectChartDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'filters': ProjectChartFiltersDTOToJSON(value.filters),
        'id': value.id,
        'metrics': ((value.metrics as Array<any>).map(ProjectChartMetricDTOToJSON)),
        'name': value.name,
    };
}


