export function getPathParts(path: string): string[] {
  return path.split('/').filter((part) => part !== '' && part !== '.');
}

export function getLastPathPart(path: string): string | undefined {
  const parts = getPathParts(path);

  return parts[parts.length - 1];
}

export function pathToSafeFileName(path: string): string {
  return path.replace(/\//g, '_');
}
