// Libs
import React from 'react';

import { bemBlock } from '../../modules/bem';
import { ScrollIntoView } from '../scroll-into-view/ScrollIntoView';
import { Dropdown } from '../dropdown/Dropdown';
import { DropdownMenu } from '../dropdown-menu/DropdownMenu';
import { DropdownItem } from '../dropdown-item/DropdownItem';
import {
  ActiveListElementChildrenProps,
  ActiveListElementProvider,
} from '../active-list-element-provider/ActiveListElementProvider';
import { DropdownToggle, DropdownToggleProps } from '../dropdown-toggle/DropdownToggle';

// Module
const block = bemBlock('n-Select');

type SelectPropsRaw<T> = {
  options: T[];
  selected?: T;
  placeholder?: string;
  getLabel?(item?: T): React.ReactNode;
  getKey?(item: T): string;
  onChange(item: T): void;
  dropdownDataRole?: string;
} & Omit<DropdownToggleProps, 'onChange'>;

export type SelectProps<T> = T extends string
  ? SelectPropsRaw<T>
  : SelectPropsRaw<T> & Required<Pick<SelectPropsRaw<T>, 'getKey' | 'getLabel'>>;

export function Select<T>({
  className,
  selected,
  placeholder,
  options,
  getLabel,
  getKey,
  onChange,
  dropdownDataRole,
  ...restProps
}: SelectProps<T>): React.ReactElement {
  const labelGetter = (getLabel || getFromString) as (item?: T) => React.ReactElement;
  const keyGetter = (getKey || getFromString) as (item?: T) => string;
  const toggleLabel = selected !== undefined ? labelGetter(selected) : placeholder;

  const toggleProps = {
    className: block({ extra: className }),
    label: toggleLabel,
    ...restProps,
  };

  return (
    <Dropdown toggle={<DropdownToggle {...toggleProps} />}>
      {({ collapse }) => (
        <DropdownMenu data-role={dropdownDataRole} onClick={collapse}>
          <ActiveListElementProvider
            entries={options}
            onKeyDown={(event: KeyboardEvent) => event.preventDefault()}
            onActivate={(event: KeyboardEvent, entry: T) => {
              onChange(entry);
              collapse();
            }}
          >
            {({ entry, select, isSelected }: ActiveListElementChildrenProps<T>) => (
              <ScrollIntoView key={keyGetter(entry)} enabled={isSelected}>
                <DropdownItem
                  active={isSelected}
                  label={labelGetter(entry)}
                  data-value={keyGetter(entry)}
                  onClick={() => onChange(entry)}
                  onMouseMove={select}
                />
              </ScrollIntoView>
            )}
          </ActiveListElementProvider>
        </DropdownMenu>
      )}
    </Dropdown>
  );
}

function getFromString(entry: string): string {
  return entry;
}
