// Module
export type Family = 'monospace' | 'sans-serif';
export type Size = 'xxs' | 'xs' | 'sm' | 'base' | 'md' | 'lg' | 'xl' | 'xxl';
export type LineHeight = Size | '1';
export type Weight = 'normal' | 'semibold' | 'bold';

export function familyClassName(value?: Family) {
  return value && `n--fontFamily-${value}`;
}

export function italicClassName(enabled?: boolean) {
  return enabled ? 'n--fontStyle-italic' : undefined;
}

export function lineHeightClassName(value?: LineHeight) {
  return value && `n--lineHeight-${value}`;
}

export function sizeClassName(value?: Size) {
  return value && `n--fontSize-${value}`;
}

export function weightClassName(value?: Weight) {
  return value && `n--fontWeight-${value}`;
}
