// Libs
import React from 'react';
import PropTypes from 'prop-types';
import { isFunction } from 'lodash';


// neptune-core-ui
// eslint-disable-next-line no-restricted-imports
import ncuiPropTypes from 'neptune-core-ui/helpers/prop-types';
// eslint-disable-next-line no-restricted-imports
import { getEventHandler } from 'neptune-core-ui/modules/events';
// eslint-disable-next-line no-restricted-imports
import { bemBlock } from 'neptune-core-ui/modules/bem';
// eslint-disable-next-line no-restricted-imports
import IconFA from 'neptune-core-ui/components/icon/IconFA';


// Module
import './Icon.less';

const propTypes = {
  className: PropTypes.string,
  flip: PropTypes.oneOf(['vertical', 'horizontal']),
  glyph: PropTypes.oneOfType([
    function (props, propName, componentName) {
      if (!/^FA\..+$/.test(props[propName])) {
        return new Error(`Invalid prop ${propName} (${props[propName]}) supplied to ${componentName}. Validation failed.`);
      }
    },
    PropTypes.oneOf([
      'action',
      'antenna',
      'bell',
      'bookmark',
      'bucket',
      'calendar',
      'chart-dots',
      'chart-line',
      'chart-smooth',
      'chat',
      'checkbox',
      'checkbox-fill',
      'checkbox-square',
      'checkbox-tick',
      'checked-thick',
      'chip',
      'clipboard',
      'clock',
      'code',
      'code-solid',
      'cog',
      'command',
      'credit-card',
      'cross',
      'cross-bold',
      'cross-thick',
      'crown',
      'database',
      'dot',
      'dot-line',
      'dots-v',
      'down-dir',
      'down-open',
      'download',
      'dropdown',
      'ellipsis',
      'exclamation',
      'exit',
      'file',
      'flask',
      'folder',
      'folder-open',
      'folder-upload',
      'fullscreen',
      'fullscreen-exit',
      'funnel',
      'gauges',
      'grid',
      'hash-solid',
      'head',
      'help',
      'home',
      'i',
      'i-circled',
      'left-double-arrow',
      'left-open',
      'level-up',
      'link-ext',
      'list',
      'list-bold',
      'magn-glass',
      'menu',
      'minus',
      'ok',
      'oooc',
      'padlock',
      'padlock-open',
      'pencil',
      'pin',
      'pin-oblique',
      'pin-oblique-o',
      'plus',
      'plus-circled',
      'question-mark',
      'report',
      'reset',
      'right-arrow',
      'right-double-arrow',
      'right-open',
      'rocket',
      'round-flask',
      'sort-down',
      'sort-up',
      'square',
      'square-circled',
      'star',
      'star-o',
      'tag',
      'theme-toggle',
      'thumbtack',
      'transition',
      'trash-bin',
      'triangle',
      'two-sheets',
      'up-dir',
      'up-open',
      'upload',
      'user',
      'warning',
      'worker-solid',
      'wrench',
      'zoom-out',
    ]),
  ]),
  monospace: PropTypes.bool,
  rotate: PropTypes.oneOf(['90', '180', '270']),
  size: PropTypes.oneOf(['sm', 'md', 'lg', '1x', '2x', '3x', '4x', '5x']),
  component: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  title: PropTypes.string,
  /**
   * Can be event handler function or object with two properties:
   *
   * - `handler`: event handler
   * - `payload`: object that will be passed to handler
   *
   * Event handler will be called with arguments:
   *
   * - `event`: [SyntheticEvent](https://facebook.github.io/react/docs/events.html)
   * - `params`: `object` (defaults to `{}` *empty object*)
   *   - `payload`: value of `onClick.payload`
   */
  onClick: ncuiPropTypes.eventHandler,
};

const defaultProps = {
  size: 'md',
  component: 'i',
};

const block = bemBlock('n-icon');


const Icon = ({
  glyph,
  ...props
}) => {
  if (glyph.startsWith('FA.')) {
    return (<IconFA glyph={glyph.slice(3)} {...props} />);
  }

  const {
    className,
    flip,
    monospace,
    rotate,
    size,
    component,
    title,
    onClick,
    ...restProps
  } = props;
  const onClickHandler = getEventHandler(onClick);
  const ownProps = {
    className: block({
      modifiers: {
        flip,
        monospace,
        rotate,
        size,
      },
      extra: [
        block(glyph),
        { clickable: isFunction(onClickHandler) },
        className,
      ],
    }),
    title,
    onClick: onClickHandler,
  };

  return React.createElement(component, {...ownProps, ...restProps}, null);
};

Icon.propTypes = propTypes;
Icon.defaultProps = defaultProps;


export default Icon;
