import { CustomIconDefinition } from '../types';

export const nepLeftOpen: CustomIconDefinition = {
  prefix: 'nep',
  iconName: 'left-open',
  icon: [
    1000,
    1000,
    [],
    'e15c',
    'M680.3 157.6L340.9 497 680.3 836.4 628.7 888 237.7 497 628.7 106 680.3 157.6Z',
  ],
};
