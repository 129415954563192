import React, { HTMLAttributes } from 'react';
import * as decorator from '../../modules/decorator';

import * as font from '../../modules/font';
import * as text from '../../modules/text';
import { joinClassNames } from '../../modules/bem';
import { WithNonInterferingComponent, createComponent } from '../../modules/utils';
import { makeRefProp } from '../make-ref-prop-util';
import './Code.less';

export type CodeProps<P extends object> = WithNonInterferingComponent<P, TextOwnProps> &
  HTMLAttributes<HTMLElement> & {
    className?: string;
    color?: text.Color;
    elementRef?: React.Ref<any>;
    fontWeight?: font.Weight;
    italic?: boolean;
    lineHeight?: font.LineHeight;
    opaque?: decorator.Opaque;
    wordBreak?: text.WordBreak;
  };

type TextOwnProps = {
  className: string;
};

export function Code<P extends object>({
  className,
  color,
  component = 'code',
  elementRef,
  fontWeight,
  italic,
  lineHeight,
  opaque,
  wordBreak,
  ...passProps
}: CodeProps<P>): React.ReactElement {
  const ownProps = {
    className: joinClassNames(
      className,
      'n-code',
      text.colorClassName(color),
      font.familyClassName('monospace'),
      font.italicClassName(italic),
      font.lineHeightClassName(lineHeight),
      decorator.opaqueClassName(opaque),
      font.weightClassName(fontWeight),
      text.transformClassName('none'),
      text.wordBreakClassName(wordBreak),
    ),
  };

  const ref = makeRefProp(component, elementRef);

  return createComponent(component, {
    ...ownProps,
    ...passProps,
    ...ref,
  });
}
