import React from 'react';
import { bemBlock } from '../../modules/bem';
import { LayoutRow, LayoutRowProps } from '../layout-row/LayoutRow';
import { ToggleSwitchContext } from './ToggleSwitchContext';
import { ToggleSwitchButton } from './ToggleSwitchButton';
import './ToggleSwitch.less';

const block = bemBlock('n-toggle-switch');

type ToggleSwitchProps = Omit<
  LayoutRowProps<{
    selected: string;
    disabled?: boolean;
  }>,
  'onChange'
> & {
  onChange: (value: string) => void;
};

function ToggleSwitchRaw({
  className,
  children,
  selected,
  disabled,
  onChange,
  ...rest
}: ToggleSwitchProps) {
  const switchContext = React.useMemo(
    () => ({ disabled, selectedValue: selected, onChange }),
    [disabled, selected, onChange],
  );

  return (
    <LayoutRow
      className={block({ extra: className })}
      withPadding="xs"
      spacedChildren="sm"
      span="auto"
      {...rest}
    >
      <ToggleSwitchContext.Provider value={switchContext}>{children}</ToggleSwitchContext.Provider>
    </LayoutRow>
  );
}

export const ToggleSwitch = Object.assign(ToggleSwitchRaw, { Button: ToggleSwitchButton });
