import React from 'react';
import { GlyphName } from '@neptune/shared/core-glyphs-domain';
import { DropdownItem } from '../dropdown-item/DropdownItem';
import { DropdownToggleInterface } from '../dropdown-toggle/DropdownToggle';

export const DropdownSubmenuToggle = ({
  onToggle,
  label,
  icon,
  'data-role': dataRole,
}: DropdownToggleInterface & { label: string; icon: GlyphName; 'data-role'?: string }) => (
  <DropdownItem
    submenu
    icon={icon}
    label={label}
    onClick={onToggle}
    width="100%"
    data-role={dataRole}
  />
);
