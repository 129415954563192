import { CustomIconDefinition } from '../types';

export const nepEye: CustomIconDefinition = {
  prefix: 'nep',
  iconName: 'eye',
  icon: [
    1000,
    1000,
    [],
    'e14d',
    'M498.7 838.1C493.8 838.1 489 838 483.9 837.9L483.7 837.9C282 831.9 105.5 705.9 34.1 516.8L28 500.2 34.2 483.6C69.9 388.5 132.7 307.7 215.9 249.9 299.1 192.1 396.4 161.5 497.3 161.5 502.6 161.5 508.1 161.6 513.4 161.8 715.2 167.8 891.7 293.8 963.1 482.8L969.4 499.4 963.2 516.1C927.5 611.3 864.5 692.1 780.9 749.9 697.6 807.6 600 838.1 498.7 838.1L498.7 838.1ZM486.7 743.5C492 743.7 497.3 743.8 502.6 743.8 659.4 743.8 802.2 649.5 866.4 503.5L868.2 499.5 866.4 495.5C835.7 425.9 786.3 366.8 723.6 324.5 660.7 281.9 587 258.3 510.7 256 506.9 255.9 503 255.8 499.2 255.8 420.1 255.8 343.7 278.4 278.4 321.2 213.5 363.8 162.6 424.2 131 496L129.2 500 131 504C161.7 573.6 211.1 632.7 273.8 675 336.7 717.5 410.3 741.2 486.7 743.5ZM502.3 613.1C420.1 613.1 353.2 546.2 353.2 464S420.1 314.9 502.3 314.9 651.4 381.8 651.4 464 584.5 613.1 502.3 613.1Z',
  ],
};
