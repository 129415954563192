// Libs
import React from 'react';
import { Icon, bemBlock } from '@neptune/shared/venus-ui';
import type { GlyphName } from '@neptune/shared/core-glyphs-domain';

// Module
import './ThemeToggle.less';

const block = bemBlock('theme-toggle');

const toggleConfig: Record<string, { glyph: GlyphName; toggleTo: string }> = {
  dark: {
    glyph: 'toggle-off',
    toggleTo: 'light',
  },
  light: {
    glyph: 'toggle-on',
    toggleTo: 'dark',
  },
};

type ThemeToggleProps = {
  className?: string;
  theme: keyof typeof toggleConfig;
  onClick(): void;
};

export const ThemeToggle = ({ className, theme, onClick }: ThemeToggleProps) => {
  const { glyph, toggleTo } = toggleConfig[theme];
  const title = `Change editor theme to ${toggleTo}`;

  return (
    <div className={block({ extra: className })} title={title} onClick={onClick}>
      <Icon className={block('icon')} glyph={glyph} />
    </div>
  );
};
