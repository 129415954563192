import React from 'react';
import { Text } from '../text/Text';
import * as font from '../../modules/font';

export type EmojiPickerProps = {
  unicode: string;
  size?: font.Size;
};

export const Emoji: React.FC<EmojiPickerProps> = ({ unicode, size = 'sm' }) => {
  return <Text size={size}>{parseNativeEmoji(unicode)}</Text>;
};

/**
 * Parses unified unicode string to native emoji.
 *
 * @param unified - represents the Emoji Unicode code points in string form connected with `-` (e.g. `1f3c4-1f3fc-200d-2642-fe0f`)
 * @returns The emoji string (e.g. `🏄🏼‍`) built from unicode code points (U+1F3C4 U+1F3FC U+200D U+2642 U+FE0F)
 *
 */
function parseNativeEmoji(unified: string): string {
  return unified
    .split('-')
    .map((hex) => String.fromCodePoint(parseInt(hex, 16)))
    .join('');
}
