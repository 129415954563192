import React from 'react';

/**
 *
 *
 * Do not create arrow functions (e: UIEvent) => { e.preventDefault() } on every rerender in N components use this one.
 *
 * @param {React.UIEvent} event - The React UI event to prevent default action in.
 * @returns {void} - This function does not return any value.
 *
 * @example
 * <Button onMouseDown={preventDefault}>Click me!</Button>
 */
export function preventDefault(event: React.UIEvent): void {
  event.preventDefault();
}
