/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AvatarSourceEnum,
    AvatarSourceEnumFromJSON,
    AvatarSourceEnumFromJSONTyped,
    AvatarSourceEnumToJSON,
} from './';

/**
 * 
 * @export
 * @interface RegisteredMemberInfoDTO
 */
export interface RegisteredMemberInfoDTO {
    /**
     * 
     * @type {AvatarSourceEnum}
     * @memberof RegisteredMemberInfoDTO
     */
    avatarSource: AvatarSourceEnum;
    /**
     * 
     * @type {string}
     * @memberof RegisteredMemberInfoDTO
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof RegisteredMemberInfoDTO
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof RegisteredMemberInfoDTO
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof RegisteredMemberInfoDTO
     */
    avatarUrl: string;
}

export function RegisteredMemberInfoDTOFromJSON(json: any): RegisteredMemberInfoDTO {
    return RegisteredMemberInfoDTOFromJSONTyped(json, false);
}

export function RegisteredMemberInfoDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): RegisteredMemberInfoDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'avatarSource': AvatarSourceEnumFromJSON(json['avatarSource']),
        'lastName': json['lastName'],
        'firstName': json['firstName'],
        'username': json['username'],
        'avatarUrl': json['avatarUrl'],
    };
}

export function RegisteredMemberInfoDTOToJSON(value?: RegisteredMemberInfoDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'avatarSource': AvatarSourceEnumToJSON(value.avatarSource),
        'lastName': value.lastName,
        'firstName': value.firstName,
        'username': value.username,
        'avatarUrl': value.avatarUrl,
    };
}


