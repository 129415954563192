const hexPattern = /^#([A-F0-9]{3}){1,2}$/i;

export function hexToRgbParts(hex: string): [number, number, number] | undefined {
  if (!hexPattern.test(hex)) {
    return;
  }

  const h = hex.substring(1);
  const l = h.length;
  const r = parseInt(h.substring(0, l / 3), 16);
  const g = parseInt(h.substring(l / 3, (2 * l) / 3), 16);
  const b = parseInt(h.substring((2 * l) / 3, (3 * l) / 3), 16);

  return [r, g, b];
}

export function hexToRgba(hex: string, alpha?: number): string | undefined {
  const parts = hexToRgbParts(hex);

  if (!parts) {
    return;
  }

  const [r, g, b] = parts;
  const formatPart = alpha !== undefined ? 'rgba' : 'rgb';
  const alphaPart = alpha !== undefined ? `, ${alpha}` : '';

  return `${formatPart}(${r}, ${g}, ${b}${alphaPart})`;
}

/**
 * Calculates the relative luminance of a color using Rec. 709 coefficients.
 *
 * References:
 * https://www.w3.org/TR/WCAG20/#relativeluminancedef
 * https://en.wikipedia.org/wiki/Luma_(video)#Rec._601_luma_versus_Rec._709_luma_coefficients
 */
export function getLuminance(color: string) {
  const [r, g, b] = hexToRgbParts(color) ?? [127, 127, 127];

  const prepareValueForLuminance = (color: number) => {
    const colorValue = color / 255;

    if (colorValue <= 0.04045) {
      return colorValue / 12.92;
    }

    return Math.pow((colorValue + 0.055) / 1.055, 2.4);
  };

  const luminance =
    0.2126 * prepareValueForLuminance(r) +
    0.7152 * prepareValueForLuminance(g) +
    0.0722 * prepareValueForLuminance(b);
  return luminance;
}

/**
 * Calculates the contrast ratio between two colors.
 *
 * References:
 *  - https://www.w3.org/TR/UNDERSTANDING-WCAG20/visual-audio-contrast-contrast.html#contrast-ratiodef
 */
export function contrastRatio(color1: string, color2: string) {
  const luminance1 = getLuminance(color1);
  const luminance2 = getLuminance(color2);

  const lighterColor = Math.max(luminance1, luminance2);
  const darkerColor = Math.min(luminance1, luminance2);

  return (lighterColor + 0.05) / (darkerColor + 0.05);
}

export const pickMostContrastingColor = (color: string, colors: string[] = ['#FFF', '#000']) => {
  const colorContrasts = colors.map((c) => contrastRatio(color, c));

  const maxContrast = Math.max(...colorContrasts);
  const maxContrastIndex = colorContrasts.indexOf(maxContrast);

  return colors[maxContrastIndex];
};
