/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ReportNodeGridDTO,
    ReportNodeGridDTOFromJSON,
    ReportNodeGridDTOFromJSONTyped,
    ReportNodeGridDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface ReportNodeDTO
 */
export interface ReportNodeDTO {
    /**
     * 
     * @type {ReportNodeGridDTO}
     * @memberof ReportNodeDTO
     */
    gridProperties?: ReportNodeGridDTO;
    /**
     * 
     * @type {string}
     * @memberof ReportNodeDTO
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ReportNodeDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportNodeDTO
     */
    type: ReportNodeDTOTypeEnum;
}

export function ReportNodeDTOFromJSON(json: any): ReportNodeDTO {
    return ReportNodeDTOFromJSONTyped(json, false);
}

export function ReportNodeDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportNodeDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'gridProperties': !exists(json, 'gridProperties') ? undefined : ReportNodeGridDTOFromJSON(json['gridProperties']),
        'id': json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'type': json['type'],
    };
}

export function ReportNodeDTOToJSON(value?: ReportNodeDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'gridProperties': ReportNodeGridDTOToJSON(value.gridProperties),
        'id': value.id,
        'name': value.name,
        'type': value.type,
    };
}

/**
* @export
* @enum {string}
*/
export enum ReportNodeDTOTypeEnum {
    Grid = 'grid'
}


