import { CustomIconDefinition } from '../types';

export const nepChartRough: CustomIconDefinition = {
  prefix: 'nep',
  iconName: 'chart-rough',
  icon: [
    1000,
    1000,
    [],
    'e00b',
    'M535 921.5L288.9 604.6 88.4 861.4 16.8 805.5 289.1 456.7 504.1 733.6 714.8 128 987.7 494.7 914.8 549 744.3 319.8',
  ],
};
