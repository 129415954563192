// Libs
import React from 'react';
import scrollIntoViewIfNeeded from 'scroll-into-view-if-needed';
import { usePrevious } from 'react-use';

export const ScrollIntoView: React.FC<{ enabled?: boolean }> = ({ enabled, children }) => {
  const ref = React.createRef<HTMLDivElement>();
  const lastEnabled = usePrevious(enabled);

  React.useEffect(() => {
    if (lastEnabled === false && enabled && ref?.current) {
      scrollIntoViewIfNeeded(ref.current, false);
    }
  }, [enabled, lastEnabled, ref]);

  return <div ref={ref}>{children}</div>;
};
