/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InvitationStatusEnumDTO,
    InvitationStatusEnumDTOFromJSON,
    InvitationStatusEnumDTOFromJSONTyped,
    InvitationStatusEnumDTOToJSON,
    InvitationTypeEnumDTO,
    InvitationTypeEnumDTOFromJSON,
    InvitationTypeEnumDTOFromJSONTyped,
    InvitationTypeEnumDTOToJSON,
    ProjectRoleDTO,
    ProjectRoleDTOFromJSON,
    ProjectRoleDTOFromJSONTyped,
    ProjectRoleDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface ProjectInvitationDTO
 */
export interface ProjectInvitationDTO {
    /**
     * 
     * @type {ProjectRoleDTO}
     * @memberof ProjectInvitationDTO
     */
    roleGrant: ProjectRoleDTO;
    /**
     * 
     * @type {string}
     * @memberof ProjectInvitationDTO
     */
    projectName: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectInvitationDTO
     */
    invitedBy: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectInvitationDTO
     */
    organizationName: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectInvitationDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectInvitationDTO
     */
    invitee: string;
    /**
     * 
     * @type {InvitationStatusEnumDTO}
     * @memberof ProjectInvitationDTO
     */
    status: InvitationStatusEnumDTO;
    /**
     * 
     * @type {Date}
     * @memberof ProjectInvitationDTO
     */
    createdAt: Date;
    /**
     * 
     * @type {InvitationTypeEnumDTO}
     * @memberof ProjectInvitationDTO
     */
    invitationType: InvitationTypeEnumDTO;
}

export function ProjectInvitationDTOFromJSON(json: any): ProjectInvitationDTO {
    return ProjectInvitationDTOFromJSONTyped(json, false);
}

export function ProjectInvitationDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectInvitationDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'roleGrant': ProjectRoleDTOFromJSON(json['roleGrant']),
        'projectName': json['projectName'],
        'invitedBy': json['invitedBy'],
        'organizationName': json['organizationName'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'invitee': json['invitee'],
        'status': InvitationStatusEnumDTOFromJSON(json['status']),
        'createdAt': (new Date(json['createdAt'])),
        'invitationType': InvitationTypeEnumDTOFromJSON(json['invitationType']),
    };
}

export function ProjectInvitationDTOToJSON(value?: ProjectInvitationDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'roleGrant': ProjectRoleDTOToJSON(value.roleGrant),
        'projectName': value.projectName,
        'invitedBy': value.invitedBy,
        'organizationName': value.organizationName,
        'id': value.id,
        'invitee': value.invitee,
        'status': InvitationStatusEnumDTOToJSON(value.status),
        'createdAt': (value.createdAt.toISOString()),
        'invitationType': InvitationTypeEnumDTOToJSON(value.invitationType),
    };
}


