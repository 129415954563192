/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    QueryLeaderboardParamsPaginationDTO,
    QueryLeaderboardParamsPaginationDTOFromJSON,
    QueryLeaderboardParamsPaginationDTOFromJSONTyped,
    QueryLeaderboardParamsPaginationDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface QueryLeaderboardParamsPaginationWithContinuationTokenDTO
 */
export interface QueryLeaderboardParamsPaginationWithContinuationTokenDTO {
    /**
     * 
     * @type {QueryLeaderboardParamsPaginationDTO}
     * @memberof QueryLeaderboardParamsPaginationWithContinuationTokenDTO
     */
    pagination: QueryLeaderboardParamsPaginationDTO;
    /**
     * 
     * @type {string}
     * @memberof QueryLeaderboardParamsPaginationWithContinuationTokenDTO
     */
    continuationToken?: string;
    /**
     * 
     * @type {string}
     * @memberof QueryLeaderboardParamsPaginationWithContinuationTokenDTO
     */
    beforeToken?: string;
}

export function QueryLeaderboardParamsPaginationWithContinuationTokenDTOFromJSON(json: any): QueryLeaderboardParamsPaginationWithContinuationTokenDTO {
    return QueryLeaderboardParamsPaginationWithContinuationTokenDTOFromJSONTyped(json, false);
}

export function QueryLeaderboardParamsPaginationWithContinuationTokenDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): QueryLeaderboardParamsPaginationWithContinuationTokenDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pagination': QueryLeaderboardParamsPaginationDTOFromJSON(json['pagination']),
        'continuationToken': !exists(json, 'continuationToken') ? undefined : json['continuationToken'],
        'beforeToken': !exists(json, 'beforeToken') ? undefined : json['beforeToken'],
    };
}

export function QueryLeaderboardParamsPaginationWithContinuationTokenDTOToJSON(value?: QueryLeaderboardParamsPaginationWithContinuationTokenDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pagination': QueryLeaderboardParamsPaginationDTOToJSON(value.pagination),
        'continuationToken': value.continuationToken,
        'beforeToken': value.beforeToken,
    };
}


