/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ComponentStatus
 */
export interface ComponentStatus {
    /**
     * 
     * @type {string}
     * @memberof ComponentStatus
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ComponentStatus
     */
    status: string;
}

export function ComponentStatusFromJSON(json: any): ComponentStatus {
    return ComponentStatusFromJSONTyped(json, false);
}

export function ComponentStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ComponentStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'status': json['status'],
    };
}

export function ComponentStatusToJSON(value?: ComponentStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'status': value.status,
    };
}


