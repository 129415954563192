/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AttributeDTO,
    AttributeDTOFromJSON,
    AttributeDTOFromJSONTyped,
    AttributeDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface QueryAttributesExperimentResultDTO
 */
export interface QueryAttributesExperimentResultDTO {
    /**
     * 
     * @type {Array<AttributeDTO>}
     * @memberof QueryAttributesExperimentResultDTO
     */
    attributes: Array<AttributeDTO>;
    /**
     * 
     * @type {string}
     * @memberof QueryAttributesExperimentResultDTO
     */
    experimentId: string;
    /**
     * 
     * @type {string}
     * @memberof QueryAttributesExperimentResultDTO
     */
    experimentShortId: string;
}

export function QueryAttributesExperimentResultDTOFromJSON(json: any): QueryAttributesExperimentResultDTO {
    return QueryAttributesExperimentResultDTOFromJSONTyped(json, false);
}

export function QueryAttributesExperimentResultDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): QueryAttributesExperimentResultDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attributes': ((json['attributes'] as Array<any>).map(AttributeDTOFromJSON)),
        'experimentId': json['experimentId'],
        'experimentShortId': json['experimentShortId'],
    };
}

export function QueryAttributesExperimentResultDTOToJSON(value?: QueryAttributesExperimentResultDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attributes': ((value.attributes as Array<any>).map(AttributeDTOToJSON)),
        'experimentId': value.experimentId,
        'experimentShortId': value.experimentShortId,
    };
}


