/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum PricingPlanDTO {
    Enterprise = 'enterprise',
    V1Free = 'v1_free',
    V1Team = 'v1_team',
    V1Academia = 'v1_academia',
    V1Individual = 'v1_individual',
    V2Trial = 'v2_trial',
    V2TrialOrganization = 'v2_trial_organization',
    V2Team = 'v2_team',
    Organization = 'organization',
    V2Academia = 'v2_academia',
    V2Individual = 'v2_individual',
    V3FreeIndividual = 'v3_free_individual',
    V3Team = 'v3_team',
    V3Scale = 'v3_scale',
    V3TrialScale = 'v3_trial_scale'
}

export function PricingPlanDTOFromJSON(json: any): PricingPlanDTO {
    return PricingPlanDTOFromJSONTyped(json, false);
}

export function PricingPlanDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): PricingPlanDTO {
    return json as PricingPlanDTO;
}

export function PricingPlanDTOToJSON(value?: PricingPlanDTO | null): any {
    return value as any;
}

