/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExperimentTypeDTO,
    ExperimentTypeDTOFromJSON,
    ExperimentTypeDTOFromJSONTyped,
    ExperimentTypeDTOToJSON,
    GitInfoDTO,
    GitInfoDTOFromJSON,
    GitInfoDTOFromJSONTyped,
    GitInfoDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface ExperimentCreationParams
 */
export interface ExperimentCreationParams {
    /**
     * 
     * @type {string}
     * @memberof ExperimentCreationParams
     */
    checkpointId?: string;
    /**
     * 
     * @type {string}
     * @memberof ExperimentCreationParams
     */
    customId?: string;
    /**
     * 
     * @type {GitInfoDTO}
     * @memberof ExperimentCreationParams
     */
    gitInfo?: GitInfoDTO;
    /**
     * 
     * @type {string}
     * @memberof ExperimentCreationParams
     */
    key?: string;
    /**
     * 
     * @type {string}
     * @memberof ExperimentCreationParams
     */
    notebookId?: string;
    /**
     * 
     * @type {string}
     * @memberof ExperimentCreationParams
     */
    parentId?: string;
    /**
     * 
     * @type {string}
     * @memberof ExperimentCreationParams
     */
    projectId?: string;
    /**
     * 
     * @type {string}
     * @memberof ExperimentCreationParams
     */
    projectIdentifier?: string;
    /**
     * 
     * @type {ExperimentTypeDTO}
     * @memberof ExperimentCreationParams
     */
    type?: ExperimentTypeDTO;
}

export function ExperimentCreationParamsFromJSON(json: any): ExperimentCreationParams {
    return ExperimentCreationParamsFromJSONTyped(json, false);
}

export function ExperimentCreationParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExperimentCreationParams {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'checkpointId': !exists(json, 'checkpointId') ? undefined : json['checkpointId'],
        'customId': !exists(json, 'customId') ? undefined : json['customId'],
        'gitInfo': !exists(json, 'gitInfo') ? undefined : GitInfoDTOFromJSON(json['gitInfo']),
        'key': !exists(json, 'key') ? undefined : json['key'],
        'notebookId': !exists(json, 'notebookId') ? undefined : json['notebookId'],
        'parentId': !exists(json, 'parentId') ? undefined : json['parentId'],
        'projectId': !exists(json, 'projectId') ? undefined : json['projectId'],
        'projectIdentifier': !exists(json, 'projectIdentifier') ? undefined : json['projectIdentifier'],
        'type': !exists(json, 'type') ? undefined : ExperimentTypeDTOFromJSON(json['type']),
    };
}

export function ExperimentCreationParamsToJSON(value?: ExperimentCreationParams | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'checkpointId': value.checkpointId,
        'customId': value.customId,
        'gitInfo': GitInfoDTOToJSON(value.gitInfo),
        'key': value.key,
        'notebookId': value.notebookId,
        'parentId': value.parentId,
        'projectId': value.projectId,
        'projectIdentifier': value.projectIdentifier,
        'type': ExperimentTypeDTOToJSON(value.type),
    };
}


