/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ServiceAccountDTO
 */
export interface ServiceAccountDTO {
    /**
     * 
     * @type {string}
     * @memberof ServiceAccountDTO
     */
    displayName: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceAccountDTO
     */
    name: string;
    /**
     * 
     * @type {Date}
     * @memberof ServiceAccountDTO
     */
    keyGeneratedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof ServiceAccountDTO
     */
    keyGeneratedBy?: string;
    /**
     * 
     * @type {Date}
     * @memberof ServiceAccountDTO
     */
    deactivated?: Date;
    /**
     * 
     * @type {string}
     * @memberof ServiceAccountDTO
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceAccountDTO
     */
    organizationId: string;
    /**
     * 
     * @type {Date}
     * @memberof ServiceAccountDTO
     */
    created: Date;
    /**
     * 
     * @type {string}
     * @memberof ServiceAccountDTO
     */
    createdBy: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceAccountDTO
     */
    deactivatedBy?: string;
}

export function ServiceAccountDTOFromJSON(json: any): ServiceAccountDTO {
    return ServiceAccountDTOFromJSONTyped(json, false);
}

export function ServiceAccountDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceAccountDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'displayName': json['displayName'],
        'name': json['name'],
        'keyGeneratedAt': !exists(json, 'keyGeneratedAt') ? undefined : (new Date(json['keyGeneratedAt'])),
        'keyGeneratedBy': !exists(json, 'keyGeneratedBy') ? undefined : json['keyGeneratedBy'],
        'deactivated': !exists(json, 'deactivated') ? undefined : (new Date(json['deactivated'])),
        'id': json['id'],
        'organizationId': json['organizationId'],
        'created': (new Date(json['created'])),
        'createdBy': json['createdBy'],
        'deactivatedBy': !exists(json, 'deactivatedBy') ? undefined : json['deactivatedBy'],
    };
}

export function ServiceAccountDTOToJSON(value?: ServiceAccountDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'displayName': value.displayName,
        'name': value.name,
        'keyGeneratedAt': value.keyGeneratedAt === undefined ? undefined : (value.keyGeneratedAt.toISOString()),
        'keyGeneratedBy': value.keyGeneratedBy,
        'deactivated': value.deactivated === undefined ? undefined : (value.deactivated.toISOString()),
        'id': value.id,
        'organizationId': value.organizationId,
        'created': (value.created.toISOString()),
        'createdBy': value.createdBy,
        'deactivatedBy': value.deactivatedBy,
    };
}


