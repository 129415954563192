import type { Userflow } from 'userflow.js';

declare global {
  interface Window {
    userflow?: Userflow;
  }
}

export const identify: Userflow['identify'] = async (userId, attributes, opts) => {
  if (!window.userflow) {
    return;
  }

  try {
    return await window.userflow.identify(userId, attributes, opts);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export const identifyAnonymous: Userflow['identifyAnonymous'] = async (attributes, opts) => {
  if (!window.userflow) {
    return;
  }

  try {
    return await window.userflow.identifyAnonymous(attributes, opts);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export const group: Userflow['group'] = async (groupId, attributes, opts) => {
  if (!window.userflow) {
    return;
  }

  try {
    return await window.userflow.group(groupId, attributes, opts);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export const track: Userflow['track'] = async (name, attributes, opts) => {
  if (!window.userflow) {
    return;
  }

  try {
    // Userflow event names must consist of only a-z, A-Z, 0-9, underscores, dashes, colons and spaces.
    const enhancedEventName = name.replace(/([^a-z0-9-:_ ]+)/gi, '-');
    return await window.userflow.track(enhancedEventName, attributes, opts);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export const reset: Userflow['reset'] = () => {
  if (!window.userflow) {
    return;
  }

  try {
    return window.userflow.reset();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export const updateUser: Userflow['updateUser'] = async (attributes) => {
  if (!window.userflow) {
    return;
  }

  try {
    return await window.userflow.updateUser(attributes);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};
