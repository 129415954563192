// Libs
import React from 'react';

import { bemBlock } from '../../modules/bem';
import type { GlyphName } from '@neptune/shared/core-glyphs-domain';
import { Icon } from '../icon/Icon';
import { LayoutColumn } from '../layout-column/LayoutColumn';
import { LayoutElement } from '../layout-element/LayoutElement';
import { LayoutRow, LayoutRowProps } from '../layout-row/LayoutRow';

// Module
import './Banner.less';

export type BannerType = 'info' | 'warning' | 'error' | 'success';
export type BannerSize = 'small' | 'normal' | 'jumbo';

type BannerWithTypeProps = Pick<
  LayoutRowProps<{}>,
  'alignItems' | 'alignSelf' | 'children' | 'className' | 'selfSpacing' | 'span' | 'width'
> & {
  size?: BannerSize;
  dataRole?: string;
};

export type BannerProps = BannerWithTypeProps & {
  dataRole?: string;
  glyph?: GlyphName;
  type: BannerType;
};

const block = bemBlock('n-Banner');

const glyphByType: Record<BannerType, GlyphName> = {
  info: 'info-circle',
  warning: 'exclamation-triangle',
  error: 'exclamation-circle',
  success: 'check-circle',
};

export class Banner extends React.PureComponent<BannerProps> {
  public static Info = (props: BannerWithTypeProps) => <Banner type="info" {...props} />;
  public static Warning = (props: BannerWithTypeProps) => <Banner type="warning" {...props} />;
  public static Error = (props: BannerWithTypeProps) => <Banner type="error" {...props} />;
  public static Success = (props: BannerWithTypeProps) => <Banner type="success" {...props} />;

  public static defaultProps = {
    size: 'normal',
    span: 'auto',
  };

  renderIcon = () => {
    const { glyph, size, type } = this.props;
    const glyphName = glyph || glyphByType[type];

    return (
      <LayoutElement className={block({ element: 'icon', modifiers: { size, type } })}>
        <Icon glyph={glyphName} fixedWidth />
      </LayoutElement>
    );
  };

  renderContent = () => {
    const { children, size } = this.props;
    return (
      <LayoutColumn
        className={block({ element: 'content', modifiers: { size } })}
        spacedChildren={size !== 'jumbo' ? 'sm' : 'md'}
        children={children}
      />
    );
  };

  getPadding(size?: BannerSize) {
    if (size === 'small') {
      return 'sm';
    }

    if (size === 'jumbo') {
      return 'lg';
    }

    return 'md';
  }

  render() {
    const { children, className, dataRole, size, type, ...passProps } = this.props;
    const Component = size === 'jumbo' ? LayoutColumn : LayoutRow;

    return (
      <Component
        data-role={dataRole}
        className={block({
          modifiers: { size, type },
          extra: [className],
        })}
        spacedChildren="sm"
        withPadding={this.getPadding(size)}
        alignItems={size === 'jumbo' || size === 'small' ? 'center' : 'start'}
        {...passProps}
      >
        {this.renderIcon()}
        {this.renderContent()}
      </Component>
    );
  }
}
