import React from 'react';
import { debounce, isFunction } from 'lodash';

import { useResizeDetector } from 'common/hoc/useResizeDetector';

import { bemBlock } from '../../modules/bem';
import { Layout } from '../layout/Layout';
import { Button } from '../button/Button';
import { Icon } from '../icon/Icon';
import { TabsMenu } from './TabsMenu';

import './ScrollableTabs.less';

type ScrollableTabsProps = {
  scrollAmount?: number;
};

const block = bemBlock('scrollable-tabs');

const ScrollableTabsRaw: React.FC<ScrollableTabsProps> = ({ children, scrollAmount = 80 }) => {
  const [navigationEnabled, setNavigationEnabled] = React.useState({ left: false, right: true });

  const { ref: innerRef, width: currentWidth, element: tabsWrapper } = useResizeDetector();

  const tabWrapperShrinked =
    tabsWrapper && currentWidth ? tabsWrapper.scrollWidth > currentWidth : false;

  const shouldShowLeftNavigationButton = tabWrapperShrinked && navigationEnabled.left;
  const shouldShowRightNavigationButton = tabWrapperShrinked && navigationEnabled.right;

  const updateScrollAtEdgeInfo = React.useCallback(
    (newPosition: number) => {
      if (tabsWrapper) {
        setNavigationEnabled({
          left: newPosition > 0,
          right: newPosition + tabsWrapper.clientWidth < tabsWrapper.scrollWidth,
        });
      }
    },
    [tabsWrapper],
  );

  const debouncedUpdateScrollAtEdgeInfo = React.useMemo(
    () =>
      debounce((newPosition: number) => {
        updateScrollAtEdgeInfo(newPosition);
      }, 100),
    [updateScrollAtEdgeInfo],
  );

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const scrollLeft = Math.ceil(event.currentTarget.scrollLeft);
    debouncedUpdateScrollAtEdgeInfo(scrollLeft);
  };

  const scrollForward = () => {
    tabsWrapper?.scrollBy({ left: scrollAmount, behavior: 'smooth' });
  };

  const scrollBackward = () => {
    tabsWrapper?.scrollBy({ left: -scrollAmount, behavior: 'smooth' });
  };

  return (
    <Layout.Row data-role="scrollable-tabs" className={block()} span="shrink">
      {shouldShowLeftNavigationButton && (
        <Button
          data-role="scroll-left"
          className={block({
            element: 'button',
            modifiers: { left: true },
          })}
          variant="basic"
          square
          onClick={scrollBackward}
        >
          <Icon glyph="chevron-left" />
        </Button>
      )}

      <Layout.Element
        span="shrink"
        data-role="tabs-wrapper"
        className={block('wrapper')}
        elementRef={isFunction(innerRef) ? innerRef : undefined}
        onScroll={handleScroll}
      >
        <TabsMenu>{children}</TabsMenu>
      </Layout.Element>

      {shouldShowRightNavigationButton && (
        <Button
          data-role="scroll-right"
          className={block({
            element: 'button',
            modifiers: { right: true },
          })}
          variant="basic"
          square
          onClick={scrollForward}
        >
          <Icon glyph="chevron-right" />
        </Button>
      )}
    </Layout.Row>
  );
};

export const ScrollableTabs = Object.assign(ScrollableTabsRaw, { Item: TabsMenu.Item });
