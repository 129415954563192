import { startsWithSegment } from 'router5-helpers';

import { AppRoute } from './types';

const ENTERPRISE_PREFIX = 'enterprise-context/';

const prefixRegExp = new RegExp(`^${ENTERPRISE_PREFIX}`);

/**
 * @deprecated use @neptune/shared/enterprise-context-util instead
 */
export function isEnterpriseRouteName(routeName = '') {
  return routeName.startsWith(ENTERPRISE_PREFIX);
}

/**
 * @deprecated use @neptune/shared/enterprise-context-util instead
 */
export function toNormalizedRouteName(routeName = '') {
  return routeName.replace(prefixRegExp, '');
}

/**
 * @deprecated use @neptune/shared/enterprise-context-util instead
 */
export function toEnterpriseRouteName(routeName = '') {
  if (isEnterpriseRouteName(routeName)) {
    return routeName;
  }

  return `${ENTERPRISE_PREFIX}${routeName}`;
}

/**
 * @deprecated use @neptune/shared/enterprise-context-util instead
 */
export function convertRouteName(routeName: string, isEnterprise: boolean) {
  return isEnterprise ? toEnterpriseRouteName(routeName) : toNormalizedRouteName(routeName);
}

/**
 * @deprecated use @neptune/shared/enterprise-context-util instead
 */
export function createNormalizedRouteTest(currentRouteName: string) {
  return startsWithSegment(toNormalizedRouteName(currentRouteName));
}

/**
 * @deprecated use @neptune/shared/enterprise-context-util instead
 */
export function makeEnterpriseRoute(route: AppRoute) {
  const { name, forwardTo, ...props } = route;
  const forward = forwardTo ? { forwardTo: toEnterpriseRouteName(forwardTo) } : {};
  return {
    ...props,
    name: toEnterpriseRouteName(name),
    ...forward,
  };
}
