import { CustomIconDefinition } from '../types';

export const nepChartDots: CustomIconDefinition = {
  prefix: 'nep',
  iconName: 'chart-dots',
  icon: [
    512,
    512,
    [],
    'e006',
    'M495.688 161.83c-10.874 10.874-23.787 16.312-38.74 16.312-4.077 0-10.874-1.36-20.389-4.078l-63.207 97.869c10.875 10.874 16.312 24.105 16.312 39.736 0 15.632-5.437 28.546-16.312 38.74-10.874 10.195-24.105 15.27-39.736 15.27-15.632 0-28.546-5.075-38.74-15.27-10.195-10.194-15.27-23.425-15.27-39.736 0-6.797 2.04-15.632 6.117-26.507l-73.402-61.168c-12.233 8.156-23.787 12.234-34.662 12.234-1.359 0-3.715-.317-7.113-.997-3.398-.68-5.754-.997-7.114-.997l-71.408 116.13c12.234 9.514 18.35 23.107 18.35 40.778 0 14.952-5.437 27.865-16.311 38.74-10.874 10.874-23.788 16.311-38.74 16.311-14.952 0-27.865-5.437-38.74-16.311C5.71 418.01.272 404.78.272 389.149c0-15.632 5.437-28.862 16.311-39.737 10.875-10.874 23.788-16.311 38.74-16.311 5.437 0 11.554 1.36 18.35 4.078l67.286-116.22c-13.593-10.874-20.39-25.147-20.39-42.817 0-14.953 5.437-27.866 16.312-38.74 10.874-10.875 24.104-16.312 39.736-16.312 15.632 0 28.545 5.437 38.74 16.312 10.195 10.874 15.27 23.787 15.27 38.74 0 6.796-2.04 15.631-6.117 26.506l73.402 63.207c12.233-8.156 23.787-12.234 34.661-12.234 4.078 0 10.875 1.36 20.39 4.078l63.207-97.869c-10.874-10.874-16.312-24.467-16.312-40.779 0-14.952 5.438-27.865 16.312-38.74C427.044 71.438 439.958 66 454.91 66c16.311 0 29.904 5.437 40.778 16.312C506.563 93.186 512 106.416 512 122.048c0 15.632-5.437 28.908-16.312 39.782z',
  ],
};
