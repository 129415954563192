/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AttributeTypeDTO,
    AttributeTypeDTOFromJSON,
    AttributeTypeDTOFromJSONTyped,
    AttributeTypeDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface LeaderboardGroupParamsDTO
 */
export interface LeaderboardGroupParamsDTO {
    /**
     * 
     * @type {Array<string>}
     * @memberof LeaderboardGroupParamsDTO
     */
    groupBy?: Array<string>;
    /**
     * 
     * @type {Array<AttributeTypeDTO>}
     * @memberof LeaderboardGroupParamsDTO
     */
    groupByFieldType?: Array<AttributeTypeDTO>;
    /**
     * 
     * @type {Array<string>}
     * @memberof LeaderboardGroupParamsDTO
     */
    groupByFieldAggregationMode?: Array<LeaderboardGroupParamsDTOGroupByFieldAggregationModeEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof LeaderboardGroupParamsDTO
     */
    openedGroups?: Array<string>;
}

export function LeaderboardGroupParamsDTOFromJSON(json: any): LeaderboardGroupParamsDTO {
    return LeaderboardGroupParamsDTOFromJSONTyped(json, false);
}

export function LeaderboardGroupParamsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): LeaderboardGroupParamsDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'groupBy': !exists(json, 'groupBy') ? undefined : json['groupBy'],
        'groupByFieldType': !exists(json, 'groupByFieldType') ? undefined : ((json['groupByFieldType'] as Array<any>).map(AttributeTypeDTOFromJSON)),
        'groupByFieldAggregationMode': !exists(json, 'groupByFieldAggregationMode') ? undefined : json['groupByFieldAggregationMode'],
        'openedGroups': !exists(json, 'openedGroups') ? undefined : json['openedGroups'],
    };
}

export function LeaderboardGroupParamsDTOToJSON(value?: LeaderboardGroupParamsDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'groupBy': value.groupBy,
        'groupByFieldType': value.groupByFieldType === undefined ? undefined : ((value.groupByFieldType as Array<any>).map(AttributeTypeDTOToJSON)),
        'groupByFieldAggregationMode': value.groupByFieldAggregationMode,
        'openedGroups': value.openedGroups,
    };
}

/**
* @export
* @enum {string}
*/
export enum LeaderboardGroupParamsDTOGroupByFieldAggregationModeEnum {
    Last = 'last',
    Min = 'min',
    Max = 'max',
    Average = 'average',
    Variance = 'variance',
    Auto = 'auto'
}


