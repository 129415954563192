import React from 'react';
import { Transition } from 'react-transition-group';
import { bemBlock } from '../../modules/bem';

import './DropdownMenuAnimation.less';

const block = bemBlock('dropdown-menu-animation');

export type DropdownMenuAnimationConfiguration = {
  direction: 'top-bottom' | 'bottom-top' | 'left-right';
  duration?: number;
  className?: string;
  minWidth?: number;
  maxWidth?: number;
};

type DropdownMenuAnimationProps = {
  in: boolean;
  onExited: () => void;
  children?: React.ReactNode;
  menuAnimation: DropdownMenuAnimationConfiguration;
};

export const DropdownMenuAnimation = React.memo<DropdownMenuAnimationProps>(
  ({ in: _in, children, onExited, menuAnimation }) => {
    const { duration = 650, className, direction, minWidth, maxWidth } = menuAnimation;

    return (
      <Transition
        exit
        appear
        in={_in}
        mountOnEnter
        unmountOnExit
        onExited={onExited}
        timeout={{ exit: duration, enter: 0 }}
      >
        {(state) => (
          <div
            className={block({ modifiers: { state, direction }, extra: className })}
            style={{ transitionDuration: `${duration}ms`, minWidth, maxWidth }}
          >
            {children}
          </div>
        )}
      </Transition>
    );
  },
);
