import React from 'react';
import { bemBlock } from '@neptune/shared/venus-ui';
import './KeycloakBenefits.less';
import checkmark from './assets/checkmark.svg';

type KeycloakBenefitsProps = {
  values: string[];
};

const block = bemBlock('keycloak-benefits');

export const KeycloakBenefits = ({ values }: KeycloakBenefitsProps) => (
  <ul className={block()}>
    {values.map((value) => (
      <li className={block('benefit-item')} key={value}>
        <img className={block('checkmark')} src={checkmark} alt="checkmark" />
        <span>{value}</span>
      </li>
    ))}
  </ul>
);
