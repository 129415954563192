// Libs
import React from 'react';

// Module
export const useLocalModal = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const open = React.useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);
  const close = React.useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  return {
    isOpen,
    open,
    close,
  };
};
