// Libs
import React from 'react';

// Module
type NavigateParams = {
  filePath: string | undefined;
  file: string | undefined;
};

type UseLocalPathManagementOutput = {
  currentPath: string;
  currentFile: string | undefined;
  handleNavigate(params: NavigateParams): void;
};

export const useLocalPathManagement = (): UseLocalPathManagementOutput => {
  const [currentFile, setCurrentFile] = React.useState<string>();
  const [currentFilePath, setCurrentFilePath] = React.useState<string>();

  const handleNavigate = React.useCallback(
    ({ filePath, file }: NavigateParams) => {
      setCurrentFile(file);
      setCurrentFilePath(filePath);
    },
    [setCurrentFile, setCurrentFilePath],
  );

  return {
    currentPath: currentFilePath ?? '.',
    currentFile,
    handleNavigate,
  };
};
