import {
  backendClient,
  OrganizationDTO,
  OrganizationTraitsSetDTO,
  OrganizationWithRoleDTO,
} from '@neptune/shared/core-apis-backend-domain';
import { AvatarSource, avatarSourceFromApiToDomain } from '@neptune/shared/core-users-domain';
import { superReaderRepository } from '@neptune/shared/core-super-reader-domain';
import { OrganizationRole, organizationRoleFromApiToDomain } from './organization-role';
import { OrganizationType, organizationTypeFromApiToDomain } from './organization-type';
import {
  PricingPlan,
  PricingPlanDefinition,
  pricingPlanFromApiToDomain,
  pricingPlanDefinitionFromApiToDomain,
} from './pricing-plan';
import { ProjectPrivacy, projectPrivacyFromApiToDomain } from './project-privacy';

export interface OrganizationTraits {
  activeProjects?: {
    currentQuota: number;
    maxQuota?: number;
    quotaEditable: boolean;
  };
  projectVisibilityRestricted?: {
    limitedTo: ProjectPrivacy[];
    disabledByAdmin: ProjectPrivacy[];
    limitedToByPlan: ProjectPrivacy[];
  };
  planUpgrade?: {
    availablePlans: PricingPlanDefinition[];
  };
  serviceAccounts?: {
    serviceAccountsLimit: number;
  };
  uiWorkspaceSwitching?: {};
  dashboardsLimits?: {
    maxDashboardsPerProject?: number;
  };
  tableViewsLimits?: {
    maxTableViewsPerProject?: number;
  };
  modelRegistryLimits?: {
    maxModelsPerProject?: number;
  };
}

export interface Organization {
  name: string;
  paymentStatus: string;
  avatarUrl: string;
  organizationType: OrganizationType;
  avatarSource: AvatarSource;
  info?: string;
  id: string;
  limitsEnforced: boolean;
  supportsMonitoringHoursLimits: boolean;
  pricingPlan: PricingPlan;
  pricingPlanDisplayName: string;
  created: Date;
  traits: OrganizationTraits;
  userflowIdentityHash: string;
}

export type OrganizationWithRole = Organization & {
  organization: Organization;
  userRole: OrganizationRole;

  // computed
  superUser?: boolean;
};

export async function listOrganizations(): Promise<OrganizationWithRole[]> {
  const result = await backendClient.listOrganizations();
  return result.map(organizationWithRoleFromApiToDomain);
}

export async function filterOrganizations(ids: string[]): Promise<Organization[]> {
  const result = await backendClient.filterOrganizations({ ids });
  return result.map(organizationFromApiToDomain);
}

export async function fetchOrganizationByName(organizationName: string): Promise<Organization> {
  const organization = await backendClient.getOrganization({ organization: organizationName });
  return organizationFromApiToDomain(organization);
}

export function organizationFromApiToDomain(source: OrganizationDTO): Organization {
  return {
    name: source.name,
    paymentStatus: source.paymentStatus,
    avatarUrl: source.avatarUrl,
    organizationType: organizationTypeFromApiToDomain(source.organizationType),
    avatarSource: avatarSourceFromApiToDomain(source.avatarSource),
    info: source.info,
    id: source.id,
    limitsEnforced: source.limitsEnforced,
    supportsMonitoringHoursLimits: source.supportsMonitoringHoursLimits,
    pricingPlan: pricingPlanFromApiToDomain(source.pricingPlan),
    pricingPlanDisplayName: source.pricingPlanDefinition.displayName,
    created: source.created,
    traits: organizationTraitsFromApiToDomain(source.traits),
    userflowIdentityHash: source.userflowIdentityHash,
  };
}

export function organizationWithRoleFromApiToDomain(
  source: OrganizationWithRoleDTO,
): OrganizationWithRole {
  const organization = organizationFromApiToDomain(source.organization);
  return {
    ...organization,
    organization,
    pricingPlanDisplayName: organization.pricingPlanDisplayName,
    userRole: source?.userRole && organizationRoleFromApiToDomain(source?.userRole),
    superUser: superReaderRepository.active,
  };
}

export function organizationTraitsFromApiToDomain(
  traits: OrganizationTraitsSetDTO,
): OrganizationTraits {
  return {
    ...(traits.activeProjects
      ? {
          activeProjects: {
            currentQuota: traits.activeProjects.currentQuota,
            maxQuota: traits.activeProjects.maxQuota,
            quotaEditable: traits.activeProjects.quotaEditable,
          },
        }
      : {}),
    ...(traits.projectVisibilityRestricted
      ? {
          projectVisibilityRestricted: {
            limitedTo: traits.projectVisibilityRestricted.limitedTo.map(
              projectPrivacyFromApiToDomain,
            ),
            disabledByAdmin: traits.projectVisibilityRestricted.disabledByAdmin.map(
              projectPrivacyFromApiToDomain,
            ),
            limitedToByPlan: traits.projectVisibilityRestricted.limitedToByPlan.map(
              projectPrivacyFromApiToDomain,
            ),
          },
        }
      : {}),
    ...(traits.planUpgrade
      ? {
          planUpgrade: {
            availablePlans: traits.planUpgrade.availablePlans.map(
              pricingPlanDefinitionFromApiToDomain,
            ),
          },
        }
      : {}),
    ...(traits.serviceAccounts
      ? {
          serviceAccounts: {
            serviceAccountsLimit: traits.serviceAccounts.serviceAccountsLimit,
          },
        }
      : {}),
    ...(traits.uiWorkspaceSwitching
      ? {
          uiWorkspaceSwitching: {},
        }
      : {}),
    ...(traits.dashboardsLimits
      ? {
          dashboardsLimits: {
            maxDashboardsPerProject: traits.dashboardsLimits.maxDashboardsPerProject,
          },
        }
      : {}),
    ...(traits.tableViewsLimits
      ? {
          tableViewsLimits: {
            maxTableViewsPerProject: traits.tableViewsLimits.maxTableViewsPerProject,
          },
        }
      : {}),

    ...(traits.modelRegistryLimits
      ? {
          modelRegistryLimits: {
            maxModelsPerProject: traits.modelRegistryLimits.maxModelsPerProject,
          },
        }
      : {}),
  };
}
