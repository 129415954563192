// Libs
import {
  isObject,
  isUndefined,
} from 'lodash';


// Module
export function getCellData({data, dataKey, children}) {
  if (isUndefined(data)) {
    return children;
  }
  if (isObject(data) && !isUndefined(dataKey)) {
    return data[dataKey];
  }
  return data;
}
