import { get, has } from 'lodash';

/* Returned on premise when license expires */
const LICENSE_EXPIRED_ERROR = 423;

export type ErrorDescriptor = {
  code: number | undefined;
  message: string | undefined;
  error: any;
};

export function createErrorDescriptor(error: any): ErrorDescriptor {
  /**
   * This code handle 3 cases
   * 1) someone passed API error object directly (it contains http error codes and "obj" property which is parsed
   * swagger error response
   * 2) someone passed only "error.obj" - parsed swagger response
   * 3) similar to a) but the "error.obj" doesn't have "code" and "message" properties and we have to infer it from other fields
   */

  // defaults / handles pt 2 and 3
  let code = get(error, 'code', get(error, 'status'));
  let message = get(error, 'message', get(error, 'statusText'));

  // handles pt 3
  if (has(error, 'obj')) {
    code = get(error, 'obj.code', code);
    message = get(error, 'obj.message', message);
  }

  return {
    code,
    message,
    error,
  };
}

export const handleError = (
  errorCode: number,
  payload: any,
  handler: (errDescriptor: ErrorDescriptor) => void,
) => {
  const errorDescriptor = createErrorDescriptor(payload);

  if (errorDescriptor.code === errorCode) {
    handler(errorDescriptor);
  }
};

export const checkLicenseError = (payload: any) => {
  const errorDescriptor = createErrorDescriptor(payload);
  return errorDescriptor.code === LICENSE_EXPIRED_ERROR;
};
