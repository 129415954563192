// Libs
import React from 'react';
import { Layout, LayoutColumnProps, bemBlock, Text } from '@neptune/shared/venus-ui';

// App

// Module
import './Testimonial.less';

type TestimonialProps = {
  photo?: string;
  name: string;
  title: string;
  citation: string;
} & LayoutColumnProps<{}>;

const block = bemBlock('team-trial-testimonial');

export const Testimonial: React.FC<TestimonialProps> = ({
  photo,
  name,
  title,
  citation,
  ...rest
}) => {
  return (
    <Layout.Column className={block()} spacedChildren="lg" span="auto" {...rest}>
      <Text size="base" color="disabled" fontWeight="semibold">
        &ldquo;{citation}&rdquo;
      </Text>
      <Layout.Row spacedChildren="md">
        {photo ? <img className={block('photo')} src={photo} /> : <span />}
        <Layout.Column spacedChildren="xxs">
          <Text className={block('name')} size="md" fontWeight="semibold">
            {name}
          </Text>
          <Text size="sm" fontWeight="semibold" color="text-alt">
            {title}
          </Text>
        </Layout.Column>
      </Layout.Row>
    </Layout.Column>
  );
};
