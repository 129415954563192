// Libs
import {
  isFinite,
  isString,
  pick,
} from 'lodash';


// Module
export function getValidProps(props, validPropsList) {
  return validPropsList ? pick(props, validPropsList) : props;
}


export function getWidthStyle(width) {
  if (isString(width)) {
    return {width};
  }
  if (isFinite(width)) {
    return {width: `${width}px`};
  }
}
