// Libs
import React, { MouseEventHandler } from 'react';

import { bemBlock } from '../../modules/bem';
import { Link } from '../link/Link';
import { Icon } from '../icon/Icon';

// Module
import './BreadcrumbsLevelUp.less';

export type BreadcrumbsLevelUpProps = {
  className?: string;
  /** If `onClick` property is not defined, `BreadcrumbsLevelUp` is in disabled state. */
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  title?: string;
};

const block = bemBlock('n-BreadcrumbsLevelUp');

/**
 * ## Import
 *
 * ```
 * import { Breadcrumbs } from '@neptune/shared/venus-ui';
 * ```
 *
 * And use `<Breadcrumbs.LevelUp />`
 */
export const BreadcrumbsLevelUp: React.FC<BreadcrumbsLevelUpProps> = ({
  className,
  onClick,
  ...props
}) => {
  return (
    <Link className={block({ extra: className })} onClick={onClick} {...props} disabled={!onClick}>
      <Icon glyph="level-up" />
    </Link>
  );
};
