import { Configuration, DefaultApi, GetUserAvatarRequest } from 'generated/backend-client';

import {
  buildEndpointUrl,
  defaultApiClientConfiguration,
} from '@neptune/shared/core-apis-common-domain';
import { backendHardcodedEndpoints } from './backend-hardcoded-endpoints';

export * from 'generated/backend-client/apis';
export * from 'generated/backend-client/models';

class BackendApi extends DefaultApi {
  /**
   * Static method, for retrieving URL to getUserAvatar GET endpoint.
   * Each such hardcoded endpoint should be defined in 'backend-hardcoded-endpoints'
   */
  getUserAvatarURL(requestParameters: GetUserAvatarRequest): string {
    return buildEndpointUrl(backendHardcodedEndpoints.getUserAvatar, requestParameters);
  }
}

export const backendClient = new BackendApi(new Configuration(defaultApiClientConfiguration));
