/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AttributeTypeDTO,
    AttributeTypeDTOFromJSON,
    AttributeTypeDTOFromJSONTyped,
    AttributeTypeDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface LeaderboardViewColumnDTO
 */
export interface LeaderboardViewColumnDTO {
    /**
     * 
     * @type {string}
     * @memberof LeaderboardViewColumnDTO
     */
    color?: string;
    /**
     * 
     * @type {AttributeTypeDTO}
     * @memberof LeaderboardViewColumnDTO
     */
    columnType: AttributeTypeDTO;
    /**
     * 
     * @type {string}
     * @memberof LeaderboardViewColumnDTO
     */
    displayName?: string;
    /**
     * 
     * @type {string}
     * @memberof LeaderboardViewColumnDTO
     */
    id: string;
    /**
     * 
     * @type {boolean}
     * @memberof LeaderboardViewColumnDTO
     */
    pinned: boolean;
    /**
     * 
     * @type {number}
     * @memberof LeaderboardViewColumnDTO
     */
    width: number;
    /**
     * 
     * @type {string}
     * @memberof LeaderboardViewColumnDTO
     */
    aggregationMode?: LeaderboardViewColumnDTOAggregationModeEnum;
    /**
     * 
     * @type {string}
     * @memberof LeaderboardViewColumnDTO
     */
    displayMode?: LeaderboardViewColumnDTODisplayModeEnum;
}

export function LeaderboardViewColumnDTOFromJSON(json: any): LeaderboardViewColumnDTO {
    return LeaderboardViewColumnDTOFromJSONTyped(json, false);
}

export function LeaderboardViewColumnDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): LeaderboardViewColumnDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'color': !exists(json, 'color') ? undefined : json['color'],
        'columnType': AttributeTypeDTOFromJSON(json['columnType']),
        'displayName': !exists(json, 'displayName') ? undefined : json['displayName'],
        'id': json['id'],
        'pinned': json['pinned'],
        'width': json['width'],
        'aggregationMode': !exists(json, 'aggregationMode') ? undefined : json['aggregationMode'],
        'displayMode': !exists(json, 'displayMode') ? undefined : json['displayMode'],
    };
}

export function LeaderboardViewColumnDTOToJSON(value?: LeaderboardViewColumnDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'color': value.color,
        'columnType': AttributeTypeDTOToJSON(value.columnType),
        'displayName': value.displayName,
        'id': value.id,
        'pinned': value.pinned,
        'width': value.width,
        'aggregationMode': value.aggregationMode,
        'displayMode': value.displayMode,
    };
}

/**
* @export
* @enum {string}
*/
export enum LeaderboardViewColumnDTOAggregationModeEnum {
    Last = 'last',
    Min = 'min',
    Max = 'max',
    Average = 'average',
    Variance = 'variance'
}
/**
* @export
* @enum {string}
*/
export enum LeaderboardViewColumnDTODisplayModeEnum {
    Scientific = 'scientific',
    Decimal = 'decimal',
    Auto = 'auto'
}


