export const DROPDOWN_Z_INDEX = 200;

// Find the top-most parent's zIndex this becomes handy when rendering tethered dropdown menus inside modals
export function getZIndex(target: Element, baseIndex: number = DROPDOWN_Z_INDEX) {
  let parent: HTMLElement | null = target.parentElement;
  let zIndex = 0;

  while (parent && parent.tagName !== 'BODY') {
    const parentZIndex = parseInt(getComputedStyle(parent).zIndex, 10);

    if (!isNaN(parentZIndex)) {
      zIndex = parentZIndex;
    }

    parent = parent.parentElement;
  }

  return zIndex + baseIndex;
}
