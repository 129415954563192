import React, { LabelHTMLAttributes } from 'react';
import { bemBlock } from '../../modules/bem';
import { Text } from '../text/Text';
import { Size } from '../../modules/font';
import { Layout } from '../layout/Layout';

import './RadioWithLabel.less';

type RadioWithLabelProps = {
  className?: string;
  id: string;
  name?: string;
  label: React.ReactNode;
  checked?: boolean;
  disabled?: boolean;
  value?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  'data-role'?: string;
  size?: Size;
  hasError?: boolean;
  inputRole?: string;
};

const block = bemBlock('radio-with-label');

export const RadioWithLabel: React.FC<RadioWithLabelProps> = ({
  className,
  id,
  name,
  label,
  checked = false,
  disabled = false,
  value,
  onChange,
  hasError,
  size = 'base',
  inputRole,
  'data-role': dataRole,
}) => {
  return (
    <Layout.Row
      inline
      alignItems="center"
      spacedChildren="sm"
      className={block({ extra: className })}
    >
      <input
        className={block({ element: 'native-input', modifiers: { error: hasError } })}
        type="radio"
        data-has-error={hasError}
        name={name}
        disabled={disabled}
        id={id}
        checked={checked}
        value={value}
        onChange={onChange}
        data-role={inputRole}
      />
      <Text<LabelHTMLAttributes<{}>>
        className={block({ element: 'label', modifiers: { disabled } })}
        htmlFor={id}
        data-role={dataRole}
        size={size}
        component="label"
        color={disabled ? 'disabled' : undefined}
      >
        {label}
      </Text>
    </Layout.Row>
  );
};
