import { AvatarSourceEnum } from '@neptune/shared/core-apis-backend-domain';

export enum AvatarSource {
  default = 'default',
  thirdParty = 'thirdParty',
  user = 'user',
  unicode = 'unicode',
  inherited = 'inherited',

  notSupported = 'notSupported',
}

export function avatarSourceFromApiToDomain(source?: AvatarSourceEnum): AvatarSource {
  switch (source) {
    case AvatarSourceEnum.Default:
      return AvatarSource.default;

    case AvatarSourceEnum.Inherited:
      return AvatarSource.inherited;

    case AvatarSourceEnum.ThirdParty:
      return AvatarSource.thirdParty;

    case AvatarSourceEnum.User:
      return AvatarSource.user;

    case AvatarSourceEnum.Unicode:
      return AvatarSource.unicode;

    default:
      return AvatarSource.notSupported;
  }
}

export function avatarSourceFromDomainToApi(source?: AvatarSource): AvatarSourceEnum {
  switch (source) {
    case AvatarSource.default:
      return AvatarSourceEnum.Default;

    case AvatarSource.inherited:
      return AvatarSourceEnum.Inherited;

    case AvatarSource.thirdParty:
      return AvatarSourceEnum.ThirdParty;

    case AvatarSource.user:
      return AvatarSourceEnum.User;

    case AvatarSource.unicode:
      return AvatarSourceEnum.Unicode;

    default:
      return AvatarSourceEnum.Default;
  }
}
