// Libs
import React from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line no-restricted-imports
import classNames from 'classnames';
import {
  isArray,
  isEmpty,
} from 'lodash';

// neptune-core-ui
// eslint-disable-next-line no-restricted-imports
import ncuiPropTypes from 'neptune-core-ui/helpers/prop-types';
// eslint-disable-next-line no-restricted-imports
import TableRow from 'neptune-core-ui/components/table/TableRow';

// Module
import './TableBody.less';


const propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,

  /**
   * Array of cell definitions.
   *
   * Cell definition is an object (see [TableCell](#tablecell) for more information):
   */
  columns: PropTypes.arrayOf(PropTypes.shape({
    align: PropTypes.oneOf(['left', 'center', 'right']),
    className: PropTypes.string,
    colSpan: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    dataKey: PropTypes.string,
    header: PropTypes.bool,
    renderer: PropTypes.func,
    renderParams: PropTypes.any,
    width: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    onClick: ncuiPropTypes.eventHandler,
  })),

  /**
   * Collection of objects or arrays of simple values
   * (see [TableRow](#tablerow) and [TableCell](#tablecell))
   */
  data: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ])),
  hoverableRows: PropTypes.bool,
  rowClassName: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
    PropTypes.func,
  ]),

  /** function to calculate rowId based on rowData and index, (rowData, index) => key */
  getRowKey: PropTypes.func,

  /** onClick event handler for generated rows (see [TableRow](#tablerow)) */
  onRowClick: ncuiPropTypes.eventHandler,

  /** onMouseOut event handler for generated rows (see [TableRow](#tablerow)) */
  onRowMouseOut: ncuiPropTypes.eventHandler,

  /** onMouseOver event handler for generated rows (see [TableRow](#tablerow)) */
  onRowMouseOver: ncuiPropTypes.eventHandler,
};


const TableBody = ({
  children,
  className,
  columns,
  data,
  hoverableRows,
  rowClassName,
  getRowKey,
  onRowClick,
  onRowMouseOut,
  onRowMouseOver,
}) => {

  const cssClasses = classNames(
    'n-table-body',
    className,
  );

  const generatedContent = renderRows(
    data,
    {
      className: rowClassName,
      columns,
      hoverable: hoverableRows,
      onClick: onRowClick,
      onMouseOut: onRowMouseOut,
      onMouseOver: onRowMouseOver,
    },
    getRowKey,
  );

  return (
    <tbody className={cssClasses}>
      {generatedContent}
      {children}
    </tbody>
  );
};

TableBody.propTypes = propTypes;


function renderRows(data, rowProps = {}, getRowKey = defaultGetRowKey) {
  if (!isArray(data) || isEmpty(data)) {
    return null;
  }

  return data.map((rowData, index) => (
    <TableRow
      key={getRowKey(rowData, index)}
      data-row={index}
      {...rowProps}
      data={rowData}
    />
  ));
}

function defaultGetRowKey (data, index) {
  return index;
}

export default TableBody;
