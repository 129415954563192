/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PriceTierDTO,
    PriceTierDTOFromJSON,
    PriceTierDTOFromJSONTyped,
    PriceTierDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface GraduatedPricingDTO
 */
export interface GraduatedPricingDTO {
    /**
     * 
     * @type {number}
     * @memberof GraduatedPricingDTO
     */
    currentValue: number;
    /**
     * 
     * @type {number}
     * @memberof GraduatedPricingDTO
     */
    stepSize: number;
    /**
     * 
     * @type {string}
     * @memberof GraduatedPricingDTO
     */
    unitSymbol: string;
    /**
     * 
     * @type {Array<PriceTierDTO>}
     * @memberof GraduatedPricingDTO
     */
    priceTiers: Array<PriceTierDTO>;
}

export function GraduatedPricingDTOFromJSON(json: any): GraduatedPricingDTO {
    return GraduatedPricingDTOFromJSONTyped(json, false);
}

export function GraduatedPricingDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): GraduatedPricingDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'currentValue': json['currentValue'],
        'stepSize': json['stepSize'],
        'unitSymbol': json['unitSymbol'],
        'priceTiers': ((json['priceTiers'] as Array<any>).map(PriceTierDTOFromJSON)),
    };
}

export function GraduatedPricingDTOToJSON(value?: GraduatedPricingDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'currentValue': value.currentValue,
        'stepSize': value.stepSize,
        'unitSymbol': value.unitSymbol,
        'priceTiers': ((value.priceTiers as Array<any>).map(PriceTierDTOToJSON)),
    };
}


