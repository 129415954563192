/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AttributesHolderIdentifier,
    AttributesHolderIdentifierFromJSON,
    AttributesHolderIdentifierFromJSONTyped,
    AttributesHolderIdentifierToJSON,
} from './';

/**
 * 
 * @export
 * @interface CustomMetric
 */
export interface CustomMetric {
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomMetric
     */
    attributes: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CustomMetric
     */
    customId: string;
    /**
     * 
     * @type {string}
     * @memberof CustomMetric
     */
    customYFormula?: string;
    /**
     * 
     * @type {AttributesHolderIdentifier}
     * @memberof CustomMetric
     */
    holder: AttributesHolderIdentifier;
    /**
     * 
     * @type {string}
     * @memberof CustomMetric
     */
    lineage?: CustomMetricLineageEnum;
}

export function CustomMetricFromJSON(json: any): CustomMetric {
    return CustomMetricFromJSONTyped(json, false);
}

export function CustomMetricFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomMetric {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attributes': json['attributes'],
        'customId': json['customId'],
        'customYFormula': !exists(json, 'customYFormula') ? undefined : json['customYFormula'],
        'holder': AttributesHolderIdentifierFromJSON(json['holder']),
        'lineage': !exists(json, 'lineage') ? undefined : json['lineage'],
    };
}

export function CustomMetricToJSON(value?: CustomMetric | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attributes': value.attributes,
        'customId': value.customId,
        'customYFormula': value.customYFormula,
        'holder': AttributesHolderIdentifierToJSON(value.holder),
        'lineage': value.lineage,
    };
}

/**
* @export
* @enum {string}
*/
export enum CustomMetricLineageEnum {
    FULL = 'FULL',
    NONE = 'NONE'
}


