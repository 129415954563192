import React from 'react';
import { isNumber } from 'lodash';

export type UseActiveItemParams<T> = {
  entries: T[];
  query: string | undefined;
  initialSelectedCursor?: number;
  onSelect?: (event: React.MouseEvent | KeyboardEvent, entry: T, index: number) => void;
  onActivate?: (event: KeyboardEvent, entry: T, index: number) => void;
  onKeyDown?: (event: KeyboardEvent) => void;
};

const INITIAL_SELECTED_CURSOR = 0;

export const useActiveItem = <T>({
  entries,
  query,
  initialSelectedCursor = INITIAL_SELECTED_CURSOR,
  onSelect,
  onActivate,
  onKeyDown,
}: UseActiveItemParams<T>) => {
  const [cursor, setCursor] = React.useState<number>(initialSelectedCursor);
  const prevQuery = React.useRef<string | undefined>(query);

  const select = React.useCallback(
    (event: React.MouseEvent | KeyboardEvent, newSelected: number) => {
      // ensure that element to be selected exist
      const toSelect = entries[newSelected] != null ? newSelected : 0;

      if (cursor === toSelect) {
        return;
      }

      const newToSelect = isNumber(toSelect) ? toSelect : 0;

      setCursor(newToSelect);
      onSelect?.(event, entries[newToSelect], newToSelect);
    },
    [entries, onSelect, cursor],
  );

  const next = React.useCallback(
    (event: KeyboardEvent) => {
      const index = Math.min(cursor + 1, entries.length - 1);
      select(event, index);
    },
    [entries.length, select, cursor],
  );

  const prev = React.useCallback(
    (event: KeyboardEvent) => {
      const index = Math.max(cursor - 1, 0);
      select(event, index);
    },
    [cursor, select],
  );

  const handleOnActivate = React.useCallback(
    (event: KeyboardEvent) => {
      const selectedEntry = entries[cursor] ?? entries[initialSelectedCursor];

      if (selectedEntry) {
        onActivate?.(event, selectedEntry, cursor);
      }
    },
    [entries, onActivate, cursor, initialSelectedCursor],
  );

  if (query !== prevQuery.current) {
    prevQuery.current = query;
    setCursor(initialSelectedCursor);
  }

  React.useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      switch (event.key) {
        case 'ArrowDown':
          next(event);
          onKeyDown?.(event);
          break;

        case 'ArrowUp':
          prev(event);
          onKeyDown?.(event);
          break;

        case 'Enter':
          handleOnActivate(event);
          onKeyDown?.(event);
          break;

        default:
          break;
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleOnActivate, next, onKeyDown, prev]);

  return { cursor, select };
};
