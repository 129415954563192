import React from 'react';
import {
  auditTime,
  distinctUntilChanged,
  map,
  mapTo,
  merge,
  mergeMap,
  Observable,
  of,
  scan,
  shareReplay,
  startWith,
  Subject,
  take,
  timer,
} from 'rxjs';

type ControlAction = {
  value: number;
  delay?: number;
};

type UnderEditContextType = {
  underEdit: boolean;
  underEdit$: Observable<boolean>;
  startEdit: () => void;
  stopEdit: () => void;
  startEditWindow: (windowCloseDelay?: number) => void;
  stopEditWindow: () => void;
};

// TODO: make it composable

export const UnderEditContext = React.createContext<UnderEditContextType>({
  underEdit: false,
  underEdit$: of(false),
  startEdit: () => {},
  stopEdit: () => {},
  startEditWindow: () => {},
  stopEditWindow: () => {},
});

export const UnderEditContextProvider: React.FC = ({ children }) => {
  const control$ = React.useRef(new Subject<ControlAction>());

  const endEditWindow$ = React.useRef(new Subject<void>());

  const [underEdit, setUnderEdit] = React.useState(false);

  const editRef$ = React.useRef(
    control$.current.pipe(
      mergeMap(({ value, delay }) => {
        if (delay === undefined) {
          return of(value);
        }

        return merge(timer(delay), endEditWindow$.current).pipe(
          take(1),
          mapTo(-value),
          startWith(value),
        );
      }),
      scan((refCount, value) => Math.max(0, refCount + value), 0),
      map((refCount) => refCount > 0),
      auditTime(300),
      distinctUntilChanged(),
      shareReplay(1),
    ),
  );

  React.useEffect(() => {
    const subscription = editRef$.current.subscribe(setUnderEdit);
    control$.current.next({ value: 0 });

    return () => subscription.unsubscribe();
  }, []);

  const startEdit = React.useCallback(() => {
    control$.current.next({ value: 1 });
  }, []);

  const stopEdit = React.useCallback(() => {
    control$.current.next({ value: -1 });
  }, []);

  const startEditWindow = React.useCallback((windowCloseDelay) => {
    control$.current.next({ value: 1, delay: windowCloseDelay });
  }, []);

  const stopEditWindow = React.useCallback(() => {
    endEditWindow$.current.next();
  }, []);

  const contextValue = React.useMemo(
    () => ({
      underEdit,
      underEdit$: editRef$.current,
      startEdit,
      stopEdit,
      startEditWindow,
      stopEditWindow,
    }),
    [underEdit, startEdit, stopEdit, startEditWindow, stopEditWindow],
  );

  return React.createElement(UnderEditContext.Provider, { value: contextValue }, children);
};
