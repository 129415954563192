import michaelPhoto from './assets/michael-ulin.png';
import maciejPhoto from './assets/maciej-bartczak.png';

type PersonData = {
  name: string;
  title: string;
  citation: string;
  photo?: string;
};

export const testimonialsData: Record<'1' | '2', PersonData> = {
  '1': {
    name: 'Michael Ulin',
    title: 'VP, Machine Learning @Zesty.ai',
    photo: michaelPhoto,
    citation:
      'Being able to see my team’s work results any time I need makes it effortless to track progress and enables easier coordination.',
  },
  '2': {
    name: 'Maciej Bartczak',
    title: 'Research Lead @Banacha Street',
    photo: maciejPhoto,
    citation:
      'Neptune is making it easy to share results with my teammates. I’m sending a link and telling what to look at, or I’m building a View on the dashboard.',
  },
};
