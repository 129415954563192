import React from 'react';
import { Merge } from 'common/utility-types';
import { bemBlock } from '@neptune/shared/venus-ui';

import './ErrorText.less';

const block = bemBlock('error-text');

export const ErrorText: React.FC<
  Merge<React.HTMLAttributes<HTMLSpanElement>, { active?: boolean }>
> = ({ active = true, className, ...restSpanProps }) => {
  return <span className={block({ modifiers: { active }, extra: className })} {...restSpanProps} />;
};
