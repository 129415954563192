/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OutputImageDTO
 */
export interface OutputImageDTO {
    /**
     * 
     * @type {string}
     * @memberof OutputImageDTO
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputImageDTO
     */
    imageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputImageDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputImageDTO
     */
    thumbnailUrl?: string;
}

export function OutputImageDTOFromJSON(json: any): OutputImageDTO {
    return OutputImageDTOFromJSONTyped(json, false);
}

export function OutputImageDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OutputImageDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description': !exists(json, 'description') ? undefined : json['description'],
        'imageUrl': !exists(json, 'imageUrl') ? undefined : json['imageUrl'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'thumbnailUrl': !exists(json, 'thumbnailUrl') ? undefined : json['thumbnailUrl'],
    };
}

export function OutputImageDTOToJSON(value?: OutputImageDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': value.description,
        'imageUrl': value.imageUrl,
        'name': value.name,
        'thumbnailUrl': value.thumbnailUrl,
    };
}


