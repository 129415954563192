/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AvatarSourceEnum,
    AvatarSourceEnumFromJSON,
    AvatarSourceEnumFromJSONTyped,
    AvatarSourceEnumToJSON,
    OrganizationRoleDTO,
    OrganizationRoleDTOFromJSON,
    OrganizationRoleDTOFromJSONTyped,
    OrganizationRoleDTOToJSON,
    OrganizationTypeDTO,
    OrganizationTypeDTOFromJSON,
    OrganizationTypeDTOFromJSONTyped,
    OrganizationTypeDTOToJSON,
    ProjectCodeAccessDTO,
    ProjectCodeAccessDTOFromJSON,
    ProjectCodeAccessDTOFromJSONTyped,
    ProjectCodeAccessDTOToJSON,
    ProjectRoleDTO,
    ProjectRoleDTOFromJSON,
    ProjectRoleDTOFromJSONTyped,
    ProjectRoleDTOToJSON,
    ProjectVisibilityDTO,
    ProjectVisibilityDTOFromJSON,
    ProjectVisibilityDTOFromJSONTyped,
    ProjectVisibilityDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface ProjectWithRoleDTO
 */
export interface ProjectWithRoleDTO {
    /**
     * 
     * @type {ProjectCodeAccessDTO}
     * @memberof ProjectWithRoleDTO
     */
    codeAccess: ProjectCodeAccessDTO;
    /**
     * 
     * @type {string}
     * @memberof ProjectWithRoleDTO
     */
    avatarUrl: string;
    /**
     * 
     * @type {OrganizationTypeDTO}
     * @memberof ProjectWithRoleDTO
     */
    organizationType: OrganizationTypeDTO;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectWithRoleDTO
     */
    featured: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProjectWithRoleDTO
     */
    organizationName: string;
    /**
     * 
     * @type {AvatarSourceEnum}
     * @memberof ProjectWithRoleDTO
     */
    avatarSource: AvatarSourceEnum;
    /**
     * 
     * @type {number}
     * @memberof ProjectWithRoleDTO
     */
    version: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectWithRoleDTO
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectWithRoleDTO
     */
    projectKey: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectWithRoleDTO
     */
    organizationId: string;
    /**
     * 
     * @type {number}
     * @memberof ProjectWithRoleDTO
     */
    userCount: number;
    /**
     * 
     * @type {ProjectVisibilityDTO}
     * @memberof ProjectWithRoleDTO
     */
    visibility: ProjectVisibilityDTO;
    /**
     * 
     * @type {string}
     * @memberof ProjectWithRoleDTO
     */
    displayClass?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectWithRoleDTO
     */
    name: string;
    /**
     * 
     * @type {Date}
     * @memberof ProjectWithRoleDTO
     */
    lastActivity: Date;
    /**
     * 
     * @type {string}
     * @memberof ProjectWithRoleDTO
     */
    description?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectWithRoleDTO
     */
    archived: boolean;
    /**
     * 
     * @type {Date}
     * @memberof ProjectWithRoleDTO
     */
    timeOfCreation: Date;
    /**
     * 
     * @type {OrganizationRoleDTO}
     * @memberof ProjectWithRoleDTO
     */
    userRoleInOrganization?: OrganizationRoleDTO;
    /**
     * 
     * @type {ProjectRoleDTO}
     * @memberof ProjectWithRoleDTO
     */
    userRoleInProject: ProjectRoleDTO;
    /**
     * 
     * @type {string}
     * @memberof ProjectWithRoleDTO
     */
    backgroundUrl?: string;
}

export function ProjectWithRoleDTOFromJSON(json: any): ProjectWithRoleDTO {
    return ProjectWithRoleDTOFromJSONTyped(json, false);
}

export function ProjectWithRoleDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectWithRoleDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'codeAccess': ProjectCodeAccessDTOFromJSON(json['codeAccess']),
        'avatarUrl': json['avatarUrl'],
        'organizationType': OrganizationTypeDTOFromJSON(json['organizationType']),
        'featured': json['featured'],
        'organizationName': json['organizationName'],
        'avatarSource': AvatarSourceEnumFromJSON(json['avatarSource']),
        'version': json['version'],
        'id': json['id'],
        'projectKey': json['projectKey'],
        'organizationId': json['organizationId'],
        'userCount': json['userCount'],
        'visibility': ProjectVisibilityDTOFromJSON(json['visibility']),
        'displayClass': !exists(json, 'displayClass') ? undefined : json['displayClass'],
        'name': json['name'],
        'lastActivity': (new Date(json['lastActivity'])),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'archived': json['archived'],
        'timeOfCreation': (new Date(json['timeOfCreation'])),
        'userRoleInOrganization': !exists(json, 'userRoleInOrganization') ? undefined : OrganizationRoleDTOFromJSON(json['userRoleInOrganization']),
        'userRoleInProject': ProjectRoleDTOFromJSON(json['userRoleInProject']),
        'backgroundUrl': !exists(json, 'backgroundUrl') ? undefined : json['backgroundUrl'],
    };
}

export function ProjectWithRoleDTOToJSON(value?: ProjectWithRoleDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'codeAccess': ProjectCodeAccessDTOToJSON(value.codeAccess),
        'avatarUrl': value.avatarUrl,
        'organizationType': OrganizationTypeDTOToJSON(value.organizationType),
        'featured': value.featured,
        'organizationName': value.organizationName,
        'avatarSource': AvatarSourceEnumToJSON(value.avatarSource),
        'version': value.version,
        'id': value.id,
        'projectKey': value.projectKey,
        'organizationId': value.organizationId,
        'userCount': value.userCount,
        'visibility': ProjectVisibilityDTOToJSON(value.visibility),
        'displayClass': value.displayClass,
        'name': value.name,
        'lastActivity': (value.lastActivity.toISOString()),
        'description': value.description,
        'archived': value.archived,
        'timeOfCreation': (value.timeOfCreation.toISOString()),
        'userRoleInOrganization': OrganizationRoleDTOToJSON(value.userRoleInOrganization),
        'userRoleInProject': ProjectRoleDTOToJSON(value.userRoleInProject),
        'backgroundUrl': value.backgroundUrl,
    };
}


