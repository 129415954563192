/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Point,
    PointFromJSON,
    PointFromJSONTyped,
    PointToJSON,
} from './';

/**
 * 
 * @export
 * @interface SingleCustomTimeSeriesView
 */
export interface SingleCustomTimeSeriesView {
    /**
     * 
     * @type {string}
     * @memberof SingleCustomTimeSeriesView
     */
    customId: string;
    /**
     * 
     * @type {Array<Point>}
     * @memberof SingleCustomTimeSeriesView
     */
    values: Array<Point>;
}

export function SingleCustomTimeSeriesViewFromJSON(json: any): SingleCustomTimeSeriesView {
    return SingleCustomTimeSeriesViewFromJSONTyped(json, false);
}

export function SingleCustomTimeSeriesViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): SingleCustomTimeSeriesView {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'customId': json['customId'],
        'values': ((json['values'] as Array<any>).map(PointFromJSON)),
    };
}

export function SingleCustomTimeSeriesViewToJSON(value?: SingleCustomTimeSeriesView | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'customId': value.customId,
        'values': ((value.values as Array<any>).map(PointToJSON)),
    };
}


