/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PropertyDTO,
    PropertyDTOFromJSON,
    PropertyDTOFromJSONTyped,
    PropertyDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface PropertyListDTO
 */
export interface PropertyListDTO {
    /**
     * 
     * @type {Array<PropertyDTO>}
     * @memberof PropertyListDTO
     */
    properties: Array<PropertyDTO>;
}

export function PropertyListDTOFromJSON(json: any): PropertyListDTO {
    return PropertyListDTOFromJSONTyped(json, false);
}

export function PropertyListDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): PropertyListDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'properties': ((json['properties'] as Array<any>).map(PropertyDTOFromJSON)),
    };
}

export function PropertyListDTOToJSON(value?: PropertyListDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'properties': ((value.properties as Array<any>).map(PropertyDTOToJSON)),
    };
}


