import { HTTPQuery, querystring } from 'generated/leaderboard-client';
import { GetEndpoint } from './types';

export function buildEndpointUrl<P>(endpoint: GetEndpoint<P>, requestParameters: P): string {
  const { pathTemplate, pathParamNames, queryParamNames } = endpoint;
  const path = pathParamNames.reduce(
    (path, key) =>
      path.replace(`{${key.toString()}}`, encodeURIComponent(String(requestParameters[key]))),
    pathTemplate,
  );
  const queryParameters: HTTPQuery = queryParamNames.reduce((queryParameters, name) => {
    if (requestParameters[name] !== undefined) {
      queryParameters[`${name.toString()}`] = requestParameters[name] as any;
    }

    return queryParameters;
  }, {} as HTTPQuery);
  const query = querystring(queryParameters);
  return path + (query ? '?' + query : '');
}
