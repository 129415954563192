/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AttributeStyleSettingsDTO,
    AttributeStyleSettingsDTOFromJSON,
    AttributeStyleSettingsDTOFromJSONTyped,
    AttributeStyleSettingsDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface AttributeStyleSettingsParamsDTO
 */
export interface AttributeStyleSettingsParamsDTO {
    /**
     * 
     * @type {string}
     * @memberof AttributeStyleSettingsParamsDTO
     */
    projectIdentifier: string;
    /**
     * 
     * @type {AttributeStyleSettingsDTO}
     * @memberof AttributeStyleSettingsParamsDTO
     */
    styleSettings: AttributeStyleSettingsDTO;
}

export function AttributeStyleSettingsParamsDTOFromJSON(json: any): AttributeStyleSettingsParamsDTO {
    return AttributeStyleSettingsParamsDTOFromJSONTyped(json, false);
}

export function AttributeStyleSettingsParamsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): AttributeStyleSettingsParamsDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'projectIdentifier': json['projectIdentifier'],
        'styleSettings': AttributeStyleSettingsDTOFromJSON(json['styleSettings']),
    };
}

export function AttributeStyleSettingsParamsDTOToJSON(value?: AttributeStyleSettingsParamsDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'projectIdentifier': value.projectIdentifier,
        'styleSettings': AttributeStyleSettingsDTOToJSON(value.styleSettings),
    };
}


