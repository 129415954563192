import React from 'react';
import {
  Pagination,
  Layout,
  Text,
  OffsetBasedPaginationPageParams,
  OffsetBasedPaginationPageChangeHandler,
} from '@neptune/shared/venus-ui';
import { PaginationLimitSelector, PageSizeChangeHandler } from './PaginationLimitSelector';

type PaginationWithLimitSelectorProps = OffsetBasedPaginationPageParams & {
  pageSizes: number[];
  onPageChange: OffsetBasedPaginationPageChangeHandler;
  onPageSizeChange: PageSizeChangeHandler;
};

export const PaginationWithLimitSelector: React.FC<PaginationWithLimitSelectorProps> = ({
  limit,
  offset,
  total,
  onPageChange,
  onPageSizeChange,
  pageSizes,
}) => (
  <Layout.Row spacedChildren="md" alignItems="center" span="auto">
    <Pagination
      offset={offset}
      limit={limit}
      total={total}
      onPageOffsetBasedChange={onPageChange}
      hideIfSinglePage={false}
      size="lg"
    />
    <Text size="xs" fontWeight="semibold">
      {Pagination.rangeRenderer({ limit, offset, total })}{' '}
      <PaginationLimitSelector onChange={onPageSizeChange} options={pageSizes} selected={limit} />
    </Text>
  </Layout.Row>
);
