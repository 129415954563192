import { CustomIconDefinition } from '../types';

export const nepPlayCircle: CustomIconDefinition = {
  prefix: 'nep',
  iconName: 'play-circle',
  icon: [
    16,
    16,
    [],
    'e00c',
    'M8 0.25C3.71875 0.25 0.25 3.71875 0.25 8C0.25 12.2812 3.71875 15.75 8 15.75C12.2812 15.75 15.75 12.2812 15.75 8C15.75 3.71875 12.2812 0.25 8 0.25ZM11.6156 8.75L6.11562 11.9062C5.62187 12.1812 5 11.8281 5 11.25V4.75C5 4.175 5.61875 3.81875 6.11562 4.09375L11.6156 7.4375C12.1281 7.725 12.1281 8.46562 11.6156 8.75Z',
  ],
};
