// Libs
import React from 'react';

import { bemBlock } from '../../modules/bem';
import { LayoutRow, LayoutRowProps } from '../layout-row/LayoutRow';
import { LayoutFill } from '../layout-fill/LayoutFill';
import { AppHeaderButton } from '../app-header-button/AppHeaderButton';
import { AppHeaderLogo } from '../app-header-logo/AppHeaderLogo';

// Module
import './AppHeader.less';

const block = bemBlock('n-AppHeader');

export type AppHeaderProps = Omit<
  LayoutRowProps<{}>,
  | 'alignItems'
  | 'alignSelf'
  | 'component'
  | 'height'
  | 'inline'
  | 'justifyContent'
  | 'overflow'
  | 'reversed'
  | 'spacedChildren'
  | 'span'
  | 'width'
  | 'withCustomScrollbar'
  | 'withGutter'
  | 'withPadding'
  | 'wrap'
>;

/**
 * `AppHeader` is a container component with defined height and background.
 *
 * ## Import
 *
 * ```
 * import { AppHeader } from '@neptune/shared/venus-ui';
 * ```
 *
 * ## Shorthands to AppHeader related components
 *
 * All AppHeader related components are exposed as properties of `AppHeader`,
 * so it is possible to import only `AppHeader` to use all those components.
 *
 * | Component          | Property name | Usage                   |
 * | ------------------ | ------------- | ----------------------- |
 * | `AppHeaderButton`  | `Button`      | `<AppHeader.Button />`  |
 * | `LayoutFill`       | `Fill`        | `<AppHeader.Fill />`    |
 * | `AppHeaderLogo`    | `Logo`        | `<AppHeader.Logo />`    |
 */
export class AppHeader extends React.Component<AppHeaderProps> {
  public static Button = AppHeaderButton;

  public static Fill = LayoutFill;

  public static Logo = AppHeaderLogo;

  render() {
    const { className, ...passProps } = this.props;

    return (
      <LayoutRow
        alignItems="center"
        className={block({ extra: className })}
        component="header"
        data-role="app-header"
        spacedChildren="sm"
        span="auto"
        withPadding="xs"
        {...passProps}
      />
    );
  }
}
