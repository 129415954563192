import { CustomIconDefinition } from '../types';

export const nepDotsV: CustomIconDefinition = {
  prefix: 'nep',
  iconName: 'dots-v',
  icon: [
    1000,
    1000,
    [],
    'e13b',
    'M500.3 149.2C552.1 149.2 594 191.2 594 242.9S552.1 336.6 500.3 336.6 406.6 294.6 406.6 242.9 448.5 149.2 500.3 149.2ZM500.3 405.9C552.1 405.9 594 447.9 594 499.6S552 593.3 500.3 593.3 406.6 551.3 406.6 499.6 448.5 405.9 500.3 405.9ZM500.3 662.6C552.1 662.6 594 704.6 594 756.3S552 850 500.3 850 406.6 808 406.6 756.3 448.5 662.6 500.3 662.6Z',
  ],
};
