/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StringPointValueDTO
 */
export interface StringPointValueDTO {
    /**
     * 
     * @type {number}
     * @memberof StringPointValueDTO
     */
    step: number;
    /**
     * 
     * @type {number}
     * @memberof StringPointValueDTO
     */
    timestampMillis: number;
    /**
     * 
     * @type {string}
     * @memberof StringPointValueDTO
     */
    value: string;
}

export function StringPointValueDTOFromJSON(json: any): StringPointValueDTO {
    return StringPointValueDTOFromJSONTyped(json, false);
}

export function StringPointValueDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): StringPointValueDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'step': json['step'],
        'timestampMillis': json['timestampMillis'],
        'value': json['value'],
    };
}

export function StringPointValueDTOToJSON(value?: StringPointValueDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'step': value.step,
        'timestampMillis': value.timestampMillis,
        'value': value.value,
    };
}


