/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WidgetLayoutDTO
 */
export interface WidgetLayoutDTO {
    /**
     * 
     * @type {number}
     * @memberof WidgetLayoutDTO
     */
    h: number;
    /**
     * 
     * @type {string}
     * @memberof WidgetLayoutDTO
     */
    id: string;
    /**
     * 
     * @type {boolean}
     * @memberof WidgetLayoutDTO
     */
    isStatic?: boolean;
    /**
     * 
     * @type {number}
     * @memberof WidgetLayoutDTO
     */
    w: number;
    /**
     * 
     * @type {number}
     * @memberof WidgetLayoutDTO
     */
    x: number;
    /**
     * 
     * @type {number}
     * @memberof WidgetLayoutDTO
     */
    y: number;
}

export function WidgetLayoutDTOFromJSON(json: any): WidgetLayoutDTO {
    return WidgetLayoutDTOFromJSONTyped(json, false);
}

export function WidgetLayoutDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): WidgetLayoutDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'h': json['h'],
        'id': json['id'],
        'isStatic': !exists(json, 'isStatic') ? undefined : json['isStatic'],
        'w': json['w'],
        'x': json['x'],
        'y': json['y'],
    };
}

export function WidgetLayoutDTOToJSON(value?: WidgetLayoutDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'h': value.h,
        'id': value.id,
        'isStatic': value.isStatic,
        'w': value.w,
        'x': value.x,
        'y': value.y,
    };
}


