/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DashboardDTO,
    DashboardDTOFromJSON,
    DashboardDTOFromJSONTyped,
    DashboardDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface DashboardListDTO
 */
export interface DashboardListDTO {
    /**
     * Dashboard list
     * @type {Array<DashboardDTO>}
     * @memberof DashboardListDTO
     */
    dashboards: Array<DashboardDTO>;
}

export function DashboardListDTOFromJSON(json: any): DashboardListDTO {
    return DashboardListDTOFromJSONTyped(json, false);
}

export function DashboardListDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): DashboardListDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dashboards': ((json['dashboards'] as Array<any>).map(DashboardDTOFromJSON)),
    };
}

export function DashboardListDTOToJSON(value?: DashboardListDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dashboards': ((value.dashboards as Array<any>).map(DashboardDTOToJSON)),
    };
}


