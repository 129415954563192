import React from 'react';
import { useAsyncWrapper } from './use-async-wrapper';

export function useAsyncValue<T, E = any>(params: {
  errorFormatter?: (error: any) => E;
  resolver: () => Promise<T>;
}): Omit<ReturnType<typeof useAsyncWrapper<E, typeof params.resolver, T>>, 'result' | 'resolve'> & {
  value: T | undefined;
  refresh(): Promise<void>;
} {
  const { resolve, result, ...metadata } = useAsyncWrapper<E, typeof params.resolver, T>(params);

  React.useEffect(() => {
    resolve();
  }, [resolve]);

  const refresh = React.useCallback(async () => {
    resolve();
  }, [resolve]);

  return {
    ...metadata,
    value: result,
    refresh,
  };
}
