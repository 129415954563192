import React from 'react';
import { Layout, Text, bemBlock } from '@neptune/shared/venus-ui';

import './IngestUsagePlaceholder.less';

const block = bemBlock('ingest-usage-placeholder');

type IngestUsagePlaceholderProps = {
  style?: React.CSSProperties;
  description: string;
};

export const IngestUsagePlaceholder: React.FC<IngestUsagePlaceholderProps> = ({
  style,
  description,
}) => {
  return (
    <Layout.Column
      spacedChildren="sm"
      justifyContent="center"
      alignItems="center"
      className={block()}
      style={style}
      withPadding="lg"
    >
      <Text fontWeight="bold" size="lg" color="text-subdued">
        Upcoming content
      </Text>
      <Text size="base" color="text-subdued">
        {description}
      </Text>
    </Layout.Column>
  );
};
