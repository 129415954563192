export function defineStorage<StorageState>(
  getState: () => Partial<StorageState>,
  updateState: (stateChange: Partial<StorageState>) => void,
) {
  function getItem<P extends keyof StorageState>(
    key: P,
    defaults: Required<StorageState>[P],
  ): Required<StorageState>[P];
  function getItem<P extends keyof StorageState>(key: P): StorageState[P] | undefined;
  function getItem<P extends keyof StorageState>(
    key: P,
    defaults?: StorageState[P],
  ): StorageState[P] | undefined {
    return getState()[key] ?? defaults;
  }

  function setItem<P extends keyof StorageState>(key: P, value: StorageState[P]) {
    updateState({ [key]: value } as StorageState);
  }

  function removeItem(key: keyof StorageState) {
    updateState({ [key]: undefined } as StorageState);
  }

  return {
    getItem,
    getState,
    removeItem,
    setItem,
    updateState,
  };
}
