/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExperimentStateDTO,
    ExperimentStateDTOFromJSON,
    ExperimentStateDTOFromJSONTyped,
    ExperimentStateDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface LeaderboardViewQuickFilterDTO
 */
export interface LeaderboardViewQuickFilterDTO {
    /**
     * 
     * @type {Date}
     * @memberof LeaderboardViewQuickFilterDTO
     */
    creationDateRangeEnd?: Date;
    /**
     * 
     * @type {Date}
     * @memberof LeaderboardViewQuickFilterDTO
     */
    creationDateRangeStart?: Date;
    /**
     * 
     * @type {Array<string>}
     * @memberof LeaderboardViewQuickFilterDTO
     */
    owner?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof LeaderboardViewQuickFilterDTO
     */
    relativeTime?: LeaderboardViewQuickFilterDTORelativeTimeEnum;
    /**
     * 
     * @type {string}
     * @memberof LeaderboardViewQuickFilterDTO
     */
    searchTerm?: string;
    /**
     * 
     * @type {Array<ExperimentStateDTO>}
     * @memberof LeaderboardViewQuickFilterDTO
     */
    status?: Array<ExperimentStateDTO>;
    /**
     * 
     * @type {Array<string>}
     * @memberof LeaderboardViewQuickFilterDTO
     */
    tags?: Array<string>;
}

export function LeaderboardViewQuickFilterDTOFromJSON(json: any): LeaderboardViewQuickFilterDTO {
    return LeaderboardViewQuickFilterDTOFromJSONTyped(json, false);
}

export function LeaderboardViewQuickFilterDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): LeaderboardViewQuickFilterDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'creationDateRangeEnd': !exists(json, 'creationDateRangeEnd') ? undefined : (new Date(json['creationDateRangeEnd'])),
        'creationDateRangeStart': !exists(json, 'creationDateRangeStart') ? undefined : (new Date(json['creationDateRangeStart'])),
        'owner': !exists(json, 'owner') ? undefined : json['owner'],
        'relativeTime': !exists(json, 'relativeTime') ? undefined : json['relativeTime'],
        'searchTerm': !exists(json, 'searchTerm') ? undefined : json['searchTerm'],
        'status': !exists(json, 'status') ? undefined : ((json['status'] as Array<any>).map(ExperimentStateDTOFromJSON)),
        'tags': !exists(json, 'tags') ? undefined : json['tags'],
    };
}

export function LeaderboardViewQuickFilterDTOToJSON(value?: LeaderboardViewQuickFilterDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'creationDateRangeEnd': value.creationDateRangeEnd === undefined ? undefined : (value.creationDateRangeEnd.toISOString()),
        'creationDateRangeStart': value.creationDateRangeStart === undefined ? undefined : (value.creationDateRangeStart.toISOString()),
        'owner': value.owner,
        'relativeTime': value.relativeTime,
        'searchTerm': value.searchTerm,
        'status': value.status === undefined ? undefined : ((value.status as Array<any>).map(ExperimentStateDTOToJSON)),
        'tags': value.tags,
    };
}

/**
* @export
* @enum {string}
*/
export enum LeaderboardViewQuickFilterDTORelativeTimeEnum {
    LastDay = 'lastDay',
    LastWeek = 'lastWeek',
    LastMonth = 'lastMonth',
    LastQuarter = 'lastQuarter'
}


