/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AuditInfo
 */
export interface AuditInfo {
    /**
     * 
     * @type {Date}
     * @memberof AuditInfo
     */
    accustomedExperimentCreatedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof AuditInfo
     */
    firstExperimentCreatedAt?: Date;
}

export function AuditInfoFromJSON(json: any): AuditInfo {
    return AuditInfoFromJSONTyped(json, false);
}

export function AuditInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuditInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accustomedExperimentCreatedAt': !exists(json, 'accustomedExperimentCreatedAt') ? undefined : (new Date(json['accustomedExperimentCreatedAt'])),
        'firstExperimentCreatedAt': !exists(json, 'firstExperimentCreatedAt') ? undefined : (new Date(json['firstExperimentCreatedAt'])),
    };
}

export function AuditInfoToJSON(value?: AuditInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accustomedExperimentCreatedAt': value.accustomedExperimentCreatedAt === undefined ? undefined : (value.accustomedExperimentCreatedAt.toISOString()),
        'firstExperimentCreatedAt': value.firstExperimentCreatedAt === undefined ? undefined : (value.firstExperimentCreatedAt.toISOString()),
    };
}


