// Libs
import React, { HtmlHTMLAttributes } from 'react';

import { bemBlock } from '../../modules/bem';

// Module
import './ViewTitle.less';

const block = bemBlock('n-ViewTitle');

export type ViewTitleProps = HtmlHTMLAttributes<HTMLHeadingElement>;

export const ViewTitle: React.FC<ViewTitleProps> = ({ className, ...passProps }) => {
  return <h1 className={block({ extra: className })} {...passProps} />;
};
