/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExperimentsDataDTO,
    ExperimentsDataDTOFromJSON,
    ExperimentsDataDTOFromJSONTyped,
    ExperimentsDataDTOToJSON,
    NotebooksDataDTO,
    NotebooksDataDTOFromJSON,
    NotebooksDataDTOFromJSONTyped,
    NotebooksDataDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface TrackingDataDTO
 */
export interface TrackingDataDTO {
    /**
     * 
     * @type {ExperimentsDataDTO}
     * @memberof TrackingDataDTO
     */
    experiments?: ExperimentsDataDTO;
    /**
     * 
     * @type {string}
     * @memberof TrackingDataDTO
     */
    identifier?: string;
    /**
     * 
     * @type {NotebooksDataDTO}
     * @memberof TrackingDataDTO
     */
    notebooks?: NotebooksDataDTO;
}

export function TrackingDataDTOFromJSON(json: any): TrackingDataDTO {
    return TrackingDataDTOFromJSONTyped(json, false);
}

export function TrackingDataDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): TrackingDataDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'experiments': !exists(json, 'experiments') ? undefined : ExperimentsDataDTOFromJSON(json['experiments']),
        'identifier': !exists(json, 'identifier') ? undefined : json['identifier'],
        'notebooks': !exists(json, 'notebooks') ? undefined : NotebooksDataDTOFromJSON(json['notebooks']),
    };
}

export function TrackingDataDTOToJSON(value?: TrackingDataDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'experiments': ExperimentsDataDTOToJSON(value.experiments),
        'identifier': value.identifier,
        'notebooks': NotebooksDataDTOToJSON(value.notebooks),
    };
}


