import React from 'react';
import { bemBlock } from '@neptune/shared/venus-ui';
import './SkeletonLoader.less';

const block = bemBlock('skeleton-loader');

type SkeletonLoaderProps = {
  className?: string;
};

export const SkeletonLoader: React.FC<SkeletonLoaderProps> = ({ className }) => {
  return (
    <div data-role="skeleton-loader" className={block({ extra: className })}>
      &nbsp;
    </div>
  );
};
