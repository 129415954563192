import React from 'react';
import { bemBlock } from '../../modules/bem';
import { Card } from '../card/Card';
import { LayoutRowProps } from '../layout-row/LayoutRow';
import './ModalBody.less';

const block = bemBlock('n-modal-body');

type ModalBodyProps = React.HTMLAttributes<HTMLElement> &
  Omit<LayoutRowProps<{}>, 'spacedChildren' | 'withPadding'>;

const ModalBody: React.FC<ModalBodyProps> = ({ className, children, ...passProps }) => {
  const cssClass = block({ extra: className });

  return (
    <Card.Section className={cssClass} {...passProps}>
      {children}
    </Card.Section>
  );
};

export default ModalBody;
