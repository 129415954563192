/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OptionalOperationError
 */
export interface OptionalOperationError {
    /**
     * 
     * @type {boolean}
     * @memberof OptionalOperationError
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OptionalOperationError
     */
    present?: boolean;
}

export function OptionalOperationErrorFromJSON(json: any): OptionalOperationError {
    return OptionalOperationErrorFromJSONTyped(json, false);
}

export function OptionalOperationErrorFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionalOperationError {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'empty': !exists(json, 'empty') ? undefined : json['empty'],
        'present': !exists(json, 'present') ? undefined : json['present'],
    };
}

export function OptionalOperationErrorToJSON(value?: OptionalOperationError | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'empty': value.empty,
        'present': value.present,
    };
}


