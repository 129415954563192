/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StringPointValueDTO,
    StringPointValueDTOFromJSON,
    StringPointValueDTOFromJSONTyped,
    StringPointValueDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface StringSeriesValuesDTO
 */
export interface StringSeriesValuesDTO {
    /**
     * 
     * @type {number}
     * @memberof StringSeriesValuesDTO
     */
    totalItemCount: number;
    /**
     * 
     * @type {Array<StringPointValueDTO>}
     * @memberof StringSeriesValuesDTO
     */
    values: Array<StringPointValueDTO>;
}

export function StringSeriesValuesDTOFromJSON(json: any): StringSeriesValuesDTO {
    return StringSeriesValuesDTOFromJSONTyped(json, false);
}

export function StringSeriesValuesDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): StringSeriesValuesDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalItemCount': json['totalItemCount'],
        'values': ((json['values'] as Array<any>).map(StringPointValueDTOFromJSON)),
    };
}

export function StringSeriesValuesDTOToJSON(value?: StringSeriesValuesDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalItemCount': value.totalItemCount,
        'values': ((value.values as Array<any>).map(StringPointValueDTOToJSON)),
    };
}


