// Libs
import Keycloak from 'keycloak-js';

// App
import config from 'config';
import { isReachable } from './is-reachable';

type Merge<M, N> = Omit<M, Extract<keyof M, keyof N>> & N;

type NeptuneKeycloak = Merge<
  Keycloak.KeycloakInstance,
  {
    tokenParsed?: Merge<
      Keycloak.KeycloakTokenParsed,
      {
        allow_new_ui?: string;
        loginMethod?: string;
        preferred_username?: string;
        neptune_role?: string;
        neptune_instance_role?: string;
      }
    >;
  }
>;

const keycloak: NeptuneKeycloak = Keycloak({
  url: config.keycloakURL,
  realm: config.keycloakRealm,
  clientId: config.keycloakFrontendClientId,
});

export default keycloak;
export const authClient = keycloak;

export async function isAuthServerReachable(): Promise<boolean> {
  // use realm endpoint because it is available cross domain
  const url = getRealmUrl();
  return isReachable(url);
}

function getRealmUrl(): string {
  if (!keycloak.authServerUrl || !keycloak.realm) {
    return '';
  }

  const pathSuffix = keycloak.authServerUrl.endsWith('/') ? '' : '/';
  return `${keycloak.authServerUrl}${pathSuffix}realms/${encodeURIComponent(keycloak.realm)}`;
}

export function getKeycloakInitOptions(): Keycloak.KeycloakInitOptions {
  return {
    onLoad: 'check-sso',
    silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso/index.html`,
  };
}

export function getPreferredUsername(): string {
  if (keycloak.tokenParsed?.loginMethod === undefined) {
    return '';
  }

  return keycloak.tokenParsed?.preferred_username || '';
}
