/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ApiErrorTypeDTO {
    LIMITOFPROJECTSREACHED = 'LIMIT_OF_PROJECTS_REACHED',
    LIMITOFMEMBERSINORGANIZATIONREACHED = 'LIMIT_OF_MEMBERS_IN_ORGANIZATION_REACHED',
    WORKSPACEREADONLY = 'WORKSPACE_READONLY',
    USERNOTINPROJECT = 'USER_NOT_IN_PROJECT',
    SERVICEACCOUNTALREADYEXISTSINORGANIZATION = 'SERVICE_ACCOUNT_ALREADY_EXISTS_IN_ORGANIZATION',
    SERVICEACCOUNTNOTINPROJECT = 'SERVICE_ACCOUNT_NOT_IN_PROJECT',
    LIMITOFSERVICEACCOUNTSINORGANIZATIONREACHED = 'LIMIT_OF_SERVICE_ACCOUNTS_IN_ORGANIZATION_REACHED',
    CANNOTADDMEMBERSTOPRIVATEINDIVIDUALPROJECT = 'CANNOT_ADD_MEMBERS_TO_PRIVATE_INDIVIDUAL_PROJECT',
    CANNOTADDMANAGERTOINDIVIDUALPROJECT = 'CANNOT_ADD_MANAGER_TO_INDIVIDUAL_PROJECT',
    CANNOTADDOUTSIDERSTOTEAMPROJECT = 'CANNOT_ADD_OUTSIDERS_TO_TEAM_PROJECT',
    CANNOTLEAVETEAMPROJECT = 'CANNOT_LEAVE_TEAM_PROJECT',
    ADMINCANNOTLEAVEPROJECT = 'ADMIN_CANNOT_LEAVE_PROJECT',
    LIMITOFPROJECTMEMBERSREACHED = 'LIMIT_OF_PROJECT_MEMBERS_REACHED',
    VISIBILITYRESTRICTED = 'VISIBILITY_RESTRICTED',
    PLANNOTAVAILABLE = 'PLAN_NOT_AVAILABLE',
    PAYMENTSNOTSUPPORTED = 'PAYMENTS_NOT_SUPPORTED',
    PROJECTNAMECOLLISION = 'PROJECT_NAME_COLLISION',
    PROJECTKEYCOLLISION = 'PROJECT_KEY_COLLISION',
    PROJECTNAMEINVALID = 'PROJECT_NAME_INVALID',
    PROJECTKEYINVALID = 'PROJECT_KEY_INVALID',
    USERSTILLOWNSSOMEORGANIZATIONS = 'USER_STILL_OWNS_SOME_ORGANIZATIONS',
    LIMITOFACTIVEPROJECTSREACHED = 'LIMIT_OF_ACTIVE_PROJECTS_REACHED',
    ACTIVEPROJECTSQUOTACOULDNOTBECHANGED = 'ACTIVE_PROJECTS_QUOTA_COULD_NOT_BE_CHANGED',
    LIMITOFACTIVEPROJECTSQUOTAREACHED = 'LIMIT_OF_ACTIVE_PROJECTS_QUOTA_REACHED',
    INSUFFICIENTACTIVEPROJECTSQUOTA = 'INSUFFICIENT_ACTIVE_PROJECTS_QUOTA'
}

export function ApiErrorTypeDTOFromJSON(json: any): ApiErrorTypeDTO {
    return ApiErrorTypeDTOFromJSONTyped(json, false);
}

export function ApiErrorTypeDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiErrorTypeDTO {
    return json as ApiErrorTypeDTO;
}

export function ApiErrorTypeDTOToJSON(value?: ApiErrorTypeDTO | null): any {
    return value as any;
}

