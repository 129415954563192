// Libs
import React, { HtmlHTMLAttributes } from 'react';

import { bemBlock } from '../../modules/bem';
import { Text, TextProps } from '../text/Text';

// Module
import './SectionTitle.less';

const block = bemBlock('n-SectionTitle');

export type SectionTitleSize = 'normal' | 'small';
export type SectionTitleProps = Pick<
  TextProps<Omit<HtmlHTMLAttributes<HTMLHeadingElement>, 'color'>>,
  'className' | 'color' | 'ellipsis' | 'lineHeight' | 'transform' | 'wordBreak'
> & {
  size?: SectionTitleSize;
};

export const SectionTitle: React.FC<SectionTitleProps> = ({
  className,
  lineHeight = '1',
  size = 'normal',
  ...passProps
}) => {
  return (
    <Text
      component="h2"
      className={block({ extra: className })}
      size={size === 'small' ? 'md' : 'lg'}
      fontWeight="semibold"
      lineHeight={lineHeight}
      {...passProps}
    />
  );
};
