/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AttributeTypeDTO,
    AttributeTypeDTOFromJSON,
    AttributeTypeDTOFromJSONTyped,
    AttributeTypeDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface QueryAttributeValuesBodyDTO
 */
export interface QueryAttributeValuesBodyDTO {
    /**
     * Required attribute name
     * @type {string}
     * @memberof QueryAttributeValuesBodyDTO
     */
    attributeName: string;
    /**
     * 
     * @type {AttributeTypeDTO}
     * @memberof QueryAttributeValuesBodyDTO
     */
    attributeType: AttributeTypeDTO;
    /**
     * Optional limit of returned values
     * @type {number}
     * @memberof QueryAttributeValuesBodyDTO
     */
    limit?: number;
    /**
     * Optional regex value filter; used only for `string` attributes
     * @type {string}
     * @memberof QueryAttributeValuesBodyDTO
     */
    regexValueFilter?: string;
}

export function QueryAttributeValuesBodyDTOFromJSON(json: any): QueryAttributeValuesBodyDTO {
    return QueryAttributeValuesBodyDTOFromJSONTyped(json, false);
}

export function QueryAttributeValuesBodyDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): QueryAttributeValuesBodyDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attributeName': json['attributeName'],
        'attributeType': AttributeTypeDTOFromJSON(json['attributeType']),
        'limit': !exists(json, 'limit') ? undefined : json['limit'],
        'regexValueFilter': !exists(json, 'regexValueFilter') ? undefined : json['regexValueFilter'],
    };
}

export function QueryAttributeValuesBodyDTOToJSON(value?: QueryAttributeValuesBodyDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attributeName': value.attributeName,
        'attributeType': AttributeTypeDTOToJSON(value.attributeType),
        'limit': value.limit,
        'regexValueFilter': value.regexValueFilter,
    };
}


