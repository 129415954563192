/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AttributeDTO,
    AttributeDTOFromJSON,
    AttributeDTOFromJSONTyped,
    AttributeDTOToJSON,
    SystemAttributesDTO,
    SystemAttributesDTOFromJSON,
    SystemAttributesDTOFromJSONTyped,
    SystemAttributesDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface ExperimentAttributesDTO
 */
export interface ExperimentAttributesDTO {
    /**
     * 
     * @type {Array<AttributeDTO>}
     * @memberof ExperimentAttributesDTO
     */
    attributes: Array<AttributeDTO>;
    /**
     * 
     * @type {string}
     * @memberof ExperimentAttributesDTO
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ExperimentAttributesDTO
     */
    organizationId: string;
    /**
     * 
     * @type {string}
     * @memberof ExperimentAttributesDTO
     */
    organizationName: string;
    /**
     * 
     * @type {string}
     * @memberof ExperimentAttributesDTO
     */
    projectId: string;
    /**
     * 
     * @type {string}
     * @memberof ExperimentAttributesDTO
     */
    projectName: string;
    /**
     * 
     * @type {SystemAttributesDTO}
     * @memberof ExperimentAttributesDTO
     */
    systemAttributes: SystemAttributesDTO;
    /**
     * 
     * @type {boolean}
     * @memberof ExperimentAttributesDTO
     */
    trashed: boolean;
}

export function ExperimentAttributesDTOFromJSON(json: any): ExperimentAttributesDTO {
    return ExperimentAttributesDTOFromJSONTyped(json, false);
}

export function ExperimentAttributesDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExperimentAttributesDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attributes': ((json['attributes'] as Array<any>).map(AttributeDTOFromJSON)),
        'id': json['id'],
        'organizationId': json['organizationId'],
        'organizationName': json['organizationName'],
        'projectId': json['projectId'],
        'projectName': json['projectName'],
        'systemAttributes': SystemAttributesDTOFromJSON(json['systemAttributes']),
        'trashed': json['trashed'],
    };
}

export function ExperimentAttributesDTOToJSON(value?: ExperimentAttributesDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attributes': ((value.attributes as Array<any>).map(AttributeDTOToJSON)),
        'id': value.id,
        'organizationId': value.organizationId,
        'organizationName': value.organizationName,
        'projectId': value.projectId,
        'projectName': value.projectName,
        'systemAttributes': SystemAttributesDTOToJSON(value.systemAttributes),
        'trashed': value.trashed,
    };
}


