// Libs
import React from 'react';
import PropTypes from 'prop-types';
import { isFunction } from 'lodash';


// neptune-core-ui
// eslint-disable-next-line no-restricted-imports
import ncuiPropTypes from 'neptune-core-ui/helpers/prop-types';
// eslint-disable-next-line no-restricted-imports
import { getEventHandler } from 'neptune-core-ui/modules/events';
// eslint-disable-next-line no-restricted-imports
import { bemBlock } from 'neptune-core-ui/modules/bem';


// Module
import './IconStack.less';


const propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  component: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  title: PropTypes.string,
  toggleChildrenOnHover: PropTypes.bool,
  /**
   * Can be event handler function or object with two properties:
   * - `handler` - event handler
   * - `payload` - object that will be passed to handler
   *
   * Event handler will be called with arguments:
   * - `event` - [SyntheticEvent](https://facebook.github.io/react/docs/events.html)
   * - `params` - `object` (defaults to `{}` *empty object*)
   *   - `payload` - value of `onClick.payload`
   */
  onClick: ncuiPropTypes.eventHandler,
};

const defaultProps = {
  component: 'span',
};

const block = bemBlock('n-icon-stack');


const IconStack = ({
  children,
  className,
  component,
  title,
  toggleChildrenOnHover,
  onClick,
  ...props
}) => {
  const onClickHandler = getEventHandler(onClick);
  const iconStackProps = {
    className: block({
      modifiers: { 'toggleable-children': toggleChildrenOnHover },
      extra: [
        { clickable: isFunction(onClickHandler) },
        className,
      ],
    }),
    title,
    onClick: onClickHandler,
  };

  return React.createElement(component, {...iconStackProps, ...props}, children);
};

IconStack.propTypes = propTypes;
IconStack.defaultProps = defaultProps;


export default IconStack;
