/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BoolAttributeDTO,
    BoolAttributeDTOFromJSON,
    BoolAttributeDTOFromJSONTyped,
    BoolAttributeDTOToJSON,
    DatetimeAttributeDTO,
    DatetimeAttributeDTOFromJSON,
    DatetimeAttributeDTOFromJSONTyped,
    DatetimeAttributeDTOToJSON,
    ExperimentStateAttributeDTO,
    ExperimentStateAttributeDTOFromJSON,
    ExperimentStateAttributeDTOFromJSONTyped,
    ExperimentStateAttributeDTOToJSON,
    FloatAttributeDTO,
    FloatAttributeDTOFromJSON,
    FloatAttributeDTOFromJSONTyped,
    FloatAttributeDTOToJSON,
    GitInfoDTO,
    GitInfoDTOFromJSON,
    GitInfoDTOFromJSONTyped,
    GitInfoDTOToJSON,
    IntAttributeDTO,
    IntAttributeDTOFromJSON,
    IntAttributeDTOFromJSONTyped,
    IntAttributeDTOToJSON,
    NotebookRefAttributeDTO,
    NotebookRefAttributeDTOFromJSON,
    NotebookRefAttributeDTOFromJSONTyped,
    NotebookRefAttributeDTOToJSON,
    StringAttributeDTO,
    StringAttributeDTOFromJSON,
    StringAttributeDTOFromJSONTyped,
    StringAttributeDTOToJSON,
    StringSetAttributeDTO,
    StringSetAttributeDTOFromJSON,
    StringSetAttributeDTOFromJSONTyped,
    StringSetAttributeDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface SystemAttributesDTO
 */
export interface SystemAttributesDTO {
    /**
     * 
     * @type {DatetimeAttributeDTO}
     * @memberof SystemAttributesDTO
     */
    creationTime: DatetimeAttributeDTO;
    /**
     * 
     * @type {StringAttributeDTO}
     * @memberof SystemAttributesDTO
     */
    customId: StringAttributeDTO;
    /**
     * 
     * @type {StringAttributeDTO}
     * @memberof SystemAttributesDTO
     */
    description: StringAttributeDTO;
    /**
     * 
     * @type {BoolAttributeDTO}
     * @memberof SystemAttributesDTO
     */
    failed: BoolAttributeDTO;
    /**
     * 
     * @type {StringAttributeDTO}
     * @memberof SystemAttributesDTO
     */
    family: StringAttributeDTO;
    /**
     * 
     * @type {GitInfoDTO}
     * @memberof SystemAttributesDTO
     */
    gitRef?: GitInfoDTO;
    /**
     * 
     * @type {StringAttributeDTO}
     * @memberof SystemAttributesDTO
     */
    hostname?: StringAttributeDTO;
    /**
     * 
     * @type {DatetimeAttributeDTO}
     * @memberof SystemAttributesDTO
     */
    modificationTime: DatetimeAttributeDTO;
    /**
     * 
     * @type {IntAttributeDTO}
     * @memberof SystemAttributesDTO
     */
    monitoringTime: IntAttributeDTO;
    /**
     * 
     * @type {StringAttributeDTO}
     * @memberof SystemAttributesDTO
     */
    name: StringAttributeDTO;
    /**
     * 
     * @type {NotebookRefAttributeDTO}
     * @memberof SystemAttributesDTO
     */
    notebookRef?: NotebookRefAttributeDTO;
    /**
     * 
     * @type {StringAttributeDTO}
     * @memberof SystemAttributesDTO
     */
    owner?: StringAttributeDTO;
    /**
     * 
     * @type {DatetimeAttributeDTO}
     * @memberof SystemAttributesDTO
     */
    pingTime: DatetimeAttributeDTO;
    /**
     * 
     * @type {FloatAttributeDTO}
     * @memberof SystemAttributesDTO
     */
    runningTime: FloatAttributeDTO;
    /**
     * 
     * @type {StringAttributeDTO}
     * @memberof SystemAttributesDTO
     */
    shortId: StringAttributeDTO;
    /**
     * 
     * @type {FloatAttributeDTO}
     * @memberof SystemAttributesDTO
     */
    size: FloatAttributeDTO;
    /**
     * 
     * @type {ExperimentStateAttributeDTO}
     * @memberof SystemAttributesDTO
     */
    state: ExperimentStateAttributeDTO;
    /**
     * 
     * @type {StringSetAttributeDTO}
     * @memberof SystemAttributesDTO
     */
    tags: StringSetAttributeDTO;
    /**
     * 
     * @type {BoolAttributeDTO}
     * @memberof SystemAttributesDTO
     */
    trashed: BoolAttributeDTO;
}

export function SystemAttributesDTOFromJSON(json: any): SystemAttributesDTO {
    return SystemAttributesDTOFromJSONTyped(json, false);
}

export function SystemAttributesDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): SystemAttributesDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'creationTime': DatetimeAttributeDTOFromJSON(json['creationTime']),
        'customId': StringAttributeDTOFromJSON(json['customId']),
        'description': StringAttributeDTOFromJSON(json['description']),
        'failed': BoolAttributeDTOFromJSON(json['failed']),
        'family': StringAttributeDTOFromJSON(json['family']),
        'gitRef': !exists(json, 'gitRef') ? undefined : GitInfoDTOFromJSON(json['gitRef']),
        'hostname': !exists(json, 'hostname') ? undefined : StringAttributeDTOFromJSON(json['hostname']),
        'modificationTime': DatetimeAttributeDTOFromJSON(json['modificationTime']),
        'monitoringTime': IntAttributeDTOFromJSON(json['monitoringTime']),
        'name': StringAttributeDTOFromJSON(json['name']),
        'notebookRef': !exists(json, 'notebookRef') ? undefined : NotebookRefAttributeDTOFromJSON(json['notebookRef']),
        'owner': !exists(json, 'owner') ? undefined : StringAttributeDTOFromJSON(json['owner']),
        'pingTime': DatetimeAttributeDTOFromJSON(json['pingTime']),
        'runningTime': FloatAttributeDTOFromJSON(json['runningTime']),
        'shortId': StringAttributeDTOFromJSON(json['shortId']),
        'size': FloatAttributeDTOFromJSON(json['size']),
        'state': ExperimentStateAttributeDTOFromJSON(json['state']),
        'tags': StringSetAttributeDTOFromJSON(json['tags']),
        'trashed': BoolAttributeDTOFromJSON(json['trashed']),
    };
}

export function SystemAttributesDTOToJSON(value?: SystemAttributesDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'creationTime': DatetimeAttributeDTOToJSON(value.creationTime),
        'customId': StringAttributeDTOToJSON(value.customId),
        'description': StringAttributeDTOToJSON(value.description),
        'failed': BoolAttributeDTOToJSON(value.failed),
        'family': StringAttributeDTOToJSON(value.family),
        'gitRef': GitInfoDTOToJSON(value.gitRef),
        'hostname': StringAttributeDTOToJSON(value.hostname),
        'modificationTime': DatetimeAttributeDTOToJSON(value.modificationTime),
        'monitoringTime': IntAttributeDTOToJSON(value.monitoringTime),
        'name': StringAttributeDTOToJSON(value.name),
        'notebookRef': NotebookRefAttributeDTOToJSON(value.notebookRef),
        'owner': StringAttributeDTOToJSON(value.owner),
        'pingTime': DatetimeAttributeDTOToJSON(value.pingTime),
        'runningTime': FloatAttributeDTOToJSON(value.runningTime),
        'shortId': StringAttributeDTOToJSON(value.shortId),
        'size': FloatAttributeDTOToJSON(value.size),
        'state': ExperimentStateAttributeDTOToJSON(value.state),
        'tags': StringSetAttributeDTOToJSON(value.tags),
        'trashed': BoolAttributeDTOToJSON(value.trashed),
    };
}


