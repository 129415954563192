// Libs
import React, { HTMLAttributes } from 'react';

import { bemBlock } from '../../modules/bem';
import * as font from '../../modules/font';
import * as text from '../../modules/text';

// Module
import './Badge.less';

const block = bemBlock('n-Badge');

export type BadgeProps = HTMLAttributes<HTMLSpanElement> & {
  color?: text.Color | 'inherit';
  size?: font.Size;
  vertical?: boolean;
};

export const Badge: React.FC<BadgeProps> = ({
  children,
  className,
  color = 'inherit',
  size,
  vertical,
  ...passProps
}) => {
  return (
    <span
      className={block({
        extra: [className, font.sizeClassName(size)],
        modifiers: {
          vertical,
          color,
        },
      })}
      {...passProps}
    >
      <span className={block('shape')}>
        <span className={block('content')}>{children}</span>
      </span>
    </span>
  );
};
