// Libs
import React from 'react';
import { Link } from 'react-router5';

// App
import { OrganizationType } from '@neptune/shared/core-organizations-domain';
import { NormalizedLinkContext } from '@neptune/shared/routing-domain';
import { WithNonInterferingComponent } from '@neptune/shared/venus-ui';
import { convertRouteName } from 'router/helpers';

type NormalizedLinkOwnProps = {
  component?: string | React.ComponentType<any>;
  disallowSameState?: boolean;
  external?: boolean;
  forceContext?: OrganizationType;
};
export type NormalizedLinkProps<P extends {} = {}> = WithNonInterferingComponent<
  P,
  NormalizedLinkOwnProps
> &
  NormalizedLinkOwnProps &
  Partial<Pick<React.ComponentProps<typeof Link>, 'activeClassName' | 'disabled' | 'onClick'>> &
  Pick<React.ComponentProps<typeof Link>, 'routeName' | 'routeParams'>;

export const NormalizedLink = <P extends {} = {}>({
  component,
  forceContext,
  external,
  routeName,
  routeParams,
  ...props
}: NormalizedLinkProps<P>) => {
  const { inEnterpriseContext } = React.useContext(NormalizedLinkContext);

  /* Adjust the route name to desired context */
  const isEnterprise = forceContext ? forceContext === OrganizationType.team : inEnterpriseContext;

  const normalizedRouteName = convertRouteName(routeName, isEnterprise);

  const extraProps: { rel?: string; target?: string; disabled?: boolean } = React.useMemo(
    () =>
      external
        ? {
            rel: 'noopener noreferrer',
            target: '_blank',
          }
        : {},
    [external],
  );

  if (component) {
    /* router5 does not allow passing custom component, invert the dependency here */
    return React.createElement(component, {
      ...props,
      ...extraProps,
      ...{
        routeName: normalizedRouteName,
        routeParams,
        component: Link,
      },
    });
  }

  return (
    <Link {...props} {...extraProps} routeName={normalizedRouteName} routeParams={routeParams} />
  );
};
