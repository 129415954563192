/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PriorityQueryAttributeDefinitionsDTO
 */
export interface PriorityQueryAttributeDefinitionsDTO {
    /**
     * Prioritize by experiment id, if null no prioritization is applied
     * @type {Array<string>}
     * @memberof PriorityQueryAttributeDefinitionsDTO
     */
    experimentIds?: Array<string>;
    /**
     * Prioritize by experiment name, if null all experiments are considered
     * @type {Array<string>}
     * @memberof PriorityQueryAttributeDefinitionsDTO
     */
    experimentNamesFilter?: Array<string>;
}

export function PriorityQueryAttributeDefinitionsDTOFromJSON(json: any): PriorityQueryAttributeDefinitionsDTO {
    return PriorityQueryAttributeDefinitionsDTOFromJSONTyped(json, false);
}

export function PriorityQueryAttributeDefinitionsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): PriorityQueryAttributeDefinitionsDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'experimentIds': !exists(json, 'experimentIds') ? undefined : json['experimentIds'],
        'experimentNamesFilter': !exists(json, 'experimentNamesFilter') ? undefined : json['experimentNamesFilter'],
    };
}

export function PriorityQueryAttributeDefinitionsDTOToJSON(value?: PriorityQueryAttributeDefinitionsDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'experimentIds': value.experimentIds,
        'experimentNamesFilter': value.experimentNamesFilter,
    };
}


