import { CustomIconDefinition } from '../types';

export const nepChip: CustomIconDefinition = {
  prefix: 'nep',
  iconName: 'chip',
  icon: [
    512,
    512,
    [],
    'e007',
    'M336.716 338.101H183.063a.842.842 0 01-.853-.852V183.595c0-.48.373-.852.853-.852h153.653c.48 0 .852.373.852.852V337.25c-.053.48-.426.852-.852.852zm104.69-169.104v29.942h70.114v42.303h-70.113v29.942h70.113v42.303h-70.113v29.942h70.113v42.303h-70.113v10.495c0 25.2-20.512 45.713-45.713 45.713h-10.389V512h-42.302v-70.06H313.06V512h-42.303v-70.06h-29.942V512h-42.303v-70.06h-29.942V512h-42.303v-70.06h-2.184c-25.2 0-45.712-20.512-45.712-45.713v-10.495H0v-42.303h78.372v-29.942H0v-42.303h78.372v-29.942H0v-42.303h78.372v-29.942H0v-42.302h78.372v-2.078c0-25.2 20.512-45.713 45.712-45.713h2.184V.48h42.303v78.424h29.942V.48h42.303v78.424h29.942V.48h42.303v78.424h29.942V.48h42.302v78.424h10.39c25.2 0 45.712 20.512 45.712 45.713v2.078h70.113v42.302h-70.113zm-21.15-44.38c0-13.533-11.029-24.561-24.562-24.561h-271.61c-13.532 0-24.561 11.028-24.561 24.56v271.611c0 13.533 11.029 24.561 24.561 24.561h271.61c13.533 0 24.562-11.028 24.562-24.56V124.616z',
  ],
};
