/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ColorsDTO,
    ColorsDTOFromJSON,
    ColorsDTOFromJSONTyped,
    ColorsDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface ColorsConfigDTO
 */
export interface ColorsConfigDTO {
    /**
     * 
     * @type {Array<ColorsDTO>}
     * @memberof ColorsConfigDTO
     */
    colors?: Array<ColorsDTO>;
}

export function ColorsConfigDTOFromJSON(json: any): ColorsConfigDTO {
    return ColorsConfigDTOFromJSONTyped(json, false);
}

export function ColorsConfigDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ColorsConfigDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'colors': !exists(json, 'colors') ? undefined : ((json['colors'] as Array<any>).map(ColorsDTOFromJSON)),
    };
}

export function ColorsConfigDTOToJSON(value?: ColorsConfigDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'colors': value.colors === undefined ? undefined : ((value.colors as Array<any>).map(ColorsDTOToJSON)),
    };
}


