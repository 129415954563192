// Libs
import React from 'react';

import { bemBlock } from '../../modules/bem';
import { LayoutColumn, LayoutColumnProps } from '../layout-column/LayoutColumn';
import { ViewBody } from '../view-body/ViewBody';
import { ViewContent } from '../view-content/ViewContent';
import { ViewHeader } from '../view-header/ViewHeader';
import { ViewSidebar } from '../view-sidebar/ViewSidebar';
import { ViewTitle } from '../view-title/ViewTitle';

// Module
import './View.less';

const block = bemBlock('n-View');

export type ViewProps = Omit<LayoutColumnProps<{}>, 'component' | 'height' | 'span' | 'width'> & {
  withBackground?: boolean;
};

/**
 * `View` is one of the top level UI building blocks of Application.
 * It is based on [LayoutColumn](#layoutcolumn).
 *
 * ## Import
 *
 * ```
 * import { View } from '@neptune/shared/venus-ui';
 * ```
 *
 * ## Shorthands to *view* components
 *
 * All View related components are exposed as properties of `View`.
 *
 * | Component                   | Property name | Usage              |
 * | --------------------------- | ------------- | ------------------ |
 * | [ViewBody](#viewbody)       | `Body`        | `<View.Body />`    |
 * | [ViewContent](#viewcontent) | `Content`     | `<View.Content />` |
 * | [ViewHeader](#viewheader)   | `Header`      | `<View.Header />`  |
 * | [ViewSidebar](#viewsidebar) | `Sidebar`     | `<View.Sidebar />` |
 * | [ViewTitle](#viewtitle)     | `Title`       | `<View.Title />`   |
 *
 * ## Predefined layouts
 *
 * Application view can be composed with `View` and `Section` components.
 *
 * `View` gives the structure and is a top level component to define application content.
 *
 * View can be divided to optional *header* and *body*.
 * Header can consist of *title* and *navigation*, both of which are optional.
 * Body can consist of *sidebars* and *content*, where *sidebar* is optional.
 *
 * *Section* holds the content.
 * It is recommended to put *view's* content within the *section*.
 * The content will be more organized and portable.
 * Accompanying *section's title* will make UI to look more consistent.
 */
export class View extends React.Component<ViewProps> {
  public static defaultProps = {
    component: 'main',
    spacedChildren: 'lg',
    withPadding: 'xl',
  };

  public static Body = ViewBody;

  public static Content = ViewContent;

  public static Header = ViewHeader;

  public static Sidebar = ViewSidebar;

  public static Title = ViewTitle;

  render() {
    const { className, withBackground, ...passProps } = this.props;

    return (
      <LayoutColumn
        className={block({ modifiers: { withBackground }, extra: className })}
        {...passProps}
      />
    );
  }
}
