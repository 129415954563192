/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PricingPlanDTO,
    PricingPlanDTOFromJSON,
    PricingPlanDTOFromJSONTyped,
    PricingPlanDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface OrganizationCreationParamsDTO
 */
export interface OrganizationCreationParamsDTO {
    /**
     * 
     * @type {string}
     * @memberof OrganizationCreationParamsDTO
     */
    organizationName: string;
    /**
     * 
     * @type {PricingPlanDTO}
     * @memberof OrganizationCreationParamsDTO
     */
    pricingPlan: PricingPlanDTO;
}

export function OrganizationCreationParamsDTOFromJSON(json: any): OrganizationCreationParamsDTO {
    return OrganizationCreationParamsDTOFromJSONTyped(json, false);
}

export function OrganizationCreationParamsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationCreationParamsDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'organizationName': json['organizationName'],
        'pricingPlan': PricingPlanDTOFromJSON(json['pricingPlan']),
    };
}

export function OrganizationCreationParamsDTOToJSON(value?: OrganizationCreationParamsDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'organizationName': value.organizationName,
        'pricingPlan': PricingPlanDTOToJSON(value.pricingPlan),
    };
}


