import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { GlyphName } from '@neptune/shared/core-glyphs-domain';
import { bemBlock } from '../../modules/bem';
import { Icon } from '../icon/Icon';
import { Layout } from '../layout/Layout';

import './InfoBar.less';

const levelToIcon: Record<'info' | 'warning', GlyphName> = {
  warning: 'exclamation-triangle',
  info: 'i-circled',
};

const block = bemBlock('info-bar');

export const InfoBar: React.FC<{
  canBeClosedManually?: boolean;
  className?: string;
  dataRole?: string;
  glyph?: GlyphName;
  level?: 'info' | 'warning';
  visible?: boolean;
}> = ({
  canBeClosedManually = false,
  children,
  className,
  dataRole,
  glyph,
  level = 'warning',
  visible: visibleProp = true,
}) => {
  const [visible, setVisible] = React.useState(visibleProp);

  React.useEffect(() => setVisible(visibleProp), [visibleProp]);

  const close = React.useCallback(() => setVisible(false), []);

  return (
    <CSSTransition
      classNames={block('animation')}
      in={visible}
      timeout={400}
      mountOnEnter
      unmountOnExit
    >
      <Layout.Row
        className={block({ extra: className, modifiers: { level } })}
        alignItems="center"
        span="auto"
        withGutter="md"
        data-role={dataRole}
      >
        <Layout.Element withGutter="md">
          <Icon glyph={glyph ?? levelToIcon[level]} size="lg" />
        </Layout.Element>
        <Layout.Row>{children}</Layout.Row>
        {canBeClosedManually ? (
          <Icon className={block('close-icon')} onClick={close} glyph="times" size="lg" />
        ) : null}
      </Layout.Row>
    </CSSTransition>
  );
};
