import { CustomIconDefinition } from '../types';

export const nepCross: CustomIconDefinition = {
  prefix: 'nep',
  iconName: 'cross',
  icon: [
    1000,
    1000,
    [],
    'e143',
    'M905 150.4L850.3 95.7 500.7 445.4 151 95.7 96.4 150.4 446 500 96.4 849.7 151 904.3 500.7 554.7 850.3 904.3 905 849.7 555.4 500Z',
  ],
};
