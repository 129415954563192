/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateTagsParams
 */
export interface UpdateTagsParams {
    /**
     * 
     * @type {string}
     * @memberof UpdateTagsParams
     */
    attributePathToBeUpdated?: UpdateTagsParamsAttributePathToBeUpdatedEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateTagsParams
     */
    experimentIdentifiers: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateTagsParams
     */
    tagsToAdd: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateTagsParams
     */
    tagsToDelete: Array<string>;
}

export function UpdateTagsParamsFromJSON(json: any): UpdateTagsParams {
    return UpdateTagsParamsFromJSONTyped(json, false);
}

export function UpdateTagsParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateTagsParams {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attributePathToBeUpdated': !exists(json, 'attributePathToBeUpdated') ? undefined : json['attributePathToBeUpdated'],
        'experimentIdentifiers': json['experimentIdentifiers'],
        'tagsToAdd': json['tagsToAdd'],
        'tagsToDelete': json['tagsToDelete'],
    };
}

export function UpdateTagsParamsToJSON(value?: UpdateTagsParams | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attributePathToBeUpdated': value.attributePathToBeUpdated,
        'experimentIdentifiers': value.experimentIdentifiers,
        'tagsToAdd': value.tagsToAdd,
        'tagsToDelete': value.tagsToDelete,
    };
}

/**
* @export
* @enum {string}
*/
export enum UpdateTagsParamsAttributePathToBeUpdatedEnum {
    Tags = 'sys/tags',
    GroupTags = 'sys/group_tags'
}


