import Bowser from 'bowser';

const browser = Bowser.getParser(window.navigator.userAgent);

// Predicate for full browser support.
// In browsers that are not fully supported, some features may be broken,
// and we will show information about supported browsers.
export function isBrowserFullySupported(): boolean {
  const supportedBrowsers = {
    chrome: '>=99',
    chromium: '>=99',
    edge: '>=100',
    firefox: '>=97',
    opera: '>=85',
    safari: '>=14',
    vivaldi: '>=5',
  };

  // "satisfies" returns undefined if it doesn't find current browser in passedParams, so for us it means not supported
  return browser.satisfies(supportedBrowsers) || false;
}

// Predicate for browser implementing our minimal security requirements.
// In browsers that are not allowed, the application won't work at all and
// we will show just an info banner at an early stage.
export function isBrowserAllowed(): boolean {
  return isIframeSandboxSupported();
}

export function isMobile(): boolean {
  return browser.getPlatformType(true) === Bowser.PLATFORMS_MAP.mobile;
}

export function isTablet(): boolean {
  return browser.getPlatformType(true) === Bowser.PLATFORMS_MAP.tablet;
}

export function getPlatformType(): string {
  return browser.getPlatformType(true);
}

export function isIframeSandboxSupported(): boolean {
  return 'sandbox' in document.createElement('iframe');
}
