export enum NodeType {
  Branch = 'branch',
  Leaf = 'leaf',
}

export type Leaf<T> = {
  name: string;
  value: T;
  type: NodeType.Leaf;
};

export type Branch<T> = {
  name: string;
  children: { [key: string]: TreeNode<T> };
  type: NodeType.Branch;
};

export type TreeNode<T> = Leaf<T> | Branch<T>;

export function makeTreeFromArray<T>(array: T[], nameGetter: (item: T) => string): Branch<T> {
  const tree: Branch<T> = createBranch<T>('root', Object.create(null));

  array.forEach((item) => {
    const path = nameGetter(item)
      .split('/')
      .filter((el) => el !== '');
    let parent = tree;
    let name = path.shift();

    while (name) {
      if (path.length) {
        const branchName = `${name}/`;
        parent.children[branchName] =
          parent.children[branchName] || createBranch(branchName, Object.create(null));
        parent = parent.children[branchName] as Branch<T>;
      } else {
        parent.children[name] = parent.children[name] || createLeaf(name, item);
      }

      name = path.shift();
    }
  });

  return tree;
}

function createBranch<T>(name: string, children: { [key: string]: TreeNode<T> }): Branch<T> {
  const branch: Branch<T> = Object.create(null);

  branch['name'] = name;
  branch['children'] = children;
  branch['type'] = NodeType.Branch;

  return branch;
}

function createLeaf<T>(name: string, value: T): Leaf<T> {
  const leaf: Leaf<T> = Object.create(null);

  leaf['name'] = name;
  leaf['value'] = value;
  leaf['type'] = NodeType.Leaf;

  return leaf;
}

export function isBranch<T>(node: TreeNode<T>): node is Branch<T> {
  return node.type === NodeType.Branch;
}

export function isLeaf<T>(node: TreeNode<T>): node is Leaf<T> {
  return node.type === NodeType.Leaf;
}

export class PathError extends Error {}

/**
 * @throws PathError
 */
function getTreeNodesForPathThrowable<T>(tree: Branch<T>, path: string): TreeNode<T>[] {
  const sortPriority = {
    [NodeType.Branch]: 0,
    [NodeType.Leaf]: 1,
  };

  function sortByType(a: TreeNode<T>, b: TreeNode<T>): number {
    return sortPriority[a.type] - sortPriority[b.type];
  }

  if (!path || path === '.') {
    return Object.values(tree.children).sort(sortByType);

    // todo: sorting by natural string comparator was disabled for performance reasons. It is slow for big arrays (1M+) of strings.
    // .sort((a, b) => naturalStringComparator(a.name, b.name));
  }

  const parts = path.split('/');
  let current: Branch<T> = tree;
  let part = parts.shift();

  while (part) {
    if (current.children) {
      const child = current.children[`${part}/`];

      if (!child || !isBranch(child)) {
        throw new PathError();
      }

      current = child;
    }

    part = parts.shift();
  }

  return Object.values(current.children).sort(sortByType);

  // todo: sorting by natural string comparator was disabled for performance reasons. It is slow for big arrays (1M+) of strings.
  // .sort((a, b) => naturalStringComparator(a.name, b.name));
}

export function getTreeNodesForPath<T>(
  tree: Branch<T>,
  path: string,
): { error: Error | null; values: TreeNode<T>[] } {
  try {
    return {
      error: null,
      values: getTreeNodesForPathThrowable<T>(tree, path),
    };
  } catch (error) {
    if (error instanceof PathError) {
      return {
        error,
        values: [],
      };
    }

    throw error;
  }
}

export function getLeafForPath<T>(
  tree: Branch<T>,
  path: string,
  name: string,
): Leaf<T> | undefined {
  const treeNodes = getTreeNodesForPath<T>(tree, path);
  const treeNode = treeNodes.values.find((current) => current.name === name);
  return treeNode && isLeaf(treeNode) ? treeNode : undefined;
}

export function getTreeNodeName<T>(node: TreeNode<T>): string {
  return isLeaf(node) ? node.name : node.name.substr(0, node.name.length - 1);
}
