/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AttributeDefinitionDTO,
    AttributeDefinitionDTOFromJSON,
    AttributeDefinitionDTOFromJSONTyped,
    AttributeDefinitionDTOToJSON,
    NextPageDTO,
    NextPageDTOFromJSON,
    NextPageDTOFromJSONTyped,
    NextPageDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface QueryAttributeDefinitionsResultDTO
 */
export interface QueryAttributeDefinitionsResultDTO {
    /**
     * 
     * @type {Array<AttributeDefinitionDTO>}
     * @memberof QueryAttributeDefinitionsResultDTO
     */
    entries: Array<AttributeDefinitionDTO>;
    /**
     * 
     * @type {NextPageDTO}
     * @memberof QueryAttributeDefinitionsResultDTO
     */
    nextPage: NextPageDTO;
}

export function QueryAttributeDefinitionsResultDTOFromJSON(json: any): QueryAttributeDefinitionsResultDTO {
    return QueryAttributeDefinitionsResultDTOFromJSONTyped(json, false);
}

export function QueryAttributeDefinitionsResultDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): QueryAttributeDefinitionsResultDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entries': ((json['entries'] as Array<any>).map(AttributeDefinitionDTOFromJSON)),
        'nextPage': NextPageDTOFromJSON(json['nextPage']),
    };
}

export function QueryAttributeDefinitionsResultDTOToJSON(value?: QueryAttributeDefinitionsResultDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entries': ((value.entries as Array<any>).map(AttributeDefinitionDTOToJSON)),
        'nextPage': NextPageDTOToJSON(value.nextPage),
    };
}


