export const prepareRegexpSearchWithAnchors = (input: string): string => {
  const userInput = input.trim();

  if (!userInput) {
    return '.*';
  }

  return `.*(${userInput}).*`
    .replace(/^...\^/, '(') // handles user provided ^ at the beginning
    .replace(/\$...$/, ')'); // handles user provided $ at the end
};

export const convertRegexpSearchToFilter = (search?: string, exclude?: string) => {
  return search || exclude
    ? {
        mustMatchRegexes: search ? [search] : undefined,
        mustNotMatchRegexes: exclude ? [exclude] : undefined,
      }
    : undefined;
};

export const isValidRegExp = (regexp: string) => {
  try {
    new RegExp(regexp);
  } catch (e) {
    return false;
  }

  return true;
};
