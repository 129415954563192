import React from 'react';

/**
 * Stops the propagation of the given React UI event.
 *
 * Do not create arrow functions (e: UIEvent) => { e.stopPropagation() } on every rerender in N components use this one.
 *
 * @param {React.UIEvent} event - The React UI event to stop propagation for.
 * @returns {void} - This function does not return any value.
 *
 * @example
 * <Button onMouseDown={stopPropagation}>Click me!</Button>
 */
export function stopPropagation(event: React.UIEvent) {
  event.stopPropagation();
}
