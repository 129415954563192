import Icon from './Icon';
import IconFA from './IconFA';
import IconLayered from './IconLayered';
import IconStack from './IconStack';

Icon.FA = IconFA;
Icon.Layered = IconLayered;
Icon.Stack = IconStack;

export {
  Icon,
  IconFA,
  IconLayered,
  IconStack,
};

export default Icon;
