/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CreateSessionParamsDTO,
    CreateSessionParamsDTOFromJSON,
    CreateSessionParamsDTOFromJSONTyped,
    CreateSessionParamsDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface MonitoringHoursPurchaseRequestDTO
 */
export interface MonitoringHoursPurchaseRequestDTO {
    /**
     * 
     * @type {number}
     * @memberof MonitoringHoursPurchaseRequestDTO
     */
    additionalHours: number;
    /**
     * 
     * @type {CreateSessionParamsDTO}
     * @memberof MonitoringHoursPurchaseRequestDTO
     */
    sessionParams: CreateSessionParamsDTO;
}

export function MonitoringHoursPurchaseRequestDTOFromJSON(json: any): MonitoringHoursPurchaseRequestDTO {
    return MonitoringHoursPurchaseRequestDTOFromJSONTyped(json, false);
}

export function MonitoringHoursPurchaseRequestDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitoringHoursPurchaseRequestDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'additionalHours': json['additionalHours'],
        'sessionParams': CreateSessionParamsDTOFromJSON(json['sessionParams']),
    };
}

export function MonitoringHoursPurchaseRequestDTOToJSON(value?: MonitoringHoursPurchaseRequestDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'additionalHours': value.additionalHours,
        'sessionParams': CreateSessionParamsDTOToJSON(value.sessionParams),
    };
}


