// Libs
import React from 'react';
import { bemBlock, Text } from '@neptune/shared/venus-ui';
// eslint-disable-next-line no-restricted-imports
import { WithPaddingRenderer } from 'neptune-core-ui/components/table/renderers';

// Module
import type { PropertyDiffTableEntry } from '../types';
import './LabelRenderer.less';

const block = bemBlock('property-diff-label-renderer');

export const LabelRenderer: React.FC<{ data: PropertyDiffTableEntry }> = ({ data }) => {
  return (
    <WithPaddingRenderer>
      <Text className={block()} fontWeight="semibold">
        {data.label}
      </Text>
    </WithPaddingRenderer>
  );
};
