/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LinkTypeDTO,
    LinkTypeDTOFromJSON,
    LinkTypeDTOFromJSONTyped,
    LinkTypeDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserProfileLinkDTO
 */
export interface UserProfileLinkDTO {
    /**
     * 
     * @type {LinkTypeDTO}
     * @memberof UserProfileLinkDTO
     */
    linkType: LinkTypeDTO;
    /**
     * 
     * @type {string}
     * @memberof UserProfileLinkDTO
     */
    url: string;
}

export function UserProfileLinkDTOFromJSON(json: any): UserProfileLinkDTO {
    return UserProfileLinkDTOFromJSONTyped(json, false);
}

export function UserProfileLinkDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserProfileLinkDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'linkType': LinkTypeDTOFromJSON(json['linkType']),
        'url': json['url'],
    };
}

export function UserProfileLinkDTOToJSON(value?: UserProfileLinkDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'linkType': LinkTypeDTOToJSON(value.linkType),
        'url': value.url,
    };
}


