/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ColorsConfigDTO,
    ColorsConfigDTOFromJSON,
    ColorsConfigDTOFromJSONTyped,
    ColorsConfigDTOToJSON,
    DashboardConfigDTO,
    DashboardConfigDTOFromJSON,
    DashboardConfigDTOFromJSONTyped,
    DashboardConfigDTOToJSON,
    DashboardLayoutsDTO,
    DashboardLayoutsDTOFromJSON,
    DashboardLayoutsDTOFromJSONTyped,
    DashboardLayoutsDTOToJSON,
    TableViewParamsDTO,
    TableViewParamsDTOFromJSON,
    TableViewParamsDTOFromJSONTyped,
    TableViewParamsDTOToJSON,
    WidgetDTO,
    WidgetDTOFromJSON,
    WidgetDTOFromJSONTyped,
    WidgetDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface DashboardDTO
 */
export interface DashboardDTO {
    /**
     * 
     * @type {number}
     * @memberof DashboardDTO
     */
    autoComparePool?: number;
    /**
     * 
     * @type {string}
     * @memberof DashboardDTO
     */
    color?: string;
    /**
     * 
     * @type {ColorsConfigDTO}
     * @memberof DashboardDTO
     */
    colorsConfig?: ColorsConfigDTO;
    /**
     * 
     * @type {DashboardConfigDTO}
     * @memberof DashboardDTO
     */
    config?: DashboardConfigDTO;
    /**
     * 
     * @type {Date}
     * @memberof DashboardDTO
     */
    createdTime?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof DashboardDTO
     */
    draft: boolean;
    /**
     * 
     * @type {string}
     * @memberof DashboardDTO
     */
    experimentShortId?: string;
    /**
     * 
     * @type {string}
     * @memberof DashboardDTO
     */
    icon?: string;
    /**
     * 
     * @type {string}
     * @memberof DashboardDTO
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof DashboardDTO
     */
    lastUpdatedTime?: Date;
    /**
     * 
     * @type {DashboardLayoutsDTO}
     * @memberof DashboardDTO
     */
    layouts: DashboardLayoutsDTO;
    /**
     * 
     * @type {string}
     * @memberof DashboardDTO
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof DashboardDTO
     */
    owner?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DashboardDTO
     */
    runIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DashboardDTO
     */
    type: DashboardDTOTypeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof DashboardDTO
     */
    userExperimentNames?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DashboardDTO
     */
    versionBranch: string;
    /**
     * 
     * @type {string}
     * @memberof DashboardDTO
     */
    versionDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof DashboardDTO
     */
    versionName?: string;
    /**
     * 
     * @type {TableViewParamsDTO}
     * @memberof DashboardDTO
     */
    viewParams?: TableViewParamsDTO;
    /**
     * 
     * @type {Array<WidgetDTO>}
     * @memberof DashboardDTO
     */
    widgets: Array<WidgetDTO>;
}

export function DashboardDTOFromJSON(json: any): DashboardDTO {
    return DashboardDTOFromJSONTyped(json, false);
}

export function DashboardDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): DashboardDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'autoComparePool': !exists(json, 'autoComparePool') ? undefined : json['autoComparePool'],
        'color': !exists(json, 'color') ? undefined : json['color'],
        'colorsConfig': !exists(json, 'colorsConfig') ? undefined : ColorsConfigDTOFromJSON(json['colorsConfig']),
        'config': !exists(json, 'config') ? undefined : DashboardConfigDTOFromJSON(json['config']),
        'createdTime': !exists(json, 'createdTime') ? undefined : (new Date(json['createdTime'])),
        'draft': json['draft'],
        'experimentShortId': !exists(json, 'experimentShortId') ? undefined : json['experimentShortId'],
        'icon': !exists(json, 'icon') ? undefined : json['icon'],
        'id': json['id'],
        'lastUpdatedTime': !exists(json, 'lastUpdatedTime') ? undefined : (new Date(json['lastUpdatedTime'])),
        'layouts': DashboardLayoutsDTOFromJSON(json['layouts']),
        'name': json['name'],
        'owner': !exists(json, 'owner') ? undefined : json['owner'],
        'runIds': !exists(json, 'runIds') ? undefined : json['runIds'],
        'type': json['type'],
        'userExperimentNames': !exists(json, 'userExperimentNames') ? undefined : json['userExperimentNames'],
        'versionBranch': json['versionBranch'],
        'versionDescription': !exists(json, 'versionDescription') ? undefined : json['versionDescription'],
        'versionName': !exists(json, 'versionName') ? undefined : json['versionName'],
        'viewParams': !exists(json, 'viewParams') ? undefined : TableViewParamsDTOFromJSON(json['viewParams']),
        'widgets': ((json['widgets'] as Array<any>).map(WidgetDTOFromJSON)),
    };
}

export function DashboardDTOToJSON(value?: DashboardDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'autoComparePool': value.autoComparePool,
        'color': value.color,
        'colorsConfig': ColorsConfigDTOToJSON(value.colorsConfig),
        'config': DashboardConfigDTOToJSON(value.config),
        'createdTime': value.createdTime === undefined ? undefined : (value.createdTime.toISOString()),
        'draft': value.draft,
        'experimentShortId': value.experimentShortId,
        'icon': value.icon,
        'id': value.id,
        'lastUpdatedTime': value.lastUpdatedTime === undefined ? undefined : (value.lastUpdatedTime.toISOString()),
        'layouts': DashboardLayoutsDTOToJSON(value.layouts),
        'name': value.name,
        'owner': value.owner,
        'runIds': value.runIds,
        'type': value.type,
        'userExperimentNames': value.userExperimentNames,
        'versionBranch': value.versionBranch,
        'versionDescription': value.versionDescription,
        'versionName': value.versionName,
        'viewParams': TableViewParamsDTOToJSON(value.viewParams),
        'widgets': ((value.widgets as Array<any>).map(WidgetDTOToJSON)),
    };
}

/**
* @export
* @enum {string}
*/
export enum DashboardDTOTypeEnum {
    Compare = 'compare',
    Experiment = 'experiment',
    Report = 'report'
}


