import React, { KeyboardEventHandler } from 'react';
import { isFunction } from 'lodash';
import { DropdownSubmenuToggle } from '../dropdown-submenu-toggle/DropdownSubmenuToggle';
import { DropdownToggle, DropdownToggleInterface } from '../dropdown-toggle/DropdownToggle';
import { DropdownMenu } from '../dropdown-menu/DropdownMenu';
import { DropdownItem } from '../dropdown-item/DropdownItem';
import { DropdownSeparator } from '../dropdown-separator/DropdownSeparator';
import { DropdownGroup } from '../dropdown-menu/DropdownGroup';
import { DropdownTitle } from '../dropdown-title/DropdownTitle';
import {
  IOnOutsideClick,
  DropdownTetherProps,
  DropdownTether,
  HackedTether,
} from '../dropdown-tether/DropdownTether';
import {
  DropdownMenuAnimation,
  DropdownMenuAnimationConfiguration,
} from '../dropdown-menu-animation/DropdownMenuAnimation';

export type DropdownChildrenRendererParams = {
  toggle: () => void;
  collapse: () => void;
  expand: () => void;
};

export type DropdownChildrenRenderer = (params: DropdownChildrenRendererParams) => React.ReactNode;

export type DropdownProps = Partial<DropdownTetherProps> & {
  disabled?: boolean;
  onExpand?: () => void;
  onCollapse?: () => void;
  onOutsideClick?: IOnOutsideClick;
  onKeyDown?: (event: React.KeyboardEvent, collapse: () => void) => void;
  onKeyUp?: (event: React.KeyboardEvent, collapse: () => void) => void;
  toggle: React.ReactElement<DropdownToggleInterface> | string;
  children?: React.ReactNode | DropdownChildrenRenderer;
  menuAnimation?: DropdownMenuAnimationConfiguration;
  elementRef?: React.MutableRefObject<HackedTether | null>;
};

type DropdownState = {
  expanded: boolean;
  pendingExitAnimation: boolean;
};

export class Dropdown extends React.PureComponent<DropdownProps, DropdownState> {
  public static Toggle = DropdownToggle;
  public static SubmenuToggle = DropdownSubmenuToggle;
  public static Menu = DropdownMenu;
  public static Tether = DropdownTether;
  public static Item = DropdownItem;
  public static Group = DropdownGroup;
  public static Separator = DropdownSeparator;
  public static Title = DropdownTitle;

  state = {
    expanded: false,
    pendingExitAnimation: false,
  };

  componentDidUpdate(_: DropdownProps, prevState: DropdownState) {
    if (prevState.expanded === false && this.state.expanded) {
      if (isFunction(this.props.onExpand)) {
        this.props.onExpand();
      }
    }

    if (prevState.expanded === true && !this.state.expanded) {
      if (isFunction(this.props.onCollapse)) {
        this.props.onCollapse();
      }
    }
  }

  handleMenuExited = () => {
    this.setState({ pendingExitAnimation: false });
  };

  collapse = () => {
    this.setState((prev) => ({
      expanded: false,
      pendingExitAnimation: prev.expanded && !!this.props.menuAnimation,
    }));
  };

  expand = () => {
    this.setState({
      expanded: true,
      pendingExitAnimation: false,
    });
  };

  toggle = () => {
    this.setState((prev) => ({
      expanded: !prev.expanded,
      pendingExitAnimation: prev.expanded && !!this.props.menuAnimation,
    }));
  };

  closeOnEscape: KeyboardEventHandler = (event) => {
    if (!this.state.expanded) {
      return;
    }

    if (event.key === 'Escape') {
      event.stopPropagation();
      this.collapse();
    }
  };

  handleKeyDown: React.KeyboardEventHandler = this.props.onKeyDown
    ? (event) => this.props.onKeyDown?.(event, this.collapse)
    : this.closeOnEscape;

  handleKeyUp: React.KeyboardEventHandler = (event) => {
    this.props.onKeyUp?.(event, this.collapse);
  };

  outsideClickHandler = (ev: MouseEvent) => {
    if (this.props.onOutsideClick) {
      this.props.onOutsideClick(ev, this.collapse);
    } else {
      this.collapse();
    }
  };

  renderChildren() {
    const { children, menuAnimation } = this.props;

    if (!this.state.expanded && !this.state.pendingExitAnimation) {
      return null;
    }

    const getChildrenComponent = () => {
      if (isFunction(children)) {
        return children({
          toggle: this.toggle,
          collapse: this.collapse,
          expand: this.expand,
        });
      }

      return children;
    };

    if (menuAnimation) {
      return (
        <DropdownMenuAnimation
          onExited={this.handleMenuExited}
          menuAnimation={menuAnimation}
          in={this.state.expanded}
        >
          {getChildrenComponent()}
        </DropdownMenuAnimation>
      );
    }

    return getChildrenComponent();
  }

  render() {
    const { disabled, toggle, onOutsideClick, ...tetherProps } = this.props;

    const { expanded } = this.state;

    const toggleWithProps =
      typeof toggle === 'string' ? (
        <DropdownToggle
          pressed={expanded}
          disabled={disabled}
          label={toggle}
          onToggle={disabled ? undefined : this.toggle}
        />
      ) : (
        React.cloneElement(toggle, {
          pressed: expanded,
          disabled,
          onToggle: disabled ? undefined : this.toggle,
          onCollapse: this.collapse,
          onExpand: this.expand,
          onKeyDown: (event: React.KeyboardEvent) => {
            toggle.props?.onKeyDown?.(event);
            this.closeOnEscape(event);
          },
        })
      );

    return (
      <DropdownTether
        onOutsideClick={expanded ? this.outsideClickHandler : undefined}
        onElementKeyDown={this.handleKeyDown}
        onElementKeyUp={this.handleKeyUp}
        autoWidthMenu="none"
        {...tetherProps}
      >
        {toggleWithProps}
        {this.renderChildren()}
      </DropdownTether>
    );
  }
}
