// Libs
import React from 'react';

import { bemBlock } from '../../modules/bem';
import { Icon } from '../icon/Icon';
import { Button } from '../button/Button';
import { LayoutRow, LayoutRowProps } from '../layout-row/LayoutRow';

// Module
import './MultiselectToggle.less';

export type MultiselectToggleProps = LayoutRowProps<{}> & {
  'data-role'?: string;
  disabled?: boolean;
  error?: boolean;
  pressed?: boolean;
  iconDisabled?: boolean;
  onBlur?: React.ReactEventHandler;
  onChange?: React.ReactEventHandler;
  onCollapse?: () => void;
  onExpand?: () => void;
  onFocus?: React.ReactEventHandler;
  onKeyDown?: React.ReactEventHandler;
  onToggle?: React.ReactEventHandler;
  placeholder?: string;
  text?: string;
};

const block = bemBlock('n-MultiselectToggle');

export class MultiselectToggle extends React.PureComponent<MultiselectToggleProps> {
  public static defaultProps = {
    'data-role': 'multiselect-toggle',
    span: 'auto',
    spacedChildren: 'sm',
    withGutter: 'sm',
  };

  input: HTMLInputElement | null = null;

  componentDidUpdate(prevProps: MultiselectToggleProps) {
    const { text, onCollapse } = this.props;

    if (onCollapse && prevProps.text !== text && text?.length === 0) {
      onCollapse();
    }
  }

  onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { onExpand, onChange } = this.props;

    if (onChange) {
      onChange(event); // was onChange(event.target.value);
    }

    if (onExpand && event.target.value.length > 0) {
      onExpand();
    }
  };

  focusInput = () => {
    if (this.input) {
      this.input.focus();
    }
  };

  render() {
    const {
      children,
      className,
      'data-role': dataRole,
      disabled,
      error,
      pressed,
      iconDisabled,
      onBlur,
      onChange,
      onCollapse,
      onExpand,
      onFocus,
      onKeyDown,
      onToggle,
      placeholder,
      text,
      ...passProps
    } = this.props;

    return (
      <LayoutRow
        className={block({
          modifiers: {
            disabled,
            error,
            pressed,
          },
          extra: [className],
        })}
        data-role={dataRole}
        onClick={this.focusInput}
        alignItems="center"
        {...passProps}
      >
        <LayoutRow
          className={block('container')}
          overflow="auto"
          alignItems="center"
          withCustomScrollbar
          wrap="wrap"
        >
          {children}
          {!disabled && (
            <input
              ref={(ref) => (this.input = ref)}
              className={block('input')}
              type="text"
              placeholder={placeholder}
              value={text}
              onKeyDown={onKeyDown}
              onFocus={onFocus}
              onBlur={onBlur}
              onChange={this.onChange}
            />
          )}
        </LayoutRow>
        <Button
          variant="transparent"
          className={block('button')}
          data-role={`${dataRole}-button`}
          withPadding={['none', 'xs']}
          onClick={onToggle}
          disabled={disabled || iconDisabled}
        >
          <Icon
            className={block({
              element: 'icon',
              modifiers: { disabled: disabled === true || iconDisabled === true },
            })}
            glyph="caret-down"
            data-role={`${dataRole}-icon`}
          />
        </Button>
      </LayoutRow>
    );
  }
}
