import React from 'react';
import {
  Dropdown,
  DropdownChildrenRenderer,
  DropdownToggleInterface,
  Emblem,
  EmblemProps,
} from '@neptune/shared/venus-ui';

const DropdownToggle = ({
  onToggle,
  children,
}: DropdownToggleInterface & { children: React.ReactNode }) => (
  <span onClick={onToggle}>{children}</span>
);

type EmblemWithDropdownMenuProps = Omit<
  EmblemProps,
  'renderEndDetail' | 'onEndDetailClick' | 'endDetailGlyph'
> & {
  renderDropdownMenu: DropdownChildrenRenderer;
};

export const EmblemWithDropdownMenu: React.FC<EmblemWithDropdownMenuProps> = ({
  renderDropdownMenu,
  ...rest
}) => {
  return (
    <Emblem
      endDetailGlyph="dots-horizontal"
      renderEndDetail={(endDetail) => {
        return (
          <Dropdown toggle={<DropdownToggle>{endDetail}</DropdownToggle>}>
            {renderDropdownMenu}
          </Dropdown>
        );
      }}
      {...rest}
    />
  );
};
