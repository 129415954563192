/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    QueryLeaderboardParamsFieldDTO,
    QueryLeaderboardParamsFieldDTOFromJSON,
    QueryLeaderboardParamsFieldDTOFromJSONTyped,
    QueryLeaderboardParamsFieldDTOToJSON,
    QueryLeaderboardParamsOpenedGroupWithPaginationParamsDTO,
    QueryLeaderboardParamsOpenedGroupWithPaginationParamsDTOFromJSON,
    QueryLeaderboardParamsOpenedGroupWithPaginationParamsDTOFromJSONTyped,
    QueryLeaderboardParamsOpenedGroupWithPaginationParamsDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface QueryLeaderboardParamsGroupingParamsDTO
 */
export interface QueryLeaderboardParamsGroupingParamsDTO {
    /**
     * 
     * @type {Array<QueryLeaderboardParamsFieldDTO>}
     * @memberof QueryLeaderboardParamsGroupingParamsDTO
     */
    groupBy: Array<QueryLeaderboardParamsFieldDTO>;
    /**
     * 
     * @type {Array<string>}
     * @memberof QueryLeaderboardParamsGroupingParamsDTO
     */
    openedGroups?: Array<string>;
    /**
     * 
     * @type {Array<QueryLeaderboardParamsOpenedGroupWithPaginationParamsDTO>}
     * @memberof QueryLeaderboardParamsGroupingParamsDTO
     */
    openedGroupsWithPagination?: Array<QueryLeaderboardParamsOpenedGroupWithPaginationParamsDTO>;
}

export function QueryLeaderboardParamsGroupingParamsDTOFromJSON(json: any): QueryLeaderboardParamsGroupingParamsDTO {
    return QueryLeaderboardParamsGroupingParamsDTOFromJSONTyped(json, false);
}

export function QueryLeaderboardParamsGroupingParamsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): QueryLeaderboardParamsGroupingParamsDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'groupBy': ((json['groupBy'] as Array<any>).map(QueryLeaderboardParamsFieldDTOFromJSON)),
        'openedGroups': !exists(json, 'openedGroups') ? undefined : json['openedGroups'],
        'openedGroupsWithPagination': !exists(json, 'openedGroupsWithPagination') ? undefined : ((json['openedGroupsWithPagination'] as Array<any>).map(QueryLeaderboardParamsOpenedGroupWithPaginationParamsDTOFromJSON)),
    };
}

export function QueryLeaderboardParamsGroupingParamsDTOToJSON(value?: QueryLeaderboardParamsGroupingParamsDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'groupBy': ((value.groupBy as Array<any>).map(QueryLeaderboardParamsFieldDTOToJSON)),
        'openedGroups': value.openedGroups,
        'openedGroupsWithPagination': value.openedGroupsWithPagination === undefined ? undefined : ((value.openedGroupsWithPagination as Array<any>).map(QueryLeaderboardParamsOpenedGroupWithPaginationParamsDTOToJSON)),
    };
}


