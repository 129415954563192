import { CustomIconDefinition } from '../types';

export const nepTrashBin: CustomIconDefinition = {
  prefix: 'nep',
  iconName: 'trash-bin',
  icon: [
    1000,
    1000,
    [],
    'e152',
    'M816.9 133.3C836.3 153.9 846 179 846 208.7V366.3H811.7V821.9 842.5C811.7 929.3 751.2 972.7 630.1 972.7H373.2C252.2 972.7 191.6 929.3 191.6 842.5V366.2H154V208.7C154 179 164.3 153.9 184.8 133.3 205.4 112.7 230.5 102.5 260.2 102.5H397.2C401.8 77.4 413.8 56.3 433.2 39.1 452.6 22 474.9 13.4 500 13.4 527.4 13.4 550.8 22 570.2 39.1 589.6 56.2 600.5 77.4 602.7 102.5H743.2C772.9 102.5 797.5 112.7 816.9 133.3ZM756.9 280.6V208.7C756.9 197.3 751.2 191.6 739.8 191.6H256.8C245.4 191.6 239.7 197.3 239.7 208.7V280.6H760.4 756.9ZM733 366.2H274V784.1C274 829.8 280.9 858.3 294.6 869.7 308.3 881.1 335.7 886.8 376.8 886.8H630.3C662.3 886.8 687.4 880.5 705.7 868 724 855.4 733.1 840 733.1 821.8V366.2ZM455.5 811.6H387V417.6H455.5V811.6ZM616.5 811.6H548V417.6H616.5V811.6Z',
  ],
};
