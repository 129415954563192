/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AvatarSourceEnum,
    AvatarSourceEnumFromJSON,
    AvatarSourceEnumFromJSONTyped,
    AvatarSourceEnumToJSON,
    UserProfileLinksDTO,
    UserProfileLinksDTOFromJSON,
    UserProfileLinksDTOFromJSONTyped,
    UserProfileLinksDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserProfileDTO
 */
export interface UserProfileDTO {
    /**
     * 
     * @type {string}
     * @memberof UserProfileDTO
     */
    usernameHash: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileDTO
     */
    email: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserProfileDTO
     */
    hasLoggedToCli: boolean;
    /**
     * 
     * @type {AvatarSourceEnum}
     * @memberof UserProfileDTO
     */
    avatarSource: AvatarSourceEnum;
    /**
     * 
     * @type {string}
     * @memberof UserProfileDTO
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileDTO
     */
    shortInfo: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileDTO
     */
    userflowIdentityHash: string;
    /**
     * 
     * @type {Date}
     * @memberof UserProfileDTO
     */
    created: Date;
    /**
     * 
     * @type {string}
     * @memberof UserProfileDTO
     */
    intercomIdentityHash: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileDTO
     */
    biography: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserProfileDTO
     */
    hasCreatedExperiments: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserProfileDTO
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileDTO
     */
    avatarUrl: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileDTO
     */
    lastName: string;
    /**
     * 
     * @type {UserProfileLinksDTO}
     * @memberof UserProfileDTO
     */
    links: UserProfileLinksDTO;
}

export function UserProfileDTOFromJSON(json: any): UserProfileDTO {
    return UserProfileDTOFromJSONTyped(json, false);
}

export function UserProfileDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserProfileDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'usernameHash': json['usernameHash'],
        'email': json['email'],
        'hasLoggedToCli': json['hasLoggedToCli'],
        'avatarSource': AvatarSourceEnumFromJSON(json['avatarSource']),
        'firstName': json['firstName'],
        'shortInfo': json['shortInfo'],
        'userflowIdentityHash': json['userflowIdentityHash'],
        'created': (new Date(json['created'])),
        'intercomIdentityHash': json['intercomIdentityHash'],
        'biography': json['biography'],
        'hasCreatedExperiments': json['hasCreatedExperiments'],
        'username': json['username'],
        'avatarUrl': json['avatarUrl'],
        'lastName': json['lastName'],
        'links': UserProfileLinksDTOFromJSON(json['links']),
    };
}

export function UserProfileDTOToJSON(value?: UserProfileDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'usernameHash': value.usernameHash,
        'email': value.email,
        'hasLoggedToCli': value.hasLoggedToCli,
        'avatarSource': AvatarSourceEnumToJSON(value.avatarSource),
        'firstName': value.firstName,
        'shortInfo': value.shortInfo,
        'userflowIdentityHash': value.userflowIdentityHash,
        'created': (value.created.toISOString()),
        'intercomIdentityHash': value.intercomIdentityHash,
        'biography': value.biography,
        'hasCreatedExperiments': value.hasCreatedExperiments,
        'username': value.username,
        'avatarUrl': value.avatarUrl,
        'lastName': value.lastName,
        'links': UserProfileLinksDTOToJSON(value.links),
    };
}


