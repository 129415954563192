/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AvatarSourceEnum,
    AvatarSourceEnumFromJSON,
    AvatarSourceEnumFromJSONTyped,
    AvatarSourceEnumToJSON,
    UserProfileLinksDTO,
    UserProfileLinksDTOFromJSON,
    UserProfileLinksDTOFromJSONTyped,
    UserProfileLinksDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface PublicUserProfileDTO
 */
export interface PublicUserProfileDTO {
    /**
     * 
     * @type {string}
     * @memberof PublicUserProfileDTO
     */
    biography: string;
    /**
     * 
     * @type {string}
     * @memberof PublicUserProfileDTO
     */
    email?: string;
    /**
     * 
     * @type {AvatarSourceEnum}
     * @memberof PublicUserProfileDTO
     */
    avatarSource: AvatarSourceEnum;
    /**
     * 
     * @type {string}
     * @memberof PublicUserProfileDTO
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicUserProfileDTO
     */
    shortInfo: string;
    /**
     * 
     * @type {string}
     * @memberof PublicUserProfileDTO
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof PublicUserProfileDTO
     */
    avatarUrl: string;
    /**
     * 
     * @type {string}
     * @memberof PublicUserProfileDTO
     */
    lastName?: string;
    /**
     * 
     * @type {UserProfileLinksDTO}
     * @memberof PublicUserProfileDTO
     */
    links: UserProfileLinksDTO;
}

export function PublicUserProfileDTOFromJSON(json: any): PublicUserProfileDTO {
    return PublicUserProfileDTOFromJSONTyped(json, false);
}

export function PublicUserProfileDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): PublicUserProfileDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'biography': json['biography'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'avatarSource': AvatarSourceEnumFromJSON(json['avatarSource']),
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'shortInfo': json['shortInfo'],
        'username': json['username'],
        'avatarUrl': json['avatarUrl'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'links': UserProfileLinksDTOFromJSON(json['links']),
    };
}

export function PublicUserProfileDTOToJSON(value?: PublicUserProfileDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'biography': value.biography,
        'email': value.email,
        'avatarSource': AvatarSourceEnumToJSON(value.avatarSource),
        'firstName': value.firstName,
        'shortInfo': value.shortInfo,
        'username': value.username,
        'avatarUrl': value.avatarUrl,
        'lastName': value.lastName,
        'links': UserProfileLinksDTOToJSON(value.links),
    };
}


