/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserListItemDTO,
    UserListItemDTOFromJSON,
    UserListItemDTOFromJSONTyped,
    UserListItemDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserListDTO
 */
export interface UserListDTO {
    /**
     * 
     * @type {Array<UserListItemDTO>}
     * @memberof UserListDTO
     */
    entries: Array<UserListItemDTO>;
    /**
     * 
     * @type {number}
     * @memberof UserListDTO
     */
    matchingItemCount: number;
    /**
     * 
     * @type {number}
     * @memberof UserListDTO
     */
    totalItemCount: number;
}

export function UserListDTOFromJSON(json: any): UserListDTO {
    return UserListDTOFromJSONTyped(json, false);
}

export function UserListDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserListDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entries': ((json['entries'] as Array<any>).map(UserListItemDTOFromJSON)),
        'matchingItemCount': json['matchingItemCount'],
        'totalItemCount': json['totalItemCount'],
    };
}

export function UserListDTOToJSON(value?: UserListDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entries': ((value.entries as Array<any>).map(UserListItemDTOToJSON)),
        'matchingItemCount': value.matchingItemCount,
        'totalItemCount': value.totalItemCount,
    };
}


