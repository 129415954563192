// Libs
import React from 'react';
import { Dropdown, DropdownToggleInterface, Icon, Link } from '@neptune/shared/venus-ui';

// Module
const PaginationLimitToggle = ({ onToggle }: DropdownToggleInterface) => (
  <Link onClick={onToggle} data-role="pagination-limit-toggle">
    <Icon glyph="chevron-down" />
  </Link>
);

export interface PageSizeChangeHandler {
  (pageSize: number): void;
}

type PaginationLimitSelectorProps = {
  onChange: PageSizeChangeHandler;
  options: number[];
  selected: number;
};

export const PaginationLimitSelector: React.FC<PaginationLimitSelectorProps> = ({
  onChange,
  options,
  selected,
}) => (
  <Dropdown
    toggle={<PaginationLimitToggle />}
    attachment="top left"
    targetAttachment="bottom left"
    constraints={[
      {
        to: 'window',
        attachment: 'together',
        // Attachment flipping for some reason didn't work horizontally, so
        // I added pin as a backup of sorts. It is not as well aligned, but
        // it keeps the dropdown inside the window.
        pin: true,
      },
    ]}
  >
    {({ collapse }) => (
      <Dropdown.Menu>
        <Dropdown.Group title="Rows per page">
          {options.map((option, index) => (
            <Dropdown.Item
              key={index}
              onClick={() => {
                onChange(option);
                collapse();
              }}
              selected={option === selected}
              label={option}
              data-role="pagination-limit-option"
              data-page-size={option}
            />
          ))}
        </Dropdown.Group>
      </Dropdown.Menu>
    )}
  </Dropdown>
);
