// Libs
import React from 'react';

export function useVisibilityChange(callback: (hidden: boolean) => void) {
  React.useEffect(() => {
    const onVisibilityChange = () => callback(document.hidden);

    document.addEventListener('visibilitychange', onVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', onVisibilityChange);
    };
  }, [callback]);
}
