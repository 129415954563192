import React from 'react';

/**
 * Creates a reactive reference to the element passed to the ref handler
 */
export function useElementReference<T extends HTMLElement>(): {
  element: T | null;
  handleRef: React.RefCallback<T>;
} {
  const [element, setElement] = React.useState<T | null>(null);

  const handleRef = React.useCallback((node: T | null) => setElement(node), []);

  return {
    element,
    handleRef,
  };
}
