/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProjectDTO
 */
export interface ProjectDTO {
    /**
     * 
     * @type {string}
     * @memberof ProjectDTO
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDTO
     */
    organizationName: string;
    /**
     * 
     * @type {number}
     * @memberof ProjectDTO
     */
    version: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectDTO
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDTO
     */
    projectKey: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDTO
     */
    organizationId: string;
}

export function ProjectDTOFromJSON(json: any): ProjectDTO {
    return ProjectDTOFromJSONTyped(json, false);
}

export function ProjectDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'organizationName': json['organizationName'],
        'version': json['version'],
        'id': json['id'],
        'projectKey': json['projectKey'],
        'organizationId': json['organizationId'],
    };
}

export function ProjectDTOToJSON(value?: ProjectDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'organizationName': value.organizationName,
        'version': value.version,
        'id': value.id,
        'projectKey': value.projectKey,
        'organizationId': value.organizationId,
    };
}


