import React from 'react';
import { Transition } from 'react-transition-group';

import { bemBlock } from '../../modules/bem';
import { Section } from '../section/Section';
import { Layout } from '../layout/Layout';
import { Button } from '../button/Button';
import { Icon } from '../icon/Icon';

import './Collapse.less';

const block = bemBlock('collapse');

type CollapseProps = Omit<React.ComponentProps<typeof Section>, 'title'> & {
  children: React.ReactNode;
  extra?: React.ReactNode;
  unmountOnExit?: boolean;
  mountOnEnter?: boolean;
  title: React.ReactNode;
  onToggle?: () => void;
  collapsed?: boolean;
  id: string;
};

export function Collapse({
  id,
  title,
  onToggle,
  collapsed,
  extra,
  children,
  mountOnEnter = true,
  unmountOnExit = true,
  ...props
}: CollapseProps) {
  return (
    <Section {...props} className={block({ modifiers: { collapsed } })}>
      <Layout.Row
        className={block({ element: 'panel', modifiers: { collapsed } })}
        spacedChildren="sm"
        alignItems="center"
        span="shrink"
      >
        <Layout.Row span="auto" alignItems="center">
          {onToggle && (
            <Button
              aria-expanded={!collapsed}
              className={block('toggle')}
              variant="transparent"
              onClick={onToggle}
              aria-controls={id}
              size="lg"
              square
            >
              <Icon
                className={block({ element: 'chevron', modifiers: { collapsed } })}
                color="text-subdued"
                glyph="caret-down"
                fixedWidth
                size="lg"
              />
            </Button>
          )}
          {title}
        </Layout.Row>
        <Layout.Row span="grow" justifyContent="end">
          {extra}
        </Layout.Row>
      </Layout.Row>
      <Transition
        exit
        appear
        in={!collapsed}
        mountOnEnter={mountOnEnter}
        unmountOnExit={unmountOnExit}
        timeout={{ exit: 250, enter: 0 }}
      >
        {(state) => (
          <div id={id} className={block({ element: 'content', modifiers: { state } })}>
            <div>{children}</div>
          </div>
        )}
      </Transition>
    </Section>
  );
}
