/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OrganizationTypeDTO,
    OrganizationTypeDTOFromJSON,
    OrganizationTypeDTOFromJSONTyped,
    OrganizationTypeDTOToJSON,
    ProjectTraitsSetDTO,
    ProjectTraitsSetDTOFromJSON,
    ProjectTraitsSetDTOFromJSONTyped,
    ProjectTraitsSetDTOToJSON,
    ProjectVisibilityDTO,
    ProjectVisibilityDTOFromJSON,
    ProjectVisibilityDTOFromJSONTyped,
    ProjectVisibilityDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface ProjectMetadataDTO
 */
export interface ProjectMetadataDTO {
    /**
     * 
     * @type {string}
     * @memberof ProjectMetadataDTO
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectMetadataDTO
     */
    archived: boolean;
    /**
     * 
     * @type {OrganizationTypeDTO}
     * @memberof ProjectMetadataDTO
     */
    organizationType: OrganizationTypeDTO;
    /**
     * 
     * @type {Date}
     * @memberof ProjectMetadataDTO
     */
    timeOfCreation: Date;
    /**
     * 
     * @type {string}
     * @memberof ProjectMetadataDTO
     */
    organizationName: string;
    /**
     * 
     * @type {number}
     * @memberof ProjectMetadataDTO
     */
    version: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectMetadataDTO
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectMetadataDTO
     */
    projectKey: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectMetadataDTO
     */
    organizationId: string;
    /**
     * 
     * @type {ProjectTraitsSetDTO}
     * @memberof ProjectMetadataDTO
     */
    traits: ProjectTraitsSetDTO;
    /**
     * 
     * @type {number}
     * @memberof ProjectMetadataDTO
     */
    storageUsage: number;
    /**
     * 
     * @type {ProjectVisibilityDTO}
     * @memberof ProjectMetadataDTO
     */
    visibility: ProjectVisibilityDTO;
}

export function ProjectMetadataDTOFromJSON(json: any): ProjectMetadataDTO {
    return ProjectMetadataDTOFromJSONTyped(json, false);
}

export function ProjectMetadataDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectMetadataDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'archived': json['archived'],
        'organizationType': OrganizationTypeDTOFromJSON(json['organizationType']),
        'timeOfCreation': (new Date(json['timeOfCreation'])),
        'organizationName': json['organizationName'],
        'version': json['version'],
        'id': json['id'],
        'projectKey': json['projectKey'],
        'organizationId': json['organizationId'],
        'traits': ProjectTraitsSetDTOFromJSON(json['traits']),
        'storageUsage': json['storageUsage'],
        'visibility': ProjectVisibilityDTOFromJSON(json['visibility']),
    };
}

export function ProjectMetadataDTOToJSON(value?: ProjectMetadataDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'archived': value.archived,
        'organizationType': OrganizationTypeDTOToJSON(value.organizationType),
        'timeOfCreation': (value.timeOfCreation.toISOString()),
        'organizationName': value.organizationName,
        'version': value.version,
        'id': value.id,
        'projectKey': value.projectKey,
        'organizationId': value.organizationId,
        'traits': ProjectTraitsSetDTOToJSON(value.traits),
        'storageUsage': value.storageUsage,
        'visibility': ProjectVisibilityDTOToJSON(value.visibility),
    };
}


