/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NextPageDTO,
    NextPageDTOFromJSON,
    NextPageDTOFromJSONTyped,
    NextPageDTOToJSON,
    QueryAttributesExperimentResultDTO,
    QueryAttributesExperimentResultDTOFromJSON,
    QueryAttributesExperimentResultDTOFromJSONTyped,
    QueryAttributesExperimentResultDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface QueryAttributesResultDTO
 */
export interface QueryAttributesResultDTO {
    /**
     * 
     * @type {Array<QueryAttributesExperimentResultDTO>}
     * @memberof QueryAttributesResultDTO
     */
    entries: Array<QueryAttributesExperimentResultDTO>;
    /**
     * 
     * @type {NextPageDTO}
     * @memberof QueryAttributesResultDTO
     */
    nextPage: NextPageDTO;
}

export function QueryAttributesResultDTOFromJSON(json: any): QueryAttributesResultDTO {
    return QueryAttributesResultDTOFromJSONTyped(json, false);
}

export function QueryAttributesResultDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): QueryAttributesResultDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entries': ((json['entries'] as Array<any>).map(QueryAttributesExperimentResultDTOFromJSON)),
        'nextPage': NextPageDTOFromJSON(json['nextPage']),
    };
}

export function QueryAttributesResultDTOToJSON(value?: QueryAttributesResultDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entries': ((value.entries as Array<any>).map(QueryAttributesExperimentResultDTOToJSON)),
        'nextPage': NextPageDTOToJSON(value.nextPage),
    };
}


