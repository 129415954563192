/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BulkOpErrorDTO
 */
export interface BulkOpErrorDTO {
    /**
     * Same format as sent in request.
     * @type {string}
     * @memberof BulkOpErrorDTO
     */
    experimentIdentifier: string;
    /**
     * Error message.
     * @type {string}
     * @memberof BulkOpErrorDTO
     */
    message: string;
    /**
     * Error reason.
     * @type {string}
     * @memberof BulkOpErrorDTO
     */
    reason: BulkOpErrorDTOReasonEnum;
}

export function BulkOpErrorDTOFromJSON(json: any): BulkOpErrorDTO {
    return BulkOpErrorDTOFromJSONTyped(json, false);
}

export function BulkOpErrorDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): BulkOpErrorDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'experimentIdentifier': json['experimentIdentifier'],
        'message': json['message'],
        'reason': json['reason'],
    };
}

export function BulkOpErrorDTOToJSON(value?: BulkOpErrorDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'experimentIdentifier': value.experimentIdentifier,
        'message': value.message,
        'reason': value.reason,
    };
}

/**
* @export
* @enum {string}
*/
export enum BulkOpErrorDTOReasonEnum {
    TRASHED = 'TRASHED',
    ALREADYTRASHED = 'ALREADY_TRASHED',
    NOTEXISTS = 'NOT_EXISTS',
    NOTINTRASH = 'NOT_IN_TRASH',
    ALREADYFINISHED = 'ALREADY_FINISHED',
    ISPROJECT = 'IS_PROJECT'
}


