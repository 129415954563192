import { LocalStorageCompatible } from './local-storage-compatible';
import { isLocalStorageV7 } from './local-storage-v7';
import { upgrade as upgradeFromPrevious } from './local-storage-v7.upgrade';
import { LocalStorageV8 } from './local-storage-v8';

/*
 * NOTE: Create new migration files only when you need to remove or edit existing field. Do not create new migration file for adding new field.
 */
export async function upgrade(raw: LocalStorageCompatible): Promise<LocalStorageV8> {
  const input = isLocalStorageV7(raw) ? raw : await upgradeFromPrevious(raw);
  const { data } = input;

  const { chartsXAxisRange, ...rest } = data;

  return {
    version: 8,
    data: {
      ...rest,
      chartsMetricsStepsRange: chartsXAxisRange,
    },
  };
}
