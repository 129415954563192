/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AttributeDefinitionDTO,
    AttributeDefinitionDTOFromJSON,
    AttributeDefinitionDTOFromJSONTyped,
    AttributeDefinitionDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface PrioritizedAttributeDefinitionDTO
 */
export interface PrioritizedAttributeDefinitionDTO {
    /**
     * 
     * @type {AttributeDefinitionDTO}
     * @memberof PrioritizedAttributeDefinitionDTO
     */
    attributeDefinitionDTO: AttributeDefinitionDTO;
    /**
     * 
     * @type {boolean}
     * @memberof PrioritizedAttributeDefinitionDTO
     */
    matchedPriorityQuery?: boolean;
}

export function PrioritizedAttributeDefinitionDTOFromJSON(json: any): PrioritizedAttributeDefinitionDTO {
    return PrioritizedAttributeDefinitionDTOFromJSONTyped(json, false);
}

export function PrioritizedAttributeDefinitionDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): PrioritizedAttributeDefinitionDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attributeDefinitionDTO': AttributeDefinitionDTOFromJSON(json['attributeDefinitionDTO']),
        'matchedPriorityQuery': !exists(json, 'matchedPriorityQuery') ? undefined : json['matchedPriorityQuery'],
    };
}

export function PrioritizedAttributeDefinitionDTOToJSON(value?: PrioritizedAttributeDefinitionDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attributeDefinitionDTO': AttributeDefinitionDTOToJSON(value.attributeDefinitionDTO),
        'matchedPriorityQuery': value.matchedPriorityQuery,
    };
}


