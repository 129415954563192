import { LayoutColumn } from '../layout-column/LayoutColumn';
import { LayoutElement } from '../layout-element/LayoutElement';
import { LayoutFill } from '../layout-fill/LayoutFill';
import { LayoutRow } from '../layout-row/LayoutRow';
import { LayoutSeparator } from '../layout-separator/LayoutSeparator';

// Module
export class Layout {
  static Column = LayoutColumn;
  static Element = LayoutElement;
  static Fill = LayoutFill;
  static Row = LayoutRow;
  static Separator = LayoutSeparator;
}
