// Libs
import React from 'react';

import { LayoutRow, LayoutRowProps } from '../layout-row/LayoutRow';
import { bemBlock, joinClassNames } from '../../modules/bem';
import * as font from '../../modules/font';

// Module
import './BreadcrumbsElement.less';

export type BreadcrumbsElementProps = Omit<LayoutRowProps<{}>, 'alignItems' | 'span' | 'inline'> & {
  size?: font.Size;
};

const block = bemBlock('n-BreadcrumbsElement');

/**
 * `BreadcrumbsElement` is a wrapper component that may contains any content and can be placed directly in `Breadcrumbs`.
 *
 * Visual separator will appear automatically between two instances of BreadcrumbsElement.
 *
 * ## Import
 *
 * ```
 * import { Breadcrumbs } from '@neptune/shared/venus-ui';
 * ```
 *
 * And use `<Breadcrumbs.Element />`
 */
// eslint-disable-next-line react/prefer-stateless-function
export class BreadcrumbsElement extends React.Component<BreadcrumbsElementProps> {
  public static defaultProps = {
    alignItems: 'center',
    span: 'auto',
  };

  render() {
    const { className, size, ...passProps } = this.props;

    return (
      <LayoutRow
        className={block({
          extra: joinClassNames(className, font.sizeClassName(size)),
        })}
        {...passProps}
      />
    );
  }
}
