/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LogFloatEntry
 */
export interface LogFloatEntry {
    /**
     * 
     * @type {number}
     * @memberof LogFloatEntry
     */
    value: number;
    /**
     * 
     * @type {number}
     * @memberof LogFloatEntry
     */
    step?: number;
    /**
     * 
     * @type {number}
     * @memberof LogFloatEntry
     */
    timestampMilliseconds: number;
}

export function LogFloatEntryFromJSON(json: any): LogFloatEntry {
    return LogFloatEntryFromJSONTyped(json, false);
}

export function LogFloatEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): LogFloatEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'value': json['value'],
        'step': !exists(json, 'step') ? undefined : json['step'],
        'timestampMilliseconds': json['timestampMilliseconds'],
    };
}

export function LogFloatEntryToJSON(value?: LogFloatEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'value': value.value,
        'step': value.step,
        'timestampMilliseconds': value.timestampMilliseconds,
    };
}


