// Module
import { CodeRenderer } from './CodeRenderer';
import LinkRenderer from './LinkRenderer';
import TextRenderer from './TextRenderer';

const renderers = {
  code: CodeRenderer,
  link: LinkRenderer,
  text: TextRenderer,
};


export default renderers;
