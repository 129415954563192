// Lib
import React from 'react';
import PropTypes from 'prop-types';

// App
import { joinClassNames } from '@neptune/shared/venus-ui';

import './TokenRenderer.less';

const propTypes = {
  className: PropTypes.string,
  tokenType: PropTypes.oneOf([
    'punctuation',
    'operator',
    'comment',
    'shebang',
    'keyword',
    'key',
    'property',
    'string',
    'template-string',
    'triple-quoted-string',
    'scalar',
    'number',
    'boolean',
    'regex',
    'variable',
    'null',
    'builtin',
    'class-name',
    'function',
    'coord',
    'diff',
    'inserted',
    'deleted',
  ]),
  darkMode: PropTypes.bool,
};

const TokenRenderer = ({
  className,
  tokenType,
  darkMode,
  ...props
}) => {

  const mode = darkMode ? 'dark' : 'light';

  const tokenClassName = `token-renderer--${mode}--${tokenType}`;

  const cssClass = joinClassNames(className, tokenClassName);

  return (
    <span
      className={cssClass}
      {...props}
    />
  );
};

TokenRenderer.propTypes = propTypes;

export default TokenRenderer;

