import { isLocalStorageLatest, upgradeToLocalStorageLatest } from './local-storage-latest';

export async function localStorageMigrate(topLevelKey: string): Promise<void> {
  try {
    const raw = window.localStorage.getItem(topLevelKey);

    if (!raw) {
      return;
    }

    const value = JSON.parse(raw);

    if (isLocalStorageLatest(value)) {
      return;
    }

    const latest = await upgradeToLocalStorageLatest(value);
    window.localStorage.setItem(topLevelKey, JSON.stringify(latest));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Localstorage migration failed', e);
  }
}
