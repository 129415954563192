// Libs
import React from 'react';
import { Button, DropdownToggleProps, Icon } from '@neptune/shared/venus-ui';

export const DotsHButton: React.FC<DropdownToggleProps> = ({
  onCollapse,
  onExpand,
  onToggle,
  size = 'lg',
  ...restProps
}) => {
  return (
    <Button size={size} variant="secondary-text" square onClick={onToggle} {...restProps}>
      <Icon glyph="dots-horizontal" />
    </Button>
  );
};
