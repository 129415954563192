/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DashboardVersionDTO,
    DashboardVersionDTOFromJSON,
    DashboardVersionDTOFromJSONTyped,
    DashboardVersionDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface PageDTODashboardVersionDTO
 */
export interface PageDTODashboardVersionDTO {
    /**
     * 
     * @type {Array<DashboardVersionDTO>}
     * @memberof PageDTODashboardVersionDTO
     */
    entries: Array<DashboardVersionDTO>;
    /**
     * 
     * @type {number}
     * @memberof PageDTODashboardVersionDTO
     */
    limit: number;
    /**
     * 
     * @type {number}
     * @memberof PageDTODashboardVersionDTO
     */
    offset: number;
    /**
     * 
     * @type {number}
     * @memberof PageDTODashboardVersionDTO
     */
    total: number;
}

export function PageDTODashboardVersionDTOFromJSON(json: any): PageDTODashboardVersionDTO {
    return PageDTODashboardVersionDTOFromJSONTyped(json, false);
}

export function PageDTODashboardVersionDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): PageDTODashboardVersionDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entries': ((json['entries'] as Array<any>).map(DashboardVersionDTOFromJSON)),
        'limit': json['limit'],
        'offset': json['offset'],
        'total': json['total'],
    };
}

export function PageDTODashboardVersionDTOToJSON(value?: PageDTODashboardVersionDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entries': ((value.entries as Array<any>).map(DashboardVersionDTOToJSON)),
        'limit': value.limit,
        'offset': value.offset,
        'total': value.total,
    };
}


