export type LocalStorageCompatible = {
  version: number;
  data: Record<string, unknown | undefined>;
};

export type LocalStorageWithAllRequiredBut<
  LS extends LocalStorageCompatible,
  newFields extends keyof LS['data'] = never,
> = {
  version: LS['version'];
  data: Omit<Required<LS['data']>, newFields> & Pick<LS['data'], newFields>;
};

export function isLocalStorageCompatibleShape(input: any): input is LocalStorageCompatible {
  return (
    typeof input === 'object' &&
    input &&
    typeof input.version === 'number' &&
    isFinite(input.version) &&
    typeof input.data === 'object' &&
    input.data !== null
  );
}
