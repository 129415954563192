/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SingleCustomTimeSeriesView,
    SingleCustomTimeSeriesViewFromJSON,
    SingleCustomTimeSeriesViewFromJSONTyped,
    SingleCustomTimeSeriesViewToJSON,
    SingleTimeSeriesView,
    SingleTimeSeriesViewFromJSON,
    SingleTimeSeriesViewFromJSONTyped,
    SingleTimeSeriesViewToJSON,
} from './';

/**
 * 
 * @export
 * @interface TimeSeriesViewResponse
 */
export interface TimeSeriesViewResponse {
    /**
     * 
     * @type {Array<SingleCustomTimeSeriesView>}
     * @memberof TimeSeriesViewResponse
     */
    customSeries: Array<SingleCustomTimeSeriesView>;
    /**
     * 
     * @type {Array<SingleTimeSeriesView>}
     * @memberof TimeSeriesViewResponse
     */
    elements: Array<SingleTimeSeriesView>;
}

export function TimeSeriesViewResponseFromJSON(json: any): TimeSeriesViewResponse {
    return TimeSeriesViewResponseFromJSONTyped(json, false);
}

export function TimeSeriesViewResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): TimeSeriesViewResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'customSeries': ((json['customSeries'] as Array<any>).map(SingleCustomTimeSeriesViewFromJSON)),
        'elements': ((json['elements'] as Array<any>).map(SingleTimeSeriesViewFromJSON)),
    };
}

export function TimeSeriesViewResponseToJSON(value?: TimeSeriesViewResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'customSeries': ((value.customSeries as Array<any>).map(SingleCustomTimeSeriesViewToJSON)),
        'elements': ((value.elements as Array<any>).map(SingleTimeSeriesViewToJSON)),
    };
}


