/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum InvitationStatusEnumDTO {
    Pending = 'pending',
    Accepted = 'accepted',
    Rejected = 'rejected',
    Revoked = 'revoked'
}

export function InvitationStatusEnumDTOFromJSON(json: any): InvitationStatusEnumDTO {
    return InvitationStatusEnumDTOFromJSONTyped(json, false);
}

export function InvitationStatusEnumDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvitationStatusEnumDTO {
    return json as InvitationStatusEnumDTO;
}

export function InvitationStatusEnumDTOToJSON(value?: InvitationStatusEnumDTO | null): any {
    return value as any;
}

