/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ReportVersionContentPreviewDTO,
    ReportVersionContentPreviewDTOFromJSON,
    ReportVersionContentPreviewDTOFromJSONTyped,
    ReportVersionContentPreviewDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface ReportVersionMetadataDTO
 */
export interface ReportVersionMetadataDTO {
    /**
     * 
     * @type {boolean}
     * @memberof ReportVersionMetadataDTO
     */
    draft: boolean;
    /**
     * 
     * @type {string}
     * @memberof ReportVersionMetadataDTO
     */
    readonly reportId: string;
    /**
     * 
     * @type {string}
     * @memberof ReportVersionMetadataDTO
     */
    versionAuthor: string;
    /**
     * 
     * @type {ReportVersionContentPreviewDTO}
     * @memberof ReportVersionMetadataDTO
     */
    versionContentPreview: ReportVersionContentPreviewDTO;
    /**
     * 
     * @type {Date}
     * @memberof ReportVersionMetadataDTO
     */
    readonly versionCreationTime: Date;
    /**
     * 
     * @type {string}
     * @memberof ReportVersionMetadataDTO
     */
    versionDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof ReportVersionMetadataDTO
     */
    readonly versionId: string;
    /**
     * 
     * @type {Date}
     * @memberof ReportVersionMetadataDTO
     */
    readonly versionLastModificationTime: Date;
    /**
     * 
     * @type {string}
     * @memberof ReportVersionMetadataDTO
     */
    versionName?: string;
}

export function ReportVersionMetadataDTOFromJSON(json: any): ReportVersionMetadataDTO {
    return ReportVersionMetadataDTOFromJSONTyped(json, false);
}

export function ReportVersionMetadataDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportVersionMetadataDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'draft': json['draft'],
        'reportId': json['reportId'],
        'versionAuthor': json['versionAuthor'],
        'versionContentPreview': ReportVersionContentPreviewDTOFromJSON(json['versionContentPreview']),
        'versionCreationTime': (new Date(json['versionCreationTime'])),
        'versionDescription': !exists(json, 'versionDescription') ? undefined : json['versionDescription'],
        'versionId': json['versionId'],
        'versionLastModificationTime': (new Date(json['versionLastModificationTime'])),
        'versionName': !exists(json, 'versionName') ? undefined : json['versionName'],
    };
}

export function ReportVersionMetadataDTOToJSON(value?: ReportVersionMetadataDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'draft': value.draft,
        'versionAuthor': value.versionAuthor,
        'versionContentPreview': ReportVersionContentPreviewDTOToJSON(value.versionContentPreview),
        'versionDescription': value.versionDescription,
        'versionName': value.versionName,
    };
}


