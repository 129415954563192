/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OrganizationInvitationUpdateProjectEntryDTO,
    OrganizationInvitationUpdateProjectEntryDTOFromJSON,
    OrganizationInvitationUpdateProjectEntryDTOFromJSONTyped,
    OrganizationInvitationUpdateProjectEntryDTOToJSON,
    OrganizationRoleDTO,
    OrganizationRoleDTOFromJSON,
    OrganizationRoleDTOFromJSONTyped,
    OrganizationRoleDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface OrganizationInvitationUpdateDTO
 */
export interface OrganizationInvitationUpdateDTO {
    /**
     * 
     * @type {OrganizationRoleDTO}
     * @memberof OrganizationInvitationUpdateDTO
     */
    roleGrant?: OrganizationRoleDTO;
    /**
     * 
     * @type {Array<OrganizationInvitationUpdateProjectEntryDTO>}
     * @memberof OrganizationInvitationUpdateDTO
     */
    projects?: Array<OrganizationInvitationUpdateProjectEntryDTO>;
}

export function OrganizationInvitationUpdateDTOFromJSON(json: any): OrganizationInvitationUpdateDTO {
    return OrganizationInvitationUpdateDTOFromJSONTyped(json, false);
}

export function OrganizationInvitationUpdateDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationInvitationUpdateDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'roleGrant': !exists(json, 'roleGrant') ? undefined : OrganizationRoleDTOFromJSON(json['roleGrant']),
        'projects': !exists(json, 'projects') ? undefined : ((json['projects'] as Array<any>).map(OrganizationInvitationUpdateProjectEntryDTOFromJSON)),
    };
}

export function OrganizationInvitationUpdateDTOToJSON(value?: OrganizationInvitationUpdateDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'roleGrant': OrganizationRoleDTOToJSON(value.roleGrant),
        'projects': value.projects === undefined ? undefined : ((value.projects as Array<any>).map(OrganizationInvitationUpdateProjectEntryDTOToJSON)),
    };
}


