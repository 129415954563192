import { ExperimentStateDTO } from '@neptune/shared/core-apis-leaderboard-domain';

import { ExperimentState } from './experiment-state-model';

type APIExperimentState = ExperimentStateDTO;

export abstract class ExperimentStateConverter {
  static experimentStateFromApiToDomain(source: APIExperimentState): ExperimentState {
    switch (source) {
      case ExperimentStateDTO.Idle:
        return 'idle';

      case ExperimentStateDTO.Running:
        return 'running';
    }
  }
}
