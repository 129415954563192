/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateDashboardVersionDTO
 */
export interface UpdateDashboardVersionDTO {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateDashboardVersionDTO
     */
    draft?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateDashboardVersionDTO
     */
    versionDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDashboardVersionDTO
     */
    versionName?: string;
}

export function UpdateDashboardVersionDTOFromJSON(json: any): UpdateDashboardVersionDTO {
    return UpdateDashboardVersionDTOFromJSONTyped(json, false);
}

export function UpdateDashboardVersionDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateDashboardVersionDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'draft': !exists(json, 'draft') ? undefined : json['draft'],
        'versionDescription': !exists(json, 'versionDescription') ? undefined : json['versionDescription'],
        'versionName': !exists(json, 'versionName') ? undefined : json['versionName'],
    };
}

export function UpdateDashboardVersionDTOToJSON(value?: UpdateDashboardVersionDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'draft': value.draft,
        'versionDescription': value.versionDescription,
        'versionName': value.versionName,
    };
}


