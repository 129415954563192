// Libs
import { isArray, isObject } from 'lodash';

// Module
export type AlignContent =
  | 'normal'
  | 'start'
  | 'end'
  | 'center'
  | 'space-between'
  | 'space-around'
  | 'stretch';
export type AlignItems = 'start' | 'end' | 'center' | 'baseline' | 'stretch';
export type AlignSelf = AlignItems | 'auto';
export type FlexDirection = 'row' | 'column';
export type JustifyContent =
  | 'start'
  | 'end'
  | 'center'
  | 'space-between'
  | 'space-around'
  | 'space-evenly';
export type LayoutUnit = 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'xxxl' | 'none';
export type OverflowBasicValue = 'auto' | 'hidden' | 'scroll' | 'visible';
export type Overflow =
  | OverflowBasicValue
  | [OverflowBasicValue, OverflowBasicValue?]
  | { vertical?: OverflowBasicValue; horizontal?: OverflowBasicValue };
export type Padding = LayoutUnit | [LayoutUnit?, LayoutUnit?, LayoutUnit?, LayoutUnit?];
export type Span =
  | 'greedy'
  | 'auto'
  | 'grow'
  | 'shrink'
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'
  | '8'
  | '9'
  | '10'
  | '11'
  | '12';
export type Wrap = 'nowrap' | 'wrap' | 'wrap-reverse';
export type NonFlexLayout = 'horizontal' | 'vertical';
export type Position = 'relative' | 'absolute' | 'static' | 'fixed' | 'sticky';

export function alignContentClassName(value?: AlignContent) {
  return value && `n--flex-alignContent-${value}`;
}

export function alignItemsClassName(value?: AlignItems) {
  return value && `n--flex-alignItems-${value}`;
}

export function alignSelfClassName(value?: AlignSelf) {
  return value && `n--flex-alignSelf-${value}`;
}

export function displayFlexClassName(inline?: boolean) {
  return `n--flex${inline ? '-inline' : ''}`;
}

export function flexDirectionClassName(direction: FlexDirection, reversed?: boolean) {
  return `n--flex-${direction}${reversed ? '-reverse' : ''}`;
}

export function justifyContentClassName(value?: JustifyContent) {
  return value && `n--flex-justifyContent-${value}`;
}

function overflow(value?: OverflowBasicValue, suffix?: string) {
  return value && `n--overflow${suffix ? `-${suffix}` : ''}-${value}`;
}

function overflowToClassName(vertical?: OverflowBasicValue, horizontal?: OverflowBasicValue) {
  return [overflow(vertical, 'vertical'), overflow(horizontal, 'horizontal')].join(' ');
}

export function overflowClassName(value?: Overflow) {
  if (isArray(value)) {
    const [vertical, horizontal = 'hidden'] = value;

    return overflowToClassName(vertical, horizontal);
  }

  if (isObject(value)) {
    const { vertical = 'hidden', horizontal = 'hidden' } = value;

    return overflowToClassName(vertical, horizontal);
  }

  return overflow(value);
}

export function spanClassName(value?: Span) {
  return value && `n--flex-span-${value}`;
}

export function wrapClassName(value?: Wrap) {
  return value && `n--flex-${value}`;
}

export function selfSpacingClassName(value?: LayoutUnit) {
  return value && `n--selfSpacing-${value}`;
}

export function spacedChildrenClassName(value?: LayoutUnit, nonFlexLayout?: NonFlexLayout) {
  if (value) {
    return (
      (nonFlexLayout ? `n--nonFlexLayout-${nonFlexLayout} ` : '') + `n--spacedChildren-${value}`
    );
  }
}

function withPadding(value?: LayoutUnit, suffix?: string) {
  return value && `n--withPadding${suffix ? `-${suffix}` : ''}-${value}`;
}

export function withGutterClassName(value?: LayoutUnit) {
  return withPadding(value, 'horizontal');
}

export function withPaddingClassName(value?: Padding) {
  if (isArray(value)) {
    const [top, right = top, bottom = top, left = right] = value;

    if (value.length === 1) {
      return withPadding(top);
    }

    if (value.length === 2) {
      return [withPadding(top, 'vertical'), withPadding(right, 'horizontal')].join(' ');
    }

    const paddings = [];

    if (top === bottom) {
      paddings.push(withPadding(top, 'vertical'));
    } else {
      paddings.push(withPadding(top, 'top'), withPadding(bottom, 'bottom'));
    }

    if (left === right) {
      paddings.push(withPadding(right, 'horizontal'));
    } else {
      paddings.push(withPadding(left, 'left'), withPadding(right, 'right'));
    }

    return paddings.join(' ');
  }

  return withPadding(value);
}

export function withCustomScrollbarClassName(enabled?: boolean) {
  return enabled ? 'n--customScrollbar' : undefined;
}

export function positionClassName(value?: Position) {
  return value && `n--position-${value}`;
}
