import { CustomIconDefinition } from '../types';

export const nepChartSmooth: CustomIconDefinition = {
  prefix: 'nep',
  iconName: 'chart-smooth',
  icon: [
    1000,
    1000,
    [],
    'e00a',
    'M85.9 863.2L24.4 797.8C47.2 773.5 75.7 736.1 108.7 692.9 244.6 514.8 450 245.6 733.6 245.6 887.1 245.6 920.4 320.2 955.6 399.1 960.7 410.6 966.1 422.7 972.1 435.2L894.7 477.7C888.2 464.2 882.4 451.2 876.8 438.8 845.1 367.7 832 338.3 733.6 338.3 491.5 338.3 301.8 586.8 176.3 751.3 141.7 796.5 111.9 835.6 85.9 863.2Z',
  ],
};
