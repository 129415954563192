// Libs
import React from 'react';
import { bemBlock } from '@neptune/shared/venus-ui';
import ReactMarkdown from 'react-markdown';
import 'github-markdown-css/github-markdown.css';

// Module
import renderers from './renderers';
import './MarkdownRenderer.less';

export type MarkdownRendererProps = {
  className?: string;
  content: string;
};

const block = bemBlock('markdown-renderer');

export const MarkdownRenderer: React.FC<MarkdownRendererProps> = ({ content, className }) => {
  const githubCssClassName = 'markdown-body';

  return (
    <ReactMarkdown
      className={block({ extra: [githubCssClassName, className] })}
      renderers={renderers}
      source={content}
    />
  );
};
