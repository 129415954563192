import { CustomIconDefinition } from '../types';

export const nepEyeStrike: CustomIconDefinition = {
  prefix: 'nep',
  iconName: 'eye-strike',
  icon: [
    1000,
    1000,
    [],
    'e14e',
    'M963.2 482.7C929.4 393.2 871.9 317.8 799.4 262.6L966 96 887.5 17.5 700.8 204.2C642.9 178.5 579.6 163.6 513.5 161.7 508.2 161.5 502.7 161.4 497.4 161.4 396.5 161.4 299.2 192 216 249.8 132.7 307.6 69.9 388.5 34.2 483.5L28 500.2 34.3 516.8C65.5 599.4 116.7 669.9 181.4 723.7L29.5 875.5 108 954 276.3 785.7C339.5 817.3 410 835.6 483.9 837.8L484.1 837.8C489.2 837.9 494 838 498.9 838 600.2 838 697.8 807.5 781.1 749.7 864.5 692 927.5 611.2 963.2 516L969.4 499.3 963.2 482.7ZM248.5 656.5C197.8 616.2 157.5 564 131 504.1L129.2 500.1 131 496.1C162.6 424.3 213.5 363.9 278.4 321.3 343.7 278.5 420 255.9 499.2 255.9 503 255.9 506.9 256 510.7 256.1 550.9 257.3 590.4 264.4 628 277.1L572.5 332.6C551.6 321.4 527.7 315 502.3 315 420.1 315 353.2 381.9 353.2 464.1 353.2 489.5 359.6 513.4 370.8 534.3L248.5 656.5ZM866.4 503.5C802.2 649.5 659.4 743.8 502.6 743.8 497.3 743.8 492 743.7 486.7 743.5 438.6 742.1 391.6 732.1 347.7 714.4L456.2 605.9C470.7 610.6 486.2 613.2 502.3 613.2 584.5 613.2 651.4 546.3 651.4 464.1 651.4 448 648.8 432.5 644.1 418L731.9 330.2C790.7 372 837 428.9 866.4 495.4L868.2 499.4 866.4 503.5Z',
  ],
};
