/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AvatarSourceEnum,
    AvatarSourceEnumFromJSON,
    AvatarSourceEnumFromJSONTyped,
    AvatarSourceEnumToJSON,
    OrganizationDTO,
    OrganizationDTOFromJSON,
    OrganizationDTOFromJSONTyped,
    OrganizationDTOToJSON,
    OrganizationRoleDTO,
    OrganizationRoleDTOFromJSON,
    OrganizationRoleDTOFromJSONTyped,
    OrganizationRoleDTOToJSON,
    OrganizationTypeDTO,
    OrganizationTypeDTOFromJSON,
    OrganizationTypeDTOFromJSONTyped,
    OrganizationTypeDTOToJSON,
    PricingPlanDTO,
    PricingPlanDTOFromJSON,
    PricingPlanDTOFromJSONTyped,
    PricingPlanDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface OrganizationWithRoleDTO
 */
export interface OrganizationWithRoleDTO {
    /**
     * 
     * @type {OrganizationDTO}
     * @memberof OrganizationWithRoleDTO
     */
    organization: OrganizationDTO;
    /**
     * 
     * @type {string}
     * @memberof OrganizationWithRoleDTO
     */
    name: string;
    /**
     * 
     * @type {OrganizationRoleDTO}
     * @memberof OrganizationWithRoleDTO
     */
    userRole: OrganizationRoleDTO;
    /**
     * 
     * @type {string}
     * @memberof OrganizationWithRoleDTO
     */
    paymentStatus: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationWithRoleDTO
     */
    avatarUrl: string;
    /**
     * 
     * @type {OrganizationTypeDTO}
     * @memberof OrganizationWithRoleDTO
     */
    organizationType: OrganizationTypeDTO;
    /**
     * 
     * @type {AvatarSourceEnum}
     * @memberof OrganizationWithRoleDTO
     */
    avatarSource: AvatarSourceEnum;
    /**
     * 
     * @type {string}
     * @memberof OrganizationWithRoleDTO
     */
    info?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationWithRoleDTO
     */
    id: string;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationWithRoleDTO
     */
    limitsEnforced: boolean;
    /**
     * 
     * @type {PricingPlanDTO}
     * @memberof OrganizationWithRoleDTO
     */
    pricingPlan: PricingPlanDTO;
    /**
     * 
     * @type {Date}
     * @memberof OrganizationWithRoleDTO
     */
    created: Date;
}

export function OrganizationWithRoleDTOFromJSON(json: any): OrganizationWithRoleDTO {
    return OrganizationWithRoleDTOFromJSONTyped(json, false);
}

export function OrganizationWithRoleDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationWithRoleDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'organization': OrganizationDTOFromJSON(json['organization']),
        'name': json['name'],
        'userRole': OrganizationRoleDTOFromJSON(json['userRole']),
        'paymentStatus': json['paymentStatus'],
        'avatarUrl': json['avatarUrl'],
        'organizationType': OrganizationTypeDTOFromJSON(json['organizationType']),
        'avatarSource': AvatarSourceEnumFromJSON(json['avatarSource']),
        'info': !exists(json, 'info') ? undefined : json['info'],
        'id': json['id'],
        'limitsEnforced': json['limitsEnforced'],
        'pricingPlan': PricingPlanDTOFromJSON(json['pricingPlan']),
        'created': (new Date(json['created'])),
    };
}

export function OrganizationWithRoleDTOToJSON(value?: OrganizationWithRoleDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'organization': OrganizationDTOToJSON(value.organization),
        'name': value.name,
        'userRole': OrganizationRoleDTOToJSON(value.userRole),
        'paymentStatus': value.paymentStatus,
        'avatarUrl': value.avatarUrl,
        'organizationType': OrganizationTypeDTOToJSON(value.organizationType),
        'avatarSource': AvatarSourceEnumToJSON(value.avatarSource),
        'info': value.info,
        'id': value.id,
        'limitsEnforced': value.limitsEnforced,
        'pricingPlan': PricingPlanDTOToJSON(value.pricingPlan),
        'created': (value.created.toISOString()),
    };
}


