import { LocalStorageCompatible } from './local-storage-compatible';
import { Column as ColumnV3, isLocalStorageV3 } from './local-storage-v3';
import { upgrade as upgradeFromPrevious } from './local-storage-v3.upgrade';
import { Column as ColumnV4, LocalStorageV4 } from './local-storage-v4';

function migrateOrderedColumns(
  obj: Record<string, Record<string, ColumnV3[]>>,
): Record<string, Record<string, ColumnV4[]>> {
  const { trash, ...restTypes } = obj;
  return {
    ...restTypes,
    ...(trash ? { trash: migrateTrash(trash) } : {}),
  };
}

function migrateTrash(obj: Record<string, ColumnV3[]>) {
  return Object.keys(obj).reduce(
    (acc, key) => {
      return {
        ...acc,
        [key]: obj[key].map((column) => ({
          ...column,
          id: column.id === 'type' && column.columnType === 'string' ? 'neptune-type' : column.id,
        })),
      };
    },
    {} as Record<string, ColumnV4[]>,
  );
}

export async function upgrade(raw: LocalStorageCompatible): Promise<LocalStorageV4> {
  const input = isLocalStorageV3(raw) ? raw : await upgradeFromPrevious(raw);
  const { data } = input;

  const { orderedColumns, ...rest } = data;

  return {
    version: 4,
    data: {
      ...rest,
      recentMemos: {},
      ...(orderedColumns ? { orderedColumns: migrateOrderedColumns(orderedColumns) } : {}),
    },
  };
}
