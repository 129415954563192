/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GitCommitDTO
 */
export interface GitCommitDTO {
    /**
     * 
     * @type {string}
     * @memberof GitCommitDTO
     */
    authorEmail: string;
    /**
     * 
     * @type {string}
     * @memberof GitCommitDTO
     */
    authorName: string;
    /**
     * 
     * @type {Date}
     * @memberof GitCommitDTO
     */
    commitDate: Date;
    /**
     * 
     * @type {string}
     * @memberof GitCommitDTO
     */
    commitId: string;
    /**
     * 
     * @type {string}
     * @memberof GitCommitDTO
     */
    message: string;
}

export function GitCommitDTOFromJSON(json: any): GitCommitDTO {
    return GitCommitDTOFromJSONTyped(json, false);
}

export function GitCommitDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): GitCommitDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'authorEmail': json['authorEmail'],
        'authorName': json['authorName'],
        'commitDate': (new Date(json['commitDate'])),
        'commitId': json['commitId'],
        'message': json['message'],
    };
}

export function GitCommitDTOToJSON(value?: GitCommitDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'authorEmail': value.authorEmail,
        'authorName': value.authorName,
        'commitDate': (value.commitDate.toISOString()),
        'commitId': value.commitId,
        'message': value.message,
    };
}


