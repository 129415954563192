/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CheckpointDTO
 */
export interface CheckpointDTO {
    /**
     * 
     * @type {Date}
     * @memberof CheckpointDTO
     */
    creationTime: Date;
    /**
     * 
     * @type {string}
     * @memberof CheckpointDTO
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof CheckpointDTO
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CheckpointDTO
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CheckpointDTO
     */
    notebookId: string;
    /**
     * 
     * @type {string}
     * @memberof CheckpointDTO
     */
    owner: string;
    /**
     * 
     * @type {string}
     * @memberof CheckpointDTO
     */
    path: string;
    /**
     * 
     * @type {boolean}
     * @memberof CheckpointDTO
     */
    removable: boolean;
    /**
     * 
     * @type {number}
     * @memberof CheckpointDTO
     */
    size: number;
}

export function CheckpointDTOFromJSON(json: any): CheckpointDTO {
    return CheckpointDTOFromJSONTyped(json, false);
}

export function CheckpointDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CheckpointDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'creationTime': (new Date(json['creationTime'])),
        'description': json['description'],
        'id': json['id'],
        'name': json['name'],
        'notebookId': json['notebookId'],
        'owner': json['owner'],
        'path': json['path'],
        'removable': json['removable'],
        'size': json['size'],
    };
}

export function CheckpointDTOToJSON(value?: CheckpointDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'creationTime': (value.creationTime.toISOString()),
        'description': value.description,
        'id': value.id,
        'name': value.name,
        'notebookId': value.notebookId,
        'owner': value.owner,
        'path': value.path,
        'removable': value.removable,
        'size': value.size,
    };
}


