// Libs
import React from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line no-restricted-imports
import classNames from 'classnames';
import {
  isArray,
  isEmpty,
} from 'lodash';


// neptune-core-ui
// eslint-disable-next-line no-restricted-imports
import ncuiPropTypes from 'neptune-core-ui/helpers/prop-types';
// eslint-disable-next-line no-restricted-imports
import { getValidProps } from 'neptune-core-ui/components/helpers';
// eslint-disable-next-line no-restricted-imports
import TableRow from 'neptune-core-ui/components/table/TableRow';
// eslint-disable-next-line no-restricted-imports
import TableHeadCell from 'neptune-core-ui/components/table/TableHeadCell';


// Module
import './TableHead.less';


const propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  /**
   * Array of row definitions.
   * Every row is an array of cell definitions.
   *
   * Cell definition is an object (see [TableHeadCell](#tableheadcell)):
   * - `actions`
   * - `align`
   * - `className`
   * - `colSpan`
   * - `data`
   * - `dataKey`
   * - `renderer`
   * - `renderParams`
   * - `rowSpan`
   * - `width`
   * - `onClick`
   *
   * Every cell is rendered as `<th>`.
   */
  rows: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        actions: PropTypes.shape({
          remove: PropTypes.object,
          reorder: PropTypes.object,
          resize: PropTypes.object,
          sort: PropTypes.object,
        }),
        align: PropTypes.oneOf(['left', 'center', 'right']),
        className: PropTypes.string,
        colSpan: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
        ]),
        data: PropTypes.oneOfType([
          PropTypes.number,
          PropTypes.string,
          PropTypes.object,
          PropTypes.array,
        ]),
        dataKey: PropTypes.string,
        renderer: PropTypes.func,
        renderParams: PropTypes.any,
        rowSpan: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
        ]),
        width: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
        ]),
        onClick: ncuiPropTypes.eventHandler,
      }),
    ),
  ),
};


const TableHead = ({
  children,
  className,
  rows,
}) => {

  const cssClasses = classNames(
    'n-table-head',
    className,
  );

  const generatedContent = renderHeaderRows(rows);

  return (
    <thead className={cssClasses}>
      {children}
      {generatedContent}
    </thead>
  );
};

TableHead.propTypes = propTypes;


const validCellProps = [
  'actions',
  'align',
  'className',
  'colSpan',
  'data',
  'dataAttributes',
  'dataKey',
  'renderer',
  'renderParams',
  'rowSpan',
  'width',
  'onClick',
];


function renderHeaderRows(rows) {
  if (!isArray(rows) || isEmpty(rows)) {
    return null;
  }

  return rows.map((row, rowIndex) => (
    <TableRow
      key={rowIndex}
      data-role="table-head-row"
    >
    {
      row.map((cell, cellIndex) => (
        <TableHeadCell
          key={cellIndex}
          columnIndex={cellIndex}
          {...getValidProps(cell, validCellProps)}
        />
      ))
    }
    </TableRow>
  ));
}


export default TableHead;
