export const getScrollableParent = (el: HTMLElement) => {
  const { position } = getComputedStyle(el) || {};
  let parent: Node | null = el;

  while ((parent = parent.parentNode) && parent?.nodeType === 1) {
    let style: CSSStyleDeclaration | undefined | null;

    try {
      style = getComputedStyle(parent as HTMLElement);
    } catch (e) {}

    if (!style) {
      return parent as HTMLElement;
    }

    const { overflow, overflowX, overflowY } = style;

    if (/(auto|scroll|overlay)/.test(overflow + overflowY + overflowX)) {
      if (
        position !== 'absolute' ||
        ['relative', 'absolute', 'fixed'].indexOf(style.position) >= 0
      ) {
        return parent as HTMLElement;
      }
    }
  }

  return null;
};
