import { OrganizationRoleDTO } from '@neptune/shared/core-apis-backend-domain';

export enum OrganizationRole {
  member = 'member',
  owner = 'owner',

  notSupported = 'notSupported',
}

export const organizationRoleLabels: Record<OrganizationRole, string> = {
  [OrganizationRole.owner]: 'Admin',
  [OrganizationRole.member]: 'Member',

  [OrganizationRole.notSupported]: 'Not implemented role',
};

export function organizationRoleFromApiToDomain(source?: OrganizationRoleDTO): OrganizationRole {
  switch (source) {
    case OrganizationRoleDTO.Owner:
      return OrganizationRole.owner;

    case OrganizationRoleDTO.Member:
      return OrganizationRole.member;

    default:
      return OrganizationRole.notSupported;
  }
}

export function organizationRoleFromDomainToApi(source?: OrganizationRole): OrganizationRoleDTO {
  switch (source) {
    case OrganizationRole.owner:
      return OrganizationRoleDTO.Owner;

    case OrganizationRole.member:
      return OrganizationRoleDTO.Member;

    default:
      return OrganizationRoleDTO.Member;
  }
}
