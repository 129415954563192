/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SearchLeaderboardEntriesParamsDTO,
    SearchLeaderboardEntriesParamsDTOFromJSON,
    SearchLeaderboardEntriesParamsDTOFromJSONTyped,
    SearchLeaderboardEntriesParamsDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface MultiSearchLeaderboardEntriesParamsDTO
 */
export interface MultiSearchLeaderboardEntriesParamsDTO {
    /**
     * 
     * @type {string}
     * @memberof MultiSearchLeaderboardEntriesParamsDTO
     */
    projectIdentifier: string;
    /**
     * 
     * @type {SearchLeaderboardEntriesParamsDTO}
     * @memberof MultiSearchLeaderboardEntriesParamsDTO
     */
    searchLeaderboardEntriesParamsDTO: SearchLeaderboardEntriesParamsDTO;
    /**
     * 
     * @type {Array<string>}
     * @memberof MultiSearchLeaderboardEntriesParamsDTO
     */
    type?: Array<string>;
}

export function MultiSearchLeaderboardEntriesParamsDTOFromJSON(json: any): MultiSearchLeaderboardEntriesParamsDTO {
    return MultiSearchLeaderboardEntriesParamsDTOFromJSONTyped(json, false);
}

export function MultiSearchLeaderboardEntriesParamsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): MultiSearchLeaderboardEntriesParamsDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'projectIdentifier': json['projectIdentifier'],
        'searchLeaderboardEntriesParamsDTO': SearchLeaderboardEntriesParamsDTOFromJSON(json['searchLeaderboardEntriesParamsDTO']),
        'type': !exists(json, 'type') ? undefined : json['type'],
    };
}

export function MultiSearchLeaderboardEntriesParamsDTOToJSON(value?: MultiSearchLeaderboardEntriesParamsDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'projectIdentifier': value.projectIdentifier,
        'searchLeaderboardEntriesParamsDTO': SearchLeaderboardEntriesParamsDTOToJSON(value.searchLeaderboardEntriesParamsDTO),
        'type': value.type,
    };
}


