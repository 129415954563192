import { isLocalStorageCompatibleShape } from './local-storage-compatible';
import {
  isLocalStorageLatest,
  LocalStorageLatest,
  localStorageLatestVersion as version,
} from './local-storage-latest';

export function parseLocalStorageLatest(input?: string | null): LocalStorageLatest {
  if (input) {
    const result = JSON.parse(input);

    if (isLocalStorageCompatibleShape(result)) {
      if (result.version < version) {
        throw new Error('Detected old version of localstorage data.');
      } else if (result.version > version) {
        throw new Error('Detected future version of localstorage data.');
      } else if (isLocalStorageLatest(result)) {
        return result;
      }
    } else {
      throw new Error('Detected invalid shape of localstorage data.');
    }
  }

  return { version, data: {} };
}
