import React from 'react';

import { bemBlock } from '../../modules/bem';

import './IconCheckbox.less';

const block = bemBlock('icon-checkbox');

type IconCheckboxPropsBase = {
  checked: boolean;
  className?: string;
  'aria-label'?: string;
  checkedIcon: React.ReactElement;
  uncheckedIcon: React.ReactElement;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

type IconCheckboxPropsIndeterminate = IconCheckboxPropsBase & {
  indeterminate: boolean;
  indeterminateIcon: React.ReactElement;
};

type IconCheckboxProps = IconCheckboxPropsBase & {
  indeterminate?: boolean;
  indeterminateIcon?: React.ReactElement;
};

export function IconCheckbox(props: IconCheckboxPropsBase): JSX.Element;
export function IconCheckbox(props: IconCheckboxPropsIndeterminate): JSX.Element;

export function IconCheckbox({
  'aria-label': ariaLabel,
  indeterminateIcon,
  uncheckedIcon,
  checkedIcon,
  checked = false,
  indeterminate,
  className,
  onChange,
}: IconCheckboxProps) {
  const inputRef = React.useRef<HTMLInputElement>(null);

  React.useLayoutEffect(() => {
    if (!inputRef.current) {
      return;
    }

    if (indeterminate) {
      inputRef.current.indeterminate = true;
    } else {
      inputRef.current.indeterminate = false;
    }
  }, [indeterminate]);

  const icon = React.useMemo(() => {
    if (indeterminate) {
      return indeterminateIcon;
    }

    return checked ? checkedIcon : uncheckedIcon;
  }, [checked, checkedIcon, indeterminate, indeterminateIcon, uncheckedIcon]);

  return (
    <label aria-label={ariaLabel} className={block({ extra: className })}>
      <input
        ref={inputRef}
        type="checkbox"
        checked={checked}
        onChange={onChange}
        className={block('input')}
      />
      {icon &&
        React.cloneElement(icon, {
          'aria-hidden': true,
        })}
    </label>
  );
}
