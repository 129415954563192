import { CustomIconDefinition } from '../types';

export const nepLeftBracket: CustomIconDefinition = {
  prefix: 'nep',
  iconName: 'left-bracket',
  icon: [
    448,
    512,
    [],
    'e002', // e001 represents a custom icon, increment this number for future icons
    'M306.507 512v-53.581h-9.526c-11.113 0-18.654-2.283-22.623-6.847-3.969-4.564-5.953-14.586-5.953-30.065 0-15.082.397-29.966 1.19-44.651.794-14.685 1.191-30.76 1.191-48.223 0-23.814-3.969-40.782-11.907-50.903-7.938-10.12-19.845-16.967-35.72-20.54v-2.38c15.875-3.573 27.782-10.42 35.72-20.54 7.938-10.121 11.907-27.089 11.907-50.903 0-17.463-.397-33.538-1.19-48.223a827.192 827.192 0 01-1.191-44.651c0-15.479 1.984-25.5 5.953-30.065 3.97-4.564 11.51-6.847 22.623-6.847h9.526V0h-41.674C237.447 0 217.503 6.35 205 19.051c-12.502 12.7-18.753 35.324-18.753 67.87 0 10.32.297 19.448.893 27.386.595 7.938 1.29 15.479 2.083 22.623a733.773 733.773 0 012.084 21.73c.595 7.343.893 15.579.893 24.707 0 10.32-3.77 19.845-11.312 28.577-7.54 8.732-20.837 13.495-39.888 14.289v59.534c19.051.794 32.347 5.557 39.888 14.289 7.541 8.732 11.312 18.257 11.312 28.577 0 9.128-.298 17.364-.893 24.707a733.773 733.773 0 01-2.084 21.73 635.878 635.878 0 00-2.083 22.623c-.596 7.938-.893 17.067-.893 27.386 0 16.273 1.488 29.966 4.465 41.08 2.976 11.112 7.74 20.043 14.288 26.79 6.549 6.747 14.685 11.61 24.41 14.586 9.723 2.977 21.531 4.465 35.423 4.465h41.674z',
  ],
};
