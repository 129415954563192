// Libs
import React from 'react';

import { bemBlock } from '../../modules/bem';
import { LayoutColumn } from '../layout-column/LayoutColumn';
import { LayoutRow, LayoutRowProps } from '../layout-row/LayoutRow';

// Module
import './ToolbarItem.less';

const block = bemBlock('n-ToolbarItem');

type LabelProps = {
  label?: React.ReactNode;
  labelClassName?: string;
};

export type ToolbarItemProps = Omit<
  LayoutRowProps<{}>,
  | 'alignSelf'
  | 'component'
  | 'height'
  | 'inline'
  | 'justifyContent'
  | 'overflow'
  | 'reversed'
  | 'span'
  | 'width'
  | 'withCustomScrollbar'
  | 'withPadding'
  | 'withGutter'
  | 'wrap'
> &
  LabelProps & {
    wrapperClassName?: string;
  };

/**
 * ## Import
 *
 * ```
 * import { Toolbar } from '@neptune/shared/venus-ui';
 * ```
 *
 * And use `<Toolbar.Item />`.
 */
export class ToolbarItem extends React.Component<ToolbarItemProps> {
  public static defaultProps = {
    spacedChildren: 'sm',
  };

  Label = ({ label, labelClassName }: LabelProps) => {
    if (!label) {
      return null;
    }

    return (
      <LayoutRow
        className={block({
          element: 'label',
          extra: labelClassName,
        })}
        alignItems="center"
        justifyContent="start"
        spacedChildren="xs"
        span="auto"
      >
        {label}
      </LayoutRow>
    );
  };

  render() {
    const {
      alignItems,
      className,
      label,
      labelClassName,
      selfSpacing,
      spacedChildren,
      wrapperClassName,
      ...passProps
    } = this.props;

    return (
      <LayoutColumn
        alignItems={alignItems}
        className={wrapperClassName}
        selfSpacing={selfSpacing}
        spacedChildren="xxs"
        span="auto"
      >
        {this.Label({ label, labelClassName })}
        <LayoutRow
          className={block({ extra: className })}
          span="auto"
          alignItems="center"
          spacedChildren={spacedChildren}
          {...passProps}
        />
      </LayoutColumn>
    );
  }
}
