/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SeriesViewDTO,
    SeriesViewDTOFromJSON,
    SeriesViewDTOFromJSONTyped,
    SeriesViewDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface SeriesViewListDTO
 */
export interface SeriesViewListDTO {
    /**
     * 
     * @type {Array<SeriesViewDTO>}
     * @memberof SeriesViewListDTO
     */
    elements: Array<SeriesViewDTO>;
}

export function SeriesViewListDTOFromJSON(json: any): SeriesViewListDTO {
    return SeriesViewListDTOFromJSONTyped(json, false);
}

export function SeriesViewListDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): SeriesViewListDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'elements': ((json['elements'] as Array<any>).map(SeriesViewDTOFromJSON)),
    };
}

export function SeriesViewListDTOToJSON(value?: SeriesViewListDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'elements': ((value.elements as Array<any>).map(SeriesViewDTOToJSON)),
    };
}


