/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum OperationLimitNameDTO {
    OpExecution = 'opExecution',
    OpExecutionSeriesPointFloat = 'opExecutionSeriesPointFloat',
    OpExecutionSeriesPointString = 'opExecutionSeriesPointString',
    OpExecutionSeriesPointOther = 'opExecutionSeriesPointOther',
    ApiCallExecuteOperations = 'apiCallExecuteOperations'
}

export function OperationLimitNameDTOFromJSON(json: any): OperationLimitNameDTO {
    return OperationLimitNameDTOFromJSONTyped(json, false);
}

export function OperationLimitNameDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OperationLimitNameDTO {
    return json as OperationLimitNameDTO;
}

export function OperationLimitNameDTOToJSON(value?: OperationLimitNameDTO | null): any {
    return value as any;
}

