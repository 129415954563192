// Libs
import React from 'react';
import { bemBlock } from '@neptune/shared/venus-ui';

// App
import { MarkdownRenderer, MarkdownRendererProps } from '../markdown-renderer/MarkdownRenderer';

// Module
import './EmptyViewMarkdownRenderer.less';

const block = bemBlock('empty-view-markdown-renderer');

export const EmptyViewMarkdownRenderer: React.FC<MarkdownRendererProps> = ({
  content,
  className,
}) => {
  return <MarkdownRenderer className={block({ extra: className })} content={content} />;
};
