/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AttributeTypeDTO,
    AttributeTypeDTOFromJSON,
    AttributeTypeDTOFromJSONTyped,
    AttributeTypeDTOToJSON,
    GitCommitDTO,
    GitCommitDTOFromJSON,
    GitCommitDTOFromJSONTyped,
    GitCommitDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface GitInfoDTO
 */
export interface GitInfoDTO {
    /**
     * 
     * @type {string}
     * @memberof GitInfoDTO
     */
    attributeName: string;
    /**
     * 
     * @type {AttributeTypeDTO}
     * @memberof GitInfoDTO
     */
    attributeType: AttributeTypeDTO;
    /**
     * 
     * @type {GitCommitDTO}
     * @memberof GitInfoDTO
     */
    commit: GitCommitDTO;
    /**
     * 
     * @type {string}
     * @memberof GitInfoDTO
     */
    currentBranch?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GitInfoDTO
     */
    remotes?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof GitInfoDTO
     */
    repositoryDirty: boolean;
}

export function GitInfoDTOFromJSON(json: any): GitInfoDTO {
    return GitInfoDTOFromJSONTyped(json, false);
}

export function GitInfoDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): GitInfoDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attributeName': json['attributeName'],
        'attributeType': AttributeTypeDTOFromJSON(json['attributeType']),
        'commit': GitCommitDTOFromJSON(json['commit']),
        'currentBranch': !exists(json, 'currentBranch') ? undefined : json['currentBranch'],
        'remotes': !exists(json, 'remotes') ? undefined : json['remotes'],
        'repositoryDirty': json['repositoryDirty'],
    };
}

export function GitInfoDTOToJSON(value?: GitInfoDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attributeName': value.attributeName,
        'attributeType': AttributeTypeDTOToJSON(value.attributeType),
        'commit': GitCommitDTOToJSON(value.commit),
        'currentBranch': value.currentBranch,
        'remotes': value.remotes,
        'repositoryDirty': value.repositoryDirty,
    };
}


