/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TableViewParamsDTO,
    TableViewParamsDTOFromJSON,
    TableViewParamsDTOFromJSONTyped,
    TableViewParamsDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface RunGroupDTO
 */
export interface RunGroupDTO {
    /**
     * 
     * @type {string}
     * @memberof RunGroupDTO
     */
    color?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RunGroupDTO
     */
    experimentMemberKeys?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof RunGroupDTO
     */
    firstMembersByQueryCount?: number;
    /**
     * 
     * @type {string}
     * @memberof RunGroupDTO
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof RunGroupDTO
     */
    name?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RunGroupDTO
     */
    runMemberKeys?: Array<string>;
    /**
     * 
     * @type {TableViewParamsDTO}
     * @memberof RunGroupDTO
     */
    viewParams: TableViewParamsDTO;
}

export function RunGroupDTOFromJSON(json: any): RunGroupDTO {
    return RunGroupDTOFromJSONTyped(json, false);
}

export function RunGroupDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): RunGroupDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'color': !exists(json, 'color') ? undefined : json['color'],
        'experimentMemberKeys': !exists(json, 'experimentMemberKeys') ? undefined : json['experimentMemberKeys'],
        'firstMembersByQueryCount': !exists(json, 'firstMembersByQueryCount') ? undefined : json['firstMembersByQueryCount'],
        'id': json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'runMemberKeys': !exists(json, 'runMemberKeys') ? undefined : json['runMemberKeys'],
        'viewParams': TableViewParamsDTOFromJSON(json['viewParams']),
    };
}

export function RunGroupDTOToJSON(value?: RunGroupDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'color': value.color,
        'experimentMemberKeys': value.experimentMemberKeys,
        'firstMembersByQueryCount': value.firstMembersByQueryCount,
        'id': value.id,
        'name': value.name,
        'runMemberKeys': value.runMemberKeys,
        'viewParams': TableViewParamsDTOToJSON(value.viewParams),
    };
}


