/* eslint no-console:0 */

// Libs
import PropTypes from 'prop-types';
import {
  intersection,
  isNumber,
} from 'lodash';


// Module
const layoutUnits = ['xs', 'sm', 'md', 'lg', 'xl', 'none'];
const layoutUnit = PropTypes.oneOf(layoutUnits);

const ncuiPropTypes = {
  dimension: createChainableTypeChecker(dimensionChecker),
  eventHandler: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      handler: PropTypes.func.isRequired,
      payload: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object,
        PropTypes.string,
        PropTypes.number,
      ]),
    }),
  ]),
  layoutAlignItems: PropTypes.oneOf([
    'start',
    'end',
    'center',
    'baseline',
    'stretch',
  ]),
  layoutChildrenSpacing: PropTypes.oneOfType([
    PropTypes.bool,
    layoutUnit,
  ]),
  layoutJustifyContent: PropTypes.oneOf([
    'start',
    'end',
    'center',
    'space-between',
    'space-around',
    'space-evenly',
  ]),
  layoutPadding: PropTypes.oneOfType([
    PropTypes.bool,
    layoutUnit,
    PropTypes.arrayOf(PropTypes.oneOf([...layoutUnits, false, null])),
    PropTypes.shape({
      vertical: layoutUnit,
      horizontal: layoutUnit,
    }),
  ]),
  layoutSpan: createChainableTypeChecker(layoutSpanChecker),
  layoutType: PropTypes.oneOf(['column', 'grid', 'row']),
  renderProp: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
  ]),
  requireOneOf: requireOneOfChecker,
  requireSomeOf: requireSomeOfChecker,
};

export default ncuiPropTypes;


// {{{ Checker functions
const LENGTH = /^[+-]?(?:\d*[.])?\d+(%|px|em|ex|vh|vw|vmin|vmax)$/;

function dimensionChecker(props, propName, componentName, location) {
  const value = props[propName];

  if (
    isNumber(value) ||
    LENGTH.test(value)
  ) {
    return null;
  }

  return new PropTypeError(
    `The ${location} \`${propName}\` in \`${componentName}\` is ${value}.` +
    ' Expected number or one of %, px, em, ex, vh, vw, vmin or vmax unit',
  );
}


function formatProps(props) {
  return props.map((propName) => '`' + propName + '`').join(', ');
}


function requireOneOfChecker(...requiredProps) {
  return function requireOneOf(props, propName, componentName) {
    const suppliedProps = Object.keys(props);

    if (intersection(suppliedProps, requiredProps).length !== 1) {
      return new PropTypeError(
        `\`${componentName}\` requires only one of: ${formatProps(requiredProps)}.`,
      );
    }
  };
}


function requireSomeOfChecker(...requiredProps) {
  return function requireSomeOf(props, propName, componentName) {
    const suppliedProps = Object.keys(props);

    if (intersection(suppliedProps, requiredProps).length === 0) {
      return new PropTypeError(
        `\`${componentName}\` requires at least one of: ${formatProps(requiredProps)}.`,
      );
    }
  };
}


const LAYOUT_SPAN = [
  'greedy', 'auto', 'grow', 'shrink', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12',
];
const LAYOUT_SPAN_STR = `[${LAYOUT_SPAN.join(', ')}]`;

function layoutSpanChecker(props, propName, componentName, location) {
  const value = '' + props[propName];

  if (!LAYOUT_SPAN.includes(value)) {
    return new PropTypeError(
      `The ${location} \`${propName}\` in \`${componentName}\` is ${value},` +
      ` while one of ${LAYOUT_SPAN_STR} is expected`,
    );
  }

  return null;
}
// Checker functions }}}


// {{{ Implementation

// PropTypeError, ANONYMOUS and createChainableTypeChecker
// are taken from prop-types lib

function PropTypeError(message) {
  this.message = message;
  this.stack = '';
}
PropTypeError.prototype = Error.prototype;


const ANONYMOUS = '<<anonymous>>';

function createChainableTypeChecker(validate) {
  function checkType(isRequired, props, propName, componentName, location, propFullName) {
    componentName = componentName || ANONYMOUS;
    propFullName = propFullName || propName;

    if (props[propName] == null) {
      if (isRequired) {
        if (props[propName] === null) {
          return new PropTypeError(`The ${location} \`${propFullName}\` is marked as required in \`${componentName}\`, but its value is \`null\`.`);
        }
        return new PropTypeError(`The ${location} \`${propFullName}\` is marked as required in \`${componentName}\`, but its value is \`undefined\`.`);
      }
      return null;
    }
    return validate(props, propName, componentName, location, propFullName);
  }

  const chainedCheckType = checkType.bind(null, false);
  chainedCheckType.isRequired = checkType.bind(null, true);

  return chainedCheckType;
}
// Implementation }}}
