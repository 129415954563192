// Libs
import React from 'react';
import { padStart } from 'lodash';
import { AutoSizer, List, ListRowProps, Size } from 'react-virtualized';
import Prism from 'prismjs';

// Languages
import 'prismjs/components/prism-bash';
import 'prismjs/components/prism-docker';
import 'prismjs/components/prism-python';
import 'prismjs/components/prism-yaml';
import 'prismjs/components/prism-json';
import 'prismjs/components/prism-r';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-diff';
import 'prismjs/components/prism-batch';

import { Banner, bemBlock, Layout } from '@neptune/shared/venus-ui';

import TokenRenderer from 'components/token-renderer/TokenRenderer';
import { parseText } from '@neptune/shared/common-util';

import { CopyToClipboard } from '../copy-to-clipboard/CopyToClipboard';
import { ThemeToggle } from '../theme-toggle/ThemeToggle';
import './FastSyntaxHighlighter.less';

interface FastSyntaxHighlighterProps {
  className?: string;
  code: string;
  language?: string;
  theme: string;
  onThemeChange: () => void;
}

const LINE_TOKENIZATION_LIMIT = 1024;

const block = bemBlock('fast-syntax-highlighter');

export const FastSyntaxHighlighter: React.FC<FastSyntaxHighlighterProps> = ({
  className,
  code,
  language,
  theme,
  onThemeChange,
}) => {
  const lines = parseText(code);

  const padLength = String(lines.length).length;

  const grammar =
    language && Prism.languages[language] ? Prism.languages[language] : Prism.languages['clike']; // this should not happen

  const lineLengthExceeded = lines.some((line) => line.length > LINE_TOKENIZATION_LIMIT);

  function rowRenderer(props: ListRowProps) {
    const { key, index, style } = props;

    const line = lines[index];
    const tokens = line.length <= LINE_TOKENIZATION_LIMIT ? Prism.tokenize(line, grammar) : [line];

    let offset = 0;
    const children = tokens.map((token, index) => {
      const text = line.slice(offset, offset + token.length);
      offset += token.length;

      if (typeof token !== 'string') {
        return (
          <TokenRenderer
            key={index}
            children={text}
            tokenType={token.type}
            darkMode={theme === 'dark'}
            className={block('renderer')}
            data-role="syntax-token"
          />
        );
      }

      return <span data-heap-redact-text key={index} children={token} data-role="syntax-token" />;
    });

    return (
      <div style={style} className={block('row')} key={key} data-row={index}>
        <span
          children={`${padStart(String(index + 1), padLength, ' ')}  `}
          className={block('line-number')}
          data-role="line-number"
        />
        {children}
        {'\n'}
      </div>
    );
  }

  const content = (
    <AutoSizer>
      {({ height, width }: Size) => (
        <List
          rowRenderer={rowRenderer}
          rowHeight={20}
          rowCount={lines.length}
          height={height}
          width={width}
          overscanRowCount={200}
          className={block('list')}
          containerProps={{
            'data-role': 'syntax-preview-content',
          }}
        />
      )}
    </AutoSizer>
  );

  const contentCssClass = block({
    element: 'content',
    extra: className,
    modifiers: {
      light: theme !== 'dark',
      dark: theme === 'dark',
    },
  });

  return (
    <Layout.Column className={block()}>
      {lineLengthExceeded && (
        <Banner.Warning size="small" data-role="syntax-preview-limit-exceeded-warning">
          Syntax highlighting was disabled on some lines exceeding the length limit (
          {LINE_TOKENIZATION_LIMIT} characters).
        </Banner.Warning>
      )}

      <div className={contentCssClass}>
        {content}

        <Layout.Row className={block('buttons')} alignItems="center">
          {onThemeChange && (
            <ThemeToggle className={block('switch')} theme={theme} onClick={onThemeChange} />
          )}
          <CopyToClipboard className={block('button')} text={code} theme="dark" />
        </Layout.Row>
      </div>
    </Layout.Column>
  );
};
