// Libs
import React from 'react';

import { bemBlock } from '../../modules/bem';
import { LayoutColumn, LayoutColumnProps } from '../layout-column/LayoutColumn';
import { ViewTitle } from '../view-title/ViewTitle';

// Module
const block = bemBlock('n-ViewHeader');

export type ViewHeaderProps = Omit<
  LayoutColumnProps<{}>,
  'component' | 'height' | 'inline' | 'span' | 'title' | 'width'
> & {
  /** If `title` is defined, `ViewTitle` instance will be rendered as a first child component. */
  title?: React.ReactNode;
};

/**
 * `ViewHeader` is a wrapper for view's title and navigation.
 *
 * It is based on `LayoutColumn`.
 *
 * ## Import
 *
 * ```
 * import { View } from '@neptune/shared/venus-ui';
 * ```
 *
 * And use `<View.Header />`.
 */
// eslint-disable-next-line react/prefer-stateless-function
export class ViewHeader extends React.Component<ViewHeaderProps> {
  public static defaultProps = {
    component: 'header',
    span: 'auto',
    spacedChildren: 'lg',
  };

  render() {
    const { children, className, title, ...passProps } = this.props;
    const titleElement = title ? <ViewTitle>{title}</ViewTitle> : null;

    return (
      <LayoutColumn className={block({ extra: className })} {...passProps}>
        {titleElement}
        {children}
      </LayoutColumn>
    );
  }
}
