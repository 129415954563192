// Libs
import React from 'react';

import { joinClassNames } from '../../modules/bem';
import * as layout from '../../modules/layout';

// Module
export type LayoutFillProps = {
  className?: string;
};

/**
 * `LayoutFill` is a helper that may be used within `LayoutColumn` and `LayoutRow`.
 * It can be used to divide elements into equally spaced groups.
 *
 * ## Import
 *
 * ```
 * import { Layout } from '@neptune/shared/venus-ui';
 * ```
 *
 * And use `<Layout.Fill />`.
 */
export const LayoutFill: React.FC<LayoutFillProps> = ({ className }) => {
  const props = {
    className: joinClassNames(layout.spanClassName('greedy'), className),
    'data-ui-role': 'layout-fill',
  };

  return <div {...props} />;
};
