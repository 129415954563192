/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PricingPlanDefinitionDTO,
    PricingPlanDefinitionDTOFromJSON,
    PricingPlanDefinitionDTOFromJSONTyped,
    PricingPlanDefinitionDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface PlanUpgradeTraitDTO
 */
export interface PlanUpgradeTraitDTO {
    /**
     * 
     * @type {Array<PricingPlanDefinitionDTO>}
     * @memberof PlanUpgradeTraitDTO
     */
    availablePlans: Array<PricingPlanDefinitionDTO>;
}

export function PlanUpgradeTraitDTOFromJSON(json: any): PlanUpgradeTraitDTO {
    return PlanUpgradeTraitDTOFromJSONTyped(json, false);
}

export function PlanUpgradeTraitDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlanUpgradeTraitDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'availablePlans': ((json['availablePlans'] as Array<any>).map(PricingPlanDefinitionDTOFromJSON)),
    };
}

export function PlanUpgradeTraitDTOToJSON(value?: PlanUpgradeTraitDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'availablePlans': ((value.availablePlans as Array<any>).map(PricingPlanDefinitionDTOToJSON)),
    };
}


