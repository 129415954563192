/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OpenRangeDTO,
    OpenRangeDTOFromJSON,
    OpenRangeDTOFromJSONTyped,
    OpenRangeDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface PointFilters
 */
export interface PointFilters {
    /**
     * 
     * @type {OpenRangeDTO}
     * @memberof PointFilters
     */
    stepRange?: OpenRangeDTO;
}

export function PointFiltersFromJSON(json: any): PointFilters {
    return PointFiltersFromJSONTyped(json, false);
}

export function PointFiltersFromJSONTyped(json: any, ignoreDiscriminator: boolean): PointFilters {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'stepRange': !exists(json, 'stepRange') ? undefined : OpenRangeDTOFromJSON(json['stepRange']),
    };
}

export function PointFiltersToJSON(value?: PointFilters | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'stepRange': OpenRangeDTOToJSON(value.stepRange),
    };
}


