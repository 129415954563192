/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ReportVersionMetadataDTO,
    ReportVersionMetadataDTOFromJSON,
    ReportVersionMetadataDTOFromJSONTyped,
    ReportVersionMetadataDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface ReportMetadataDTO
 */
export interface ReportMetadataDTO {
    /**
     * 
     * @type {Date}
     * @memberof ReportMetadataDTO
     */
    creationTime: Date;
    /**
     * 
     * @type {Date}
     * @memberof ReportMetadataDTO
     */
    latestPublishTime: Date;
    /**
     * 
     * @type {ReportVersionMetadataDTO}
     * @memberof ReportMetadataDTO
     */
    latestVersionMetadata: ReportVersionMetadataDTO;
    /**
     * 
     * @type {string}
     * @memberof ReportMetadataDTO
     */
    reportId: string;
}

export function ReportMetadataDTOFromJSON(json: any): ReportMetadataDTO {
    return ReportMetadataDTOFromJSONTyped(json, false);
}

export function ReportMetadataDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportMetadataDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'creationTime': (new Date(json['creationTime'])),
        'latestPublishTime': (new Date(json['latestPublishTime'])),
        'latestVersionMetadata': ReportVersionMetadataDTOFromJSON(json['latestVersionMetadata']),
        'reportId': json['reportId'],
    };
}

export function ReportMetadataDTOToJSON(value?: ReportMetadataDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'creationTime': (value.creationTime.toISOString()),
        'latestPublishTime': (value.latestPublishTime.toISOString()),
        'latestVersionMetadata': ReportVersionMetadataDTOToJSON(value.latestVersionMetadata),
        'reportId': value.reportId,
    };
}


