// Libs
import React from 'react';
import { isEqual } from 'lodash';
import { Icon } from '@neptune/shared/venus-ui';
// eslint-disable-next-line no-restricted-imports
import { WithPaddingRenderer } from 'neptune-core-ui/components/table/renderers';
import type { GlyphName } from '@neptune/shared/core-glyphs-domain';

// Module
import type { PropertyDiffTableEntry } from '../types';

export const EqualityRenderer: React.FC<{ data: PropertyDiffTableEntry }> = ({ data }) => {
  const status = getStatus(data);

  return (
    <WithPaddingRenderer>
      {status !== 'same' && (
        <Icon
          color={data.oldValue !== undefined ? 'error' : 'success'}
          glyph={iconMap[status]}
          data-role="equality-renderer"
          data-value={status}
          size="sm"
        />
      )}
    </WithPaddingRenderer>
  );
};

type Status = 'new' | 'removed' | 'greater-than' | 'less-than' | 'changed' | 'same';
const iconMap: Record<Exclude<Status, 'same'>, GlyphName> = {
  new: 'long-arrow-alt-right',
  removed: 'long-arrow-alt-left',
  'greater-than': 'greater-than',
  'less-than': 'less-than',
  changed: 'not-equal',
};

function getStatus(data: PropertyDiffTableEntry): Status {
  if (data.oldValue === undefined) {
    return 'new';
  }

  if (data.newValue === undefined) {
    return 'removed';
  }

  if (data.dataType === 'number') {
    if (data.oldValue > data.newValue) {
      return 'greater-than';
    }

    if (data.oldValue < data.newValue) {
      return 'less-than';
    }
  }

  return isEqual(data.oldValue, data.newValue) ? 'same' : 'changed';
}
