// Lib
import React from 'react';
import { Layout, Text } from '@neptune/shared/venus-ui';

// App
import { formatMoney } from '@neptune/shared/common-util';
import { ProgressBar } from './progress-bar/ProgressBar';

// Module
interface IUsageData {
  used: string;
  limit?: string;
  label?: string;
  price?: number;
  progress?: number;
}

interface IUsageContent {
  data: IUsageData[];
  size?: 'xs' | 'sm' | 'md';
}

export const UsageContent: React.FC<IUsageContent> = ({ data = [], size = 'md' }) => {
  return (
    <Layout.Column spacedChildren="md" data-role="usage">
      {data.map(({ used, limit, label, price, progress }, idx) => (
        <Layout.Column spacedChildren="sm" key={idx} data-row={idx} data-role="usage-row">
          <Text>
            <Text size={size}>
              <span data-role="usage-value" children={used} />
              {typeof limit === 'string' && ` of ${limit}`}
              {typeof label === 'string' && ` ${label}`}
            </Text>
          </Text>
          {typeof progress === 'number' && <ProgressBar ratio={progress} size={size} />}
          {typeof price === 'number' && (
            <Text>
              <Text size={size}>
                <Text fontWeight="semibold" data-role="storage-price">
                  {price > 0 ? '+' : ''}
                  {formatMoney(price)}
                </Text>
                /month
              </Text>
            </Text>
          )}
        </Layout.Column>
      ))}
    </Layout.Column>
  );
};
