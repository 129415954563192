// Libs
import React from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line no-restricted-imports
import classNames from 'classnames';
import { uniqueId } from 'lodash';


// neptune-core-ui
// eslint-disable-next-line no-restricted-imports
import { getWidthStyle } from 'neptune-core-ui/components/helpers';
// eslint-disable-next-line no-restricted-imports
import ncuiPropTypes from 'neptune-core-ui/helpers/prop-types';
// eslint-disable-next-line no-restricted-imports
import {
  getEventHandler,
  getRelativeMousePosition,
} from 'neptune-core-ui/modules/events';
// eslint-disable-next-line no-restricted-imports
import { getCellData } from 'neptune-core-ui/components/table/helpers';
// eslint-disable-next-line no-restricted-imports
import {
  simpleRenderer,
  WithPaddingRenderer,
} from 'neptune-core-ui/components/table/renderers';
// eslint-disable-next-line no-restricted-imports
import {
  ConfigAction,
  RemoveAction,
  ResizeAction,
  SortAction,
} from 'neptune-core-ui/components/table/actions';


// Module
import './TableHeadCell.less';

const propTypes = {
  // TODO: dodac dokladniejsza definicje actions
  actions: PropTypes.shape({
    config: PropTypes.object,
    /** See [RemoveAction](#removeaction) */
    remove: PropTypes.object,
    /** See [DragAction](#dragaction) */
    reorder: PropTypes.shape({
      column: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
      ]).isRequired,
      onOrderChange: ncuiPropTypes.eventHandler,
      draggable: PropTypes.bool, // defaults to true
      droppable: PropTypes.oneOfType([ // defaults to true
        PropTypes.bool,
        PropTypes.shape({
          after: PropTypes.bool,
          before: PropTypes.bool,
        }),
      ]),
    }),
    /** See [ResizeAction](#resizeaction) */
    resize: PropTypes.object,
    /** See [SortAction](#sortaction) */
    sort: PropTypes.object,
  }),
  /** Align of the content */
  align: PropTypes.oneOf(['left', 'center', 'right']),
  children: PropTypes.node,
  className: PropTypes.string,
  colSpan: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  data: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
  ]),
  dataKey: PropTypes.string,
  /**
   * Renderer will be called with one argument
   * - `options` - object with the following properties:
   *    - `data` - value of *data* property
   *    - `dataKey`- value of *dataKey* property
   *    - `children` - Content passed to renderer component
   *    - `params` - value of *renderParams* property
   */
  renderer: PropTypes.func,
  renderParams: PropTypes.any,
  rowSpan: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  /** Number will be converted to pixels (10 -> 10px) */
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  /**
   * Can be event handler function or object with two properties:
   * - `handler` - event handler function
   * - `payload` - object that will be passed to handler
   *
   * Event handler will be called with arguments:
   * - `event` - [SyntheticEvent](https://facebook.github.io/react/docs/events.html)
   * - `params` - `object` (defaults to `{}` *empty object*)
   *   - `data` - value of `data` property
   *   - `dataKey`- value of `dataKey` property
   *   - `value` - evaluated value before passing it to the `renderer`
   *   - `payload` - value of `onClick.payload`
   */
  onClick: ncuiPropTypes.eventHandler,
};

const defaultProps = {
  actions: {},
  align: 'left',
  renderer: (props) => (
    <WithPaddingRenderer>
      {simpleRenderer(props)}
    </WithPaddingRenderer>
  ),
};


const columnType = 'text/x-column';
const columnDNDType = RegExp(`${columnType}:(n.+)`);


class TableHeadCell extends React.Component {
  constructor(...args) {
    super(...args);

    // {{{ Drag
    this.onDragStart = this.onDragStart.bind(this);
    // Drag }}}

    // {{{ Drop
    this.onDragEnter = this.onDragEnter.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
    this.onDrop = this.onDrop.bind(this);
    // Drop }}}

    this.state = {
      id: uniqueId('n'),
      dropping: false,
    };
  }


  setDNDRules(reorder) {
    this.isDraggable = false;
    this.isDroppable = false;
    this.acceptsDropBefore = false;
    this.acceptsDropAfter = false;

    if (!reorder) {
      return;
    }

    const {
      onOrderChange,
      draggable = true,
      droppable = true,
    } = reorder;

    this.isDraggable = draggable === true;

    if (!onOrderChange) {
      return;
    }

    this.acceptsDropAfter = droppable === true || droppable.after === true;
    this.acceptsDropBefore = droppable === true || droppable.before === true;
    this.isDroppable = this.acceptsDropAfter || this.acceptsDropBefore;
  }

  getAction(action, actionProps) {
    return React.createElement(action, {...actionProps});
  }

  getRemoveAction() {
    const { remove } = this.props.actions;
    if (remove) {
      return this.getAction(RemoveAction, remove);
    }
  }

  getResizeAction() {
    const { resize } = this.props.actions;
    if (resize) {
      return this.getAction(ResizeAction, resize);
    }
  }

  getSortAction() {
    const { sort } = this.props.actions;
    if (sort) {
      return this.getAction(SortAction, sort);
    }
  }

  getConfigAction() {
    const { config } = this.props.actions;

    if (config) {
      return this.getAction(ConfigAction, config);
    }
  }


  decodedColumnData(data) {
    return JSON.parse(data);
  }

  encodedColumnData() {
    return JSON.stringify(this.props.actions.reorder.column);
  }

  getDecomposedColumnIDType(types) {
    const columnIDType = types.find((type) => columnDNDType.test(type));
    let columnID;
    if (columnIDType) {
      columnID = columnIDType.match(columnDNDType).pop();
    }
    return {
      columnID,
      columnIDType,
    };
  }

  isValidDropTarget({dataTransfer}) {
    if (!this.isDroppable) {
      return false;
    }
    const {
      columnID,
      columnIDType,
    } = this.getDecomposedColumnIDType(dataTransfer.types);
    if (columnIDType) {
      return columnID !== this.state.id;
    }
  }

  // {{{ Drag
  onDragStart(event) {
    event.dataTransfer.effectAllowed = 'move';
    event.dataTransfer.setData(`${columnType}:${this.state.id}`, this.encodedColumnData());
  }
  // Drag }}}


  // {{{ Drop
  onDragEnter(event) {
    if (this.isValidDropTarget(event)) {
      event.dataTransfer.dropEffect = 'move';
      event.preventDefault();
      event.stopPropagation();
    }
  }

  onDragOver(event) {
    if (this.isValidDropTarget(event)) {
      const [ mouseX ] = getRelativeMousePosition(event, event.currentTarget);
      const { width } = event.currentTarget.getBoundingClientRect();
      const dropping = (mouseX < width / 2) ? 'before' : 'after';
      if (
        dropping === 'after' && this.acceptsDropAfter
        || dropping === 'before' && this.acceptsDropBefore
      ) {
        this.setState({dropping});
        event.dataTransfer.dropEffect = 'move';
        event.preventDefault();
        event.stopPropagation();
      } else {
        this.setState({dropping: false});
      }
    }
  }

  onDragLeave() {
    this.setState({dropping: false});
  }

  onDrop(event) {
    const { dropping } = this.state;
    const {
      column,
      onOrderChange,
    } = this.props.actions.reorder;
    const orderChangeHandler = getEventHandler(onOrderChange, {}, false);
    const { columnIDType } = this.getDecomposedColumnIDType(event.dataTransfer.types);
    const droppedColumn = this.decodedColumnData(
      event.dataTransfer.getData(columnIDType),
    );
    this.setState({dropping: false});
    orderChangeHandler({
      target: column,
      source: droppedColumn,
      order: dropping,
    });
    event.preventDefault();
    event.stopPropagation();
  }
  // Drop }}}


  parseCellActions() {
    const removeAction = this.getRemoveAction();
    const resizeAction = this.getResizeAction();
    const sortAction = this.getSortAction();
    const configAction = this.getConfigAction();
    const cellWithActions = !!(removeAction || resizeAction || sortAction || configAction);

    return {
      configAction,
      cellWithActions,
      removeAction,
      resizeAction,
      sortAction,
    };
  }


  parseReorderAction() {
    const reorderProps = {
      id: this.state.id,
    };

    if (this.isDraggable) {
      Object.assign(reorderProps, {
        draggable: true,
        onDragStart: this.onDragStart,
      });
    }

    if (this.isDroppable) {
      Object.assign(reorderProps, {
        onDragEnter: this.onDragEnter,
        onDragOver: this.onDragOver,
        onDragLeave: this.onDragLeave,
        onDrop: this.onDrop,
      });
    }

    return reorderProps;
  }


  render() {
    const {
      align,
      children,
      className,
      colSpan,
      data,
      dataAttributes,
      dataKey,
      renderer,
      renderParams,
      rowSpan,
      width,
      columnIndex,
      onClick,
    } = this.props;

    this.setDNDRules(this.props.actions.reorder);

    const onClickHandler = getEventHandler(
      onClick,
      {
        value: getCellData({data, dataKey, children}),
        data,
        dataKey,
      },
    );
    const {
      configAction,
      cellWithActions,
      removeAction,
      resizeAction,
      sortAction,
    } = this.parseCellActions();
    const reorderProps = this.parseReorderAction();
    const cellProps = {
      className: classNames(
        'n-table-head-cell',
        `n-table-cell--align-${align}`,
        {
          'n-table-head-cell--with-actions': cellWithActions,
          'n-table-head-cell--draggable': this.isDraggable,
          [`n-table-head-cell--dropping-${this.state.dropping}`]: this.state.dropping,
          'clickable': onClickHandler,
        },
        className,
      ),
      colSpan,
      rowSpan,
      style: {...getWidthStyle(width)},
      'data-role': 'table-head-cell',
      'data-column': columnIndex,
      onClick: onClickHandler,
      ...dataAttributes,
    };
    const cellContent = React.createElement(renderer, {
      data,
      dataKey,
      children,
      params: renderParams,
    });

    return (
      <th {...cellProps} {...reorderProps}>
        {cellContent}
        {removeAction}
        {sortAction}
        {configAction}
        {resizeAction}
      </th>
    );
  }
}

TableHeadCell.propTypes = propTypes;
TableHeadCell.defaultProps = defaultProps;


export default TableHeadCell;
