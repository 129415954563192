export const namedColors = [
  'elm',
  'green-vogue',
  'orient',
  'endeavour',
  'eastern-blue',
  'bali-hai',
  'pelorous',
  'shamrock',
  'old-gold',
  // these 2 colors are supported but not available for select now (it may be for backward compatibility or for special colors for neptune flavored projects
  // 'malachite',
  // 'mantis',
  'di-serria',
  'tree-poppy',
  'flamingo',
  'roman',
  'stiletto',
  'violet-eggplant',
  'dallas',
];
