import React from 'react';
import { bemBlock, Icon } from '@neptune/shared/venus-ui';
import { InputVariant } from '../types';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { SearchInputProps } from './types';
import './SearchInputIcon.less';

const block = bemBlock('search-input-icon');

const getSize = (variant: InputVariant | undefined): SizeProp => {
  switch (variant) {
    case 'small':
      return 'sm';
    case 'medium':
      return '1x';
    case 'large':
      return 'xl';

    default:
      return '1x';
  }
};

export const SearchInputIcon: React.FC<
  Pick<SearchInputProps, 'className' | 'variant' | 'glyph'>
> = ({ className, variant, glyph = 'search' }) => (
  <div
    className={block({
      extra: className,
      modifiers: {
        variant,
      },
    })}
  >
    <Icon glyph={glyph} size={getSize(variant)} />
  </div>
);
