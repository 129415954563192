/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum SubscriptionStatusDTO {
    Inactive = 'inactive',
    PastDue = 'past_due',
    Ok = 'ok'
}

export function SubscriptionStatusDTOFromJSON(json: any): SubscriptionStatusDTO {
    return SubscriptionStatusDTOFromJSONTyped(json, false);
}

export function SubscriptionStatusDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubscriptionStatusDTO {
    return json as SubscriptionStatusDTO;
}

export function SubscriptionStatusDTOToJSON(value?: SubscriptionStatusDTO | null): any {
    return value as any;
}

