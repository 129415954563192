// Libs
import React from 'react';

import { bemBlock } from '../../modules/bem';
import * as uiState from '../../modules/ui-state';
import { LayoutRow, LayoutRowProps } from '../layout-row/LayoutRow';

// Module
import './SidebarElement.less';

const block = bemBlock('n-SidebarElement');

export type SidebarElementProps = LayoutRowProps<{}> & {
  /** Force element to be visually clickable, even if onClick is not provided */
  active?: boolean;
  disabled?: boolean;
  selected?: boolean;
};

/**
 * ## Import
 *
 * ```
 * import { Sidebar } from '@neptune/shared/venus-ui';
 * ```
 *
 * And use `<Sidebar.Element />`.
 */
export const SidebarElement = ({
  active,
  alignItems = 'center',
  className,
  disabled,
  onClick,
  selected,
  spacedChildren = 'sm',
  span = 'auto',
  withPadding = ['none', 'none', 'none', 'md'],
  ...passProps
}: SidebarElementProps) => {
  const clickable = active || !!onClick;

  return (
    <LayoutRow
      className={block({
        extra: [
          className,
          uiState.clickableClassName(!disabled && clickable),
          uiState.disabledClassName(disabled),
          uiState.selectedClassName(!disabled && selected),
        ],
      })}
      alignItems={alignItems}
      spacedChildren={spacedChildren}
      withPadding={withPadding}
      span={span}
      onClick={onClick}
      {...passProps}
    />
  );
};
