// Libs
import React from 'react';

import { bemBlock } from '../../modules/bem';

// Module
import './LayoutSeparator.less';

const block = bemBlock('n-LayoutSeparator');

export type LayoutSeparatorProps = {
  className?: string;
  style?: React.CSSProperties;
};

/**
 * `LayoutSeparator` is a helper that may be used within[LayoutColumn](#layoutcolumn) and[LayoutRow](#layoutrow).
 *
 * ## Import
 *
 * ```
 * import { Layout } from '@neptune/shared/venus-ui';
 * ```
 *
 * And use`<Layout.Separator />`.
 */
export const LayoutSeparator: React.FC<LayoutSeparatorProps> = ({ className, style }) => {
  const ownProps = {
    className: block({ extra: className }),
    style,
  };

  return <div {...ownProps} />;
};
