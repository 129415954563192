/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OutputImageDTO,
    OutputImageDTOFromJSON,
    OutputImageDTOFromJSONTyped,
    OutputImageDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface YOutput
 */
export interface YOutput {
    /**
     * 
     * @type {OutputImageDTO}
     * @memberof YOutput
     */
    imageValue?: OutputImageDTO;
    /**
     * 
     * @type {number}
     * @memberof YOutput
     */
    numericValue?: number;
    /**
     * 
     * @type {string}
     * @memberof YOutput
     */
    textValue?: string;
}

export function YOutputFromJSON(json: any): YOutput {
    return YOutputFromJSONTyped(json, false);
}

export function YOutputFromJSONTyped(json: any, ignoreDiscriminator: boolean): YOutput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'imageValue': !exists(json, 'imageValue') ? undefined : OutputImageDTOFromJSON(json['imageValue']),
        'numericValue': !exists(json, 'numericValue') ? undefined : json['numericValue'],
        'textValue': !exists(json, 'textValue') ? undefined : json['textValue'],
    };
}

export function YOutputToJSON(value?: YOutput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'imageValue': OutputImageDTOToJSON(value.imageValue),
        'numericValue': value.numericValue,
        'textValue': value.textValue,
    };
}


