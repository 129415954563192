// Module
export type Align = 'left' | 'right' | 'center' | 'justify';
export type Color =
  | 'text'
  | 'text-secondary'
  | 'text-alt'
  | 'text-subdued'
  | 'warning'
  | 'error'
  | 'success'
  | 'info'
  | 'disabled'
  | 'decorator'
  | 'dark-decorator';
export type WordBreak = 'normal' | 'break-all' | 'keep-all' | 'break-word';
export type Transform = 'capitalize' | 'lowercase' | 'uppercase' | 'none';

export function colorClassName(value?: Color) {
  return value && `n--color-${value}`;
}

export function ellipsisClassName(value?: boolean) {
  return value ? 'n--ellipsis' : undefined;
}

export function wordBreakClassName(value?: WordBreak) {
  return value && `n--wordBreak-${value}`;
}

export function transformClassName(value?: Transform) {
  return value && `n--textTransform-${value}`;
}

export function alignClassName(value?: Align) {
  return value && `n--textAlign-${value}`;
}
