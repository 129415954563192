export enum TextWrapping {
  Clip = 'Clip',
  Wrap = 'Wrap',
}

export class TextWrappingConverter {
  static fromLocalStorageToDomain(
    mode: string | undefined,
    defaultMode: TextWrapping,
  ): TextWrapping {
    switch (mode) {
      case 'Clip':
        return TextWrapping.Clip;

      case 'Wrap':
        return TextWrapping.Wrap;
    }

    return defaultMode;
  }
}
