/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IntercomEntryDTO,
    IntercomEntryDTOFromJSON,
    IntercomEntryDTOFromJSONTyped,
    IntercomEntryDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserIntercomDataDTO
 */
export interface UserIntercomDataDTO {
    /**
     * 
     * @type {string}
     * @memberof UserIntercomDataDTO
     */
    username: string;
    /**
     * 
     * @type {Array<IntercomEntryDTO>}
     * @memberof UserIntercomDataDTO
     */
    userProperties: Array<IntercomEntryDTO>;
}

export function UserIntercomDataDTOFromJSON(json: any): UserIntercomDataDTO {
    return UserIntercomDataDTOFromJSONTyped(json, false);
}

export function UserIntercomDataDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserIntercomDataDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'username': json['username'],
        'userProperties': ((json['userProperties'] as Array<any>).map(IntercomEntryDTOFromJSON)),
    };
}

export function UserIntercomDataDTOToJSON(value?: UserIntercomDataDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'username': value.username,
        'userProperties': ((value.userProperties as Array<any>).map(IntercomEntryDTOToJSON)),
    };
}


