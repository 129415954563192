/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ReportVersionMetadataDTO,
    ReportVersionMetadataDTOFromJSON,
    ReportVersionMetadataDTOFromJSONTyped,
    ReportVersionMetadataDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface ReportVersionListDTO
 */
export interface ReportVersionListDTO {
    /**
     * 
     * @type {Array<ReportVersionMetadataDTO>}
     * @memberof ReportVersionListDTO
     */
    reportVersionsMetadata: Array<ReportVersionMetadataDTO>;
}

export function ReportVersionListDTOFromJSON(json: any): ReportVersionListDTO {
    return ReportVersionListDTOFromJSONTyped(json, false);
}

export function ReportVersionListDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportVersionListDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'reportVersionsMetadata': ((json['reportVersionsMetadata'] as Array<any>).map(ReportVersionMetadataDTOFromJSON)),
    };
}

export function ReportVersionListDTOToJSON(value?: ReportVersionListDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'reportVersionsMetadata': ((value.reportVersionsMetadata as Array<any>).map(ReportVersionMetadataDTOToJSON)),
    };
}


