/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PrioritizedAttributeDefinitionDTO,
    PrioritizedAttributeDefinitionDTOFromJSON,
    PrioritizedAttributeDefinitionDTOFromJSONTyped,
    PrioritizedAttributeDefinitionDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface QueryAttributeDefinitionsPrioritizedResultDTO
 */
export interface QueryAttributeDefinitionsPrioritizedResultDTO {
    /**
     * 
     * @type {Array<PrioritizedAttributeDefinitionDTO>}
     * @memberof QueryAttributeDefinitionsPrioritizedResultDTO
     */
    entries: Array<PrioritizedAttributeDefinitionDTO>;
    /**
     * 
     * @type {boolean}
     * @memberof QueryAttributeDefinitionsPrioritizedResultDTO
     */
    hasMore: boolean;
}

export function QueryAttributeDefinitionsPrioritizedResultDTOFromJSON(json: any): QueryAttributeDefinitionsPrioritizedResultDTO {
    return QueryAttributeDefinitionsPrioritizedResultDTOFromJSONTyped(json, false);
}

export function QueryAttributeDefinitionsPrioritizedResultDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): QueryAttributeDefinitionsPrioritizedResultDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entries': ((json['entries'] as Array<any>).map(PrioritizedAttributeDefinitionDTOFromJSON)),
        'hasMore': json['hasMore'],
    };
}

export function QueryAttributeDefinitionsPrioritizedResultDTOToJSON(value?: QueryAttributeDefinitionsPrioritizedResultDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entries': ((value.entries as Array<any>).map(PrioritizedAttributeDefinitionDTOToJSON)),
        'hasMore': value.hasMore,
    };
}


