// Libs
import React, { HTMLAttributes } from 'react';

import * as font from '../../modules/font';
import * as text from '../../modules/text';
import { joinClassNames } from '../../modules/bem';
import { WithNonInterferingComponent, createComponent } from '../../modules/utils';
import { makeRefProp } from '../make-ref-prop-util';

// Module
export type TextProps<P extends object> = WithNonInterferingComponent<P, TextOwnProps> &
  HTMLAttributes<HTMLSpanElement> & {
    align?: text.Align;
    className?: string;
    color?: text.Color;
    elementRef?: React.Ref<any>;
    ellipsis?: boolean;
    fontFamily?: font.Family;
    fontWeight?: font.Weight;
    italic?: boolean;
    lineHeight?: font.LineHeight;
    size?: font.Size;
    transform?: text.Transform;
    wordBreak?: text.WordBreak;
  };

type TextOwnProps = {
  className: string;
};

export function Text<P extends object>({
  align,
  className,
  color,
  component = 'span',
  elementRef,
  ellipsis,
  fontFamily,
  fontWeight,
  italic,
  lineHeight,
  size,
  transform,
  wordBreak,
  ...passProps
}: TextProps<P>): React.ReactElement {
  const ownProps = {
    className: joinClassNames(
      className,
      text.alignClassName(align),
      text.colorClassName(color),
      text.ellipsisClassName(ellipsis),
      font.familyClassName(fontFamily),
      font.italicClassName(italic),
      font.lineHeightClassName(lineHeight),
      font.sizeClassName(size),
      font.weightClassName(fontWeight),
      text.transformClassName(transform),
      text.wordBreakClassName(wordBreak),
    ),
  };

  const ref = makeRefProp(component, elementRef);

  return createComponent(component, {
    ...ownProps,
    ...passProps,
    ...ref,
  });
}
