// Libs
import parse from 'url-parse';


// Module
export function sanitizeDangerousUrl(url) {
  const urlObject = parse(url, /* do not use browser environment */ {}, false);

  const {
    protocol,
    href,
  } = urlObject;

  if (protocol === '') {
    return 'http://' + url;
  }

  if (protocol !== 'https:' && protocol !== 'http:') {
    return '';
  }

  return href;
}
