/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Custom
 */
export interface Custom {
    /**
     * 
     * @type {string}
     * @memberof Custom
     */
    attribute?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Custom
     */
    attributes?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Custom
     */
    customXFormula?: string;
}

export function CustomFromJSON(json: any): Custom {
    return CustomFromJSONTyped(json, false);
}

export function CustomFromJSONTyped(json: any, ignoreDiscriminator: boolean): Custom {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attribute': !exists(json, 'attribute') ? undefined : json['attribute'],
        'attributes': !exists(json, 'attributes') ? undefined : json['attributes'],
        'customXFormula': !exists(json, 'customXFormula') ? undefined : json['customXFormula'],
    };
}

export function CustomToJSON(value?: Custom | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attribute': value.attribute,
        'attributes': value.attributes,
        'customXFormula': value.customXFormula,
    };
}


