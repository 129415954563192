/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Custom,
    CustomFromJSON,
    CustomFromJSONTyped,
    CustomToJSON,
} from './';

/**
 * 
 * @export
 * @interface XAxis
 */
export interface XAxis {
    /**
     * 
     * @type {Custom}
     * @memberof XAxis
     */
    custom?: Custom;
    /**
     * 
     * @type {object}
     * @memberof XAxis
     */
    epochMillis?: object;
    /**
     * 
     * @type {object}
     * @memberof XAxis
     */
    relativeMillis?: object;
    /**
     * 
     * @type {object}
     * @memberof XAxis
     */
    steps?: object;
}

export function XAxisFromJSON(json: any): XAxis {
    return XAxisFromJSONTyped(json, false);
}

export function XAxisFromJSONTyped(json: any, ignoreDiscriminator: boolean): XAxis {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'custom': !exists(json, 'custom') ? undefined : CustomFromJSON(json['custom']),
        'epochMillis': !exists(json, 'epochMillis') ? undefined : json['epochMillis'],
        'relativeMillis': !exists(json, 'relativeMillis') ? undefined : json['relativeMillis'],
        'steps': !exists(json, 'steps') ? undefined : json['steps'],
    };
}

export function XAxisToJSON(value?: XAxis | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'custom': CustomToJSON(value.custom),
        'epochMillis': value.epochMillis,
        'relativeMillis': value.relativeMillis,
        'steps': value.steps,
    };
}


