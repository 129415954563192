/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ReportNodeDTO,
    ReportNodeDTOFromJSON,
    ReportNodeDTOFromJSONTyped,
    ReportNodeDTOToJSON,
    ReportVersionMetadataDTO,
    ReportVersionMetadataDTOFromJSON,
    ReportVersionMetadataDTOFromJSONTyped,
    ReportVersionMetadataDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface ReportVersionDTO
 */
export interface ReportVersionDTO {
    /**
     * 
     * @type {ReportVersionMetadataDTO}
     * @memberof ReportVersionDTO
     */
    metadata: ReportVersionMetadataDTO;
    /**
     * 
     * @type {Array<ReportNodeDTO>}
     * @memberof ReportVersionDTO
     */
    nodes: Array<ReportNodeDTO>;
    /**
     * 
     * @type {string}
     * @memberof ReportVersionDTO
     */
    reportName: string;
}

export function ReportVersionDTOFromJSON(json: any): ReportVersionDTO {
    return ReportVersionDTOFromJSONTyped(json, false);
}

export function ReportVersionDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportVersionDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'metadata': ReportVersionMetadataDTOFromJSON(json['metadata']),
        'nodes': ((json['nodes'] as Array<any>).map(ReportNodeDTOFromJSON)),
        'reportName': json['reportName'],
    };
}

export function ReportVersionDTOToJSON(value?: ReportVersionDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'metadata': ReportVersionMetadataDTOToJSON(value.metadata),
        'nodes': ((value.nodes as Array<any>).map(ReportNodeDTOToJSON)),
        'reportName': value.reportName,
    };
}


