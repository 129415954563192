import { CustomIconDefinition } from '../types';

export const nepAxis: CustomIconDefinition = {
  prefix: 'nep',
  iconName: 'axis',
  icon: [
    20,
    20,
    [],
    'e170',
    'M2.35 1.50003C2.81946 1.50003 3.2 1.88062 3.2 2.35003V16.8H17.65C18.1194 16.8 18.5 17.1806 18.5 17.65C18.5 18.1195 18.1194 18.5 17.65 18.5H3.2C2.26113 18.5 1.5 17.7389 1.5 16.8V2.35003C1.5 1.88062 1.88054 1.50003 2.35 1.50003ZM5.14897 1.74895C5.48089 1.41702 6.01909 1.41702 6.35102 1.74895L8.29999 3.69797L10.249 1.74895C10.5809 1.41702 11.1191 1.41702 11.451 1.74895C11.7829 2.08098 11.7829 2.61908 11.451 2.9511L9.14999 5.25209V9.15003C9.14999 9.61944 8.76945 10 8.29999 10C7.83053 10 7.44999 9.61944 7.44999 9.15003V5.25209L5.14897 2.9511C4.81698 2.61908 4.81698 2.08098 5.14897 1.74895ZM13.1954 7.74688C12.8899 7.39047 12.3533 7.34917 11.9968 7.65464C11.6404 7.96021 11.5991 8.49676 11.9047 8.85317L13.9804 11.275L11.9047 13.6969C11.5991 14.0533 11.6404 14.5899 11.9968 14.8954C12.3533 15.2009 12.8899 15.1596 13.1954 14.8032L15.1 12.5812L17.0047 14.8032C17.3101 15.1596 17.8468 15.2009 18.2032 14.8954C18.5596 14.5899 18.6009 14.0533 18.2954 13.6969L16.2195 11.275L18.2954 8.85317C18.6009 8.49676 18.5596 7.96021 18.2032 7.65464C17.8468 7.34917 17.3101 7.39047 17.0047 7.74688L15.1 9.9689L13.1954 7.74688Z',
  ],
};
