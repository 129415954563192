// Libs
import React from 'react';
import { Icon, Layout, Link, Section, Text } from '@neptune/shared/venus-ui';

// Module
type ErrorPlaceholderProps = {
  isOnPremiseDeployment: boolean;
  statusPageUrl?: string;
} & Pick<React.ComponentProps<typeof Section>, 'withBorder' | 'span'>;

export const ErrorPlaceholder: React.FC<ErrorPlaceholderProps> = ({
  isOnPremiseDeployment,
  statusPageUrl,
  withBorder,
  ...restProps
}) => {
  return (
    <Section
      span="greedy"
      withPadding={['md', 'xl']}
      overflow="auto"
      withCustomScrollbar
      withBorder={withBorder}
      data-role="error-placeholder"
      {...restProps}
    >
      <Layout.Column
        alignItems="center"
        span="shrink"
        spacedChildren="md"
        style={{ margin: 'auto' }}
      >
        <WarningIcon />
        <Header>Sorry, we ran into a problem loading the data for this page.</Header>
        {getSubtext(isOnPremiseDeployment, statusPageUrl)}
      </Layout.Column>
    </Section>
  );
};

function getSubtext(isOnPremiseDeployment: boolean, statusPageUrl?: string): React.ReactChild {
  return isOnPremiseDeployment ? (
    <Info>
      Please try again in a few minutes. If this problem persists please contact your local Neptune
      administrator.
    </Info>
  ) : (
    <>
      <Info>
        We are aware of the issue and are already working on the fix. Please try again in a few
        minutes.
      </Info>
      {statusPageUrl ? (
        <Info>
          You can monitor the status of our service on the{' '}
          <Link external href={statusPageUrl}>
            Status page&nbsp;
            <Icon glyph="external-link" />
          </Link>
          .
        </Info>
      ) : null}
    </>
  );
}

const Header: React.FC = ({ children }) => (
  <Text align="center" color="text-secondary" size="md" children={children} />
);
const Info: React.FC = ({ children }) => <Text align="center" children={children} />;
const WarningIcon = () => <Icon glyph="exclamation-triangle" color="warning" size="lg" />;
