/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrganizationNameAvailableDTO
 */
export interface OrganizationNameAvailableDTO {
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationNameAvailableDTO
     */
    available: boolean;
}

export function OrganizationNameAvailableDTOFromJSON(json: any): OrganizationNameAvailableDTO {
    return OrganizationNameAvailableDTOFromJSONTyped(json, false);
}

export function OrganizationNameAvailableDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationNameAvailableDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'available': json['available'],
    };
}

export function OrganizationNameAvailableDTOToJSON(value?: OrganizationNameAvailableDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'available': value.available,
    };
}


