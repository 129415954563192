// Libs
import React from 'react';

import * as decorator from '../../modules/decorator';
import { bemBlock } from '../../modules/bem';
import { LayoutColumn, LayoutColumnProps } from '../layout-column/LayoutColumn';
import { LayoutSeparator } from '../layout-separator/LayoutSeparator';

// Module
import { DropdownItem } from '../dropdown-item/DropdownItem';
import { DropdownTitle } from '../dropdown-title/DropdownTitle';
import './Menu.less';

const block = bemBlock('n-Menu');

export type MenuType = 'dropdown';
export type MenuProps = Pick<
  LayoutColumnProps<{}>,
  | 'alignItems'
  | 'alignSelf'
  | 'className'
  | 'component'
  | 'justifyContent'
  | 'onClick'
  | 'onKeyDown'
  | 'overflow'
  | 'reversed'
  | 'selfSpacing'
  | 'spacedChildren'
  | 'span'
  | 'style'
  | 'width'
  | 'withCustomScrollbar'
  | 'withGutter'
  | 'withPadding'
  | 'onPointerLeave'
> & {
  opaque?: decorator.Opaque;
  withBorder?: boolean;
};

/**
 * @deprecated
 * For dropdown-like usage (when we've got popup dropdown using react-tether), use Dropdown.tsx instead.
 * For standalone menu (not in dropdown context) use TabsMenu.tsx or custom solution if needed.
 */
export class Menu extends React.PureComponent<MenuProps> {
  public static Item = DropdownItem;
  public static Separator = LayoutSeparator;
  public static Title = DropdownTitle;

  public static defaultProps = {
    alignItems: 'strech',
    justifyContent: 'start',
    span: 'auto',
  };

  render() {
    const { className, opaque, withBorder, ...passProps } = this.props;
    const ownProps = {
      className: block({
        modifiers: {
          withBorder,
        },
        extra: [className, decorator.opaqueClassName(opaque)],
      }),
    };

    return <LayoutColumn {...ownProps} {...passProps} />;
  }
}
