/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AttributeNameFilterDTO,
    AttributeNameFilterDTOFromJSON,
    AttributeNameFilterDTOFromJSONTyped,
    AttributeNameFilterDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface QueryAttributeDefinitionsDTO
 */
export interface QueryAttributeDefinitionsDTO {
    /**
     * 
     * @type {AttributeNameFilterDTO}
     * @memberof QueryAttributeDefinitionsDTO
     */
    attributeNameFilter?: AttributeNameFilterDTO;
}

export function QueryAttributeDefinitionsDTOFromJSON(json: any): QueryAttributeDefinitionsDTO {
    return QueryAttributeDefinitionsDTOFromJSONTyped(json, false);
}

export function QueryAttributeDefinitionsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): QueryAttributeDefinitionsDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attributeNameFilter': !exists(json, 'attributeNameFilter') ? undefined : AttributeNameFilterDTOFromJSON(json['attributeNameFilter']),
    };
}

export function QueryAttributeDefinitionsDTOToJSON(value?: QueryAttributeDefinitionsDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attributeNameFilter': AttributeNameFilterDTOToJSON(value.attributeNameFilter),
    };
}


