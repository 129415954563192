/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AttributeNameFilterDTO
 */
export interface AttributeNameFilterDTO {
    /**
     * An attribute must match all of the regexes from the list to be returned
     * @type {Array<string>}
     * @memberof AttributeNameFilterDTO
     */
    mustMatchRegexes?: Array<string>;
    /**
     * An attribute must match none of the regexes from the list to be returned
     * @type {Array<string>}
     * @memberof AttributeNameFilterDTO
     */
    mustNotMatchRegexes?: Array<string>;
}

export function AttributeNameFilterDTOFromJSON(json: any): AttributeNameFilterDTO {
    return AttributeNameFilterDTOFromJSONTyped(json, false);
}

export function AttributeNameFilterDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): AttributeNameFilterDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'mustMatchRegexes': !exists(json, 'mustMatchRegexes') ? undefined : json['mustMatchRegexes'],
        'mustNotMatchRegexes': !exists(json, 'mustNotMatchRegexes') ? undefined : json['mustNotMatchRegexes'],
    };
}

export function AttributeNameFilterDTOToJSON(value?: AttributeNameFilterDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mustMatchRegexes': value.mustMatchRegexes,
        'mustNotMatchRegexes': value.mustNotMatchRegexes,
    };
}


