import { differenceWith } from 'lodash';
import { AttributeDefinition, AggregationMode } from './attribute-definition';

export const attributeDefinitionComparator = (lhs: AttributeDefinition, rhs: AttributeDefinition) =>
  lhs.name === rhs.name &&
  lhs.type === rhs.type &&
  getSubProperty(lhs.subproperty) === getSubProperty(rhs.subproperty);

export function excludeAttributes(
  collection: AttributeDefinition[],
  excluded: AttributeDefinition[],
) {
  return differenceWith(collection, excluded, attributeDefinitionComparator);
}

function getSubProperty(text?: string): string {
  return text || 'auto';
}

const WORD_SEPARATOR = '\u0003';
const stringMap: (keyof AttributeDefinition)[] = ['name', 'subproperty', 'displayName'];

export function convertAttributeToSearchString(attribute: AttributeDefinition): string {
  return stringMap.map((propertyName) => getProperty(attribute, propertyName)).join(WORD_SEPARATOR);
}

export function getSubpropertyMatch(matchString: string): string {
  const index = stringMap.indexOf('subproperty');
  return matchString.split(WORD_SEPARATOR)[index];
}

export function getNameMatch(matchString: string): string {
  const index = stringMap.indexOf('name');
  return matchString.split(WORD_SEPARATOR)[index];
}

export function getDisplayNameMatch(matchString: string): string {
  const index = stringMap.indexOf('displayName');
  return matchString.split(WORD_SEPARATOR)[index];
}

function getProperty(attribute: AttributeDefinition, propertyName: keyof AttributeDefinition) {
  return attribute[propertyName] || '';
}

export function getAttributeSubpropertiesDefinitions(
  attribute: AttributeDefinition,
): AttributeDefinition[] {
  if (attribute.type === 'floatSeries' && attribute.subproperty == null) {
    return [
      addSubproperty(attribute, 'average'),
      addSubproperty(attribute, 'last'),
      addSubproperty(attribute, 'max'),
      addSubproperty(attribute, 'min'),
      addSubproperty(attribute, 'variance'),
    ];
  }

  return [];
}

/**
 * Until we don't have full support for generic subproperties we only allow aggregation mode as subproperty value
 */
function addSubproperty(
  attr: AttributeDefinition,
  subproperty: AggregationMode,
): AttributeDefinition {
  return {
    ...attr,
    subproperty,
  };
}
