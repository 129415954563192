/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CreateSessionParamsDTO,
    CreateSessionParamsDTOFromJSON,
    CreateSessionParamsDTOFromJSONTyped,
    CreateSessionParamsDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface StoragePurchaseRequestDTO
 */
export interface StoragePurchaseRequestDTO {
    /**
     * 
     * @type {number}
     * @memberof StoragePurchaseRequestDTO
     */
    targetStorageSizeGb: number;
    /**
     * 
     * @type {CreateSessionParamsDTO}
     * @memberof StoragePurchaseRequestDTO
     */
    sessionParams: CreateSessionParamsDTO;
}

export function StoragePurchaseRequestDTOFromJSON(json: any): StoragePurchaseRequestDTO {
    return StoragePurchaseRequestDTOFromJSONTyped(json, false);
}

export function StoragePurchaseRequestDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): StoragePurchaseRequestDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'targetStorageSizeGb': json['targetStorageSizeGb'],
        'sessionParams': CreateSessionParamsDTOFromJSON(json['sessionParams']),
    };
}

export function StoragePurchaseRequestDTOToJSON(value?: StoragePurchaseRequestDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'targetStorageSizeGb': value.targetStorageSizeGb,
        'sessionParams': CreateSessionParamsDTOToJSON(value.sessionParams),
    };
}


