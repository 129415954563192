/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FlatPricingDTO,
    FlatPricingDTOFromJSON,
    FlatPricingDTOFromJSONTyped,
    FlatPricingDTOToJSON,
    GraduatedPricingDTO,
    GraduatedPricingDTOFromJSON,
    GraduatedPricingDTOFromJSONTyped,
    GraduatedPricingDTOToJSON,
    PaidSubscriptionPricingDTO,
    PaidSubscriptionPricingDTOFromJSON,
    PaidSubscriptionPricingDTOFromJSONTyped,
    PaidSubscriptionPricingDTOToJSON,
    PricingPlanDTO,
    PricingPlanDTOFromJSON,
    PricingPlanDTOFromJSONTyped,
    PricingPlanDTOToJSON,
    SimplePricingDTO,
    SimplePricingDTOFromJSON,
    SimplePricingDTOFromJSONTyped,
    SimplePricingDTOToJSON,
    V1TeamPricingDTO,
    V1TeamPricingDTOFromJSON,
    V1TeamPricingDTOFromJSONTyped,
    V1TeamPricingDTOToJSON,
    V2IndividualPricingDTO,
    V2IndividualPricingDTOFromJSON,
    V2IndividualPricingDTOFromJSONTyped,
    V2IndividualPricingDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface OrganizationPricingDTO
 */
export interface OrganizationPricingDTO {
    /**
     * 
     * @type {V2IndividualPricingDTO}
     * @memberof OrganizationPricingDTO
     */
    v2IndividualPricing?: V2IndividualPricingDTO;
    /**
     * 
     * @type {PaidSubscriptionPricingDTO}
     * @memberof OrganizationPricingDTO
     */
    organizationPlanPricing?: PaidSubscriptionPricingDTO;
    /**
     * 
     * @type {SimplePricingDTO}
     * @memberof OrganizationPricingDTO
     */
    users?: SimplePricingDTO;
    /**
     * 
     * @type {V1TeamPricingDTO}
     * @memberof OrganizationPricingDTO
     */
    v1TeamPricing?: V1TeamPricingDTO;
    /**
     * 
     * @type {GraduatedPricingDTO}
     * @memberof OrganizationPricingDTO
     */
    monitoringTime?: GraduatedPricingDTO;
    /**
     * 
     * @type {GraduatedPricingDTO}
     * @memberof OrganizationPricingDTO
     */
    activeProjects?: GraduatedPricingDTO;
    /**
     * 
     * @type {PaidSubscriptionPricingDTO}
     * @memberof OrganizationPricingDTO
     */
    v2TeamPricing?: PaidSubscriptionPricingDTO;
    /**
     * 
     * @type {GraduatedPricingDTO}
     * @memberof OrganizationPricingDTO
     */
    storage?: GraduatedPricingDTO;
    /**
     * 
     * @type {PricingPlanDTO}
     * @memberof OrganizationPricingDTO
     */
    pricingPlan: PricingPlanDTO;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationPricingDTO
     */
    supportsPayments: boolean;
    /**
     * 
     * @type {FlatPricingDTO}
     * @memberof OrganizationPricingDTO
     */
    flatFee?: FlatPricingDTO;
}

export function OrganizationPricingDTOFromJSON(json: any): OrganizationPricingDTO {
    return OrganizationPricingDTOFromJSONTyped(json, false);
}

export function OrganizationPricingDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationPricingDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'v2IndividualPricing': !exists(json, 'v2IndividualPricing') ? undefined : V2IndividualPricingDTOFromJSON(json['v2IndividualPricing']),
        'organizationPlanPricing': !exists(json, 'organizationPlanPricing') ? undefined : PaidSubscriptionPricingDTOFromJSON(json['organizationPlanPricing']),
        'users': !exists(json, 'users') ? undefined : SimplePricingDTOFromJSON(json['users']),
        'v1TeamPricing': !exists(json, 'v1TeamPricing') ? undefined : V1TeamPricingDTOFromJSON(json['v1TeamPricing']),
        'monitoringTime': !exists(json, 'monitoringTime') ? undefined : GraduatedPricingDTOFromJSON(json['monitoringTime']),
        'activeProjects': !exists(json, 'activeProjects') ? undefined : GraduatedPricingDTOFromJSON(json['activeProjects']),
        'v2TeamPricing': !exists(json, 'v2TeamPricing') ? undefined : PaidSubscriptionPricingDTOFromJSON(json['v2TeamPricing']),
        'storage': !exists(json, 'storage') ? undefined : GraduatedPricingDTOFromJSON(json['storage']),
        'pricingPlan': PricingPlanDTOFromJSON(json['pricingPlan']),
        'supportsPayments': json['supportsPayments'],
        'flatFee': !exists(json, 'flatFee') ? undefined : FlatPricingDTOFromJSON(json['flatFee']),
    };
}

export function OrganizationPricingDTOToJSON(value?: OrganizationPricingDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'v2IndividualPricing': V2IndividualPricingDTOToJSON(value.v2IndividualPricing),
        'organizationPlanPricing': PaidSubscriptionPricingDTOToJSON(value.organizationPlanPricing),
        'users': SimplePricingDTOToJSON(value.users),
        'v1TeamPricing': V1TeamPricingDTOToJSON(value.v1TeamPricing),
        'monitoringTime': GraduatedPricingDTOToJSON(value.monitoringTime),
        'activeProjects': GraduatedPricingDTOToJSON(value.activeProjects),
        'v2TeamPricing': PaidSubscriptionPricingDTOToJSON(value.v2TeamPricing),
        'storage': GraduatedPricingDTOToJSON(value.storage),
        'pricingPlan': PricingPlanDTOToJSON(value.pricingPlan),
        'supportsPayments': value.supportsPayments,
        'flatFee': FlatPricingDTOToJSON(value.flatFee),
    };
}


