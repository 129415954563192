/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AttributeTypeDTO,
    AttributeTypeDTOFromJSON,
    AttributeTypeDTOFromJSONTyped,
    AttributeTypeDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface AttributeDefinitionDTO
 */
export interface AttributeDefinitionDTO {
    /**
     * 
     * @type {string}
     * @memberof AttributeDefinitionDTO
     */
    name: string;
    /**
     * 
     * @type {AttributeTypeDTO}
     * @memberof AttributeDefinitionDTO
     */
    type: AttributeTypeDTO;
}

export function AttributeDefinitionDTOFromJSON(json: any): AttributeDefinitionDTO {
    return AttributeDefinitionDTOFromJSONTyped(json, false);
}

export function AttributeDefinitionDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): AttributeDefinitionDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'type': AttributeTypeDTOFromJSON(json['type']),
    };
}

export function AttributeDefinitionDTOToJSON(value?: AttributeDefinitionDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'type': AttributeTypeDTOToJSON(value.type),
    };
}


