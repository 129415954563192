// Libs
import * as Sentry from '@sentry/browser';
import { Primitive } from '@sentry/types';

// Module

export type LoggerExtraInfo = { [p: string]: any };
export type LoggerTags = { [p: string]: Primitive };

type NeptuneExceptionContext = {
  module: string;
  extra?: LoggerExtraInfo;
};

/**
 * Logs exception to Sentry
 * @param e exception to be logged
 * @param context information about "module" and a dict like information "extra" that is attached to particular Event
 *  for easier problems discovery
 * @param tags dict like information, similar to "extra" but:
 *  * can be used for advanced filtering and analysis in Sentry UI
 *  * has limit of 200 characters per tag (Sentry automatically truncates it)
 */
function logException(e: any, context: NeptuneExceptionContext, tags?: LoggerTags) {
  const { module, extra } = context;

  Sentry.withScope((scope) => {
    scope.setExtras({
      module,
      ...extra,
    });

    if (tags) {
      scope.setTags(tags);
    }

    Sentry.captureException(e);
  });
}

/**
 * Logs message to Sentry
 * @param msg message to be logged
 * @param extra dict like information that is attached to particular Event for easier problems discovery
 * @param tags dict like information, similar to "extra" but:
 *  * can be used for advanced filtering and analysis in Sentry UI
 *  * has limit of 200 characters per tag (Sentry automatically truncates it)
 */
function logMessage(msg: string, extra: LoggerExtraInfo, tags?: LoggerTags) {
  Sentry.withScope((scope) => {
    scope.setExtras(extra);

    if (tags) {
      scope.setTags(tags);
    }

    Sentry.captureMessage(msg);
  });
}

function setUserContext(userContext: Sentry.User | null) {
  Sentry.configureScope((scope) => {
    scope.setUser(userContext);
  });
}

export const logger = {
  logException,
  logMessage,
  setUserContext,
};

export default logger;
