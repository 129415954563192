import { neptuneSupervisor } from 'apis/neptune-supervisor';
import { ConfigurationParameters } from 'generated/backend-client';

import { getBasePath } from './getBasePath';
import { updateTokenMiddleware } from './update-token-middleware';

export const defaultApiClientConfiguration: ConfigurationParameters = {
  basePath: getBasePath(),
  middleware: [neptuneSupervisor.asOpenApiMiddleware(), updateTokenMiddleware],
};
