/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OperationError
 */
export interface OperationError {
    /**
     * 
     * @type {string}
     * @memberof OperationError
     */
    errorDescription: string;
    /**
     * 
     * @type {string}
     * @memberof OperationError
     */
    errorType: OperationErrorErrorTypeEnum;
}

export function OperationErrorFromJSON(json: any): OperationError {
    return OperationErrorFromJSONTyped(json, false);
}

export function OperationErrorFromJSONTyped(json: any, ignoreDiscriminator: boolean): OperationError {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'errorDescription': json['errorDescription'],
        'errorType': json['errorType'],
    };
}

export function OperationErrorToJSON(value?: OperationError | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'errorDescription': value.errorDescription,
        'errorType': value.errorType,
    };
}

/**
* @export
* @enum {string}
*/
export enum OperationErrorErrorTypeEnum {
    MetadataInconsistency = 'metadata_inconsistency',
    LogSeriesValueError = 'logSeriesValueError',
    AssignFloatValueError = 'assignFloatValueError'
}


