/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AttributeTypeDTO,
    AttributeTypeDTOFromJSON,
    AttributeTypeDTOFromJSONTyped,
    AttributeTypeDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface NotebookRefAttributeDTO
 */
export interface NotebookRefAttributeDTO {
    /**
     * 
     * @type {string}
     * @memberof NotebookRefAttributeDTO
     */
    attributeName: string;
    /**
     * 
     * @type {AttributeTypeDTO}
     * @memberof NotebookRefAttributeDTO
     */
    attributeType: AttributeTypeDTO;
    /**
     * 
     * @type {Date}
     * @memberof NotebookRefAttributeDTO
     */
    checkpointCreated?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof NotebookRefAttributeDTO
     */
    checkpointDeleted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof NotebookRefAttributeDTO
     */
    checkpointId?: string;
    /**
     * 
     * @type {string}
     * @memberof NotebookRefAttributeDTO
     */
    checkpointName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof NotebookRefAttributeDTO
     */
    notebookDeleted: boolean;
    /**
     * 
     * @type {string}
     * @memberof NotebookRefAttributeDTO
     */
    notebookId: string;
    /**
     * 
     * @type {string}
     * @memberof NotebookRefAttributeDTO
     */
    notebookName?: string;
}

export function NotebookRefAttributeDTOFromJSON(json: any): NotebookRefAttributeDTO {
    return NotebookRefAttributeDTOFromJSONTyped(json, false);
}

export function NotebookRefAttributeDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotebookRefAttributeDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attributeName': json['attributeName'],
        'attributeType': AttributeTypeDTOFromJSON(json['attributeType']),
        'checkpointCreated': !exists(json, 'checkpointCreated') ? undefined : (new Date(json['checkpointCreated'])),
        'checkpointDeleted': !exists(json, 'checkpointDeleted') ? undefined : json['checkpointDeleted'],
        'checkpointId': !exists(json, 'checkpointId') ? undefined : json['checkpointId'],
        'checkpointName': !exists(json, 'checkpointName') ? undefined : json['checkpointName'],
        'notebookDeleted': json['notebookDeleted'],
        'notebookId': json['notebookId'],
        'notebookName': !exists(json, 'notebookName') ? undefined : json['notebookName'],
    };
}

export function NotebookRefAttributeDTOToJSON(value?: NotebookRefAttributeDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attributeName': value.attributeName,
        'attributeType': AttributeTypeDTOToJSON(value.attributeType),
        'checkpointCreated': value.checkpointCreated === undefined ? undefined : (value.checkpointCreated.toISOString()),
        'checkpointDeleted': value.checkpointDeleted,
        'checkpointId': value.checkpointId,
        'checkpointName': value.checkpointName,
        'notebookDeleted': value.notebookDeleted,
        'notebookId': value.notebookId,
        'notebookName': value.notebookName,
    };
}


