/**
 * subset of emojis from emoji-picker-react/src/data/emojis.json
 */
export const EMOJIS_LIST = [
  '1f453',
  '1f576-fe0f',
  '1f97d',
  '1f97c',
  '1f9ba',
  '1f454',
  '1f455',
  '1f456',
  '1f9e3',
  '1f9e4',
  '1f9e5',
  '1f9e6',
  '1f457',
  '1f458',
  '1f97b',
  '1fa71',
  '1fa72',
  '1fa73',
  '1f459',
  '1f45a',
  '1f45b',
  '1f45c',
  '1f45d',
  '1f6cd-fe0f',
  '1f392',
  '1fa74',
  '1f45e',
  '1f45f',
  '1f97e',
  '1f97f',
  '1f460',
  '1f461',
  '1fa70',
  '1f462',
  '1f451',
  '1f452',
  '1f3a9',
  '1f393',
  '1f9e2',
  '1fa96',
  '26d1-fe0f',
  '1f4ff',
  '1f484',
  '1f48d',
  '1f48e',
  '1f507',
  '1f508',
  '1f509',
  '1f50a',
  '1f4e2',
  '1f4e3',
  '1f4ef',
  '1f514',
  '1f515',
  '1f3bc',
  '1f3b5',
  '1f3b6',
  '1f399-fe0f',
  '1f39a-fe0f',
  '1f39b-fe0f',
  '1f3a4',
  '1f3a7',
  '1f4fb',
  '1f3b7',
  '1fa97',
  '1f3b8',
  '1f3b9',
  '1f3ba',
  '1f3bb',
  '1fa95',
  '1f941',
  '1fa98',
  '1f4f1',
  '1f4f2',
  '260e-fe0f',
  '1f4de',
  '1f4df',
  '1f4e0',
  '1f50b',
  '1faab',
  '1f50c',
  '1f4bb',
  '1f5a5-fe0f',
  '1f5a8-fe0f',
  '2328-fe0f',
  '1f5b1-fe0f',
  '1f5b2-fe0f',
  '1f4bd',
  '1f4be',
  '1f4bf',
  '1f4c0',
  '1f9ee',
  '1f3a5',
  '1f39e-fe0f',
  '1f4fd-fe0f',
  '1f3ac',
  '1f4fa',
  '1f4f7',
  '1f4f8',
  '1f4f9',
  '1f4fc',
  '1f50d',
  '1f50e',
  '1f56f-fe0f',
  '1f4a1',
  '1f526',
  '1f3ee',
  '1fa94',
  '1f4d4',
  '1f4d5',
  '1f4d6',
  '1f4d7',
  '1f4d8',
  '1f4d9',
  '1f4da',
  '1f4d3',
  '1f4d2',
  '1f4c3',
  '1f4dc',
  '1f4c4',
  '1f4f0',
  '1f5de-fe0f',
  '1f4d1',
  '1f516',
  '1f3f7-fe0f',
  '1f4b0',
  '1fa99',
  '1f4b4',
  '1f4b5',
  '1f4b6',
  '1f4b7',
  '1f4b8',
  '1f4b3',
  '1f9fe',
  '1f4b9',
  '2709-fe0f',
  '1f4e7',
  '1f4e8',
  '1f4e9',
  '1f4e4',
  '1f4e5',
  '1f4e6',
  '1f4eb',
  '1f4ea',
  '1f4ec',
  '1f4ed',
  '1f4ee',
  '1f5f3-fe0f',
  '270f-fe0f',
  '2712-fe0f',
  '1f58b-fe0f',
  '1f58a-fe0f',
  '1f58c-fe0f',
  '1f58d-fe0f',
  '1f4dd',
  '1f4bc',
  '1f4c1',
  '1f4c2',
  '1f5c2-fe0f',
  '1f4c5',
  '1f4c6',
  '1f5d2-fe0f',
  '1f5d3-fe0f',
  '1f4c7',
  '1f4c8',
  '1f4c9',
  '1f4ca',
  '1f4cb',
  '1f4cc',
  '1f4cd',
  '1f4ce',
  '1f587-fe0f',
  '1f4cf',
  '1f4d0',
  '2702-fe0f',
  '1f5c3-fe0f',
  '1f5c4-fe0f',
  '1f5d1-fe0f',
  '1f512',
  '1f513',
  '1f50f',
  '1f510',
  '1f511',
  '1f5dd-fe0f',
  '1f528',
  '1fa93',
  '26cf-fe0f',
  '2692-fe0f',
  '1f6e0-fe0f',
  '1f5e1-fe0f',
  '2694-fe0f',
  '1f52b',
  '1fa83',
  '1f3f9',
  '1f6e1-fe0f',
  '1fa9a',
  '1f527',
  '1fa9b',
  '1f529',
  '2699-fe0f',
  '1f5dc-fe0f',
  '2696-fe0f',
  '1f9af',
  '1f517',
  '26d3-fe0f',
  '1fa9d',
  '1f9f0',
  '1f9f2',
  '1fa9c',
  '2697-fe0f',
  '1f9ea',
  '1f9eb',
  '1f9ec',
  '1f52c',
  '1f52d',
  '1f4e1',
  '1f489',
  '1fa78',
  '1f48a',
  '1fa79',
  '1fa7c',
  '1fa7a',
  '1fa7b',
  '1f6aa',
  '1f6d7',
  '1fa9e',
  '1fa9f',
  '1f6cf-fe0f',
  '1f6cb-fe0f',
  '1fa91',
  '1f6bd',
  '1faa0',
  '1f6bf',
  '1f6c1',
  '1faa4',
  '1fa92',
  '1f9f4',
  '1f9f7',
  '1f9f9',
  '1f9fa',
  '1f9fb',
  '1faa3',
  '1f9fc',
  '1fae7',
  '1faa5',
  '1f9fd',
  '1f9ef',
  '1f6d2',
  '1f6ac',
  '26b0-fe0f',
  '1faa6',
  '26b1-fe0f',
  '1f5ff',
  '1faa7',
  '1faaa',
  '1f383',
  '1f384',
  '1f386',
  '1f387',
  '1f9e8',
  '2728',
  '1f388',
  '1f389',
  '1f38a',
  '1f38b',
  '1f38d',
  '1f38e',
  '1f38f',
  '1f390',
  '1f391',
  '1f9e7',
  '1f380',
  '1f381',
  '1f397-fe0f',
  '1f39f-fe0f',
  '1f3ab',
  '1f396-fe0f',
  '1f3c6',
  '1f3c5',
  '1f947',
  '1f948',
  '1f949',
  '26be',
  '1f94e',
  '1f3c0',
  '1f3d0',
  '1f3c8',
  '1f3c9',
  '1f3be',
  '1f94f',
  '1f3b3',
  '1f3cf',
  '1f3d1',
  '1f3d2',
  '1f94d',
  '1f3d3',
  '1f3f8',
  '1f94a',
  '1f94b',
  '1f945',
  '26f3',
  '26f8-fe0f',
  '1f3a3',
  '1f93f',
  '1f3bd',
  '1f3bf',
  '1f6f7',
  '1f94c',
  '1f3af',
  '1fa80',
  '1fa81',
  '1f3b1',
  '1f52e',
  '1fa84',
  '1f9ff',
  '1faac',
  '1f3ae',
  '1f579-fe0f',
  '1f3b0',
  '1f3b2',
  '1f9e9',
  '1f9f8',
  '1fa85',
  '1faa9',
  '1fa86',
  '2660-fe0f',
  '2665-fe0f',
  '2666-fe0f',
  '2663-fe0f',
  '265f-fe0f',
  '1f0cf',
  '1f004',
  '1f3b4',
  '1f3ad',
  '1f5bc-fe0f',
  '1f3a8',
  '1f9f5',
  '1faa1',
  '1f9f6',
  '1faa2',
  '1f412',
  '1f98d',
  '1f9a7',
  '1f436',
  '1f415',
  '1f9ae',
  '1f415-200d-1f9ba',
  '1f429',
  '1f43a',
  '1f98a',
  '1f99d',
  '1f431',
  '1f408',
  '1f408-200d-2b1b',
  '1f981',
  '1f42f',
  '1f405',
  '1f406',
  '1f434',
  '1f40e',
  '1f984',
  '1f993',
  '1f98c',
  '1f9ac',
  '1f42e',
  '1f402',
  '1f403',
  '1f404',
  '1f437',
  '1f416',
  '1f417',
  '1f43d',
  '1f40f',
  '1f411',
  '1f410',
  '1f42a',
  '1f42b',
  '1f999',
  '1f992',
  '1f418',
  '1f9a3',
  '1f98f',
  '1f99b',
  '1f42d',
  '1f401',
  '1f400',
  '1f439',
  '1f430',
  '1f407',
  '1f43f-fe0f',
  '1f9ab',
  '1f994',
  '1f987',
  '1f43b',
  '1f43b-200d-2744-fe0f',
  '1f428',
  '1f43c',
  '1f9a5',
  '1f9a6',
  '1f9a8',
  '1f998',
  '1f9a1',
  '1f43e',
  '1f983',
  '1f414',
  '1f413',
  '1f423',
  '1f424',
  '1f425',
  '1f426',
  '1f427',
  '1f54a-fe0f',
  '1f985',
  '1f986',
  '1f9a2',
  '1f989',
  '1f9a4',
  '1fab6',
  '1f9a9',
  '1f99a',
  '1f99c',
  '1f438',
  '1f40a',
  '1f422',
  '1f98e',
  '1f40d',
  '1f432',
  '1f409',
  '1f995',
  '1f996',
  '1f433',
  '1f40b',
  '1f42c',
  '1f9ad',
  '1f41f',
  '1f420',
  '1f421',
  '1f988',
  '1f419',
  '1f41a',
  '1fab8',
  '1f40c',
  '1f98b',
  '1f41b',
  '1f41c',
  '1f41d',
  '1fab2',
  '1f41e',
  '1f997',
  '1fab3',
  '1f577-fe0f',
  '1f578-fe0f',
  '1f982',
  '1f99f',
  '1fab0',
  '1fab1',
  '1f9a0',
  '1f490',
  '1f338',
  '1f4ae',
  '1fab7',
  '1f3f5-fe0f',
  '1f339',
  '1f940',
  '1f33a',
  '1f33b',
  '1f33c',
  '1f337',
  '1f331',
  '1fab4',
  '1f332',
  '1f333',
  '1f334',
  '1f335',
  '1f33e',
  '1f33f',
  '2618-fe0f',
  '1f340',
  '1f341',
  '1f342',
  '1f343',
  '1fab9',
  '1faba',
] as const;
