/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PlotMode,
    PlotModeFromJSON,
    PlotModeFromJSONTyped,
    PlotModeToJSON,
    PointFilters,
    PointFiltersFromJSON,
    PointFiltersFromJSONTyped,
    PointFiltersToJSON,
    Scale,
    ScaleFromJSON,
    ScaleFromJSONTyped,
    ScaleToJSON,
} from './';

/**
 * 
 * @export
 * @interface View
 */
export interface View {
    /**
     * 
     * @type {number}
     * @memberof View
     */
    from?: number;
    /**
     * 
     * @type {number}
     * @memberof View
     */
    maxUniqueX: number;
    /**
     * 
     * @type {PlotMode}
     * @memberof View
     */
    plotMode: PlotMode;
    /**
     * 
     * @type {PointFilters}
     * @memberof View
     */
    pointFilters?: PointFilters;
    /**
     * 
     * @type {number}
     * @memberof View
     */
    to?: number;
    /**
     * 
     * @type {Scale}
     * @memberof View
     */
    xscale: Scale;
    /**
     * 
     * @type {Scale}
     * @memberof View
     */
    yscale: Scale;
}

export function ViewFromJSON(json: any): View {
    return ViewFromJSONTyped(json, false);
}

export function ViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): View {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'from': !exists(json, 'from') ? undefined : json['from'],
        'maxUniqueX': json['maxUniqueX'],
        'plotMode': PlotModeFromJSON(json['plotMode']),
        'pointFilters': !exists(json, 'pointFilters') ? undefined : PointFiltersFromJSON(json['pointFilters']),
        'to': !exists(json, 'to') ? undefined : json['to'],
        'xscale': ScaleFromJSON(json['xscale']),
        'yscale': ScaleFromJSON(json['yscale']),
    };
}

export function ViewToJSON(value?: View | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'from': value.from,
        'maxUniqueX': value.maxUniqueX,
        'plotMode': PlotModeToJSON(value.plotMode),
        'pointFilters': PointFiltersToJSON(value.pointFilters),
        'to': value.to,
        'xscale': ScaleToJSON(value.xscale),
        'yscale': ScaleToJSON(value.yscale),
    };
}


