/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ExperimentStateDTO {
    Running = 'running',
    Idle = 'idle'
}

export function ExperimentStateDTOFromJSON(json: any): ExperimentStateDTO {
    return ExperimentStateDTOFromJSONTyped(json, false);
}

export function ExperimentStateDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExperimentStateDTO {
    return json as ExperimentStateDTO;
}

export function ExperimentStateDTOToJSON(value?: ExperimentStateDTO | null): any {
    return value as any;
}

