import React from 'react';
import {
  KeyShortcut,
  Layout,
  Text,
  UITooltip,
  useTooltip,
  UseTooltipProps,
} from '@neptune/shared/venus-ui';

type InfoTooltipProps = {
  children: React.ReactElement;
  placement?: UseTooltipProps['placement'];
  delayEnter?: UseTooltipProps['delayEnter'];
  show?: boolean;
  text?: React.ReactNode;
  className?: string;
  'data-role'?: string;
};

const InfoTooltipComponent: React.FC<InfoTooltipProps> = ({
  delayEnter,
  children,
  show = true,
  text,
  placement = 'top-center',
  className,
  'data-role': dataRole,
}) => {
  const tooltipProps = useTooltip({
    delayEnter,
    placement,
  });

  if (!show || text == null) {
    return children;
  }

  return (
    <div {...tooltipProps.triggerProps} className={className} data-role={dataRole}>
      {children}
      {tooltipProps.isOpen &&
        tooltipProps.renderLayer(<UITooltip {...tooltipProps.layerProps}>{text}</UITooltip>)}
    </div>
  );
};

const ContentWithKeyShortcut: React.FC<{ shortcut?: string }> = ({ children, shortcut }) => {
  if (!shortcut) {
    return <Text children={children} />;
  }

  return (
    <Layout.Row alignItems="baseline" spacedChildren="md">
      <Text children={children} />
      <KeyShortcut children={shortcut} theme="dark" />
    </Layout.Row>
  );
};

export const InfoTooltip = Object.assign(InfoTooltipComponent, {
  ContentWithKeyShortcut,
});
