/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AvatarSourceEnum,
    AvatarSourceEnumFromJSON,
    AvatarSourceEnumFromJSONTyped,
    AvatarSourceEnumToJSON,
    ProjectCodeAccessDTO,
    ProjectCodeAccessDTOFromJSON,
    ProjectCodeAccessDTOFromJSONTyped,
    ProjectCodeAccessDTOToJSON,
    ProjectVisibilityDTO,
    ProjectVisibilityDTOFromJSON,
    ProjectVisibilityDTOFromJSONTyped,
    ProjectVisibilityDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface ProjectUpdateDTO
 */
export interface ProjectUpdateDTO {
    /**
     * 
     * @type {ProjectCodeAccessDTO}
     * @memberof ProjectUpdateDTO
     */
    codeAccess?: ProjectCodeAccessDTO;
    /**
     * 
     * @type {string}
     * @memberof ProjectUpdateDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectUpdateDTO
     */
    avatarUrl?: string;
    /**
     * 
     * @type {AvatarSourceEnum}
     * @memberof ProjectUpdateDTO
     */
    avatarSource?: AvatarSourceEnum;
    /**
     * 
     * @type {ProjectVisibilityDTO}
     * @memberof ProjectUpdateDTO
     */
    visibility?: ProjectVisibilityDTO;
    /**
     * 
     * @type {string}
     * @memberof ProjectUpdateDTO
     */
    displayClass?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectUpdateDTO
     */
    description?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectUpdateDTO
     */
    archived?: boolean;
}

export function ProjectUpdateDTOFromJSON(json: any): ProjectUpdateDTO {
    return ProjectUpdateDTOFromJSONTyped(json, false);
}

export function ProjectUpdateDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectUpdateDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'codeAccess': !exists(json, 'codeAccess') ? undefined : ProjectCodeAccessDTOFromJSON(json['codeAccess']),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'avatarUrl': !exists(json, 'avatarUrl') ? undefined : json['avatarUrl'],
        'avatarSource': !exists(json, 'avatarSource') ? undefined : AvatarSourceEnumFromJSON(json['avatarSource']),
        'visibility': !exists(json, 'visibility') ? undefined : ProjectVisibilityDTOFromJSON(json['visibility']),
        'displayClass': !exists(json, 'displayClass') ? undefined : json['displayClass'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'archived': !exists(json, 'archived') ? undefined : json['archived'],
    };
}

export function ProjectUpdateDTOToJSON(value?: ProjectUpdateDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'codeAccess': ProjectCodeAccessDTOToJSON(value.codeAccess),
        'name': value.name,
        'avatarUrl': value.avatarUrl,
        'avatarSource': AvatarSourceEnumToJSON(value.avatarSource),
        'visibility': ProjectVisibilityDTOToJSON(value.visibility),
        'displayClass': value.displayClass,
        'description': value.description,
        'archived': value.archived,
    };
}


