/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AttributeTypeDTO,
    AttributeTypeDTOFromJSON,
    AttributeTypeDTOFromJSONTyped,
    AttributeTypeDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface IntAttributeDTO
 */
export interface IntAttributeDTO {
    /**
     * 
     * @type {string}
     * @memberof IntAttributeDTO
     */
    attributeName: string;
    /**
     * 
     * @type {AttributeTypeDTO}
     * @memberof IntAttributeDTO
     */
    attributeType: AttributeTypeDTO;
    /**
     * 
     * @type {number}
     * @memberof IntAttributeDTO
     */
    value: number;
}

export function IntAttributeDTOFromJSON(json: any): IntAttributeDTO {
    return IntAttributeDTOFromJSONTyped(json, false);
}

export function IntAttributeDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntAttributeDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attributeName': json['attributeName'],
        'attributeType': AttributeTypeDTOFromJSON(json['attributeType']),
        'value': json['value'],
    };
}

export function IntAttributeDTOToJSON(value?: IntAttributeDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attributeName': value.attributeName,
        'attributeType': AttributeTypeDTOToJSON(value.attributeType),
        'value': value.value,
    };
}


