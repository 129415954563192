import { distinctUntilChanged, map, pipe } from 'rxjs';

export const snapRowCoordinates = (threshold: number) => {
  return pipe(
    // Snap overscan coords to avoid unnecessary refetches.
    map(({ rowStart, rowEnd }) => ({
      rowStart: Math.floor(rowStart / threshold) * threshold,
      rowEnd: Math.ceil(rowEnd / threshold) * threshold,
    })),
    distinctUntilChanged(
      (prev, current) => prev.rowStart === current.rowStart && prev.rowEnd === current.rowEnd,
    ),
  );
};
