/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LogFloatEntry,
    LogFloatEntryFromJSON,
    LogFloatEntryFromJSONTyped,
    LogFloatEntryToJSON,
} from './';

/**
 * 
 * @export
 * @interface LogFloatsOperation
 */
export interface LogFloatsOperation {
    /**
     * 
     * @type {Array<LogFloatEntry>}
     * @memberof LogFloatsOperation
     */
    entries: Array<LogFloatEntry>;
}

export function LogFloatsOperationFromJSON(json: any): LogFloatsOperation {
    return LogFloatsOperationFromJSONTyped(json, false);
}

export function LogFloatsOperationFromJSONTyped(json: any, ignoreDiscriminator: boolean): LogFloatsOperation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entries': ((json['entries'] as Array<any>).map(LogFloatEntryFromJSON)),
    };
}

export function LogFloatsOperationToJSON(value?: LogFloatsOperation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entries': ((value.entries as Array<any>).map(LogFloatEntryToJSON)),
    };
}


