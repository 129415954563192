import type { DefaultApi } from 'generated/backend-client';
// note: this file is imported also by ts-node
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import type {
  HardcodedEndpointDefs,
  OnlyMethods,
} from '../../common/domain/hardcoded-endpoints/types';

type HardcodedEndpoints = Pick<
  OnlyMethods<DefaultApi>,
  // list hardcoded endpoint names here for strong type checking
  'getUserAvatar'
>;

export const backendHardcodedEndpoints: HardcodedEndpointDefs<HardcodedEndpoints> = {
  getUserAvatar: {
    pathTemplate: '/api/backend/v1/users/{username}/avatar',
    pathParamNames: ['username'],
    queryParamNames: [],
  },
};
