// Libs
import React from 'react';

import { LayoutElement, LayoutElementProps } from '../layout-element/LayoutElement';
import { Text, TextProps } from '../text/Text';

// Module
type DropdownTitleVariant = 'primary' | 'secondary';

export type DropdownTitleProps = Pick<
  LayoutElementProps<{}>,
  'alignSelf' | 'className' | 'selfSpacing' | 'withPadding'
> & {
  variant?: DropdownTitleVariant;
};

export class DropdownTitle extends React.PureComponent<DropdownTitleProps> {
  public static defaultProps = {
    variant: 'primary',
    withPadding: 'xs',
  };

  variantProps = (variant?: DropdownTitleVariant): TextProps<{}> => {
    if (variant === 'secondary') {
      return {
        color: 'text-subdued',
        size: 'xs',
      };
    }

    return {
      color: 'text-secondary',
      size: 'md',
    };
  };

  render() {
    const { variant, ...passProps } = this.props;

    return (
      <Text<typeof LayoutElement>
        component={LayoutElement}
        wordBreak="break-word"
        fontWeight="semibold"
        {...this.variantProps(variant)}
        {...passProps}
      />
    );
  }
}
