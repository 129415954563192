/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    QueryLeaderboardParamsPaginationWithContinuationTokenDTO,
    QueryLeaderboardParamsPaginationWithContinuationTokenDTOFromJSON,
    QueryLeaderboardParamsPaginationWithContinuationTokenDTOFromJSONTyped,
    QueryLeaderboardParamsPaginationWithContinuationTokenDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface QueryLeaderboardParamsOpenedGroupWithPaginationParamsDTO
 */
export interface QueryLeaderboardParamsOpenedGroupWithPaginationParamsDTO {
    /**
     * 
     * @type {string}
     * @memberof QueryLeaderboardParamsOpenedGroupWithPaginationParamsDTO
     */
    openedGroup: string;
    /**
     * 
     * @type {QueryLeaderboardParamsPaginationWithContinuationTokenDTO}
     * @memberof QueryLeaderboardParamsOpenedGroupWithPaginationParamsDTO
     */
    pagination?: QueryLeaderboardParamsPaginationWithContinuationTokenDTO;
}

export function QueryLeaderboardParamsOpenedGroupWithPaginationParamsDTOFromJSON(json: any): QueryLeaderboardParamsOpenedGroupWithPaginationParamsDTO {
    return QueryLeaderboardParamsOpenedGroupWithPaginationParamsDTOFromJSONTyped(json, false);
}

export function QueryLeaderboardParamsOpenedGroupWithPaginationParamsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): QueryLeaderboardParamsOpenedGroupWithPaginationParamsDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'openedGroup': json['openedGroup'],
        'pagination': !exists(json, 'pagination') ? undefined : QueryLeaderboardParamsPaginationWithContinuationTokenDTOFromJSON(json['pagination']),
    };
}

export function QueryLeaderboardParamsOpenedGroupWithPaginationParamsDTOToJSON(value?: QueryLeaderboardParamsOpenedGroupWithPaginationParamsDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'openedGroup': value.openedGroup,
        'pagination': QueryLeaderboardParamsPaginationWithContinuationTokenDTOToJSON(value.pagination),
    };
}


