const minusSign = '-\u00a0';
const noSign = '';
const plusSign = '+\u00a0';

type FormatMoneyStrategy = 'natural' | 'explicitPlusForPositive';

const strategies: Map<FormatMoneyStrategy, Map<number, string>> = new Map([
  [
    'natural',
    new Map([
      [-1, minusSign],
      [0, noSign],
      [1, noSign],
    ]),
  ],
  [
    'explicitPlusForPositive',
    new Map([
      [-1, minusSign],
      [0, noSign],
      [1, plusSign],
    ]),
  ],
]);

export function formatMoney(number: number, strategy: FormatMoneyStrategy = 'natural'): string {
  if (!Number.isFinite(number)) {
    return '';
  }

  const sign = strategies.get(strategy)?.get(Math.sign(number)) ?? '';
  const amount = Math.abs(number);
  const parsedAmount = String(amount).includes('.') ? amount.toFixed(2) : amount;

  return `${sign}$${parsedAmount}`;
}
