// Libs
import React from 'react';

import { bemBlock } from '../../modules/bem';
import { Menu, MenuProps } from '../menu/Menu';

// Module
import './DropdownMenu.less';

const block = bemBlock('n-DropdownMenu');

export type DropdownMenuProps = MenuProps & {
  'data-role'?: string;
  adaptiveWidth?: boolean;
  elementRef?: React.Ref<HTMLDivElement | null>;
};

export const DropdownMenu: React.FC<DropdownMenuProps> = ({
  className,
  opaque = 'white',
  withPadding = 'sm',
  overflow = { vertical: 'auto', horizontal: 'hidden' },
  withCustomScrollbar = true,
  'data-role': dataRole = 'dropdown-menu',
  adaptiveWidth = false,
  ...passProps
}) => {
  return (
    <Menu
      className={block({ extra: className, modifiers: { adaptiveWidth } })}
      opaque={opaque}
      withPadding={withPadding}
      overflow={overflow}
      withCustomScrollbar={withCustomScrollbar}
      data-role={dataRole}
      {...passProps}
    />
  );
};
