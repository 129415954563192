import React from 'react';

import { bemBlock } from '../../modules/bem';
import { Layout } from '../layout/Layout';

import './CardFooter.less';

const block = bemBlock('n-card-footer');

export type CardFooterProps = {
  className?: string;
  children?: React.ReactNode;
  nextSlot?: React.ReactNode;
};

export const CardFooter: React.FC<CardFooterProps> = ({ className, children, nextSlot }) => {
  return (
    <Layout.Row
      className={block({ extra: className })}
      withPadding="lg"
      justifyContent="space-between"
      reversed={!!nextSlot}
      spacedChildren="md"
    >
      {nextSlot && <Layout.Element>{nextSlot}</Layout.Element>}
      <Layout.Row spacedChildren="md" span="greedy">
        {children}
      </Layout.Row>
    </Layout.Row>
  );
};
