import React from 'react';
import { ITetherConstraint } from 'tether';
import { Button, Dropdown, DropdownToggleInterface, Icon } from '@neptune/shared/venus-ui';
import { getScrollableParent } from '@neptune/shared/common-util';
import { fullscreenActiveDefaultZIndex } from '../fullscreen/fullscreen-active-default-zindex';

export type RemoveFromCompareProps = {
  color: string;
  inFullscreen?: boolean;
  onRemove(): void;
};

const defaultConstraints: ITetherConstraint[] = [
  {
    to: 'scrollParent',
    attachment: 'together',
    pin: true,
  },
];

/** @deprecated: TODO: this component shouldn't be in common */
export const RemoveFromCompare: React.FC<RemoveFromCompareProps> = ({
  color,
  inFullscreen = false,
  onRemove,
}) => {
  const [constraints, setConstraints] = React.useState<ITetherConstraint[]>(defaultConstraints);

  const onRefChange = React.useCallback((element) => {
    const constraints: ITetherConstraint[] = [...defaultConstraints];

    if (element) {
      let parent = element;

      while ((parent = getScrollableParent(parent)) && parent) {
        constraints.push({
          to: parent,
          attachment: 'together',
          pin: true,
        });
      }
    }

    setConstraints(constraints);
  }, []);

  return (
    <div ref={onRefChange}>
      <Dropdown
        toggle={<RemoveFromCompareToggle color={color} />}
        attachment="top left"
        targetAttachment="bottom left"
        constraints={inFullscreen ? defaultConstraints : constraints}
        zIndex={inFullscreen ? fullscreenActiveDefaultZIndex : undefined}
      >
        {({ collapse }) => (
          <Dropdown.Menu>
            <Dropdown.Item
              onClick={() => {
                onRemove();
                collapse();
              }}
              data-role="remove-from-compare"
              children="Remove from comparison"
            />
          </Dropdown.Menu>
        )}
      </Dropdown>
    </div>
  );
};

const RemoveFromCompareToggle: React.FC<
  DropdownToggleInterface & {
    color: string;
  }
> = ({ color, onToggle }) => {
  return (
    <Button
      variant="transparent"
      onClick={onToggle}
      square
      data-role="remove-from-compare-dropdown-toggle"
    >
      <Icon glyph="show" style={{ color }} />
    </Button>
  );
};
