// Libs
import React, { ImgHTMLAttributes } from 'react';

import { bemBlock } from '../../modules/bem';

// Module
import './Avatar.less';

const block = bemBlock('n-Avatar');

export type AvatarSize = 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export type AvatarProps = ImgHTMLAttributes<HTMLImageElement> & {
  size?: AvatarSize;
  shape?: 'rect' | 'round' | 'round-rect';
};

/**
 * `Avatar` may be treated as an image with additional properties and behaviors.
 */
export class Avatar extends React.Component<AvatarProps> {
  public static readonly MAX_SIZE = 125;

  public static readonly DEFAULT_IMAGE =
    'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==';

  public static onError = (ev: any) => {
    ev.target.src = Avatar.DEFAULT_IMAGE;
  };

  public static defaultProps = {
    alt: '',
    shape: 'round',
    size: 'md',
    src: Avatar.DEFAULT_IMAGE,
    onError: Avatar.onError,
  };

  render() {
    const { className, shape, size, ...passProps } = this.props;
    const ownProps = {
      className: block({
        modifiers: {
          size,
          shape,
        },
        extra: className,
      }),
      'data-role': 'avatar',
    };

    return <img {...ownProps} {...passProps} />;
  }
}
