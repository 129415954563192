/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BulkOpErrorDTO,
    BulkOpErrorDTOFromJSON,
    BulkOpErrorDTOFromJSONTyped,
    BulkOpErrorDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface BulkOpResultDTO
 */
export interface BulkOpResultDTO {
    /**
     * Errors.
     * @type {Array<BulkOpErrorDTO>}
     * @memberof BulkOpResultDTO
     */
    errors: Array<BulkOpErrorDTO>;
    /**
     * The entries updated successfully.
     * @type {Array<string>}
     * @memberof BulkOpResultDTO
     */
    updatedExperimentIdentifiers: Array<string>;
}

export function BulkOpResultDTOFromJSON(json: any): BulkOpResultDTO {
    return BulkOpResultDTOFromJSONTyped(json, false);
}

export function BulkOpResultDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): BulkOpResultDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'errors': ((json['errors'] as Array<any>).map(BulkOpErrorDTOFromJSON)),
        'updatedExperimentIdentifiers': json['updatedExperimentIdentifiers'],
    };
}

export function BulkOpResultDTOToJSON(value?: BulkOpResultDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'errors': ((value.errors as Array<any>).map(BulkOpErrorDTOToJSON)),
        'updatedExperimentIdentifiers': value.updatedExperimentIdentifiers,
    };
}


