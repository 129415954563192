/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AttributeDefinitionDTO,
    AttributeDefinitionDTOFromJSON,
    AttributeDefinitionDTOFromJSONTyped,
    AttributeDefinitionDTOToJSON,
    AttributesDTO,
    AttributesDTOFromJSON,
    AttributesDTOFromJSONTyped,
    AttributesDTOToJSON,
    LeaderboardEntryGroupDTO,
    LeaderboardEntryGroupDTOFromJSON,
    LeaderboardEntryGroupDTOFromJSONTyped,
    LeaderboardEntryGroupDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface LeaderboardEntriesSearchResultDTO
 */
export interface LeaderboardEntriesSearchResultDTO {
    /**
     * The entries matching the given criteria.
     * @type {Array<AttributesDTO>}
     * @memberof LeaderboardEntriesSearchResultDTO
     */
    entries: Array<AttributesDTO>;
    /**
     * The groups of entities matching given criteria.
     * @type {Array<LeaderboardEntryGroupDTO>}
     * @memberof LeaderboardEntriesSearchResultDTO
     */
    groups?: Array<LeaderboardEntryGroupDTO>;
    /**
     * The total number of entries matching the given criteria.
     * @type {number}
     * @memberof LeaderboardEntriesSearchResultDTO
     */
    matchingItemCount: number;
    /**
     * The column suggestions to the user, most recommended columns first.
     * @type {Array<AttributeDefinitionDTO>}
     * @memberof LeaderboardEntriesSearchResultDTO
     */
    suggestions?: Array<AttributeDefinitionDTO>;
    /**
     * The total number of group entries.
     * @type {number}
     * @memberof LeaderboardEntriesSearchResultDTO
     */
    totalGroupCount?: number;
}

export function LeaderboardEntriesSearchResultDTOFromJSON(json: any): LeaderboardEntriesSearchResultDTO {
    return LeaderboardEntriesSearchResultDTOFromJSONTyped(json, false);
}

export function LeaderboardEntriesSearchResultDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): LeaderboardEntriesSearchResultDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entries': ((json['entries'] as Array<any>).map(AttributesDTOFromJSON)),
        'groups': !exists(json, 'groups') ? undefined : ((json['groups'] as Array<any>).map(LeaderboardEntryGroupDTOFromJSON)),
        'matchingItemCount': json['matchingItemCount'],
        'suggestions': !exists(json, 'suggestions') ? undefined : ((json['suggestions'] as Array<any>).map(AttributeDefinitionDTOFromJSON)),
        'totalGroupCount': !exists(json, 'totalGroupCount') ? undefined : json['totalGroupCount'],
    };
}

export function LeaderboardEntriesSearchResultDTOToJSON(value?: LeaderboardEntriesSearchResultDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entries': ((value.entries as Array<any>).map(AttributesDTOToJSON)),
        'groups': value.groups === undefined ? undefined : ((value.groups as Array<any>).map(LeaderboardEntryGroupDTOToJSON)),
        'matchingItemCount': value.matchingItemCount,
        'suggestions': value.suggestions === undefined ? undefined : ((value.suggestions as Array<any>).map(AttributeDefinitionDTOToJSON)),
        'totalGroupCount': value.totalGroupCount,
    };
}


