// Libs
import React from 'react';

import { bemBlock } from '../../modules/bem';
import * as decorator from '../../modules/decorator';
import * as uiState from '../../modules/ui-state';
import { LayoutSeparator } from '../layout-separator/LayoutSeparator';
import { LayoutColumn, LayoutColumnProps } from '../layout-column/LayoutColumn';
import { SidebarElement } from '../sidebar-element/SidebarElement';

// Module
import './Sidebar.less';

const block = bemBlock('n-Sidebar');

export type SidebarProps = LayoutColumnProps<{}> & {
  expanded?: boolean;
  opaque?: decorator.Opaque;
};

/**
 * ## Shorthands to sidebar related components
 *
 * All sidebar related components are exposed as properties of `Sidebar`,
 * so it is possible to import only `Sidebar` to use all those components.
 *
 * | Component          | Property name | Usage                   |
 * | ------------------ | ------------- | ----------------------- |
 * | `SidebarElement`   | `Element`     | `<Sidebar.Element />`    |
 * | `LayoutSeparator`  | `Separator`   | `<Sidebar.Separator />` |
 */
export class Sidebar extends React.PureComponent<SidebarProps> {
  public static Element = SidebarElement;
  public static Separator = LayoutSeparator;

  render() {
    const { className, expanded, opaque, ...passProps } = this.props;

    return (
      <LayoutColumn
        className={block({
          extra: [
            className,
            decorator.opaqueClassName(opaque),
            uiState.expandedClassName(expanded),
          ],
        })}
        {...passProps}
      />
    );
  }
}
