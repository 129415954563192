// Libs
import React from 'react';

import { bemBlock } from '../../modules/bem';
import { LayoutColumn, LayoutColumnProps } from '../layout-column/LayoutColumn';

// Module
const block = bemBlock('n-ViewContent');

export type ViewContentProps = Omit<
  LayoutColumnProps<{}>,
  'component' | 'height' | 'span' | 'width'
>;

/**
 * `ViewContent` is a wrapper for the view's main content.
 *
 * It is based on `LayoutColumn`.
 *
 * ## Import
 *
 * ```
 * import { View } from '@neptune/shared/venus-ui';
 * ```
 *
 * And use `<View.Content />`.
 */
// eslint-disable-next-line react/prefer-stateless-function
export class ViewContent extends React.Component<ViewContentProps> {
  public static defaultProps = {
    spacedChildren: 'lg',
  };

  render() {
    const { className, ...passProps } = this.props;

    return <LayoutColumn className={block({ extra: className })} {...passProps} />;
  }
}
