/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserProfileLinksDTO
 */
export interface UserProfileLinksDTO {
    /**
     * 
     * @type {string}
     * @memberof UserProfileLinksDTO
     */
    github?: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileLinksDTO
     */
    linkedin?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserProfileLinksDTO
     */
    others: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UserProfileLinksDTO
     */
    kaggle?: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileLinksDTO
     */
    twitter?: string;
}

export function UserProfileLinksDTOFromJSON(json: any): UserProfileLinksDTO {
    return UserProfileLinksDTOFromJSONTyped(json, false);
}

export function UserProfileLinksDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserProfileLinksDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'github': !exists(json, 'github') ? undefined : json['github'],
        'linkedin': !exists(json, 'linkedin') ? undefined : json['linkedin'],
        'others': json['others'],
        'kaggle': !exists(json, 'kaggle') ? undefined : json['kaggle'],
        'twitter': !exists(json, 'twitter') ? undefined : json['twitter'],
    };
}

export function UserProfileLinksDTOToJSON(value?: UserProfileLinksDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'github': value.github,
        'linkedin': value.linkedin,
        'others': value.others,
        'kaggle': value.kaggle,
        'twitter': value.twitter,
    };
}


