import React from 'react';

type ToggleSwitchContext =
  | {
      selectedValue: any;
      disabled?: boolean;
      onChange: (value: string) => void;
    }
  | undefined;

export const ToggleSwitchContext = React.createContext<ToggleSwitchContext>(undefined);
