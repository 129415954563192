import type { SupportedOs } from 'components/modals/api-token-modal/text-by-os/os-util';
import { PreviewTheme } from '@neptune/shared/file-preview-domain';
import type { AxisScale, XAxisMode, JsonCompatibleAxisRange } from '@neptune/charts-domain';
import { LeaderboardColumn } from '@neptune/shared/leaderboard-domain';
import { TimeScale } from 'domain/common/time-scale';
import type { SearchQuery } from '@neptune/search-query-domain';
import { AttributeDefinition } from 'domain/experiment/attribute';
import {
  LocalStorageLatest,
  parseLocalStorageLatest,
  stringifyLocalStorageLatest,
  wrapAsLocalStorageLatest,
} from '../local-storage';
import { ChartGlobalConfig } from 'domain/widget';
import type { CompoundSearchCriterion } from '@neptune/recent-searches-domain';

type SidebarIds = 'dashboard-menu';

export const localStorageTopKey = 'neptune-store-state-v2';

export const loadState = (): LocalStorageState => {
  try {
    const serializedState = localStorage.getItem(localStorageTopKey);
    const { data } = parseLocalStorageLatest(serializedState);
    // If line below causes type mismatch, it means you have introduced a not compatible
    // change to LocalStorageState application model.
    // See local-storage-migration-guide.md for more information.
    return data;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.warn('Failed to get state from localStorage.', err);
    return {};
  }
};

export const persistState = (stateChange: Partial<LocalStorageState>) => {
  const oldState = loadState();
  const newState: LocalStorageState = { ...oldState, ...stateChange };
  // If line below causes type mismatch, it means you have introduced a not compatible
  // change to LocalStorageState application model.
  // See local-storage-migration-guide.md for more information.
  const toPersist: LocalStorageLatest = wrapAsLocalStorageLatest(newState);

  try {
    const serializedState = stringifyLocalStorageLatest(toPersist);
    localStorage.setItem(localStorageTopKey, serializedState);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('Failed to save state to localStorage.', err);
  }
};

export const clearStorage = () => localStorage.setItem(localStorageTopKey, '');

export type LocalStorageState = {
  compareTabByProject?: Record<string, string | undefined>;
  lastCompareDashboardIds?: { [projectPath: string]: string };
  chartsAxisScales?: { [chartId: string]: { x?: AxisScale; y?: AxisScale } | undefined };
  chartsXAxisMode?: { [chartId: string]: XAxisMode | undefined };
  chartsXAxisMetric?: { [chartId: string]: AttributeDefinition | undefined };
  chartsYAxisRange?: { [chartId: string]: JsonCompatibleAxisRange | undefined | null };
  chartsMetricsStepsRange?: { [chartId: string]: JsonCompatibleAxisRange | undefined | null };
  defaultOrganizationName?: string;
  dontShowBrowserSupportInfo?: boolean;
  dontShowInviteUsersBanner?: boolean;
  expandableLayout?: Record<string, Record<string, string | undefined> | undefined>;
  lastExperimentDashboardTab?: string;
  lastExperimentDashboardIds?: { [projectPath: string]: string };
  orderedColumns?: Record<string, Record<string, LeaderboardColumn[]>>;
  os?: SupportedOs;
  previewTheme?: PreviewTheme;
  recentMemos?: Record<string, Record<string, string[] | undefined> | undefined>;
  recentSearchQueries?: Record<string, Record<string, SearchQuery[] | undefined> | undefined>;
  recentCompoundQueries?: Record<
    string,
    Record<string, CompoundSearchCriterion[][] | undefined> | undefined
  >;
  sideBarsState?: Partial<{ [key in SidebarIds]: boolean }>;
  stringSeriesTimeScale?: { [attribute: string]: TimeScale | undefined };
  shownHelp?: boolean;
  leaderboardPageSize?: Record<string, number | undefined>;
  bulkAcceptInvitationMuted?: {
    byId: Record<string, number | undefined>;
    projects: Record<string, number | undefined>;
    organizations: Record<string, number | undefined>;
  };
  lbViewIdByProject?: { [projectIdentifier: string]: string | undefined };
  textWrapping?: Record<string, Record<string, string | undefined> | undefined>;
  projectLeaderboardSplitPx?: number;
  reportsTableSplitPx?: number;
  attributeViewerSplitRate?: Record<string, number | undefined> | undefined;
  lastProjectTab?: string;
  lastProjectRunsTab?: 'table' | 'details' | 'compare';
  structureAsideCollapsed?: boolean;
  chartGlobalConfig?: Record<string, ChartGlobalConfig>;
};

// If lines below cause type mismatch, it means you have introduced an incompatible
// change to the LocalStorageState application model.
// See local-storage-migration-guide.md for more information.

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const compatibilityCheck1: Required<LocalStorageState> = {} as Required<LocalStorageLatest['data']>;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const compatibilityCheck2: Required<LocalStorageLatest['data']> = {} as Required<LocalStorageState>;
