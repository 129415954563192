/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OrganizationInvitationDTO,
    OrganizationInvitationDTOFromJSON,
    OrganizationInvitationDTOFromJSONTyped,
    OrganizationInvitationDTOToJSON,
    OrganizationRoleDTO,
    OrganizationRoleDTOFromJSON,
    OrganizationRoleDTOFromJSONTyped,
    OrganizationRoleDTOToJSON,
    RegisteredMemberInfoDTO,
    RegisteredMemberInfoDTOFromJSON,
    RegisteredMemberInfoDTOFromJSONTyped,
    RegisteredMemberInfoDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface OrganizationMemberDTO
 */
export interface OrganizationMemberDTO {
    /**
     * 
     * @type {OrganizationRoleDTO}
     * @memberof OrganizationMemberDTO
     */
    role: OrganizationRoleDTO;
    /**
     * 
     * @type {Date}
     * @memberof OrganizationMemberDTO
     */
    joinedAt?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationMemberDTO
     */
    editableRole: boolean;
    /**
     * 
     * @type {RegisteredMemberInfoDTO}
     * @memberof OrganizationMemberDTO
     */
    registeredMemberInfo?: RegisteredMemberInfoDTO;
    /**
     * 
     * @type {OrganizationInvitationDTO}
     * @memberof OrganizationMemberDTO
     */
    invitationInfo?: OrganizationInvitationDTO;
    /**
     * 
     * @type {number}
     * @memberof OrganizationMemberDTO
     */
    totalNumberOfProjects?: number;
    /**
     * 
     * @type {number}
     * @memberof OrganizationMemberDTO
     */
    numberOfProjects?: number;
}

export function OrganizationMemberDTOFromJSON(json: any): OrganizationMemberDTO {
    return OrganizationMemberDTOFromJSONTyped(json, false);
}

export function OrganizationMemberDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationMemberDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'role': OrganizationRoleDTOFromJSON(json['role']),
        'joinedAt': !exists(json, 'joinedAt') ? undefined : (new Date(json['joinedAt'])),
        'editableRole': json['editableRole'],
        'registeredMemberInfo': !exists(json, 'registeredMemberInfo') ? undefined : RegisteredMemberInfoDTOFromJSON(json['registeredMemberInfo']),
        'invitationInfo': !exists(json, 'invitationInfo') ? undefined : OrganizationInvitationDTOFromJSON(json['invitationInfo']),
        'totalNumberOfProjects': !exists(json, 'totalNumberOfProjects') ? undefined : json['totalNumberOfProjects'],
        'numberOfProjects': !exists(json, 'numberOfProjects') ? undefined : json['numberOfProjects'],
    };
}

export function OrganizationMemberDTOToJSON(value?: OrganizationMemberDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'role': OrganizationRoleDTOToJSON(value.role),
        'joinedAt': value.joinedAt === undefined ? undefined : (value.joinedAt.toISOString()),
        'editableRole': value.editableRole,
        'registeredMemberInfo': RegisteredMemberInfoDTOToJSON(value.registeredMemberInfo),
        'invitationInfo': OrganizationInvitationDTOToJSON(value.invitationInfo),
        'totalNumberOfProjects': value.totalNumberOfProjects,
        'numberOfProjects': value.numberOfProjects,
    };
}


