import { CustomIconDefinition } from '../types';

export const nepWarning: CustomIconDefinition = {
  prefix: 'nep',
  iconName: 'warning',
  icon: [
    1000,
    1000,
    [],
    'e118',
    'M597.8 93.7H403.9L27.8 745.1 117 892.4H888.6L970 745.1 597.8 93.7ZM434.2 264.3H558.3V601.6H434.2V264.3ZM562.2 807.2H438.1V683.1H562.2V807.2Z',
  ],
};
