// Libs
import React from 'react';
import ReactSyntaxHighlighter, { registerLanguage } from 'react-syntax-highlighter/dist/light';
import bash from 'react-syntax-highlighter/dist/languages/bash';
import json from 'react-syntax-highlighter/dist/languages/json';
import js from 'react-syntax-highlighter/dist/languages/javascript';
import python from 'react-syntax-highlighter/dist/languages/python';
import r from 'react-syntax-highlighter/dist/languages/r';
import yaml from 'react-syntax-highlighter/dist/languages/yaml';
import darkStyle from 'react-syntax-highlighter/dist/styles/agate';
import lightStyle from 'react-syntax-highlighter/dist/styles/xcode';
import gitHubStyle from 'react-syntax-highlighter/dist/styles/github';
import { bemBlock, Layout } from '@neptune/shared/venus-ui';

// App
import { ArrayElementType } from 'common/utility-types';

// Module
import { CopyToClipboard } from '../copy-to-clipboard/CopyToClipboard';
import { ThemeToggle } from '../theme-toggle/ThemeToggle';
import './SyntaxHighlighter.less';

registerLanguage('bash', bash);
registerLanguage('json', json);
registerLanguage('python', python);
registerLanguage('yaml', yaml);
registerLanguage('js', js);
registerLanguage('r', r);

const supportedLanguages = ['json', 'python', 'bash', 'yaml'] as const;
const supportedThemes = {
  dark: darkStyle,
  light: lightStyle,
  github: gitHubStyle,
};

const highlightedLineStyle = {
  backgroundColor: 'rgba(19, 138, 174, 0.15)',
  display: 'block',
};

export type SyntaxHighlighterLanguage = ArrayElementType<typeof supportedLanguages>;

type SyntaxHighlighterProps = {
  codeToCopy?: string;
  textId?: string;
  className?: string;
  codeToHighlight?: string;
  copyButtonTitle?: string;
  copyDisabled?: boolean;
  theme?: keyof typeof supportedThemes;
  language?: SyntaxHighlighterLanguage;
  hlLines?: number[];
  wrapLines?: boolean;
  onThemeChange?(): void;
};

const block = bemBlock('syntax-highlighter');

export const SyntaxHighlighter = ({
  codeToCopy = '',
  className,
  codeToHighlight = '',
  copyButtonTitle,
  copyDisabled = false,
  language,
  hlLines,
  theme = 'light',
  wrapLines = true,
  textId,
  onThemeChange,
  ...highlighterProps
}: SyntaxHighlighterProps) => {
  const hlLinesSet = new Set(hlLines);
  const validLanguage = language && supportedLanguages.includes(language) ? language : null;
  const highlightStyle = supportedThemes[theme];

  return (
    <Layout.Column
      className={block({
        modifiers: { wrapLines },
        extra: className,
      })}
      overflow="hidden"
      span="auto"
    >
      <Layout.Row className={block('buttons')} alignItems="center">
        {onThemeChange && (
          <ThemeToggle className={block('switch')} theme={theme} onClick={onThemeChange} />
        )}
        {!copyDisabled && (
          <CopyToClipboard
            data-heap-redact-text
            className={block('button')}
            text={codeToCopy || codeToHighlight}
            textId={textId}
            title={copyButtonTitle}
            theme="dark"
          />
        )}
      </Layout.Row>
      <ReactSyntaxHighlighter
        className={block('code')}
        language={validLanguage}
        style={highlightStyle}
        wrapLines
        lineStyle={(lineNo: number) => {
          if (hlLinesSet.has(lineNo)) {
            return highlightedLineStyle;
          }

          return null;
        }}
        data-role="syntax-highlighter"
        data-heap-redact-text
        {...highlighterProps}
      >
        {codeToHighlight}
      </ReactSyntaxHighlighter>
    </Layout.Column>
  );
};
