// Libs
import React from 'react';
import { Emojione } from 'react-emoji-render';

// Module
const TextRenderer = (text: string, key: string) => {
  return <Emojione key={key} text={text} />;
};

export default TextRenderer;
