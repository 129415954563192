/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum WorkspaceModeDTO {
    ReadOnly = 'read_only',
    Normal = 'normal'
}

export function WorkspaceModeDTOFromJSON(json: any): WorkspaceModeDTO {
    return WorkspaceModeDTOFromJSONTyped(json, false);
}

export function WorkspaceModeDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkspaceModeDTO {
    return json as WorkspaceModeDTO;
}

export function WorkspaceModeDTOToJSON(value?: WorkspaceModeDTO | null): any {
    return value as any;
}

