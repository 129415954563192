/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DashboardVersionDTO
 */
export interface DashboardVersionDTO {
    /**
     * 
     * @type {Date}
     * @memberof DashboardVersionDTO
     */
    createdTime: Date;
    /**
     * 
     * @type {boolean}
     * @memberof DashboardVersionDTO
     */
    draft?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DashboardVersionDTO
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof DashboardVersionDTO
     */
    lastUpdatedTime: Date;
    /**
     * 
     * @type {string}
     * @memberof DashboardVersionDTO
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof DashboardVersionDTO
     */
    owner?: string;
    /**
     * 
     * @type {string}
     * @memberof DashboardVersionDTO
     */
    type: DashboardVersionDTOTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DashboardVersionDTO
     */
    versionBranch: string;
    /**
     * 
     * @type {string}
     * @memberof DashboardVersionDTO
     */
    versionDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof DashboardVersionDTO
     */
    versionName?: string;
}

export function DashboardVersionDTOFromJSON(json: any): DashboardVersionDTO {
    return DashboardVersionDTOFromJSONTyped(json, false);
}

export function DashboardVersionDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): DashboardVersionDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdTime': (new Date(json['createdTime'])),
        'draft': !exists(json, 'draft') ? undefined : json['draft'],
        'id': json['id'],
        'lastUpdatedTime': (new Date(json['lastUpdatedTime'])),
        'name': json['name'],
        'owner': !exists(json, 'owner') ? undefined : json['owner'],
        'type': json['type'],
        'versionBranch': json['versionBranch'],
        'versionDescription': !exists(json, 'versionDescription') ? undefined : json['versionDescription'],
        'versionName': !exists(json, 'versionName') ? undefined : json['versionName'],
    };
}

export function DashboardVersionDTOToJSON(value?: DashboardVersionDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdTime': (value.createdTime.toISOString()),
        'draft': value.draft,
        'id': value.id,
        'lastUpdatedTime': (value.lastUpdatedTime.toISOString()),
        'name': value.name,
        'owner': value.owner,
        'type': value.type,
        'versionBranch': value.versionBranch,
        'versionDescription': value.versionDescription,
        'versionName': value.versionName,
    };
}

/**
* @export
* @enum {string}
*/
export enum DashboardVersionDTOTypeEnum {
    Compare = 'compare',
    Experiment = 'experiment',
    Report = 'report'
}


