import { FetchParams, Middleware, RequestContext } from 'generated/backend-client';

import { updateAndAppendAuthTokenToHeaders } from '@neptune/shared/core-auth-domain';

export const updateTokenMiddleware: Middleware = {
  async pre(context: RequestContext): Promise<FetchParams | void> {
    const headers = await updateAndAppendAuthTokenToHeaders(context.init.headers);

    return {
      ...context,
      init: {
        ...context.init,
        headers,
      },
    };
  },
};
