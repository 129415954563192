export enum LayoutType {
  Expanded = 'expanded',
  Mosaic = 'mosaic',
}

export class ExpandableLayoutConverter {
  static fromLocalStorageToDomain(
    layout: string | undefined,
    defaultLayout: LayoutType,
  ): LayoutType {
    switch (layout) {
      case 'expanded':
        return LayoutType.Expanded;

      case 'mosaic':
        return LayoutType.Mosaic;
    }

    return defaultLayout;
  }
}
