import LoadingIndicator from 'components/loading-indicator/LoadingIndicator';
import React from 'react';
import { Icon, Layout, bemBlock, Text, layout } from '@neptune/shared/venus-ui';
import { InputVariant } from '../types';

import './InputWithValidation.less';

export type Validation = 'valid' | 'invalid' | 'pending';

type InputWithValidationProps = {
  className?: string;
  disabled?: boolean;
  status?: Validation;
  variant?: InputVariant;
  label?: string;
  errorMessage?: React.ReactNode;
  span?: layout.Span;
} & (
  | {
      type: 'textarea';
      inputProps?: React.TextareaHTMLAttributes<HTMLTextAreaElement> & { 'data-role'?: string };
    }
  | {
      type?: 'input';
      inputProps?: React.InputHTMLAttributes<HTMLInputElement> & { 'data-role'?: string };
    }
);

const block = bemBlock('input-with-validation');

export const InputWithValidation: React.FC<InputWithValidationProps> = (props) => {
  const [focused, setFocused] = React.useState(false);

  const {
    className,
    disabled,
    status,
    span,
    variant = 'medium',
    type = 'input',
    errorMessage,
  } = props;
  const input =
    props.type === 'textarea' ? (
      <textarea
        {...props.inputProps}
        className={block({ element: 'input', extra: props.inputProps?.className })}
        disabled={props.disabled}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
      />
    ) : (
      <input
        type="text"
        {...props.inputProps}
        className={block({ element: 'input', extra: props.inputProps?.className })}
        disabled={props.disabled}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
      />
    );

  return (
    <Layout.Column spacedChildren="xs" component="label" span={span}>
      {props.label && (
        <Text color="text" fontWeight="semibold" size="xs">
          {props.label}
        </Text>
      )}
      <Layout.Row
        className={block({
          extra: className,
          modifiers: {
            disabled,
            status,
            variant,
            type,
            focused,
          },
        })}
        alignItems={type === 'textarea' ? 'start' : 'center'}
        withGutter="xs"
        spacedChildren="xs"
        span="auto"
      >
        {input}
        {status && disabled !== true && (
          <>
            {status === 'pending' ? (
              <LoadingIndicator size="small" isVisible />
            ) : (
              <Icon
                className={block({
                  element: 'status',
                  modifiers: { status },
                })}
                glyph={status === 'valid' ? 'check-circle' : 'exclamation-circle'}
              />
            )}
          </>
        )}
      </Layout.Row>
      {errorMessage && status === 'invalid' && (
        <Text color="error" size="xs">
          {errorMessage}
        </Text>
      )}
    </Layout.Column>
  );
};
