// Libs
import React from 'react';
import { DebounceInput } from 'react-debounce-input';
import { bemBlock, Icon, Layout } from '@neptune/shared/venus-ui';

// Module
import { SearchInputIcon } from './SearchInputIcon';
import { SearchInputProps } from './types';
import './SearchInput.less';

const block = bemBlock('app-search-input');

export const SearchInput: React.FC<SearchInputProps> = ({
  autoFocus,
  className,
  debounceTimeout = 200,
  disabled,
  elementRef,
  onApply,
  onBlur,
  onChange,
  onFocus,
  onKeyDown,
  onKeyUp,
  onClear,
  placeholder,
  value,
  variant = 'medium',
  withIcon = false,
  glyph = 'search',
  'data-role': dataRole = 'search-input',
  HelpInfo,
  span = 'auto',
  inputRef,
  suffixElement,
  hasError = false,
}) => {
  const [isFocused, setIsFocused] = React.useState(false);

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange?.(event.target.value);
    },
    [onChange],
  );

  const handleClear = React.useCallback(() => {
    onChange?.('');
    onClear?.();
  }, [onChange, onClear]);

  const handleKeyDown = React.useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        onApply?.(event.currentTarget.value);
        event.preventDefault();
      }

      onKeyDown?.(event);
    },
    [onApply, onKeyDown],
  );

  const handleKeyUp = React.useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Escape') {
        handleClear();
      }

      onKeyUp?.(event);
    },
    [handleClear, onKeyUp],
  );

  const handleFocus = React.useCallback(
    (event: React.FocusEvent) => {
      setIsFocused(true);
      onFocus?.(event);
    },
    [onFocus],
  );

  const handleBlur = React.useCallback(
    (event: React.FocusEvent) => {
      setIsFocused(false);
      onBlur?.(event);
    },
    [onBlur],
  );

  const searchIcon = withIcon ? (
    <SearchInputIcon className={block('icon')} variant={variant} glyph={glyph} />
  ) : null;

  const clearButton =
    value.length > 0 ? (
      <div onClick={handleClear} className={block('clear-button')} data-role="clear-search">
        <Icon glyph="cross" />
      </div>
    ) : null;

  const helpInfoElement =
    HelpInfo && value.length > 0 ? <HelpInfo className={block('help-button')} /> : null;

  return (
    <Layout.Row
      className={block({
        extra: className,
        modifiers: {
          variant,
          disabled: disabled === true,
          focused: isFocused,
          'invalid-value': hasError,
        },
      })}
      alignItems="stretch"
      elementRef={elementRef}
      span={span}
    >
      {searchIcon}
      <DebounceInput
        debounceTimeout={debounceTimeout}
        disabled={disabled}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onKeyUp={handleKeyUp}
        onFocus={handleFocus}
        onBlur={handleBlur}
        value={value}
        placeholder={placeholder}
        autoFocus={autoFocus}
        data-role={dataRole}
        inputRef={inputRef}
        data-error={hasError}
      />
      <Layout.Row span="shrink" alignItems="center">
        {suffixElement}
        {helpInfoElement}
        {clearButton}
      </Layout.Row>
    </Layout.Row>
  );
};
