import React from 'react';

import { bemBlock } from '../../modules/bem';
import { Text } from '../text/Text';

import { useEmblemContext } from './EmblemContext';

import './EmblemText.less';

const block = bemBlock('n-emblem-text');

export const EmblemText: React.FC = ({ children }) => {
  const { size, active } = useEmblemContext();

  const cssClass = block({ modifiers: { size, active } });

  return (
    <Text className={cssClass} ellipsis title={typeof children === 'string' ? children : undefined}>
      {children}
    </Text>
  );
};
