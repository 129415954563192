/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ColorsConfigDTO,
    ColorsConfigDTOFromJSON,
    ColorsConfigDTOFromJSONTyped,
    ColorsConfigDTOToJSON,
    DashboardConfigDTO,
    DashboardConfigDTOFromJSON,
    DashboardConfigDTOFromJSONTyped,
    DashboardConfigDTOToJSON,
    DashboardLayoutsDTO,
    DashboardLayoutsDTOFromJSON,
    DashboardLayoutsDTOFromJSONTyped,
    DashboardLayoutsDTOToJSON,
    TableViewParamsDTO,
    TableViewParamsDTOFromJSON,
    TableViewParamsDTOFromJSONTyped,
    TableViewParamsDTOToJSON,
    WidgetDTO,
    WidgetDTOFromJSON,
    WidgetDTOFromJSONTyped,
    WidgetDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface NewVersionDashboardDTO
 */
export interface NewVersionDashboardDTO {
    /**
     * 
     * @type {number}
     * @memberof NewVersionDashboardDTO
     */
    autoComparePool?: number;
    /**
     * 
     * @type {string}
     * @memberof NewVersionDashboardDTO
     */
    color?: string;
    /**
     * 
     * @type {ColorsConfigDTO}
     * @memberof NewVersionDashboardDTO
     */
    colorsConfig?: ColorsConfigDTO;
    /**
     * 
     * @type {DashboardConfigDTO}
     * @memberof NewVersionDashboardDTO
     */
    config?: DashboardConfigDTO;
    /**
     * 
     * @type {boolean}
     * @memberof NewVersionDashboardDTO
     */
    draft?: boolean;
    /**
     * 
     * @type {string}
     * @memberof NewVersionDashboardDTO
     */
    icon?: string;
    /**
     * 
     * @type {DashboardLayoutsDTO}
     * @memberof NewVersionDashboardDTO
     */
    layouts: DashboardLayoutsDTO;
    /**
     * 
     * @type {string}
     * @memberof NewVersionDashboardDTO
     */
    name: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof NewVersionDashboardDTO
     */
    runIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof NewVersionDashboardDTO
     */
    type: NewVersionDashboardDTOTypeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof NewVersionDashboardDTO
     */
    userExperimentNames?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof NewVersionDashboardDTO
     */
    versionBranch: string;
    /**
     * 
     * @type {string}
     * @memberof NewVersionDashboardDTO
     */
    versionDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof NewVersionDashboardDTO
     */
    versionName?: string;
    /**
     * 
     * @type {TableViewParamsDTO}
     * @memberof NewVersionDashboardDTO
     */
    viewParams?: TableViewParamsDTO;
    /**
     * 
     * @type {Array<WidgetDTO>}
     * @memberof NewVersionDashboardDTO
     */
    widgets: Array<WidgetDTO>;
}

export function NewVersionDashboardDTOFromJSON(json: any): NewVersionDashboardDTO {
    return NewVersionDashboardDTOFromJSONTyped(json, false);
}

export function NewVersionDashboardDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewVersionDashboardDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'autoComparePool': !exists(json, 'autoComparePool') ? undefined : json['autoComparePool'],
        'color': !exists(json, 'color') ? undefined : json['color'],
        'colorsConfig': !exists(json, 'colorsConfig') ? undefined : ColorsConfigDTOFromJSON(json['colorsConfig']),
        'config': !exists(json, 'config') ? undefined : DashboardConfigDTOFromJSON(json['config']),
        'draft': !exists(json, 'draft') ? undefined : json['draft'],
        'icon': !exists(json, 'icon') ? undefined : json['icon'],
        'layouts': DashboardLayoutsDTOFromJSON(json['layouts']),
        'name': json['name'],
        'runIds': !exists(json, 'runIds') ? undefined : json['runIds'],
        'type': json['type'],
        'userExperimentNames': !exists(json, 'userExperimentNames') ? undefined : json['userExperimentNames'],
        'versionBranch': json['versionBranch'],
        'versionDescription': !exists(json, 'versionDescription') ? undefined : json['versionDescription'],
        'versionName': !exists(json, 'versionName') ? undefined : json['versionName'],
        'viewParams': !exists(json, 'viewParams') ? undefined : TableViewParamsDTOFromJSON(json['viewParams']),
        'widgets': ((json['widgets'] as Array<any>).map(WidgetDTOFromJSON)),
    };
}

export function NewVersionDashboardDTOToJSON(value?: NewVersionDashboardDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'autoComparePool': value.autoComparePool,
        'color': value.color,
        'colorsConfig': ColorsConfigDTOToJSON(value.colorsConfig),
        'config': DashboardConfigDTOToJSON(value.config),
        'draft': value.draft,
        'icon': value.icon,
        'layouts': DashboardLayoutsDTOToJSON(value.layouts),
        'name': value.name,
        'runIds': value.runIds,
        'type': value.type,
        'userExperimentNames': value.userExperimentNames,
        'versionBranch': value.versionBranch,
        'versionDescription': value.versionDescription,
        'versionName': value.versionName,
        'viewParams': TableViewParamsDTOToJSON(value.viewParams),
        'widgets': ((value.widgets as Array<any>).map(WidgetDTOToJSON)),
    };
}

/**
* @export
* @enum {string}
*/
export enum NewVersionDashboardDTOTypeEnum {
    Compare = 'compare',
    Experiment = 'experiment',
    Report = 'report'
}


