import {
  Configuration,
  DefaultApi,
  DownloadAttributeRequest,
  GetFloatSeriesValuesCSVRequest,
  GetImageSeriesValuesZipRequest,
  GetStringSeriesValuesCSVRequest,
} from 'generated/leaderboard-client';
import {
  buildEndpointUrl,
  defaultApiClientConfiguration,
} from '@neptune/shared/core-apis-common-domain';
import { leaderboardHardcodedEndpoints } from './leaderboard-hardcoded-endpoints';

export * from 'generated/leaderboard-client/apis';
export * from 'generated/leaderboard-client/models';

export type { GroupByKey } from './extended-types/LeaderboardEntryGroupDTO';

class LeaderboardAPI extends DefaultApi {
  getStringSeriesCSVDownloadURL(requestParameters: GetStringSeriesValuesCSVRequest): string {
    return buildEndpointUrl(
      leaderboardHardcodedEndpoints.getStringSeriesValuesCSV,
      requestParameters,
    );
  }

  getFloatSeriesCSVDownloadURL(requestParameters: GetFloatSeriesValuesCSVRequest): string {
    return buildEndpointUrl(
      leaderboardHardcodedEndpoints.getFloatSeriesValuesCSV,
      requestParameters,
    );
  }

  getImageSeriesValuesZipURL(requestParameters: GetImageSeriesValuesZipRequest): string {
    return buildEndpointUrl(
      leaderboardHardcodedEndpoints.getImageSeriesValuesZip,
      requestParameters,
    );
  }

  getStorageFileURL(requestParameters: DownloadAttributeRequest): string {
    return buildEndpointUrl(leaderboardHardcodedEndpoints.downloadAttribute, requestParameters);
  }
}

export const leaderboardClient = new LeaderboardAPI(
  new Configuration(defaultApiClientConfiguration),
);
