/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FileEntry
 */
export interface FileEntry {
    /**
     * 
     * @type {string}
     * @memberof FileEntry
     */
    fileType: FileEntryFileTypeEnum;
    /**
     * 
     * @type {Date}
     * @memberof FileEntry
     */
    mtime: Date;
    /**
     * 
     * @type {string}
     * @memberof FileEntry
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof FileEntry
     */
    size?: number;
}

export function FileEntryFromJSON(json: any): FileEntry {
    return FileEntryFromJSONTyped(json, false);
}

export function FileEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): FileEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fileType': json['fileType'],
        'mtime': (new Date(json['mtime'])),
        'name': json['name'],
        'size': !exists(json, 'size') ? undefined : json['size'],
    };
}

export function FileEntryToJSON(value?: FileEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fileType': value.fileType,
        'mtime': (value.mtime.toISOString()),
        'name': value.name,
        'size': value.size,
    };
}

/**
* @export
* @enum {string}
*/
export enum FileEntryFileTypeEnum {
    File = 'file',
    Directory = 'directory'
}


