/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    YOutput,
    YOutputFromJSON,
    YOutputFromJSONTyped,
    YOutputToJSON,
} from './';

/**
 * 
 * @export
 * @interface PointValueDTO
 */
export interface PointValueDTO {
    /**
     * 
     * @type {number}
     * @memberof PointValueDTO
     */
    timestampMillis: number;
    /**
     * 
     * @type {number}
     * @memberof PointValueDTO
     */
    x?: number;
    /**
     * 
     * @type {YOutput}
     * @memberof PointValueDTO
     */
    y: YOutput;
}

export function PointValueDTOFromJSON(json: any): PointValueDTO {
    return PointValueDTOFromJSONTyped(json, false);
}

export function PointValueDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): PointValueDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'timestampMillis': json['timestampMillis'],
        'x': !exists(json, 'x') ? undefined : json['x'],
        'y': YOutputFromJSON(json['y']),
    };
}

export function PointValueDTOToJSON(value?: PointValueDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'timestampMillis': value.timestampMillis,
        'x': value.x,
        'y': YOutputToJSON(value.y),
    };
}


