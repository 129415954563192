import { ProjectVisibilityDTO } from 'generated/backend-client';

export enum ProjectPrivacy {
  public = 'pub',
  workspace = 'workspace',
  private = 'priv',

  notSupported = 'notSupported',
}

export interface PrivacyAvailability {
  privacy: ProjectPrivacy;
  available: boolean;
  availableInPlan: boolean;
  disabledByAdmin: boolean;
}

export function projectPrivacyFromApiToDomain(source?: ProjectVisibilityDTO): ProjectPrivacy {
  switch (source) {
    case ProjectVisibilityDTO.Priv:
      return ProjectPrivacy.private;

    case ProjectVisibilityDTO.Workspace:
      return ProjectPrivacy.workspace;

    case ProjectVisibilityDTO.Pub:
      return ProjectPrivacy.public;

    default:
      return ProjectPrivacy.notSupported;
  }
}

export function projectPrivacyFromDomainToApi(source?: ProjectPrivacy): ProjectVisibilityDTO {
  switch (source) {
    case ProjectPrivacy.private:
      return ProjectVisibilityDTO.Priv;

    case ProjectPrivacy.workspace:
      return ProjectVisibilityDTO.Workspace;

    case ProjectPrivacy.public:
      return ProjectVisibilityDTO.Pub;

    default:
      return ProjectVisibilityDTO.Priv;
  }
}
