import React from 'react';
import { isString } from 'lodash';
import {
  Button,
  ButtonProps,
  GenericLink,
  GenericLinkProps,
  Layout,
  Section,
  Text,
  bemBlock,
  GlyphName,
  Icon,
  isGlyph,
  LinkProps,
  Link,
} from '@neptune/shared/venus-ui';
import { useResizeDetector } from 'common/hoc/useResizeDetector';
import './EmptyView.less';

export type EmptyViewButtonData = ButtonProps<GenericLinkProps> & {
  caption: React.ReactNode;
  href?: string;
  onClick?(event: React.MouseEvent): void;
  title?: string;
  'data-role'?: string;
};

export const EMPTY_VIEW_ALWAYS_SINGLE_COLUMN = 'always single column';

type EmptyViewVariant = 'default' | 'subdued';

type EmptyViewProps = {
  action?: React.ReactNode;
  className?: string;
  'data-role'?: string;
  icon?: GlyphName | React.ReactNode;
  subtitle: React.ReactNode;
  title: string;
  variant: EmptyViewVariant;
};

type LegacyEmptyViewProps = {
  variant: 'legacy';
  buttons?: EmptyViewButtonData[];
  className?: string;
  'data-role'?: string;
  description?: React.ReactNode;
  example?: React.ReactNode;
  exampleTitle?: React.ReactNode;
  footer?: React.ReactNode;
  icon?: React.ReactNode;
  subtitle?: React.ReactNode;
  title?: React.ReactNode;
  twoColumnsWidth?: number | typeof EMPTY_VIEW_ALWAYS_SINGLE_COLUMN;
};

const block = bemBlock('empty-view');

const TWO_COLUMNS_WIDTH = 1280;

const LegacyEmptyView: React.FC<LegacyEmptyViewProps> = ({
  buttons = [],
  className,
  'data-role': dataRole,
  description,
  example,
  exampleTitle,
  footer,
  icon,
  subtitle,
  title,
  twoColumnsWidth = TWO_COLUMNS_WIDTH,
}) => {
  const { ref, width = 1280 } = useResizeDetector();
  const isTwoColumns =
    twoColumnsWidth !== EMPTY_VIEW_ALWAYS_SINGLE_COLUMN && Number(width) >= twoColumnsWidth;

  const iconElement = icon && (
    <Layout.Column className={block('icon')} alignItems="center">
      {icon}
    </Layout.Column>
  );

  const titleElement = title && (
    <Text className={block('title')} size="md" color="text-secondary" align="center">
      {title}
    </Text>
  );

  const subtitleElement = subtitle && (
    <Text className={block('subtitle')} align="center">
      {subtitle}
    </Text>
  );

  const descriptionElement = description && (
    <Layout.Column className={block('description')} width="100%" alignItems="center">
      {description}
    </Layout.Column>
  );

  const exampleElement = example && (
    <Section className={block('example')} opaque="grey" width="100%">
      {example}
    </Section>
  );

  const buttonsElement = buttons?.length > 0 && (
    <Layout.Row className={block('buttons')} spacedChildren="sm">
      {buttons.map(({ caption, ...restProps }, index) => (
        <Button
          component={GenericLink}
          key={index}
          children={caption}
          variant="primary"
          size="lg"
          {...restProps}
        />
      ))}
    </Layout.Row>
  );

  const footerElement = footer && (
    <Layout.Column className={block('footer')} width="100%" alignItems="center">
      {footer}
    </Layout.Column>
  );

  return (
    <Layout.Column
      elementRef={ref}
      className={block({
        extra: className,
        modifiers: {
          isTwoColumns,
        },
      })}
      data-role={dataRole}
      overflow="auto"
    >
      <Layout.Row alignItems="center" justifyContent="center" withPadding="lg">
        <Layout.Column className={block('column')} alignItems="center" spacedChildren="lg">
          {iconElement}
          {(titleElement || subtitleElement) && (
            <Layout.Column alignItems="center" spacedChildren="md">
              {titleElement}
              {subtitleElement}
            </Layout.Column>
          )}
          {descriptionElement}
          {!isTwoColumns && exampleTitle}
          {!isTwoColumns && exampleElement}
          {buttonsElement}
          {footerElement}
        </Layout.Column>
        {isTwoColumns && exampleElement && (
          <Layout.Column className={block('column')} spacedChildren="md">
            {exampleTitle}
            {exampleElement}
          </Layout.Column>
        )}
      </Layout.Row>
    </Layout.Column>
  );
};

const NewEmptyView: React.FC<EmptyViewProps> = ({
  action,
  children,
  className,
  'data-role': dataRole,
  icon,
  subtitle,
  title,
  variant,
}) => {
  const iconElement = isString(icon) ? (
    <Layout.Row
      className={block('icon-backdrop')}
      alignItems="center"
      justifyContent="center"
      span="auto"
    >
      <Icon glyph={isGlyph(icon) ? icon : 'question-circle'} size="xl" />
    </Layout.Row>
  ) : (
    icon
  );

  const titleElement = title && (
    <Text
      align="center"
      className={block('title')}
      color={variant === 'subdued' ? 'text-alt' : 'text'}
      size="md"
      fontWeight="bold"
    >
      {title}
    </Text>
  );

  const subtitleElement = subtitle && (
    <Text
      align="center"
      className={block('subtitle')}
      color={variant === 'subdued' ? 'text-alt' : 'text'}
    >
      {subtitle}
    </Text>
  );

  return (
    <Layout.Column
      className={block({
        extra: className,
      })}
      data-role={dataRole}
      overflow="auto"
    >
      <Layout.Row alignItems="center" justifyContent="center" withPadding="lg">
        <Layout.Column className={block('column')} alignItems="center" spacedChildren="lg">
          {variant === 'default' ? iconElement : null}
          <Layout.Column alignItems="center" spacedChildren="md">
            {titleElement}
            {subtitleElement}
          </Layout.Column>
          {action}
          {children}
        </Layout.Column>
      </Layout.Row>
    </Layout.Column>
  );
};

const DefaultActionButton: React.FC<
  Pick<ButtonProps<{}>, 'className' | 'disabled' | 'onClick' | 'title'> & {
    icon?: GlyphName;
    label: string;
    'data-role'?: string;
    variant?: Extract<ButtonProps<{}>['variant'], 'primary' | 'basic'>;
  }
> = ({ className, disabled, icon, label, onClick, title, 'data-role': dataRole, variant }) => {
  return (
    <Button
      className={className}
      data-role={dataRole}
      disabled={disabled}
      onClick={onClick}
      size="lg"
      variant={variant}
      title={title}
    >
      {icon && <Icon glyph={icon} />} {label}
    </Button>
  );
};

type DefaultActionLinkProps = Pick<LinkProps<{}>, 'className' | 'disabled' | 'href' | 'title'> & {
  icon?: GlyphName;
  label: string;
  'data-role'?: string;
  variant?: Extract<ButtonProps<{}>['variant'], 'primary' | 'basic'>;
};

const DefaultActionLink: React.FC<DefaultActionLinkProps> = ({
  className,
  disabled,
  href,
  icon,
  label,
  title,
  variant = 'primary',
  'data-role': dataRole,
}) => {
  return (
    <Button<LinkProps<{}>>
      component={Link}
      className={block({ element: 'action-link', extra: className, modifiers: { variant } })}
      data-role={dataRole}
      disabled={disabled}
      external
      href={href}
      size="lg"
      variant={variant}
      title={title}
    >
      {icon && <Icon glyph={icon} />} {label}
    </Button>
  );
};

const SubduedActionButton: React.FC<
  Pick<ButtonProps<{}>, 'className' | 'disabled' | 'onClick' | 'title'> & {
    icon?: GlyphName;
    label: string;
  }
> = ({ className, disabled, icon, label, onClick, title }) => {
  return (
    <Button
      className={className}
      disabled={disabled}
      onClick={onClick}
      size="lg"
      variant={icon ? 'secondary-text' : 'secondary'}
      title={title}
    >
      {icon && <Icon glyph={icon} />} {label}
    </Button>
  );
};

const SubduedActionLink: React.FC<
  Pick<LinkProps<{}>, 'className' | 'disabled' | 'href' | 'title'> & {
    icon?: GlyphName;
    label: string;
  }
> = ({ className, disabled, href, icon, label, title }) => {
  return (
    <Button<LinkProps<{}>>
      component={Link}
      className={block({ element: 'action-link', extra: className })}
      disabled={disabled}
      external
      href={href}
      size="lg"
      variant={icon ? 'secondary-text' : 'secondary'}
      title={title}
    >
      {icon && <Icon glyph={icon} />} {label}
    </Button>
  );
};

const RawEmptyView = (props: EmptyViewProps | LegacyEmptyViewProps) => {
  if (props.variant === 'legacy') {
    return <LegacyEmptyView {...props} />;
  }

  return <NewEmptyView {...props} />;
};

RawEmptyView.ActionButton = DefaultActionButton;
RawEmptyView.SubduedActionButton = SubduedActionButton;
RawEmptyView.ActionLink = DefaultActionLink;
RawEmptyView.SubduedActionLink = SubduedActionLink;

export const EmptyView: React.FC<EmptyViewProps | LegacyEmptyViewProps> & {
  ActionButton: typeof DefaultActionButton;
  SubduedActionButton: typeof SubduedActionButton;
  ActionLink: typeof DefaultActionLink;
  SubduedActionLink: typeof SubduedActionLink;
} = RawEmptyView;
