/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AttributeTypeDTO,
    AttributeTypeDTOFromJSON,
    AttributeTypeDTOFromJSONTyped,
    AttributeTypeDTOToJSON,
    LeaderboardSortParamsDTO,
    LeaderboardSortParamsDTOFromJSON,
    LeaderboardSortParamsDTOFromJSONTyped,
    LeaderboardSortParamsDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface LeaderboardCsvExportParamsDTO
 */
export interface LeaderboardCsvExportParamsDTO {
    /**
     * 
     * @type {Array<string>}
     * @memberof LeaderboardCsvExportParamsDTO
     */
    exportFieldAggregations: Array<LeaderboardCsvExportParamsDTOExportFieldAggregationsEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof LeaderboardCsvExportParamsDTO
     */
    exportFieldNames: Array<string>;
    /**
     * 
     * @type {Array<AttributeTypeDTO>}
     * @memberof LeaderboardCsvExportParamsDTO
     */
    exportFieldTypes: Array<AttributeTypeDTO>;
    /**
     * 
     * @type {Array<string>}
     * @memberof LeaderboardCsvExportParamsDTO
     */
    exportFields: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof LeaderboardCsvExportParamsDTO
     */
    query?: string;
    /**
     * 
     * @type {LeaderboardSortParamsDTO}
     * @memberof LeaderboardCsvExportParamsDTO
     */
    sorting?: LeaderboardSortParamsDTO;
    /**
     * 
     * @type {number}
     * @memberof LeaderboardCsvExportParamsDTO
     */
    zoneOffset: number;
    /**
     * 
     * @type {string}
     * @memberof LeaderboardCsvExportParamsDTO
     */
    zoneRegion?: string;
}

export function LeaderboardCsvExportParamsDTOFromJSON(json: any): LeaderboardCsvExportParamsDTO {
    return LeaderboardCsvExportParamsDTOFromJSONTyped(json, false);
}

export function LeaderboardCsvExportParamsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): LeaderboardCsvExportParamsDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'exportFieldAggregations': json['exportFieldAggregations'],
        'exportFieldNames': json['exportFieldNames'],
        'exportFieldTypes': ((json['exportFieldTypes'] as Array<any>).map(AttributeTypeDTOFromJSON)),
        'exportFields': json['exportFields'],
        'query': !exists(json, 'query') ? undefined : json['query'],
        'sorting': !exists(json, 'sorting') ? undefined : LeaderboardSortParamsDTOFromJSON(json['sorting']),
        'zoneOffset': json['zoneOffset'],
        'zoneRegion': !exists(json, 'zoneRegion') ? undefined : json['zoneRegion'],
    };
}

export function LeaderboardCsvExportParamsDTOToJSON(value?: LeaderboardCsvExportParamsDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'exportFieldAggregations': value.exportFieldAggregations,
        'exportFieldNames': value.exportFieldNames,
        'exportFieldTypes': ((value.exportFieldTypes as Array<any>).map(AttributeTypeDTOToJSON)),
        'exportFields': value.exportFields,
        'query': value.query,
        'sorting': LeaderboardSortParamsDTOToJSON(value.sorting),
        'zoneOffset': value.zoneOffset,
        'zoneRegion': value.zoneRegion,
    };
}

/**
* @export
* @enum {string}
*/
export enum LeaderboardCsvExportParamsDTOExportFieldAggregationsEnum {
    Last = 'last',
    Min = 'min',
    Max = 'max',
    Average = 'average',
    Variance = 'variance',
    Auto = 'auto'
}


