const intRegex = /^[-+]?[0-9]+$/;

// Regex from https://stackoverflow.com/a/55592455, with [0-9] instead of \d.
const floatRegex = /^[+-]?([0-9]+([.][0-9]*)?([eE][+-]?[0-9]+)?|[.][0-9]+([eE][+-]?[0-9]+)?)$/;

export function isValidIntegerInput(input: string): boolean {
  return intRegex.test(input);
}

export function isValidFloatInput(input: string): boolean {
  return floatRegex.test(input);
}
