import React from 'react';

import { bemBlock, Layout } from '@neptune/shared/venus-ui';
import { useFullScreenAnimation } from './use-fullscreen-animation';

import './FullscreenElementWrapper.less';

const block = bemBlock('fullscreen-element-wrapper');

export const FullscreenElementWrapper: React.FC<{
  className?: string;
  fullscreen: boolean;
}> = ({ fullscreen, children }) => {
  const setRef = useFullScreenAnimation(fullscreen);

  return (
    <Layout.Column elementRef={setRef} span="greedy" className={block()} overflow="hidden">
      {children}
    </Layout.Column>
  );
};
