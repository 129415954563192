// Libs
import React from 'react';

import { bemBlock } from '../../modules/bem';
import { LayoutColumn, LayoutColumnProps } from '../layout-column/LayoutColumn';

// Module
import './ViewSidebar.less';

const block = bemBlock('n-ViewSidebar');

export type ViewSidebarProps = Omit<
  LayoutColumnProps<{}>,
  'component' | 'height' | 'span' | 'width'
> & {
  variant?: 'narrow' | 'wide' | 'very-narrow';
};

/**
 * `ViewSidebar` is a wrapper for view's alternate content.
 *
 * It is based on `LayoutColumn`.
 *
 * ## Import
 *
 * ```
 * import { View } from '@neptune/shared/venus-ui';
 * ```
 *
 * And use `<View.Sidebar />`.
 */
// eslint-disable-next-line react/prefer-stateless-function
export class ViewSidebar extends React.Component<ViewSidebarProps> {
  public static defaultProps = {
    component: 'aside',
    spacedChildren: 'md',
    span: 'auto',
    variant: 'wide',
  };

  render() {
    const { className, variant, ...passProps } = this.props;

    return (
      <LayoutColumn
        className={block({
          modifiers: variant,
          extra: className,
        })}
        {...passProps}
      />
    );
  }
}
