/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserMetadataEntryDTO,
    UserMetadataEntryDTOFromJSON,
    UserMetadataEntryDTOFromJSONTyped,
    UserMetadataEntryDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserMetadataDTO
 */
export interface UserMetadataDTO {
    /**
     * 
     * @type {Array<UserMetadataEntryDTO>}
     * @memberof UserMetadataDTO
     */
    entries: Array<UserMetadataEntryDTO>;
}

export function UserMetadataDTOFromJSON(json: any): UserMetadataDTO {
    return UserMetadataDTOFromJSONTyped(json, false);
}

export function UserMetadataDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserMetadataDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entries': ((json['entries'] as Array<any>).map(UserMetadataEntryDTOFromJSON)),
    };
}

export function UserMetadataDTOToJSON(value?: UserMetadataDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entries': ((value.entries as Array<any>).map(UserMetadataEntryDTOToJSON)),
    };
}


