/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AttributeTypeDTO,
    AttributeTypeDTOFromJSON,
    AttributeTypeDTOFromJSONTyped,
    AttributeTypeDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface AttributeFilterDTO
 */
export interface AttributeFilterDTO {
    /**
     * 
     * @type {AttributeTypeDTO}
     * @memberof AttributeFilterDTO
     */
    attributeType: AttributeTypeDTO;
}

export function AttributeFilterDTOFromJSON(json: any): AttributeFilterDTO {
    return AttributeFilterDTOFromJSONTyped(json, false);
}

export function AttributeFilterDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): AttributeFilterDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attributeType': AttributeTypeDTOFromJSON(json['attributeType']),
    };
}

export function AttributeFilterDTOToJSON(value?: AttributeFilterDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attributeType': AttributeTypeDTOToJSON(value.attributeType),
    };
}


