import { PricingPlanDefinitionDTO, PricingPlanDTO } from '@neptune/shared/core-apis-backend-domain';
import { invertMapTyped } from 'common/tsHelpers';

export type PricingPlan =
  | 'v1_individual'
  | 'v1_free'
  | 'v1_team'
  | 'v1_academia'
  | 'organization'
  | 'v2_individual'
  | 'v2_trial'
  | 'v2_trial_organization'
  | 'v2_team'
  | 'v2_academia'
  | 'enterprise'
  | 'v3_free_individual'
  | 'v3_team'
  | 'v3_scale'
  | 'v3_trial_scale';

export type V3PricingPlan = 'v3_free_individual' | 'v3_team' | 'v3_scale' | 'v3_trial_scale';

export const v3PricingPlans: PricingPlan[] = [
  'v3_free_individual',
  'v3_team',
  'v3_scale',
  'v3_trial_scale',
];

export type PricingPlanDefinition = {
  plan: PricingPlan;
  displayName: string;
};

const PricingPlanMap: Record<PricingPlanDTO, PricingPlan> = {
  [PricingPlanDTO.V1Individual]: 'v1_individual',
  [PricingPlanDTO.V1Free]: 'v1_free',
  [PricingPlanDTO.V1Team]: 'v1_team',
  [PricingPlanDTO.V1Academia]: 'v1_academia',

  [PricingPlanDTO.V2Individual]: 'v2_individual',
  [PricingPlanDTO.V2Trial]: 'v2_trial',
  [PricingPlanDTO.V2TrialOrganization]: 'v2_trial_organization',
  [PricingPlanDTO.V2Team]: 'v2_team',
  [PricingPlanDTO.V2Academia]: 'v2_academia',

  [PricingPlanDTO.Enterprise]: 'enterprise',
  [PricingPlanDTO.Organization]: 'organization',

  [PricingPlanDTO.V3FreeIndividual]: 'v3_free_individual',
  [PricingPlanDTO.V3Team]: 'v3_team',
  [PricingPlanDTO.V3Scale]: 'v3_scale',
  [PricingPlanDTO.V3TrialScale]: 'v3_trial_scale',
};

const invertedPricingPlanMap = invertMapTyped(PricingPlanMap);

export function pricingPlanFromApiToDomain(pricing: PricingPlanDTO): PricingPlan {
  return PricingPlanMap[pricing];
}

export function pricingPlanFromDomainToApi(pricing: PricingPlan): PricingPlanDTO {
  const result = invertedPricingPlanMap[pricing];

  if (!result) {
    throw new Error('Cannot map pricing plan'); // should not happen, making typescript happy
  }

  return result;
}

export function pricingPlanDefinitionFromApiToDomain({
  plan,
  displayName,
}: PricingPlanDefinitionDTO): PricingPlanDefinition {
  return {
    plan: pricingPlanFromApiToDomain(plan),
    displayName,
  };
}
