// Libs
import React from 'react';


// Module
const WithPaddingRenderer = ({
  children,
}) => (
  <div className="n-table-content n-table-content--with-padding">
    {children}
  </div>
);


export default WithPaddingRenderer;
