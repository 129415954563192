import React from 'react';
import { bemBlock } from '../../modules/bem';
import './KeyShortcut.less';
import { Text } from '../text/Text';

type KeyShortcutProps = {
  theme: 'light' | 'dark';
  variant?: 'sm' | 'md';
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
  'data-role'?: string;
};

const block = bemBlock('n-key-shortcut');

export const KeyShortcut: React.FC<KeyShortcutProps> = ({
  children,
  theme,
  variant = 'sm',
  onClick,
  className,
  'data-role': dataRole,
}) => {
  return (
    <Text
      data-role={dataRole}
      onClick={onClick}
      component="kbd"
      className={block({ modifiers: { theme, clickable: !!onClick }, extra: className })}
      children={children}
      color="text-subdued"
      ellipsis={false}
      fontFamily="sans-serif"
      fontWeight={variant === 'sm' ? 'semibold' : 'normal'}
      italic={false}
      lineHeight="base"
      size={variant === 'sm' ? 'xxs' : 'sm'}
      transform="none"
      wordBreak="keep-all"
    />
  );
};
