import React from 'react';

import { EmblemColor, EmblemSize, EmblemVariant } from './types';

type EmblemContextProps = {
  color: EmblemColor;
  size: EmblemSize;
  variant: EmblemVariant;
  active: boolean;
};

export const EmblemContext = React.createContext<EmblemContextProps | undefined>(undefined);

export function useEmblemContext() {
  const context = React.useContext(EmblemContext);

  if (!context) {
    throw new Error('useEmblemContext must be used within a Emblem');
  }

  return context;
}
