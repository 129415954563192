/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OperationsLimitDTO,
    OperationsLimitDTOFromJSON,
    OperationsLimitDTOFromJSONTyped,
    OperationsLimitDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface OperationsLimitTraitDTO
 */
export interface OperationsLimitTraitDTO {
    /**
     * 
     * @type {Array<OperationsLimitDTO>}
     * @memberof OperationsLimitTraitDTO
     */
    limits: Array<OperationsLimitDTO>;
}

export function OperationsLimitTraitDTOFromJSON(json: any): OperationsLimitTraitDTO {
    return OperationsLimitTraitDTOFromJSONTyped(json, false);
}

export function OperationsLimitTraitDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OperationsLimitTraitDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'limits': ((json['limits'] as Array<any>).map(OperationsLimitDTOFromJSON)),
    };
}

export function OperationsLimitTraitDTOToJSON(value?: OperationsLimitTraitDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'limits': ((value.limits as Array<any>).map(OperationsLimitDTOToJSON)),
    };
}


