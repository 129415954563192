/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ProjectRoleDTO,
    ProjectRoleDTOFromJSON,
    ProjectRoleDTOFromJSONTyped,
    ProjectRoleDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface NewProjectServiceAccountDTO
 */
export interface NewProjectServiceAccountDTO {
    /**
     * 
     * @type {string}
     * @memberof NewProjectServiceAccountDTO
     */
    serviceAccountId: string;
    /**
     * 
     * @type {ProjectRoleDTO}
     * @memberof NewProjectServiceAccountDTO
     */
    role: ProjectRoleDTO;
}

export function NewProjectServiceAccountDTOFromJSON(json: any): NewProjectServiceAccountDTO {
    return NewProjectServiceAccountDTOFromJSONTyped(json, false);
}

export function NewProjectServiceAccountDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewProjectServiceAccountDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'serviceAccountId': json['serviceAccountId'],
        'role': ProjectRoleDTOFromJSON(json['role']),
    };
}

export function NewProjectServiceAccountDTOToJSON(value?: NewProjectServiceAccountDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'serviceAccountId': value.serviceAccountId,
        'role': ProjectRoleDTOToJSON(value.role),
    };
}


