import { LocalStorageCompatible } from './local-storage-compatible';
import { isLocalStorageV4 } from './local-storage-v4';
import { upgrade as upgradeFromPrevious } from './local-storage-v4.upgrade';
import { LocalStorageV5 } from './local-storage-v5';

/*
 * NOTE: Create new migration files only when you need to remove or edit existing field. Do not create new migration file for adding new field.
 */
export async function upgrade(raw: LocalStorageCompatible): Promise<LocalStorageV5> {
  const input = isLocalStorageV4(raw) ? raw : await upgradeFromPrevious(raw);
  const { data } = input;

  const { chartsWithLogScale, ...rest } = data;

  return {
    version: 5,
    data: {
      ...rest,
      chartsWithYLogScale: chartsWithLogScale,
    },
  };
}
