/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AttributeDefinitionDTO,
    AttributeDefinitionDTOFromJSON,
    AttributeDefinitionDTOFromJSONTyped,
    AttributeDefinitionDTOToJSON,
    OpenRangeDTO,
    OpenRangeDTOFromJSON,
    OpenRangeDTOFromJSONTyped,
    OpenRangeDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface DashboardConfigDTO
 */
export interface DashboardConfigDTO {
    /**
     * 
     * @type {OpenRangeDTO}
     * @memberof DashboardConfigDTO
     */
    metricsStepsRange?: OpenRangeDTO;
    /**
     * 
     * @type {AttributeDefinitionDTO}
     * @memberof DashboardConfigDTO
     */
    xaxisMetric?: AttributeDefinitionDTO;
    /**
     * 
     * @type {string}
     * @memberof DashboardConfigDTO
     */
    xaxisMode?: string;
    /**
     * 
     * @type {string}
     * @memberof DashboardConfigDTO
     */
    xaxisScale?: string;
    /**
     * 
     * @type {string}
     * @memberof DashboardConfigDTO
     */
    yaxisScale?: string;
}

export function DashboardConfigDTOFromJSON(json: any): DashboardConfigDTO {
    return DashboardConfigDTOFromJSONTyped(json, false);
}

export function DashboardConfigDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): DashboardConfigDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'metricsStepsRange': !exists(json, 'metricsStepsRange') ? undefined : OpenRangeDTOFromJSON(json['metricsStepsRange']),
        'xaxisMetric': !exists(json, 'xaxisMetric') ? undefined : AttributeDefinitionDTOFromJSON(json['xaxisMetric']),
        'xaxisMode': !exists(json, 'xaxisMode') ? undefined : json['xaxisMode'],
        'xaxisScale': !exists(json, 'xaxisScale') ? undefined : json['xaxisScale'],
        'yaxisScale': !exists(json, 'yaxisScale') ? undefined : json['yaxisScale'],
    };
}

export function DashboardConfigDTOToJSON(value?: DashboardConfigDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'metricsStepsRange': OpenRangeDTOToJSON(value.metricsStepsRange),
        'xaxisMetric': AttributeDefinitionDTOToJSON(value.xaxisMetric),
        'xaxisMode': value.xaxisMode,
        'xaxisScale': value.xaxisScale,
        'yaxisScale': value.yaxisScale,
    };
}


