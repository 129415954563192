import React from 'react';
import { debounce } from 'lodash';

import type { ViewportContext } from './ViewportContext';

type UseInViewportParams = {
  viewport: ViewportContext;
  debounceMs?: number;
  onShow?: () => unknown;
  onHide?: () => unknown;
  domContainer: HTMLElement | null;
};

export const useInViewport: (params: UseInViewportParams) => void = ({
  viewport,
  debounceMs = 100,
  onShow,
  onHide,
  domContainer,
}) => {
  React.useEffect(() => {
    if (!domContainer) {
      return;
    }

    const callback = (visible: boolean) => {
      if (visible) {
        onShow?.();
      } else {
        onHide?.();
      }
    };

    viewport.add(domContainer, debounce(callback, debounceMs));

    return () => viewport.remove(domContainer);
  }, [onShow, onHide, domContainer, viewport, debounceMs]);
};
