import React from 'react';
import { isFunction } from 'lodash';

type AsyncWrapperState<T, E> = {
  /** Indicates if the async action did not start. */
  idle: boolean;
  /** Indicates if the async action is currently loading. */
  loading: boolean;
  /**  Represents any error that occurred during the async action. */
  error?: E;
  /** Indicates if the data has been initially loaded. */
  initiallyLoaded: boolean;
  /** Represents the value returned by the async action. */
  result?: T;
};

export function useAsyncWrapper<
  E,
  F extends (...args: any[]) => Promise<unknown>,
  T = F extends (...args: any[]) => Promise<infer A> ? A : unknown,
>({
  resolver,
  errorFormatter,
}: {
  resolver: F;
  errorFormatter?: (error: any) => E;
}): AsyncWrapperState<T, E> & {
  resolve: ((...params: Parameters<F>) => Promise<T | undefined>) | (() => Promise<T | undefined>);
} {
  const [state, setState] = React.useState<AsyncWrapperState<T, E>>({
    idle: true,
    loading: false,
    result: undefined,
    error: undefined,
    initiallyLoaded: false,
  });

  const currentFetchId = React.useRef(0);

  const resolve = React.useCallback(
    async (...params: Parameters<F>) => {
      const fetchId = ++currentFetchId.current;

      setState((prev) => ({ ...prev, idle: false, loading: true, error: undefined }));

      try {
        const value = await resolver(...params);

        if (fetchId !== currentFetchId.current) {
          return; // The result is already stale, drop it.
        }

        setState((prev) => ({
          ...prev,
          result: value as T,
          loading: false,
          initiallyLoaded: true,
        }));

        return value as Promise<T>;
      } catch (e) {
        if (fetchId !== currentFetchId.current) {
          return; // The error occurred in stale request, ignore it.
        }

        const err = isFunction(errorFormatter) ? errorFormatter(e) : e;

        setState((prev) => ({ ...prev, error: err as E, loading: false }));
      }
    },
    [resolver, errorFormatter],
  );

  return {
    ...state,
    resolve,
  };
}
