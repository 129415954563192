/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WidgetAttributeDTO
 */
export interface WidgetAttributeDTO {
    /**
     * 
     * @type {string}
     * @memberof WidgetAttributeDTO
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof WidgetAttributeDTO
     */
    subproperty?: string;
    /**
     * 
     * @type {string}
     * @memberof WidgetAttributeDTO
     */
    type: WidgetAttributeDTOTypeEnum;
}

export function WidgetAttributeDTOFromJSON(json: any): WidgetAttributeDTO {
    return WidgetAttributeDTOFromJSONTyped(json, false);
}

export function WidgetAttributeDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): WidgetAttributeDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'subproperty': !exists(json, 'subproperty') ? undefined : json['subproperty'],
        'type': json['type'],
    };
}

export function WidgetAttributeDTOToJSON(value?: WidgetAttributeDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'subproperty': value.subproperty,
        'type': value.type,
    };
}

/**
* @export
* @enum {string}
*/
export enum WidgetAttributeDTOTypeEnum {
    ExperimentState = 'experimentState',
    Float = 'float',
    Int = 'int',
    Bool = 'bool',
    String = 'string',
    File = 'file',
    Datetime = 'datetime',
    FloatSeries = 'floatSeries',
    StringSeries = 'stringSeries',
    ImageSeries = 'imageSeries',
    StringSet = 'stringSet',
    FileSet = 'fileSet',
    GitRef = 'gitRef',
    Artifact = 'artifact',
    NotebookRef = 'notebookRef',
    NotSupported = 'notSupported'
}


