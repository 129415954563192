/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrganizationLimitsDTO
 */
export interface OrganizationLimitsDTO {
    /**
     * 
     * @type {number}
     * @memberof OrganizationLimitsDTO
     */
    storageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof OrganizationLimitsDTO
     */
    membersLimit?: number;
    /**
     * 
     * @type {boolean}
     * @memberof OrganizationLimitsDTO
     */
    isExpired: boolean;
    /**
     * 
     * @type {number}
     * @memberof OrganizationLimitsDTO
     */
    projectsLimit?: number;
    /**
     * 
     * @type {Date}
     * @memberof OrganizationLimitsDTO
     */
    expirationDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof OrganizationLimitsDTO
     */
    privateProjectMembers?: number;
    /**
     * 
     * @type {number}
     * @memberof OrganizationLimitsDTO
     */
    subscriptionHours?: number;
}

export function OrganizationLimitsDTOFromJSON(json: any): OrganizationLimitsDTO {
    return OrganizationLimitsDTOFromJSONTyped(json, false);
}

export function OrganizationLimitsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationLimitsDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'storageSize': !exists(json, 'storageSize') ? undefined : json['storageSize'],
        'membersLimit': !exists(json, 'membersLimit') ? undefined : json['membersLimit'],
        'isExpired': json['isExpired'],
        'projectsLimit': !exists(json, 'projectsLimit') ? undefined : json['projectsLimit'],
        'expirationDate': !exists(json, 'expirationDate') ? undefined : (new Date(json['expirationDate'])),
        'privateProjectMembers': !exists(json, 'privateProjectMembers') ? undefined : json['privateProjectMembers'],
        'subscriptionHours': !exists(json, 'subscriptionHours') ? undefined : json['subscriptionHours'],
    };
}

export function OrganizationLimitsDTOToJSON(value?: OrganizationLimitsDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'storageSize': value.storageSize,
        'membersLimit': value.membersLimit,
        'isExpired': value.isExpired,
        'projectsLimit': value.projectsLimit,
        'expirationDate': value.expirationDate === undefined ? undefined : (value.expirationDate.toISOString()),
        'privateProjectMembers': value.privateProjectMembers,
        'subscriptionHours': value.subscriptionHours,
    };
}


