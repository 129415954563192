// Libs
import React from 'react';

// App
import {
  SyntaxHighlighter,
  SyntaxHighlighterLanguage,
} from '../../syntax-highlighter/SyntaxHighlighter';

// Module
type CodeRendererProps = {
  language: SyntaxHighlighterLanguage;
  value: string;
};

export const CodeRenderer = ({ language, value }: CodeRendererProps) => {
  return <SyntaxHighlighter language={language} codeToHighlight={value} theme="github" />;
};
