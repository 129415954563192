/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ClientVersionsConfigDTO
 */
export interface ClientVersionsConfigDTO {
    /**
     * 
     * @type {string}
     * @memberof ClientVersionsConfigDTO
     */
    minRecommendedVersion?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientVersionsConfigDTO
     */
    minCompatibleVersion?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientVersionsConfigDTO
     */
    maxCompatibleVersion?: string;
}

export function ClientVersionsConfigDTOFromJSON(json: any): ClientVersionsConfigDTO {
    return ClientVersionsConfigDTOFromJSONTyped(json, false);
}

export function ClientVersionsConfigDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClientVersionsConfigDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'minRecommendedVersion': !exists(json, 'minRecommendedVersion') ? undefined : json['minRecommendedVersion'],
        'minCompatibleVersion': !exists(json, 'minCompatibleVersion') ? undefined : json['minCompatibleVersion'],
        'maxCompatibleVersion': !exists(json, 'maxCompatibleVersion') ? undefined : json['maxCompatibleVersion'],
    };
}

export function ClientVersionsConfigDTOToJSON(value?: ClientVersionsConfigDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'minRecommendedVersion': value.minRecommendedVersion,
        'minCompatibleVersion': value.minCompatibleVersion,
        'maxCompatibleVersion': value.maxCompatibleVersion,
    };
}


