// Libs
import React from 'react';
import parse from 'url-parse';

// Module
export type TComponent = string | React.ComponentType<any>;
export type WithComponent = React.PropsWithChildren<{ component?: TComponent }>;
export type WithoutInterferers<T, I> = Omit<T, keyof I>;
export type WithNonInterferingComponent<T, I> = WithoutInterferers<T, I> & WithComponent;

export function createComponent(
  component: TComponent,
  props?: any,
  ...children: React.ReactNode[]
) {
  return React.createElement(component, props, ...children);
}

export function sanitizeDangerousUrl(url: string) {
  const urlObject = parse(url, /* do not use browser environment */ {}, false);
  const { protocol, href } = urlObject;

  if (protocol === '') {
    return 'http://' + url;
  }

  if (protocol !== 'https:' && protocol !== 'http:') {
    return '';
  }

  return href;
}
