// Libs
import React from 'react';

// neptune-core-ui
import {
  Dropdown,
  Text,
} from '@neptune/shared/venus-ui';
// eslint-disable-next-line no-restricted-imports
import { bemBlock } from 'neptune-core-ui/modules/bem';
// eslint-disable-next-line no-restricted-imports
import Icon from 'neptune-core-ui/components/icon';

// App
import { ComponentToggle } from 'components/toggles/component-toggle/ComponentToggle';

// Module
import './ConfigAction.less';

const block = bemBlock('n-action-config');

export const ConfigAction = ({
  className,
  name,
  column,
  MenuComponent,
}) => {
  const cssClass = block({
    extra: [className, 'n-table-cell-action'],
  });
  return (
    <Dropdown
      toggle={(
        <ComponentToggle
          className={cssClass}
          component={ConfigWithName}
          name={name}
        />
      )}
      constraints={[{
        to: 'window',
        attachment: 'together',
        pin: true,
      }]}
    >
      {
        ({collapse}) => (
          <Dropdown.Menu>
            <MenuComponent column={column} collapse={collapse} />
          </Dropdown.Menu>
        )
      }
    </Dropdown>
  );
};

function ConfigWithName({
  className,
  pressed,
  name,
  ...rest
}) {
  return (
    <span
      className={block({
        element: 'toggle',
        modifiers: {
          pressed,
        },
        extra: className,
      })}
      {...rest}
    >
      {name && (<Text size="xs">{name.toLowerCase()}</Text>)}
      <Icon glyph="FA.cog" />
    </span>
  );
}
