// Libs
import React from 'react';

import { joinClassNames } from '../../modules/bem';
import * as layout from '../../modules/layout';
import { LayoutElement, LayoutElementProps } from '../layout-element/LayoutElement';

// Module
export type LayoutRowProps<P extends object> = LayoutElementProps<P> & {
  alignContent?: layout.AlignContent;
  alignItems?: layout.AlignItems;
  inline?: boolean;
  justifyContent?: layout.JustifyContent;
  reversed?: boolean;
  spacedChildren?: layout.LayoutUnit;
  wrap?: layout.Wrap;
};

/**
 * ## Import
 *
 * ```
 * import { Layout } from '@neptune/shared/venus-ui';
 * ```
 *
 * And use `<Layout.Row />`.
 */
export function LayoutRow<P extends object>({
  alignContent,
  alignItems = 'stretch',
  className,
  inline = false,
  justifyContent = 'start',
  reversed = false,
  spacedChildren,
  span = 'greedy',
  wrap = 'nowrap',
  ...passProps
}: LayoutRowProps<P>): React.ReactElement {
  const ownProps = {
    className: joinClassNames(
      className,
      layout.displayFlexClassName(inline),
      layout.flexDirectionClassName('row', reversed),
      layout.alignContentClassName(alignContent),
      layout.alignItemsClassName(alignItems),
      layout.justifyContentClassName(justifyContent),
      layout.spacedChildrenClassName(spacedChildren),
      layout.wrapClassName(wrap),
    ),
    span,
  };

  return <LayoutElement {...ownProps} {...passProps} />;
}
