// Libs

// eslint-disable-next-line no-restricted-imports
import * as font from '../modules/font';
// eslint-disable-next-line no-restricted-imports
import * as text from '../modules/text';
// eslint-disable-next-line no-restricted-imports
import * as layout from '../modules/layout';

// Module

// {{{ Helpers
export type SelectValue<T> = Record<string, T>;
export type OptionalSelectValue<T> = Record<string, T | undefined>;
// }}} Helpers

// {{{ fontSizeControl
const fontSizeValues: OptionalSelectValue<font.Size> = {
  '[undefined]': undefined,
  'xxs (10px)': 'xxs',
  'xs (12px)': 'xs',
  'sm (14px)': 'sm',
  'base (16px)': 'base',
  'md (18px)': 'md',
  'lg (22px)': 'lg',
  'xl (24px)': 'xl',
  'xxl (32px)': 'xxl',
};

export const fontSizeControl = {
  control: {
    type: 'select',
    options: fontSizeValues,
  },
};
// }}} fontSizeControl

// {{{ emojiControl
const emojiArray = [
  '1F60D',
  '1F44B',
  '1F602',
  '1F609',
  '1F680',
  '1f3c9',
  '1f3be',
  '1f401',
  '1f400',
  '1f439',
  '1f94f',
  '1f3b3',
  '1f3cf',
];

export const emojiControl = {
  control: {
    type: 'select',
    options: emojiArray,
  },
};
// }}} emojiControl

// {{{ lineHeightControl
const lineHeightValues: OptionalSelectValue<font.LineHeight> = {
  '[undefined]': undefined,
  '1em': '1',
  'xxs (10px)': 'xxs',
  'xs (12px)': 'xs',
  'sm (14px)': 'sm',
  'base (16px)': 'base',
  'md (18px)': 'md',
  'lg (22px)': 'lg',
  'xl (24px)': 'xl',
  'xxl (32px)': 'xxl',
};

export const lineHeightControl = {
  control: {
    type: 'select',
    options: lineHeightValues,
  },
};
// }}} lineHeightControl

// {{{ layoutUnitControl
const layoutUnitValues: OptionalSelectValue<layout.LayoutUnit> = {
  '[undefined]': undefined,
  'none (0px)': 'none',
  'xxs (2px)': 'xxs',
  'xs (4px)': 'xs',
  'sm (8px)': 'sm',
  'md (16px)': 'md',
  'lg (24px)': 'lg',
  'xl (32px)': 'xl',
};

export const layoutUnitControl = {
  control: {
    type: 'select',
    options: layoutUnitValues,
  },
};
// }}} layoutUnitControl

// {{{ textColorControls
const textColorValues: OptionalSelectValue<text.Color> = {
  '[undefined]': undefined,
  Text: 'text',
  'Secondary text': 'text-secondary',
  'Alt text': 'text-alt',
  Disabled: 'disabled',
  Success: 'success',
  Warning: 'warning',
  Error: 'error',
  Info: 'info',
  Decorator: 'decorator',
  'Dark decorator': 'dark-decorator',
};

export const textColorControl = {
  control: {
    type: 'select',
    options: textColorValues,
  },
};
// }}} textColorControls

// {{{ spanControl
const spanValues: OptionalSelectValue<layout.Span> = {
  '[undefined]': undefined,
  greedy: 'greedy',
  auto: 'auto',
  grow: 'grow',
  shrink: 'shrink',
  '1': '1',
  '2': '2',
  '3': '3',
  '4': '4',
  '5': '5',
  '6': '6',
  '7': '7',
  '8': '8',
  '9': '9',
  '10': '10',
  '11': '11',
  '12': '12',
};

export const spanControl = {
  control: {
    type: 'select',
    options: spanValues,
  },
};
// }}} spanControl

// {{{ overflowControl
const overflowValues: OptionalSelectValue<layout.OverflowBasicValue> = {
  '[undefined]': undefined,
  auto: 'auto',
  hidden: 'hidden',
  scroll: 'scroll',
  visible: 'visible',
};

export const overflowControl = {
  control: {
    type: 'select',
    options: overflowValues,
  },
};
// }}} overflowControl
