/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum LinkTypeDTO {
    Github = 'github',
    Twitter = 'twitter',
    Kaggle = 'kaggle',
    Linkedin = 'linkedin',
    Other = 'other'
}

export function LinkTypeDTOFromJSON(json: any): LinkTypeDTO {
    return LinkTypeDTOFromJSONTyped(json, false);
}

export function LinkTypeDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): LinkTypeDTO {
    return json as LinkTypeDTO;
}

export function LinkTypeDTOToJSON(value?: LinkTypeDTO | null): any {
    return value as any;
}

