/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LeaderboardFieldDTO,
    LeaderboardFieldDTOFromJSON,
    LeaderboardFieldDTOFromJSONTyped,
    LeaderboardFieldDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface LeaderboardFieldWithValueDTO
 */
export interface LeaderboardFieldWithValueDTO {
    /**
     * 
     * @type {LeaderboardFieldDTO}
     * @memberof LeaderboardFieldWithValueDTO
     */
    field: LeaderboardFieldDTO;
    /**
     * 
     * @type {object}
     * @memberof LeaderboardFieldWithValueDTO
     */
    value?: object;
}

export function LeaderboardFieldWithValueDTOFromJSON(json: any): LeaderboardFieldWithValueDTO {
    return LeaderboardFieldWithValueDTOFromJSONTyped(json, false);
}

export function LeaderboardFieldWithValueDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): LeaderboardFieldWithValueDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'field': LeaderboardFieldDTOFromJSON(json['field']),
        'value': !exists(json, 'value') ? undefined : json['value'],
    };
}

export function LeaderboardFieldWithValueDTOToJSON(value?: LeaderboardFieldWithValueDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'field': LeaderboardFieldDTOToJSON(value.field),
        'value': value.value,
    };
}


