/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AttributeQueryDTO
 */
export interface AttributeQueryDTO {
    /**
     * Filter attribute paths, if null api returns all attributes
     * @type {Array<string>}
     * @memberof AttributeQueryDTO
     */
    attributePathsFilter?: Array<string>;
}

export function AttributeQueryDTOFromJSON(json: any): AttributeQueryDTO {
    return AttributeQueryDTOFromJSONTyped(json, false);
}

export function AttributeQueryDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): AttributeQueryDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attributePathsFilter': !exists(json, 'attributePathsFilter') ? undefined : json['attributePathsFilter'],
    };
}

export function AttributeQueryDTOToJSON(value?: AttributeQueryDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attributePathsFilter': value.attributePathsFilter,
    };
}


