// Libs
import React from 'react';
import PropTypes from 'prop-types';
import { isFunction } from 'lodash';


// neptune-core-ui
// eslint-disable-next-line no-restricted-imports
import ncuiPropTypes from 'neptune-core-ui/helpers/prop-types';
// eslint-disable-next-line no-restricted-imports
import { getEventHandler } from 'neptune-core-ui/modules/events';
// eslint-disable-next-line no-restricted-imports
import { bemBlock } from 'neptune-core-ui/modules/bem';


// Module
const propTypes = {
  className: PropTypes.string,
  /**
   * Can be event handler function or object with two properties:
   *
   * - `handler`: event handler
   * - `payload`: object that will be passed to handler
   *
   * Event handler will be called with arguments:
   *
   * - `event`: [SyntheticEvent](https://facebook.github.io/react/docs/events.html)
   * - `params`: `object` (defaults to `{}` *empty object*)
   *   - `payload`: value of `onClick.payload`
   */
  onClick: ncuiPropTypes.eventHandler,
  // HACK: 'md' is not used by FontAwesomeIcon
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', '1x', '2x', '3x', '4x', '5x', '6x', '7x', '8x', '9x', '10x']),
  component: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  title: PropTypes.string,
};
const defaultProps = {
  component: 'span',
};

const block = bemBlock('n-icon-layers');


const IconLayers = ({
  children,
  className,
  onClick,
  size,
  component,
  ...restProps
}) => {
  const onClickHandler = getEventHandler(onClick);
  // TODO: pass size directly when Icon is no longer needed
  const safeSize = size !== 'md' ? size : null;
  const ownProps = {
    className: block({
      extra: [
        'fa-layers',
        'fa-fw',
        { [`fa-${safeSize}`]: safeSize },
        { clickable: isFunction(onClickHandler) },
        className,
      ],
    }),
    onClick: onClickHandler,
  };

  return React.createElement(component, {...ownProps, ...restProps}, children);
};

IconLayers.propTypes = propTypes;
IconLayers.defaultProps = defaultProps;


export default IconLayers;
