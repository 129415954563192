/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DownloadPrepareRequest
 */
export interface DownloadPrepareRequest {
    /**
     * 
     * @type {string}
     * @memberof DownloadPrepareRequest
     */
    downloadUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof DownloadPrepareRequest
     */
    id: string;
}

export function DownloadPrepareRequestFromJSON(json: any): DownloadPrepareRequest {
    return DownloadPrepareRequestFromJSONTyped(json, false);
}

export function DownloadPrepareRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): DownloadPrepareRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'downloadUrl': !exists(json, 'downloadUrl') ? undefined : json['downloadUrl'],
        'id': json['id'],
    };
}

export function DownloadPrepareRequestToJSON(value?: DownloadPrepareRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'downloadUrl': value.downloadUrl,
        'id': value.id,
    };
}


