import { CustomIconDefinition } from '../types';

export const nepFastForward: CustomIconDefinition = {
  prefix: 'nep',
  iconName: 'fast-forward',
  icon: [
    16,
    16,
    [],
    'e00d',
    'M15.6406 7.23145L9.64062 2.23145C8.99687 1.69707 8 2.14395 8 3.0002V13.0002C8 13.8564 8.99687 14.3064 9.64062 13.7689L15.6406 8.76895C16.1187 8.36895 16.1187 7.63145 15.6406 7.23145ZM7.64062 7.23145L1.64062 2.23145C0.996875 1.69707 0 2.14395 0 3.0002V13.0002C0 13.8564 0.996875 14.3064 1.64062 13.7689L7.64062 8.76895C8.11875 8.36895 8.11875 7.63145 7.64062 7.23145Z',
  ],
};
