import React from 'react';

function selectInputContent(ev: React.FocusEvent<HTMLInputElement>) {
  ev.target.select();
}

export const selectContentBeforeWrap =
  (onFocus?: React.FocusEventHandler<HTMLInputElement>) =>
  (ev: React.FocusEvent<HTMLInputElement>) => {
    selectInputContent(ev);
    return onFocus?.(ev);
  };
