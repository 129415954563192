/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AttributeNameFilterDTO,
    AttributeNameFilterDTOFromJSON,
    AttributeNameFilterDTOFromJSONTyped,
    AttributeNameFilterDTOToJSON,
    NextPageDTO,
    NextPageDTOFromJSON,
    NextPageDTOFromJSONTyped,
    NextPageDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface QueryAttributesBodyDTO
 */
export interface QueryAttributesBodyDTO {
    /**
     * 
     * @type {AttributeNameFilterDTO}
     * @memberof QueryAttributesBodyDTO
     */
    attributeNameFilter?: AttributeNameFilterDTO;
    /**
     * Filter by attribute name, if null all attributes are considered
     * @type {Array<string>}
     * @memberof QueryAttributesBodyDTO
     */
    attributeNamesFilter?: Array<string>;
    /**
     * Filter by experiment id, if null all experiments are considered
     * @type {Array<string>}
     * @memberof QueryAttributesBodyDTO
     */
    experimentIdsFilter?: Array<string>;
    /**
     * Filter by experiment name, if null all experiments are considered
     * @type {Array<string>}
     * @memberof QueryAttributesBodyDTO
     */
    experimentNamesFilter?: Array<string>;
    /**
     * 
     * @type {NextPageDTO}
     * @memberof QueryAttributesBodyDTO
     */
    nextPage?: NextPageDTO;
}

export function QueryAttributesBodyDTOFromJSON(json: any): QueryAttributesBodyDTO {
    return QueryAttributesBodyDTOFromJSONTyped(json, false);
}

export function QueryAttributesBodyDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): QueryAttributesBodyDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attributeNameFilter': !exists(json, 'attributeNameFilter') ? undefined : AttributeNameFilterDTOFromJSON(json['attributeNameFilter']),
        'attributeNamesFilter': !exists(json, 'attributeNamesFilter') ? undefined : json['attributeNamesFilter'],
        'experimentIdsFilter': !exists(json, 'experimentIdsFilter') ? undefined : json['experimentIdsFilter'],
        'experimentNamesFilter': !exists(json, 'experimentNamesFilter') ? undefined : json['experimentNamesFilter'],
        'nextPage': !exists(json, 'nextPage') ? undefined : NextPageDTOFromJSON(json['nextPage']),
    };
}

export function QueryAttributesBodyDTOToJSON(value?: QueryAttributesBodyDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attributeNameFilter': AttributeNameFilterDTOToJSON(value.attributeNameFilter),
        'attributeNamesFilter': value.attributeNamesFilter,
        'experimentIdsFilter': value.experimentIdsFilter,
        'experimentNamesFilter': value.experimentNamesFilter,
        'nextPage': NextPageDTOToJSON(value.nextPage),
    };
}


