/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NqlQueryParamsDTO,
    NqlQueryParamsDTOFromJSON,
    NqlQueryParamsDTOFromJSONTyped,
    NqlQueryParamsDTOToJSON,
    QueryLeaderboardParamsAttributeFilterDTO,
    QueryLeaderboardParamsAttributeFilterDTOFromJSON,
    QueryLeaderboardParamsAttributeFilterDTOFromJSONTyped,
    QueryLeaderboardParamsAttributeFilterDTOToJSON,
    QueryLeaderboardParamsGroupingParamsDTO,
    QueryLeaderboardParamsGroupingParamsDTOFromJSON,
    QueryLeaderboardParamsGroupingParamsDTOFromJSONTyped,
    QueryLeaderboardParamsGroupingParamsDTOToJSON,
    QueryLeaderboardParamsPaginationDTO,
    QueryLeaderboardParamsPaginationDTOFromJSON,
    QueryLeaderboardParamsPaginationDTOFromJSONTyped,
    QueryLeaderboardParamsPaginationDTOToJSON,
    QueryLeaderboardParamsSortingParamsDTO,
    QueryLeaderboardParamsSortingParamsDTOFromJSON,
    QueryLeaderboardParamsSortingParamsDTOFromJSONTyped,
    QueryLeaderboardParamsSortingParamsDTOToJSON,
    QueryLeaderboardParamsSuggestionsParamsDTO,
    QueryLeaderboardParamsSuggestionsParamsDTOFromJSON,
    QueryLeaderboardParamsSuggestionsParamsDTOFromJSONTyped,
    QueryLeaderboardParamsSuggestionsParamsDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface SearchLeaderboardEntriesParamsDTO
 */
export interface SearchLeaderboardEntriesParamsDTO {
    /**
     * 
     * @type {Array<QueryLeaderboardParamsAttributeFilterDTO>}
     * @memberof SearchLeaderboardEntriesParamsDTO
     */
    attributeFilters?: Array<QueryLeaderboardParamsAttributeFilterDTO>;
    /**
     * 
     * @type {boolean}
     * @memberof SearchLeaderboardEntriesParamsDTO
     */
    experimentLeader?: boolean;
    /**
     * 
     * @type {QueryLeaderboardParamsGroupingParamsDTO}
     * @memberof SearchLeaderboardEntriesParamsDTO
     */
    grouping?: QueryLeaderboardParamsGroupingParamsDTO;
    /**
     * 
     * @type {QueryLeaderboardParamsPaginationDTO}
     * @memberof SearchLeaderboardEntriesParamsDTO
     */
    pagination?: QueryLeaderboardParamsPaginationDTO;
    /**
     * 
     * @type {NqlQueryParamsDTO}
     * @memberof SearchLeaderboardEntriesParamsDTO
     */
    query?: NqlQueryParamsDTO;
    /**
     * 
     * @type {QueryLeaderboardParamsSortingParamsDTO}
     * @memberof SearchLeaderboardEntriesParamsDTO
     */
    sorting?: QueryLeaderboardParamsSortingParamsDTO;
    /**
     * 
     * @type {QueryLeaderboardParamsSuggestionsParamsDTO}
     * @memberof SearchLeaderboardEntriesParamsDTO
     */
    suggestions?: QueryLeaderboardParamsSuggestionsParamsDTO;
    /**
     * 
     * @type {number}
     * @memberof SearchLeaderboardEntriesParamsDTO
     */
    truncateStringTo?: number;
}

export function SearchLeaderboardEntriesParamsDTOFromJSON(json: any): SearchLeaderboardEntriesParamsDTO {
    return SearchLeaderboardEntriesParamsDTOFromJSONTyped(json, false);
}

export function SearchLeaderboardEntriesParamsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchLeaderboardEntriesParamsDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attributeFilters': !exists(json, 'attributeFilters') ? undefined : ((json['attributeFilters'] as Array<any>).map(QueryLeaderboardParamsAttributeFilterDTOFromJSON)),
        'experimentLeader': !exists(json, 'experimentLeader') ? undefined : json['experimentLeader'],
        'grouping': !exists(json, 'grouping') ? undefined : QueryLeaderboardParamsGroupingParamsDTOFromJSON(json['grouping']),
        'pagination': !exists(json, 'pagination') ? undefined : QueryLeaderboardParamsPaginationDTOFromJSON(json['pagination']),
        'query': !exists(json, 'query') ? undefined : NqlQueryParamsDTOFromJSON(json['query']),
        'sorting': !exists(json, 'sorting') ? undefined : QueryLeaderboardParamsSortingParamsDTOFromJSON(json['sorting']),
        'suggestions': !exists(json, 'suggestions') ? undefined : QueryLeaderboardParamsSuggestionsParamsDTOFromJSON(json['suggestions']),
        'truncateStringTo': !exists(json, 'truncateStringTo') ? undefined : json['truncateStringTo'],
    };
}

export function SearchLeaderboardEntriesParamsDTOToJSON(value?: SearchLeaderboardEntriesParamsDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attributeFilters': value.attributeFilters === undefined ? undefined : ((value.attributeFilters as Array<any>).map(QueryLeaderboardParamsAttributeFilterDTOToJSON)),
        'experimentLeader': value.experimentLeader,
        'grouping': QueryLeaderboardParamsGroupingParamsDTOToJSON(value.grouping),
        'pagination': QueryLeaderboardParamsPaginationDTOToJSON(value.pagination),
        'query': NqlQueryParamsDTOToJSON(value.query),
        'sorting': QueryLeaderboardParamsSortingParamsDTOToJSON(value.sorting),
        'suggestions': QueryLeaderboardParamsSuggestionsParamsDTOToJSON(value.suggestions),
        'truncateStringTo': value.truncateStringTo,
    };
}


