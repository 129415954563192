/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OperationError,
    OperationErrorFromJSON,
    OperationErrorFromJSONTyped,
    OperationErrorToJSON,
} from './';

/**
 * 
 * @export
 * @interface OperationErrorsListDTO
 */
export interface OperationErrorsListDTO {
    /**
     * 
     * @type {Array<OperationError>}
     * @memberof OperationErrorsListDTO
     */
    errors?: Array<OperationError>;
}

export function OperationErrorsListDTOFromJSON(json: any): OperationErrorsListDTO {
    return OperationErrorsListDTOFromJSONTyped(json, false);
}

export function OperationErrorsListDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OperationErrorsListDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'errors': !exists(json, 'errors') ? undefined : ((json['errors'] as Array<any>).map(OperationErrorFromJSON)),
    };
}

export function OperationErrorsListDTOToJSON(value?: OperationErrorsListDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'errors': value.errors === undefined ? undefined : ((value.errors as Array<any>).map(OperationErrorToJSON)),
    };
}


