/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SessionDTO,
    SessionDTOFromJSON,
    SessionDTOFromJSONTyped,
    SessionDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface PurchaseResultDTO
 */
export interface PurchaseResultDTO {
    /**
     * 
     * @type {SessionDTO}
     * @memberof PurchaseResultDTO
     */
    session?: SessionDTO;
}

export function PurchaseResultDTOFromJSON(json: any): PurchaseResultDTO {
    return PurchaseResultDTOFromJSONTyped(json, false);
}

export function PurchaseResultDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): PurchaseResultDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'session': !exists(json, 'session') ? undefined : SessionDTOFromJSON(json['session']),
    };
}

export function PurchaseResultDTOToJSON(value?: PurchaseResultDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'session': SessionDTOToJSON(value.session),
    };
}


