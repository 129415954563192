/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TimeDurationDTO,
    TimeDurationDTOFromJSON,
    TimeDurationDTOFromJSONTyped,
    TimeDurationDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface ProjectChartFiltersDTO
 */
export interface ProjectChartFiltersDTO {
    /**
     * Filter experiments since
     * @type {Date}
     * @memberof ProjectChartFiltersDTO
     */
    from?: Date;
    /**
     * Filtering owners
     * @type {Array<string>}
     * @memberof ProjectChartFiltersDTO
     */
    owners: Array<string>;
    /**
     * Filtering states
     * @type {Array<string>}
     * @memberof ProjectChartFiltersDTO
     */
    states: Array<ProjectChartFiltersDTOStatesEnum>;
    /**
     * Filtering tags
     * @type {Array<string>}
     * @memberof ProjectChartFiltersDTO
     */
    tags: Array<string>;
    /**
     * 
     * @type {TimeDurationDTO}
     * @memberof ProjectChartFiltersDTO
     */
    timePeriod?: TimeDurationDTO;
    /**
     * Filter experiments up to
     * @type {Date}
     * @memberof ProjectChartFiltersDTO
     */
    to?: Date;
}

export function ProjectChartFiltersDTOFromJSON(json: any): ProjectChartFiltersDTO {
    return ProjectChartFiltersDTOFromJSONTyped(json, false);
}

export function ProjectChartFiltersDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectChartFiltersDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'from': !exists(json, 'from') ? undefined : (new Date(json['from'])),
        'owners': json['owners'],
        'states': json['states'],
        'tags': json['tags'],
        'timePeriod': !exists(json, 'timePeriod') ? undefined : TimeDurationDTOFromJSON(json['timePeriod']),
        'to': !exists(json, 'to') ? undefined : (new Date(json['to'])),
    };
}

export function ProjectChartFiltersDTOToJSON(value?: ProjectChartFiltersDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'from': value.from === undefined ? undefined : (value.from.toISOString()),
        'owners': value.owners,
        'states': value.states,
        'tags': value.tags,
        'timePeriod': TimeDurationDTOToJSON(value.timePeriod),
        'to': value.to === undefined ? undefined : (value.to.toISOString()),
    };
}

/**
* @export
* @enum {string}
*/
export enum ProjectChartFiltersDTOStatesEnum {
    Running = 'running',
    Succeeded = 'succeeded',
    Aborted = 'aborted',
    Failed = 'failed'
}


