// serialized version of local storage from old domain

import { isLocalStorageCompatibleShape } from './local-storage-compatible';

export const version = 0 as const;
export type LocalStorageV0 = {
  version: typeof version;
  data: {};
};

export function isLocalStorageV0(input: any): input is LocalStorageV0 {
  return isLocalStorageCompatibleShape(input) && input.version === version;
}
