// Libs
import React from 'react';
import PropTypes from 'prop-types';
import {
  isArray,
  isUndefined,
} from 'lodash';


// neptune-core-ui
// eslint-disable-next-line no-restricted-imports
import { bemBlock } from 'neptune-core-ui/modules/bem';


// neptune-core-ui
// eslint-disable-next-line no-restricted-imports
import ncuiPropTypes from 'neptune-core-ui/helpers/prop-types';
// eslint-disable-next-line no-restricted-imports
import { getEventHandler } from 'neptune-core-ui/modules/events';
// eslint-disable-next-line no-restricted-imports
import { getValidProps } from 'neptune-core-ui/components/helpers';
// eslint-disable-next-line no-restricted-imports
import TableCell from 'neptune-core-ui/components/table/TableCell';


// Module
import './TableRow.less';

const propTypes = {
  children: PropTypes.node,
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
    PropTypes.func,
  ]),
  /**
   * Array of cell definitions.
   *
   * Cell definition is an object (see [TableCell](#tablecell) for more information):
   */
  columns: PropTypes.arrayOf(PropTypes.shape({
    align: PropTypes.oneOf(['left', 'center', 'right']),
    className: PropTypes.string,
    canSpanRows: PropTypes.bool,
    colSpan: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    dataKey: PropTypes.string,
    header: PropTypes.bool,
    renderer: PropTypes.func,
    renderParams: PropTypes.any,
    width: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    onClick: ncuiPropTypes.eventHandler,
  })),
  data: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
  hoverable: PropTypes.bool,
  /**
   * Can be event handler function or object with two properties:
   *
   * - `handler` -> event handler
   * - `payload` -> object that will be passed to handler
   *
   * Event handler will be called with arguments:
   *
   * - `event` -> [SyntheticEvent](https://facebook.github.io/react/docs/events.html)
   * - `params` -> `object` (defaults to `{}` *empty object*)
   *   - `data` -> value of `data` property
   *   - `payload` -> value of `onClick.payload`
   */
  onClick: ncuiPropTypes.eventHandler,

  /**
   * Can be event handler function or object with two properties:
   *
   * - `handler` -> event handler
   * - `payload` -> object that will be passed to handler
   *
   * Event handler will be called with arguments:
   *
   * - `event` -> [SyntheticEvent](https://facebook.github.io/react/docs/events.html)
   * - `params` -> `object` (defaults to `{}` *empty object*)
   *   - `data` -> value of `data` property
   *   - `payload` -> value of `onMouseOut.payload`
   */
  onMouseOut: ncuiPropTypes.eventHandler,

  /**
   * Can be event handler function or object with two properties:
   *
   * - `handler` -> event handler
   * - `payload` -> object that will be passed to handler
   *
   * Event handler will be called with arguments:
   *
   * - `event` -> [SyntheticEvent](https://facebook.github.io/react/docs/events.html)
   * - `params` -> `object` (defaults to `{}` *empty object*)
   *   - `data` -> value of `data` property
   *   - `payload` -> value of `onMouseOver.payload`
   */
  onMouseOver: ncuiPropTypes.eventHandler,
};

const block = bemBlock('n-table-row');


const TableRow = ({
  children,
  className,
  columns,
  data,
  hoverable,
  onClick,
  onMouseOut,
  onMouseOver,
  ...props
}) => {
  const onClickHandler = getEventHandler(onClick, { data });
  const onMouseOutHandler = getEventHandler(onMouseOut, { data });
  const onMouseOverHandler = getEventHandler(onMouseOver, { data });
  const ownProps = {
    className: block({
      modifiers: {
        hoverable,
      },
      extra: [
        {'clickable': !isUndefined(onClickHandler)},
        className,
      ],
      data,
    }),
    onClick: onClickHandler,
    onMouseOut: onMouseOutHandler,
    onMouseOver: onMouseOverHandler,
  };
  const rowContent = renderCells(data, columns, children);

  return (
    <tr
      data-role="table-row"
      {...props}
      {...ownProps}
    >
      {rowContent}
    </tr>
  );
};

TableRow.propTypes = propTypes;


const validCellProps = [
  'align',
  'className',
  'colSpan',
  'dataKey',
  'header',
  'renderer',
  'renderParams',
  'width',
  'dataAttributes',
  'onClick',
];


function renderCells(data, columns, children) {
  if (isUndefined(data)) {
    return children;
  }

  if (isUndefined(columns)) {
    if (isArray(data)) {
      return data.map((value, index) => (
        <TableCell key={index} data={value} />
      ));
    }
    throw new ReferenceError('columns is missing - cannot interpret data');
  }

  return columns.map((column, index) => {
    const canSpan = column.canSpanRows;

    if (canSpan && data.rowSpan === 0) {
      return null;
    }

    return (
      <TableCell
        key={index}
        {...getValidProps(column, validCellProps)}
        data={data}
        columnIndex={index}
        rowSpan={canSpan ? data.rowSpan : undefined}
      />
    );
  });
}

export default TableRow;
