import React from 'react';
import { bemBlock } from '../../modules/bem';
import { ButtonGroup } from '../button-group/ButtonGroup';
import { Button, ButtonVariant } from '../button/Button';
import { Icon } from '../icon/Icon';
import { LayoutElement } from '../layout-element/LayoutElement';
import { LayoutRow, LayoutRowProps } from '../layout-row/LayoutRow';
import './Pagination.less';

const block = bemBlock('n-Pagination');

export type OffsetBasedPaginationPageParams = {
  limit: number;
  offset: number;
  /** If `total` is not exceeding the `limit`, `Pagination` will not be rendered. */
  total: number;
};

type TokenBasedPaginationPageParams = {
  beforeToken?: string;
  continuationToken?: string;
  total: number;
};

export type TokenBasedPaginationPageTarget =
  | { beforeToken: string }
  | { continuationToken: string };

export const isTokenBasedPaginationTarget = (
  optionsOrTargetPageToken: OffsetBasedPaginationPageParams | TokenBasedPaginationPageTarget,
): optionsOrTargetPageToken is TokenBasedPaginationPageTarget =>
  'beforeToken' in optionsOrTargetPageToken || 'continuationToken' in optionsOrTargetPageToken;

export const isOffsetBasedPaginationTarget = (
  optionsOrTargetPageToken: OffsetBasedPaginationPageParams | TokenBasedPaginationPageTarget,
): optionsOrTargetPageToken is OffsetBasedPaginationPageParams =>
  !isTokenBasedPaginationTarget(optionsOrTargetPageToken);

export type TPaginationPageParams =
  | OffsetBasedPaginationPageParams
  | TokenBasedPaginationPageParams;

export const isOffsetBasedPagination = (
  input: TPaginationPageParams,
): input is OffsetBasedPaginationPageParams =>
  'offset' in input &&
  'limit' in input &&
  Number.isFinite(input.offset) &&
  Number.isFinite(input.limit);

export type OffsetBasedPaginationPageChangeHandler = (
  options: OffsetBasedPaginationPageParams,
) => void;
export type TokenBasedPaginationPageChangeHandler = (
  target: TokenBasedPaginationPageTarget,
) => void;

export type TPaginationPageChangeHandler = (
  optionsOrTargetPageToken: OffsetBasedPaginationPageParams | TokenBasedPaginationPageTarget,
) => void;
export type TPaginationRangeRenderer = (options: TPaginationPageParams) => React.ReactNode;

export type PaginationProps = Omit<
  LayoutRowProps<{}>,
  | 'alignItems'
  | 'component'
  | 'height'
  | 'justifyContent'
  | 'overflow'
  | 'reversed'
  | 'spacedChildren'
  | 'withGutter'
  | 'withPadding'
  | 'withCustomScrollbar'
  | 'wrap'
> &
  TPaginationPageParams & {
    /**
     * If `hideIfSinglePage` is set to `true` and `total` is not exceeding the `limit`,
     * `Pagination` will not be rendered.
     */
    hideIfSinglePage?: boolean;
    onPageChange?: TPaginationPageChangeHandler;
    onPageOffsetBasedChange?: OffsetBasedPaginationPageChangeHandler;
    onPageTokenBasedChange?: TokenBasedPaginationPageChangeHandler;
    /**
     * How the range is rendered is defined by `Pagination.rangeRenderer`.
     * You may define your own range renderer with `rangeRenderer` property.
     *
     * ({ limit: number, offset: number, total: number }) => React.ReactNode
     */
    rangeRenderer?: TPaginationRangeRenderer;
    showRange?: boolean;
    size?: 'md' | 'lg';
    variant: ButtonVariant;
  };

export class Pagination extends React.Component<PaginationProps> {
  public static defaultProps = {
    alignItems: 'center',
    hideIfSinglePage: true,
    size: 'md',
    span: 'auto',
    spacedChildren: 'sm',
    variant: 'basic',
  };

  public static rangeRenderer: TPaginationRangeRenderer = (options) => {
    if (!isOffsetBasedPagination(options)) {
      return null;
    }

    const { limit, offset, total } = options;

    return `${Math.min(total, offset + 1)} - ${Math.min(total, offset + limit)} of ${total}`;
  };

  get isFirstPage(): boolean {
    if (!isOffsetBasedPagination(this.props)) {
      return !this.props.beforeToken;
    }

    return this.props.offset === 0;
  }

  get isLastPage(): boolean {
    if (!isOffsetBasedPagination(this.props)) {
      return !this.props.continuationToken;
    }

    const { total, offset, limit } = this.props;
    return total - offset <= limit;
  }

  get isSinglePage(): boolean {
    if (!isOffsetBasedPagination(this.props)) {
      return this.isFirstPage && this.isLastPage;
    }

    const { total, offset, limit } = this.props;
    return offset === 0 && total <= limit;
  }

  goToNextPage = () => {
    if (!isOffsetBasedPagination(this.props)) {
      const { onPageChange, onPageTokenBasedChange, continuationToken } = this.props;
      continuationToken && onPageChange?.({ continuationToken });
      continuationToken && onPageTokenBasedChange?.({ continuationToken });
      return;
    }

    this.moveBy(this.props.limit);
  };

  goToPrevPage = () => {
    if (!isOffsetBasedPagination(this.props)) {
      const { onPageChange, onPageTokenBasedChange, beforeToken } = this.props;
      beforeToken && onPageChange?.({ beforeToken });
      beforeToken && onPageTokenBasedChange?.({ beforeToken });
      return;
    }

    this.moveBy(-this.props.limit);
  };

  private moveBy(diff: number) {
    if (!isOffsetBasedPagination(this.props)) {
      return;
    }

    const { limit, offset: baseOffset, onPageChange, onPageOffsetBasedChange, total } = this.props;
    const offset = Math.max(baseOffset + diff, 0);
    onPageChange?.({ limit, offset, total });
    onPageOffsetBasedChange?.({ limit, offset, total });
  }

  renderRange() {
    if (!isOffsetBasedPagination(this.props)) {
      return;
    }

    const {
      limit,
      offset,
      total,
      rangeRenderer = Pagination.rangeRenderer,
      showRange,
    } = this.props;

    if (showRange === true) {
      return <LayoutElement>{rangeRenderer({ limit, offset, total })}</LayoutElement>;
    }
  }

  render() {
    const { className, hideIfSinglePage, size, variant } = this.props;

    let passProps;

    if (isOffsetBasedPagination(this.props)) {
      const {
        className,
        hideIfSinglePage,
        limit,
        offset,
        onPageChange,
        onPageOffsetBasedChange,
        onPageTokenBasedChange,
        rangeRenderer,
        showRange,
        size,
        total,
        variant,
        ...restProps
      } = this.props;
      passProps = restProps;
    } else {
      const {
        className,
        hideIfSinglePage,
        beforeToken,
        continuationToken,
        onPageChange,
        onPageOffsetBasedChange,
        onPageTokenBasedChange,
        rangeRenderer,
        showRange,
        size,
        total,
        variant,
        ...restProps
      } = this.props;
      passProps = restProps;
    }

    if (this.isSinglePage && hideIfSinglePage) {
      return null;
    }

    const ownProps = {
      className: block({ extra: className }),
    };

    return (
      <LayoutRow {...ownProps} {...passProps}>
        {this.renderRange()}
        <ButtonGroup>
          <Button
            square
            size={size}
            disabled={this.isFirstPage}
            variant={variant}
            data-role="pagination-button-left"
            onClick={this.goToPrevPage}
          >
            <Icon glyph="chevron-left" fixedWidth />
          </Button>
          <Button
            square
            size={size}
            disabled={this.isLastPage}
            variant={variant}
            data-role="pagination-button-right"
            onClick={this.goToNextPage}
          >
            <Icon glyph="chevron-right" fixedWidth />
          </Button>
        </ButtonGroup>
      </LayoutRow>
    );
  }
}
