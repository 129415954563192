import React from 'react';
import { bemBlock } from '../../modules/bem';
import { Button } from '../button/Button';
import { Icon } from '../icon/Icon';

const block = bemBlock('n-modal-close');

interface ModalCloseProps {
  className?: string;
  onClose?: () => void;
}

const ModalClose: React.FC<ModalCloseProps> = ({ className, onClose }) => {
  const cssClass = block({ extra: className });
  return (
    <Button className={cssClass} variant="secondary-text" size="lg" onClick={onClose} square>
      <Icon glyph="cross" />
    </Button>
  );
};

export default ModalClose;
