/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OrganizationInvitationDTO,
    OrganizationInvitationDTOFromJSON,
    OrganizationInvitationDTOFromJSONTyped,
    OrganizationInvitationDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface OrganizationInvitationsDTO
 */
export interface OrganizationInvitationsDTO {
    /**
     * 
     * @type {Array<OrganizationInvitationDTO>}
     * @memberof OrganizationInvitationsDTO
     */
    invitations: Array<OrganizationInvitationDTO>;
}

export function OrganizationInvitationsDTOFromJSON(json: any): OrganizationInvitationsDTO {
    return OrganizationInvitationsDTOFromJSONTyped(json, false);
}

export function OrganizationInvitationsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationInvitationsDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'invitations': ((json['invitations'] as Array<any>).map(OrganizationInvitationDTOFromJSON)),
    };
}

export function OrganizationInvitationsDTOToJSON(value?: OrganizationInvitationsDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'invitations': ((value.invitations as Array<any>).map(OrganizationInvitationDTOToJSON)),
    };
}


