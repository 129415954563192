// Libs
import React from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line no-restricted-imports
import classNames from 'classnames';


// neptune-core-ui
// eslint-disable-next-line no-restricted-imports
import { getWidthStyle } from 'neptune-core-ui/components/helpers';
// eslint-disable-next-line no-restricted-imports
import ncuiPropTypes from 'neptune-core-ui/helpers/prop-types';
// eslint-disable-next-line no-restricted-imports
import { getEventHandler } from 'neptune-core-ui/modules/events';
// eslint-disable-next-line no-restricted-imports
import { getCellData } from 'neptune-core-ui/components/table/helpers';
// eslint-disable-next-line no-restricted-imports
import {
  simpleRenderer,
  WithPaddingRenderer,
} from 'neptune-core-ui/components/table/renderers';


// Module
import './TableCell.less';

const propTypes = {
  /** Align of the content */
  align: PropTypes.oneOf(['left', 'center', 'right']),
  children: PropTypes.node,
  className: PropTypes.string,
  colSpan: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  data: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
  ]),
  dataKey: PropTypes.string,
  /** If set to true, `<th>` element will be generated */
  header: PropTypes.bool,
  /**
   * Renderer will be called with one argument
   * - `options` - object with the following properties:
   *    - `data` - value of *data* property
   *    - `dataKey`- value of *dataKey* property
   *    - `children` - Content passed to renderer component
   *    - `params` - value of *renderParams* property
   */
  renderer: PropTypes.func,
  renderParams: PropTypes.any,
  rowSpan: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  /** Number will be converted to pixels (10 -> 10px) */
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  /**
   * Can be event handler function or object with two properties:
   * - `handler` - event handler function
   * - `payload` - object that will be passed to handler
   *
   * Event handler will be called with arguments:
   * - `event` - [SyntheticEvent](https://facebook.github.io/react/docs/events.html)
   * - `params` - `object` (defaults to `{}` *empty object*)
   *   - `data` - value of `data` property
   *   - `dataKey`- value of `dataKey` property
   *   - `value` - evaluated value before passing it to the `renderer`
   *   - `payload` - value of `onClick.payload`
   */
  onClick: ncuiPropTypes.eventHandler,

  dataAttributes: PropTypes.object,
};


const defaultProps = {
  align: 'left',
  header: false,
  renderer: (props) => (
    <WithPaddingRenderer>
      {simpleRenderer(props)}
    </WithPaddingRenderer>
  ),
};


export const TableCell = ({
  align,
  children,
  className,
  colSpan,
  columnIndex,
  data,
  dataKey,
  header,
  renderer,
  renderParams,
  rowSpan,
  width,
  dataAttributes = {},
  onClick,
}) => {
  const nodeTag = header ? 'th' : 'td';
  const onClickHandler = getEventHandler(
    onClick,
    {
      value: getCellData({data, dataKey, children}),
      data,
      dataKey,
    },
  );
  const cellProps = {
    className: classNames(
      'n-table-cell',
      {'n-table-cell--header': header},
      `n-table-cell--align-${align}`,
      {'clickable': onClickHandler},
      className,
    ),
    colSpan,
    rowSpan,
    style: {...getWidthStyle(width)},
    'data-column': columnIndex,
    onClick: onClickHandler,
    ...dataAttributes,
  };

  return React.createElement(
    nodeTag,
    cellProps,
    React.createElement(
      renderer,
      {
        data,
        dataKey,
        children,
        params: renderParams,
      },
    ),
  );
};

TableCell.propTypes = propTypes;
TableCell.defaultProps = defaultProps;


export default TableCell;
