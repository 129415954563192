/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SeriesViewPointDTO,
    SeriesViewPointDTOFromJSON,
    SeriesViewPointDTOFromJSONTyped,
    SeriesViewPointDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface SeriesViewDTO
 */
export interface SeriesViewDTO {
    /**
     * 
     * @type {string}
     * @memberof SeriesViewDTO
     */
    attributePath: string;
    /**
     * 
     * @type {boolean}
     * @memberof SeriesViewDTO
     */
    downsampled: boolean;
    /**
     * 
     * @type {number}
     * @memberof SeriesViewDTO
     */
    firstPointIndex: number;
    /**
     * 
     * @type {string}
     * @memberof SeriesViewDTO
     */
    holderIdentifier: string;
    /**
     * 
     * @type {string}
     * @memberof SeriesViewDTO
     */
    holderType: string;
    /**
     * 
     * @type {number}
     * @memberof SeriesViewDTO
     */
    lastPointIndex: number;
    /**
     * 
     * @type {number}
     * @memberof SeriesViewDTO
     */
    maxY: number;
    /**
     * 
     * @type {number}
     * @memberof SeriesViewDTO
     */
    minY: number;
    /**
     * 
     * @type {string}
     * @memberof SeriesViewDTO
     */
    projectId: string;
    /**
     * 
     * @type {string}
     * @memberof SeriesViewDTO
     */
    projectName: string;
    /**
     * 
     * @type {Array<SeriesViewPointDTO>}
     * @memberof SeriesViewDTO
     */
    values: Array<SeriesViewPointDTO>;
}

export function SeriesViewDTOFromJSON(json: any): SeriesViewDTO {
    return SeriesViewDTOFromJSONTyped(json, false);
}

export function SeriesViewDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): SeriesViewDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attributePath': json['attributePath'],
        'downsampled': json['downsampled'],
        'firstPointIndex': json['firstPointIndex'],
        'holderIdentifier': json['holderIdentifier'],
        'holderType': json['holderType'],
        'lastPointIndex': json['lastPointIndex'],
        'maxY': json['maxY'],
        'minY': json['minY'],
        'projectId': json['projectId'],
        'projectName': json['projectName'],
        'values': ((json['values'] as Array<any>).map(SeriesViewPointDTOFromJSON)),
    };
}

export function SeriesViewDTOToJSON(value?: SeriesViewDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attributePath': value.attributePath,
        'downsampled': value.downsampled,
        'firstPointIndex': value.firstPointIndex,
        'holderIdentifier': value.holderIdentifier,
        'holderType': value.holderType,
        'lastPointIndex': value.lastPointIndex,
        'maxY': value.maxY,
        'minY': value.minY,
        'projectId': value.projectId,
        'projectName': value.projectName,
        'values': ((value.values as Array<any>).map(SeriesViewPointDTOToJSON)),
    };
}


