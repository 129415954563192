// Libs
import React from 'react';
import { isFunction } from 'lodash';

import { bemBlock } from '../../modules/bem';
import { Icon } from '../icon/Icon';
import { LayoutRow, LayoutRowProps } from '../layout-row/LayoutRow';

// Module
import './LegacyEmblem.less';

declare module 'react' {
  interface CSSProperties {
    '--legacy-emblem-custom-color'?: string;
  }
}

const block = bemBlock('n-LegacyEmblem');

export type LegacyEmblemVariant = 'default' | 'framed';

export type LegacyEmblemProps = Omit<
  LayoutRowProps<{}>,
  'alignItems' | 'component' | 'height' | 'inline' | 'reversed' | 'width' | 'withGutter' | 'wrap'
> & {
  iconClassname?: string;
  iconStyle?: React.CSSProperties;
  error?: boolean;
  success?: boolean;
  selected?: boolean;
  title?: string;
  variant?: LegacyEmblemVariant;
  onRemove?: (event: any) => void;
};

export const LegacyEmblem: React.FC<LegacyEmblemProps> = ({
  className,
  iconClassname,
  iconStyle,
  error,
  success,
  selected,
  title,
  variant,
  color,
  children,
  onClick,
  onRemove,
  ...props
}) => {
  const cssClass = block({
    modifiers: {
      error,
      success,
      selected,
      selectable: isFunction(onClick),
      variant,
    },
    extra: className,
  });

  const iconCssClass = block({
    element: 'icon',
    extra: iconClassname,
    modifiers: {
      variant,
    },
  });

  return (
    <LayoutRow
      style={{ '--legacy-emblem-custom-color': color }}
      alignItems="center"
      className={cssClass}
      inline
      onClick={onClick}
      span="shrink"
      title={title}
      withGutter="sm"
      {...props}
    >
      {children}
      {onRemove && (
        <Icon
          style={iconStyle}
          className={iconCssClass}
          size={variant === 'framed' ? 'lg' : undefined}
          glyph={variant === 'framed' ? 'times-circle' : 'times'}
          onClick={onRemove}
          data-role="remove-action"
        />
      )}
    </LayoutRow>
  );
};
