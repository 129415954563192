// Libs
import React from 'react';
import { isNumber } from 'lodash';

// Module
export type ActiveListElementChildrenProps<T> = {
  index: number;
  entry: T;
  isSelected: boolean;
  select(event: React.MouseEvent | KeyboardEvent): void;
};

type ActiveListElementProviderProps<T> = {
  entries: T[];
  onActivate?(event: KeyboardEvent, entry: T, index: number): void;
  onKeyDown?(event: KeyboardEvent): void;
  onSelect?(event: React.MouseEvent | KeyboardEvent, entry: T, index: number): void;
  children(props: ActiveListElementChildrenProps<T>): React.ReactElement;
};

type ActiveListElementProviderState = {
  selected: number;
};

export class ActiveListElementProvider<T> extends React.PureComponent<
  ActiveListElementProviderProps<T>,
  ActiveListElementProviderState
> {
  state = {
    selected: 0,
  };

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  handleKeyDown = (event: KeyboardEvent) => {
    switch (event.key) {
      case 'ArrowDown':
        this.next(event);
        this.props.onKeyDown?.(event);
        break;

      case 'ArrowUp':
        this.prev(event);
        this.props.onKeyDown?.(event);
        break;

      case 'Enter':
        this.onActivate(event);
        this.props.onKeyDown?.(event);
        break;

      default:
        break;
    }
  };

  next = (event: KeyboardEvent) => {
    const { entries } = this.props;
    const { selected } = this.state;

    const index = Math.min(selected + 1, entries.length - 1);
    this.select(event, index);
  };

  prev = (event: KeyboardEvent) => {
    const { selected } = this.state;

    const index = Math.max(selected - 1, 0);
    this.select(event, index);
  };

  select = (event: React.MouseEvent | KeyboardEvent, selected: number) => {
    const { entries, onSelect } = this.props;

    // ensure that element to be selected exist
    const toSelect = entries[selected] != null ? selected : 0;

    if (this.state.selected === toSelect) {
      return;
    }

    this.setState(
      {
        selected: isNumber(toSelect) ? toSelect : 0,
      },
      () => onSelect?.(event, entries[this.state.selected], toSelect),
    );
  };

  onActivate = (event: KeyboardEvent) => {
    const { selected } = this.state;
    const { entries, onActivate } = this.props;

    const selectedEntry = entries[selected] || entries[0];

    if (selectedEntry) {
      onActivate?.(event, selectedEntry, selected);
    }
  };

  render() {
    const { children, entries } = this.props;

    const { selected } = this.state;

    return (
      <React.Fragment>
        {entries.map((entry, index) =>
          children({
            index,
            entry,
            isSelected: index === selected,
            select: (event: React.MouseEvent | KeyboardEvent) => this.select(event, index),
          }),
        )}
      </React.Fragment>
    );
  }
}
