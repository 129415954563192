/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FloatPointValueDTO,
    FloatPointValueDTOFromJSON,
    FloatPointValueDTOFromJSONTyped,
    FloatPointValueDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface FloatSeriesValuesDTO
 */
export interface FloatSeriesValuesDTO {
    /**
     * 
     * @type {number}
     * @memberof FloatSeriesValuesDTO
     */
    totalItemCount: number;
    /**
     * 
     * @type {Array<FloatPointValueDTO>}
     * @memberof FloatSeriesValuesDTO
     */
    values: Array<FloatPointValueDTO>;
}

export function FloatSeriesValuesDTOFromJSON(json: any): FloatSeriesValuesDTO {
    return FloatSeriesValuesDTOFromJSONTyped(json, false);
}

export function FloatSeriesValuesDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): FloatSeriesValuesDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalItemCount': json['totalItemCount'],
        'values': ((json['values'] as Array<any>).map(FloatPointValueDTOFromJSON)),
    };
}

export function FloatSeriesValuesDTOToJSON(value?: FloatSeriesValuesDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalItemCount': value.totalItemCount,
        'values': ((value.values as Array<any>).map(FloatPointValueDTOToJSON)),
    };
}


