import { format } from 'date-fns';

export function formatDate(date: Date | number): string {
  return format(date, 'yyyy/MM/dd HH:mm:ss');
}

export function formatDateReadableShort(date: Date | number): string {
  return format(date, 'PPP');
}

export function formatDateShort(date: Date | number): string {
  return format(date, 'yyyy/MM/dd');
}

export function formatTimeOnly(date: Date | number): string {
  return format(date, 'HH:mm:ss');
}
