import config from 'config';

const deploymentModes = {
  ON_PREMISES: 'on-premises',
  CLASSIC: 'classic',
};

export function isOnPremiseDeployment() {
  return config.neptuneDeploymentMode === deploymentModes.ON_PREMISES;
}

export function isClassicDeployment() {
  return config.neptuneDeploymentMode === deploymentModes.CLASSIC;
}
