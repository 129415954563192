/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AttributeStyleSettingsDTO,
    AttributeStyleSettingsDTOFromJSON,
    AttributeStyleSettingsDTOFromJSONTyped,
    AttributeStyleSettingsDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface AttributesStyleSettingsResultDTO
 */
export interface AttributesStyleSettingsResultDTO {
    /**
     * 
     * @type {Array<AttributeStyleSettingsDTO>}
     * @memberof AttributesStyleSettingsResultDTO
     */
    settings?: Array<AttributeStyleSettingsDTO>;
}

export function AttributesStyleSettingsResultDTOFromJSON(json: any): AttributesStyleSettingsResultDTO {
    return AttributesStyleSettingsResultDTOFromJSONTyped(json, false);
}

export function AttributesStyleSettingsResultDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): AttributesStyleSettingsResultDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'settings': !exists(json, 'settings') ? undefined : ((json['settings'] as Array<any>).map(AttributeStyleSettingsDTOFromJSON)),
    };
}

export function AttributesStyleSettingsResultDTOToJSON(value?: AttributesStyleSettingsResultDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'settings': value.settings === undefined ? undefined : ((value.settings as Array<any>).map(AttributeStyleSettingsDTOToJSON)),
    };
}


