/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FloatSeriesAttributeConfigDTO
 */
export interface FloatSeriesAttributeConfigDTO {
    /**
     * 
     * @type {number}
     * @memberof FloatSeriesAttributeConfigDTO
     */
    max?: number;
    /**
     * 
     * @type {number}
     * @memberof FloatSeriesAttributeConfigDTO
     */
    min?: number;
    /**
     * 
     * @type {string}
     * @memberof FloatSeriesAttributeConfigDTO
     */
    unit?: string;
}

export function FloatSeriesAttributeConfigDTOFromJSON(json: any): FloatSeriesAttributeConfigDTO {
    return FloatSeriesAttributeConfigDTOFromJSONTyped(json, false);
}

export function FloatSeriesAttributeConfigDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): FloatSeriesAttributeConfigDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'max': !exists(json, 'max') ? undefined : json['max'],
        'min': !exists(json, 'min') ? undefined : json['min'],
        'unit': !exists(json, 'unit') ? undefined : json['unit'],
    };
}

export function FloatSeriesAttributeConfigDTOToJSON(value?: FloatSeriesAttributeConfigDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'max': value.max,
        'min': value.min,
        'unit': value.unit,
    };
}


