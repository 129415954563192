import React from 'react';

import { Text } from '../text/Text';

export type CardHeaderTextProps = {
  className?: string;
  children?: React.ReactNode;
};

export const CardHeaderText: React.FC<CardHeaderTextProps> = ({ children }) => {
  return <Text size="md" fontWeight="bold" children={children} />;
};
