export type Unit = 'TB' | 'GB' | 'MB' | 'KB' | 'B';

const units: Unit[] = ['B', 'KB', 'MB', 'GB', 'TB'];

export function toUnit(value: string, fallbackValue: Unit): Unit {
  const index = units.indexOf(value as any);
  return units[index] ?? fallbackValue;
}

export function convertSizeToUnit(
  value: number,
  outputUnit: Unit,
  inputUnit: Unit = 'B',
  decimal = false,
): number {
  const outputIndex = units.indexOf(outputUnit);
  const multiplier = decimal ? 1000 : 1024;
  let inputIndex = units.indexOf(inputUnit);

  if (inputIndex < outputIndex) {
    while (inputIndex < outputIndex) {
      value /= multiplier;
      inputIndex += 1;
    }
  } else {
    while (inputIndex > outputIndex) {
      value *= multiplier;
      inputIndex -= 1;
    }
  }

  if (outputUnit === 'B') {
    value = Math.round(value);
  }

  return value;
}

const sizeRegex = /^(\d+(?:\.\d+)?)\s*([KMGT]?B)?$/i;

/**
 * Parses a size string to a numeric value.
 * A unitless value is treated as being bytes.
 * An output unit can be specified (defaults to bytes).
 * E.g. "1KB" -> 1024
 * @param value
 * @param outputUnit
 */
export function parseSize(value: string, outputUnit: Unit = 'B'): number | undefined {
  const match = sizeRegex.exec(value);

  if (!match || !match[1]) {
    return;
  }

  return convertSizeToUnit(Number(match[1]), outputUnit, (match[2]?.toUpperCase() as Unit) || 'B');
}

/**
 * Finds the best unit to render given bytes value in a human-readable fashion.
 * E.g. 3 * 1024 * 1024 -> "MB"
 * @param bytes
 * @param availableUnits
 */
export function findBestSizeUnit(bytes: number, availableUnits: Unit[] = units) {
  let i = 0;
  let adjustedValue = bytes;

  while (adjustedValue >= 1024 && i < availableUnits.length - 1) {
    adjustedValue /= 1024;
    i += 1;
  }

  return availableUnits[i];
}
