/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Point
 */
export interface Point {
    /**
     * 
     * @type {boolean}
     * @memberof Point
     */
    interpolation: boolean;
    /**
     * 
     * @type {number}
     * @memberof Point
     */
    maxY?: number;
    /**
     * 
     * @type {number}
     * @memberof Point
     */
    minY?: number;
    /**
     * 
     * @type {number}
     * @memberof Point
     */
    x: number;
    /**
     * 
     * @type {number}
     * @memberof Point
     */
    y?: number;
}

export function PointFromJSON(json: any): Point {
    return PointFromJSONTyped(json, false);
}

export function PointFromJSONTyped(json: any, ignoreDiscriminator: boolean): Point {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'interpolation': json['interpolation'],
        'maxY': !exists(json, 'maxY') ? undefined : json['maxY'],
        'minY': !exists(json, 'minY') ? undefined : json['minY'],
        'x': json['x'],
        'y': !exists(json, 'y') ? undefined : json['y'],
    };
}

export function PointToJSON(value?: Point | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'interpolation': value.interpolation,
        'maxY': value.maxY,
        'minY': value.minY,
        'x': value.x,
        'y': value.y,
    };
}


