import React from 'react';

type ViewportCallback = (visible: boolean) => unknown;

const useCreateViewportContext = () => {
  const viewportContextRef = React.useRef<ViewportContext>(new ViewportContext());
  const viewportContext = viewportContextRef.current;

  React.useEffect(() => {
    return () => viewportContext.destroy();
  }, [viewportContext]);

  return viewportContext;
};

export class ViewportContext {
  private readonly mapping = new Map<Element, { callback: ViewportCallback; last: boolean }>();
  private readonly observer: IntersectionObserver;

  static useCreateViewportContext = useCreateViewportContext;

  constructor(rootMargin?: string, root?: Element | Document | null) {
    this.observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const data = this.mapping.get(entry.target);

          if (!data) {
            return;
          }

          if (data.last !== entry.isIntersecting) {
            data.callback && data.callback.call(null, entry.isIntersecting);
            data.last = entry.isIntersecting;
          }
        });
      },
      { rootMargin, root },
    );
  }

  destroy() {
    this.mapping.clear();
    this.observer.disconnect();
  }

  add(element: Element, callback: ViewportCallback) {
    this.mapping.set(element, { callback, last: false });
    this.observer.observe(element);
  }

  remove(element: Element) {
    this.mapping.delete(element);
    this.observer.unobserve(element);
  }
}
