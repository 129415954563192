import { CustomIconDefinition } from '../types';

export const nepGrid: CustomIconDefinition = {
  prefix: 'nep',
  iconName: 'grid',
  icon: [
    512,
    512,
    [],
    'e003',
    'M0 375.269h136.731V512H0V375.269zm188.006 0h136.731V512H188.006V375.269zM375.269 512V375.269H512V512H375.269zM0 188.006h136.731v135.988H0V188.006zm188.006 0h136.731v135.988H188.006V188.006zm187.263 0H512v135.988H375.269V188.006zM0 0h136.731v136.731H0V0zm188.006 0h136.731v136.731H188.006V0zm187.263 0H512v136.731H375.269V0z',
  ],
};
