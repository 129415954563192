// Libs
import { isArray, isString } from 'lodash';

// Module
export function joinClassNames(...classes: Array<string | null | undefined>) {
  return classes.filter((item) => item && item.trim()).join(' ');
}

const EL_SEP = '__';
const MOD_SEP = '--';
const VAL_SEP = '-';

type EntryValue = string | number | boolean | null | undefined;
type Entries = Record<string, EntryValue>;
type RawEntry = Entries | EntryValue;
type RawEntires = RawEntry[] | Entries | string;

export class BEM {
  private block: string;
  private element?: string;
  private modifiers: Entries = {};
  private extra: Entries = {};

  get baseClassName() {
    return this.element ? `${this.block}${EL_SEP}${this.element}` : this.block;
  }

  get modifierClassNames() {
    const prefix = this.baseClassName + MOD_SEP;

    return entriesToArray(this.modifiers, keyValueReducer).map((item) => `${prefix}${item}`);
  }

  get extraClassNames() {
    return entriesToArray(this.extra, keyReducer);
  }

  constructor(block: string, element?: string, modifiers?: RawEntires, extra?: RawEntires) {
    this.block = block;

    const validElement = element && element.trim();

    if (validElement) {
      this.element = validElement;
    }

    if (modifiers) {
      if (isArray(modifiers)) {
        this.is(...modifiers);
      } else {
        this.is(modifiers);
      }
    }

    if (extra) {
      if (isArray(extra)) {
        this.add(...extra);
      } else {
        this.add(extra);
      }
    }
  }

  add(...entries: RawEntry[]) {
    this.extra = mergeEntries(this.extra, entries);

    return this;
  }

  el(element: string, modifiers?: RawEntires, extra?: RawEntires) {
    return new BEM(this.block, element, modifiers, extra);
  }

  is(...entries: RawEntry[]) {
    this.modifiers = mergeEntries(this.modifiers, entries);

    return this;
  }

  toString() {
    return [this.baseClassName, ...this.modifierClassNames, ...this.extraClassNames].join(' ');
  }
}

function stringToArray(value: string) {
  return value.split(/\s+/g).filter((word) => !!word);
}

function sanitizeValue(value: EntryValue) {
  if (isString(value)) {
    return value.trim();
  }

  return value;
}

function setEntry(collection: Entries, [entry, value]: [string, EntryValue]) {
  const _value = sanitizeValue(value);
  const keys = stringToArray(entry);
  keys.forEach((key) => {
    collection[key] = _value;
  });

  return collection;
}

function mergeEntries(entries: Entries, changes: RawEntry[]) {
  return changes.reduce((updatedEntries: Entries, change) => {
    if (typeof change === 'string' || typeof change === 'number') {
      return setEntry(updatedEntries, ['' + change, true]);
    }

    if (change === null) {
      return updatedEntries;
    }

    if (typeof change === 'object') {
      const newEntries = Object.entries(change).reduce(setEntry, {} as Entries);

      return { ...entries, ...newEntries };
    }

    return updatedEntries;
  }, entries);
}

type EntryReducer = (items: string[], [key, value]: [string, EntryValue]) => string[];

function entriesToArray(entries: Entries, reducer: EntryReducer) {
  return Object.entries(entries).reduce(reducer, [] as string[]);
}

function getValue(value: EntryValue) {
  return typeof value === 'number' ? '' + value : value;
}

function keyValueReducer(items: string[], [key, value]: [string, EntryValue]) {
  const realValue = getValue(value);

  if (realValue) {
    if (typeof realValue === 'string') {
      items.push(`${key}${VAL_SEP}${realValue}`);
    } else {
      items.push(key);
    }
  }

  return items;
}

function keyReducer(items: string[], [key, value]: [string, EntryValue]) {
  if (getValue(value)) {
    items.push(key);
  }

  return items;
}

type ElementDefinition =
  | string
  | {
      element?: string;
      modifiers?: RawEntires;
      extra?: RawEntires;
    };

type BlockHelper = (options?: ElementDefinition) => string;

export function bemBlock(block: string): BlockHelper {
  return (options?: ElementDefinition) => {
    if (!options) {
      return new BEM(block).toString();
    }

    if (typeof options === 'string') {
      return new BEM(block, options).toString();
    }

    const { element, modifiers, extra } = options;

    return new BEM(block, element, modifiers, extra).toString();
  };
}
