import React from 'react';

import { GlyphName } from '../icon/Icon';

import { EmblemColor, EmblemSize, EmblemVariant } from './types';
import { EmblemWrapper } from './EmblemWrapper';
import { EmblemText } from './EmblemText';
import { EmblemEndDetailWrapper } from './EmblemEndDetailWrapper';
import { EmblemProvider } from './EmblemProvider';
import { EmblemIcon } from './EmblemIcon';

export type EmblemProps = {
  color?: EmblemColor; // only hash colors are supported
  size?: EmblemSize;
  variant?: EmblemVariant;
  text: React.ReactNode;
  startDetailGlyph?: GlyphName;
  onEndDetailClick?: React.MouseEventHandler<HTMLElement>;
  endDetailGlyph?: GlyphName;
  renderEndDetail?: (endDetail?: React.ReactNode) => React.ReactNode;
  className?: string;
  active?: boolean;
  onClick?: React.MouseEventHandler<HTMLElement>;
  title?: string;
  'data-role'?: string;
  'data-id'?: string;
};

export const Emblem: React.FC<EmblemProps> = ({
  color = '#b3b3b3',
  size = 'sm',
  variant = 'solid',
  active = false,
  text,
  startDetailGlyph,
  onEndDetailClick,
  endDetailGlyph,
  renderEndDetail,
  className,
  onClick,
  'data-role': dataRole = 'emblem',
  'data-id': dataId,
}) => {
  return (
    <EmblemProvider emblemActive={active} color={color} variant={variant} size={size}>
      <EmblemWrapper onClick={onClick} className={className} data-role={dataRole} data-id={dataId}>
        {startDetailGlyph && <EmblemIcon glyph={startDetailGlyph} />}

        <EmblemText>{text}</EmblemText>

        {endDetailGlyph && (
          <EmblemEndDetailWrapper onClick={onEndDetailClick}>
            {!renderEndDetail && <EmblemIcon glyph={endDetailGlyph} />}

            {renderEndDetail?.(<EmblemIcon glyph={endDetailGlyph} />)}
          </EmblemEndDetailWrapper>
        )}
      </EmblemWrapper>
    </EmblemProvider>
  );
};
