// Libs
import React from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line no-restricted-imports
import classNames from 'classnames';
// eslint-disable-next-line no-restricted-imports
import { getEventHandler } from 'neptune-core-ui/modules/events';


// Module
import './Button.less';

const baseCSSClass = 'n-button';

const propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  pressed: PropTypes.bool,
  size: PropTypes.oneOf(['medium', 'large']),
  type: PropTypes.oneOf(['button', 'reset', 'submit']),
  variant: PropTypes.oneOf(['primary', 'secondary']),
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
};

const defaultProps = {
  disabled: false,
  pressed: false,
  size: 'medium',
  type: 'button',
  variant: 'primary',
  component: 'button',
};


const Button = ({
  children,
  className,
  disabled,
  pressed,
  size,
  type,
  variant,
  component,
  onClick,
  ...props
}) => {

  const cssClasses = classNames(
    baseCSSClass,
    `${baseCSSClass}--size-${size}`,
    `${baseCSSClass}--variant-${variant}`,
    {
      'disabled': disabled,
      'pressed': pressed,
    },
    className,
  );

  const onClickHandler = getEventHandler(onClick);

  return React.createElement(
    component,
    {
      className: cssClasses,
      onClick: onClickHandler,
      disabled,
      type,
      ...props,
    },
    children,
  );
};

Button.propTypes = propTypes;
Button.defaultProps = defaultProps;


export default Button;
