import TableWrapper from './Table';
import TableBody from './TableBody';
import TableCell from './TableCell';
import TableFoot from './TableFoot';
import TableHead from './TableHead';
import TableHeadCell from './TableHeadCell';
import TableRow from './TableRow';

const Table = Object.assign(TableWrapper, {
  Body: TableBody,
  Cell: TableCell,
  Foot: TableFoot,
  Head: TableHead,
  HeadCell: TableHeadCell,
  Row: TableRow,
});

export { Table, TableBody, TableCell, TableFoot, TableHead, TableHeadCell, TableRow };

export default Table;
