import { LocalStorageCompatible } from './local-storage-compatible';
import { isLocalStorageV0 } from './local-storage-v0';
import { upgrade as upgradeFromPrevious } from './local-storage-v0.upgrade';
import { LocalStorageV1 } from './local-storage-v1';

export async function upgrade(raw: LocalStorageCompatible): Promise<LocalStorageV1> {
  const input = isLocalStorageV0(raw) ? raw : await upgradeFromPrevious(raw);
  const { data } = input;

  return {
    version: 1,
    data,
  };
}
