import React from 'react';

export type FullscreenContextOptions = {
  fullscreen: boolean;
  maximize: () => void;
  restore: () => void;
};

export const FullscreenContext = React.createContext<FullscreenContextOptions>({
  fullscreen: false,
  maximize: () => undefined,
  restore: () => undefined,
});

export const GlobalFullscreenContext = React.createContext<FullscreenContextOptions>({
  fullscreen: false,
  maximize: () => undefined,
  restore: () => undefined,
});
