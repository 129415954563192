/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LeaderboardFieldWithValueDTO,
    LeaderboardFieldWithValueDTOFromJSON,
    LeaderboardFieldWithValueDTOFromJSONTyped,
    LeaderboardFieldWithValueDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface LeaderboardEntryGroupDTO
 */
export interface LeaderboardEntryGroupDTO {
    /**
     * Token for searching previous group entries (in case of pagination)
     * @type {string}
     * @memberof LeaderboardEntryGroupDTO
     */
    beforeToken?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof LeaderboardEntryGroupDTO
     */
    childrenIds: Array<string>;
    /**
     * Token for searching next group entries (in case of pagination)
     * @type {string}
     * @memberof LeaderboardEntryGroupDTO
     */
    continuationToken?: string;
    /**
     * ID of the group
     * @type {string}
     * @memberof LeaderboardEntryGroupDTO
     */
    id: string;
    /**
     * The number of entries in the group.
     * @type {number}
     * @memberof LeaderboardEntryGroupDTO
     */
    itemCount: number;
    /**
     * 
     * @type {Array<LeaderboardFieldWithValueDTO>}
     * @memberof LeaderboardEntryGroupDTO
     */
    key: Array<LeaderboardFieldWithValueDTO>;
}

export function LeaderboardEntryGroupDTOFromJSON(json: any): LeaderboardEntryGroupDTO {
    return LeaderboardEntryGroupDTOFromJSONTyped(json, false);
}

export function LeaderboardEntryGroupDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): LeaderboardEntryGroupDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'beforeToken': !exists(json, 'beforeToken') ? undefined : json['beforeToken'],
        'childrenIds': json['childrenIds'],
        'continuationToken': !exists(json, 'continuationToken') ? undefined : json['continuationToken'],
        'id': json['id'],
        'itemCount': json['itemCount'],
        'key': ((json['key'] as Array<any>).map(LeaderboardFieldWithValueDTOFromJSON)),
    };
}

export function LeaderboardEntryGroupDTOToJSON(value?: LeaderboardEntryGroupDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'beforeToken': value.beforeToken,
        'childrenIds': value.childrenIds,
        'continuationToken': value.continuationToken,
        'id': value.id,
        'itemCount': value.itemCount,
        'key': ((value.key as Array<any>).map(LeaderboardFieldWithValueDTOToJSON)),
    };
}


