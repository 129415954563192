/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AttributeTypeDTO,
    AttributeTypeDTOFromJSON,
    AttributeTypeDTOFromJSONTyped,
    AttributeTypeDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface StringSeriesAttributeDTO
 */
export interface StringSeriesAttributeDTO {
    /**
     * 
     * @type {string}
     * @memberof StringSeriesAttributeDTO
     */
    attributeName: string;
    /**
     * 
     * @type {AttributeTypeDTO}
     * @memberof StringSeriesAttributeDTO
     */
    attributeType: AttributeTypeDTO;
    /**
     * 
     * @type {string}
     * @memberof StringSeriesAttributeDTO
     */
    last?: string;
    /**
     * 
     * @type {number}
     * @memberof StringSeriesAttributeDTO
     */
    lastStep?: number;
}

export function StringSeriesAttributeDTOFromJSON(json: any): StringSeriesAttributeDTO {
    return StringSeriesAttributeDTOFromJSONTyped(json, false);
}

export function StringSeriesAttributeDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): StringSeriesAttributeDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attributeName': json['attributeName'],
        'attributeType': AttributeTypeDTOFromJSON(json['attributeType']),
        'last': !exists(json, 'last') ? undefined : json['last'],
        'lastStep': !exists(json, 'lastStep') ? undefined : json['lastStep'],
    };
}

export function StringSeriesAttributeDTOToJSON(value?: StringSeriesAttributeDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attributeName': value.attributeName,
        'attributeType': AttributeTypeDTOToJSON(value.attributeType),
        'last': value.last,
        'lastStep': value.lastStep,
    };
}


