/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ColorsConfigDTO,
    ColorsConfigDTOFromJSON,
    ColorsConfigDTOFromJSONTyped,
    ColorsConfigDTOToJSON,
    DashboardConfigDTO,
    DashboardConfigDTOFromJSON,
    DashboardConfigDTOFromJSONTyped,
    DashboardConfigDTOToJSON,
    RunGroupDTO,
    RunGroupDTOFromJSON,
    RunGroupDTOFromJSONTyped,
    RunGroupDTOToJSON,
    WidgetDTO,
    WidgetDTOFromJSON,
    WidgetDTOFromJSONTyped,
    WidgetDTOToJSON,
    WidgetLayoutDTO,
    WidgetLayoutDTOFromJSON,
    WidgetLayoutDTOFromJSONTyped,
    WidgetLayoutDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface ReportNodeGridDTO
 */
export interface ReportNodeGridDTO {
    /**
     * 
     * @type {boolean}
     * @memberof ReportNodeGridDTO
     */
    aggregateByGroup: boolean;
    /**
     * 
     * @type {DashboardConfigDTO}
     * @memberof ReportNodeGridDTO
     */
    chartsConfig: DashboardConfigDTO;
    /**
     * 
     * @type {ColorsConfigDTO}
     * @memberof ReportNodeGridDTO
     */
    colorsConfig: ColorsConfigDTO;
    /**
     * 
     * @type {boolean}
     * @memberof ReportNodeGridDTO
     */
    inheritRunColorFromGroup: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReportNodeGridDTO
     */
    openRunGroups: Array<string>;
    /**
     * 
     * @type {Array<RunGroupDTO>}
     * @memberof ReportNodeGridDTO
     */
    runGroups: Array<RunGroupDTO>;
    /**
     * 
     * @type {boolean}
     * @memberof ReportNodeGridDTO
     */
    runsLineage: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReportNodeGridDTO
     */
    selectedRunGroups: Array<string>;
    /**
     * 
     * @type {Array<WidgetLayoutDTO>}
     * @memberof ReportNodeGridDTO
     */
    widgetLayouts: Array<WidgetLayoutDTO>;
    /**
     * 
     * @type {Array<WidgetDTO>}
     * @memberof ReportNodeGridDTO
     */
    widgets: Array<WidgetDTO>;
}

export function ReportNodeGridDTOFromJSON(json: any): ReportNodeGridDTO {
    return ReportNodeGridDTOFromJSONTyped(json, false);
}

export function ReportNodeGridDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportNodeGridDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'aggregateByGroup': json['aggregateByGroup'],
        'chartsConfig': DashboardConfigDTOFromJSON(json['chartsConfig']),
        'colorsConfig': ColorsConfigDTOFromJSON(json['colorsConfig']),
        'inheritRunColorFromGroup': json['inheritRunColorFromGroup'],
        'openRunGroups': json['openRunGroups'],
        'runGroups': ((json['runGroups'] as Array<any>).map(RunGroupDTOFromJSON)),
        'runsLineage': json['runsLineage'],
        'selectedRunGroups': json['selectedRunGroups'],
        'widgetLayouts': ((json['widgetLayouts'] as Array<any>).map(WidgetLayoutDTOFromJSON)),
        'widgets': ((json['widgets'] as Array<any>).map(WidgetDTOFromJSON)),
    };
}

export function ReportNodeGridDTOToJSON(value?: ReportNodeGridDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'aggregateByGroup': value.aggregateByGroup,
        'chartsConfig': DashboardConfigDTOToJSON(value.chartsConfig),
        'colorsConfig': ColorsConfigDTOToJSON(value.colorsConfig),
        'inheritRunColorFromGroup': value.inheritRunColorFromGroup,
        'openRunGroups': value.openRunGroups,
        'runGroups': ((value.runGroups as Array<any>).map(RunGroupDTOToJSON)),
        'runsLineage': value.runsLineage,
        'selectedRunGroups': value.selectedRunGroups,
        'widgetLayouts': ((value.widgetLayouts as Array<any>).map(WidgetLayoutDTOToJSON)),
        'widgets': ((value.widgets as Array<any>).map(WidgetDTOToJSON)),
    };
}


