// Libs
import React from 'react';

import { bemBlock } from '../../modules/bem';
import { LayoutRow, LayoutRowProps } from '../layout-row/LayoutRow';

// Module
import './ViewBody.less';

const block = bemBlock('n-ViewBody');

export type ViewBodyProps = Omit<LayoutRowProps<{}>, 'component' | 'height' | 'inline' | 'width'>;

/**
 * `ViewBody` is a wrapper for view's content.
 * It should be used if there is two or more columns in the view.
 * It may be used if there is only one column in the view, however it is recommended always to use it.
 *
 * It is based on `LayoutRow`.
 *
 * ## Import
 *
 * ```
 * import { View } from '@neptune/shared/venus-ui';
 * ```
 *
 * And use `<View.Body />`.
 */
// eslint-disable-next-line react/prefer-stateless-function
export class ViewBody extends React.Component<ViewBodyProps> {
  public static defaultProps = {
    component: 'article',
    spacedChildren: 'xl',
  };

  render() {
    const { className, ...passProps } = this.props;

    return <LayoutRow className={block({ extra: className })} {...passProps} />;
  }
}
