// Libs
import React from 'react';

// App
import { Layout, LayoutColumnProps, layout, bemBlock } from '@neptune/shared/venus-ui';

// Module
import './PropertyTable.less';

const block = bemBlock('property-table');

export type PropertyRow = [React.ReactNode, React.ReactNode];

type PropertyTableProps = LayoutColumnProps<{
  properties: PropertyRow[];
  columnsSpans?: [layout.Span, layout.Span];
}>;

export const PropertyTable: React.FC<PropertyTableProps> = ({
  className,
  properties = [],
  spacedChildren = 'sm',
  columnsSpans = ['4', '8'],
  ...passProps
}) => {
  return (
    <Layout.Column
      className={block({ extra: className })}
      spacedChildren={spacedChildren}
      {...passProps}
    >
      {properties.map((row, key) => (
        <Layout.Row key={key}>
          <Layout.Element span={columnsSpans[0]} className={block('first-item')}>
            {row[0]}
          </Layout.Element>
          <Layout.Element span={columnsSpans[1]}>{row[1]}</Layout.Element>
        </Layout.Row>
      ))}
    </Layout.Column>
  );
};
