import React from 'react';

export function makeRefProp<T = any>(
  component: string | React.ComponentType<any>,
  elementRef?: ((instance: T) => void) | React.RefObject<T> | null | undefined,
) {
  if (typeof component == 'string') {
    return { ref: elementRef };
  }

  if (elementRef) {
    return { elementRef };
  }

  return {};
}
