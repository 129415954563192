import React from 'react';

import { SizeProp } from '@fortawesome/fontawesome-svg-core';

import { GlyphName, Icon } from '../icon/Icon';

import { useEmblemContext } from './EmblemContext';
import { EmblemSize } from './types';

type EmblemIconProps = {
  glyph: GlyphName;
};

export const EmblemIcon: React.FC<EmblemIconProps> = ({ glyph }) => {
  const { size, active } = useEmblemContext();

  const styles: React.CSSProperties = {
    color: active ? 'var(--emblem-detail-active)' : 'var(--emblem-detail)',
  };

  const iconSizeByEmblemSize: Record<EmblemSize, SizeProp> = {
    xs: 'xs',
    sm: 'sm',
    md: '1x',
    lg: 'lg',
  };

  return <Icon glyph={glyph} style={styles} size={iconSizeByEmblemSize[size]} />;
};
