export class FetchTimeoutError extends Error {}

export async function fetchWithTimeout(
  request: RequestInfo,
  initConfig: RequestInit = {},
  time = 5000,
): Promise<Response> {
  const controller = new AbortController();
  const config: RequestInit = {
    ...initConfig,
    signal: controller.signal,
  };

  const timeoutId = window.setTimeout(() => controller.abort(), time);

  try {
    const res = await fetch(request, config);
    window.clearTimeout(timeoutId);

    return res;
  } catch (error) {
    // When we abort our `fetch`, the controller conveniently throws a named
    // error, allowing us to handle them separately from other errors.
    if (error instanceof DOMException && error.name === 'AbortError') {
      throw new FetchTimeoutError('Request timeout');
    }

    throw error;
  }
}
