/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum OrganizationTypeDTO {
    Individual = 'individual',
    Team = 'team'
}

export function OrganizationTypeDTOFromJSON(json: any): OrganizationTypeDTO {
    return OrganizationTypeDTOFromJSONTyped(json, false);
}

export function OrganizationTypeDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationTypeDTO {
    return json as OrganizationTypeDTO;
}

export function OrganizationTypeDTOToJSON(value?: OrganizationTypeDTO | null): any {
    return value as any;
}

