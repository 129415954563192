import { neptune } from 'analytics/neptune';

import { Supervisor } from './supervisor';

export const neptuneTrackingMetadata: {
  maintenanceInfoVisible: string;
} = {
  maintenanceInfoVisible: 'false',
};

const eventName = 'Web UI Backend API Call Issue';
export const neptuneSupervisor = new Supervisor((context) =>
  neptune.trackError(eventName, {
    ...context,
    ...neptuneTrackingMetadata,
    documentFocused: window.document.hasFocus().toString(),
    documentHidden: window.document.hidden.toString(),
  }),
);
