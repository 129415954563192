/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AssignArtifactOperation,
    AssignArtifactOperationFromJSON,
    AssignArtifactOperationFromJSONTyped,
    AssignArtifactOperationToJSON,
    AssignBoolOperation,
    AssignBoolOperationFromJSON,
    AssignBoolOperationFromJSONTyped,
    AssignBoolOperationToJSON,
    AssignComplexOperation,
    AssignComplexOperationFromJSON,
    AssignComplexOperationFromJSONTyped,
    AssignComplexOperationToJSON,
    AssignDatetimeOperation,
    AssignDatetimeOperationFromJSON,
    AssignDatetimeOperationFromJSONTyped,
    AssignDatetimeOperationToJSON,
    AssignFloatOperation,
    AssignFloatOperationFromJSON,
    AssignFloatOperationFromJSONTyped,
    AssignFloatOperationToJSON,
    AssignIntOperation,
    AssignIntOperationFromJSON,
    AssignIntOperationFromJSONTyped,
    AssignIntOperationToJSON,
    AssignStringOperation,
    AssignStringOperationFromJSON,
    AssignStringOperationFromJSONTyped,
    AssignStringOperationToJSON,
    ConfigFloatSeries,
    ConfigFloatSeriesFromJSON,
    ConfigFloatSeriesFromJSONTyped,
    ConfigFloatSeriesToJSON,
    DeleteFilesOperation,
    DeleteFilesOperationFromJSON,
    DeleteFilesOperationFromJSONTyped,
    DeleteFilesOperationToJSON,
    InsertStringsOperation,
    InsertStringsOperationFromJSON,
    InsertStringsOperationFromJSONTyped,
    InsertStringsOperationToJSON,
    LogFloatsOperation,
    LogFloatsOperationFromJSON,
    LogFloatsOperationFromJSONTyped,
    LogFloatsOperationToJSON,
    LogImages,
    LogImagesFromJSON,
    LogImagesFromJSONTyped,
    LogImagesToJSON,
    LogStringsOperation,
    LogStringsOperationFromJSON,
    LogStringsOperationFromJSONTyped,
    LogStringsOperationToJSON,
    RemoveStringsOperation,
    RemoveStringsOperationFromJSON,
    RemoveStringsOperationFromJSONTyped,
    RemoveStringsOperationToJSON,
} from './';

/**
 * 
 * @export
 * @interface Operation
 */
export interface Operation {
    /**
     * 
     * @type {string}
     * @memberof Operation
     */
    path?: string;
    /**
     * 
     * @type {AssignComplexOperation}
     * @memberof Operation
     */
    assignComplex?: AssignComplexOperation;
    /**
     * 
     * @type {AssignFloatOperation}
     * @memberof Operation
     */
    assignFloat?: AssignFloatOperation;
    /**
     * 
     * @type {AssignIntOperation}
     * @memberof Operation
     */
    assignInt?: AssignIntOperation;
    /**
     * 
     * @type {AssignBoolOperation}
     * @memberof Operation
     */
    assignBool?: AssignBoolOperation;
    /**
     * 
     * @type {AssignStringOperation}
     * @memberof Operation
     */
    assignString?: AssignStringOperation;
    /**
     * 
     * @type {AssignArtifactOperation}
     * @memberof Operation
     */
    assignArtifact?: AssignArtifactOperation;
    /**
     * 
     * @type {AssignDatetimeOperation}
     * @memberof Operation
     */
    assignDatetime?: AssignDatetimeOperation;
    /**
     * 
     * @type {LogFloatsOperation}
     * @memberof Operation
     */
    logFloats?: LogFloatsOperation;
    /**
     * 
     * @type {LogStringsOperation}
     * @memberof Operation
     */
    logStrings?: LogStringsOperation;
    /**
     * 
     * @type {LogImages}
     * @memberof Operation
     */
    logImages?: LogImages;
    /**
     * 
     * @type {object}
     * @memberof Operation
     */
    clearFloatSeries?: object;
    /**
     * 
     * @type {object}
     * @memberof Operation
     */
    clearStringSeries?: object;
    /**
     * 
     * @type {object}
     * @memberof Operation
     */
    clearImageSeries?: object;
    /**
     * 
     * @type {ConfigFloatSeries}
     * @memberof Operation
     */
    configFloatSeries?: ConfigFloatSeries;
    /**
     * 
     * @type {InsertStringsOperation}
     * @memberof Operation
     */
    insertStrings?: InsertStringsOperation;
    /**
     * 
     * @type {RemoveStringsOperation}
     * @memberof Operation
     */
    removeStrings?: RemoveStringsOperation;
    /**
     * 
     * @type {object}
     * @memberof Operation
     */
    clearStringSet?: object;
    /**
     * 
     * @type {DeleteFilesOperation}
     * @memberof Operation
     */
    deleteFiles?: DeleteFilesOperation;
    /**
     * 
     * @type {object}
     * @memberof Operation
     */
    deleteAttribute?: object;
}

export function OperationFromJSON(json: any): Operation {
    return OperationFromJSONTyped(json, false);
}

export function OperationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Operation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'path': !exists(json, 'path') ? undefined : json['path'],
        'assignComplex': !exists(json, 'assignComplex') ? undefined : AssignComplexOperationFromJSON(json['assignComplex']),
        'assignFloat': !exists(json, 'assignFloat') ? undefined : AssignFloatOperationFromJSON(json['assignFloat']),
        'assignInt': !exists(json, 'assignInt') ? undefined : AssignIntOperationFromJSON(json['assignInt']),
        'assignBool': !exists(json, 'assignBool') ? undefined : AssignBoolOperationFromJSON(json['assignBool']),
        'assignString': !exists(json, 'assignString') ? undefined : AssignStringOperationFromJSON(json['assignString']),
        'assignArtifact': !exists(json, 'assignArtifact') ? undefined : AssignArtifactOperationFromJSON(json['assignArtifact']),
        'assignDatetime': !exists(json, 'assignDatetime') ? undefined : AssignDatetimeOperationFromJSON(json['assignDatetime']),
        'logFloats': !exists(json, 'logFloats') ? undefined : LogFloatsOperationFromJSON(json['logFloats']),
        'logStrings': !exists(json, 'logStrings') ? undefined : LogStringsOperationFromJSON(json['logStrings']),
        'logImages': !exists(json, 'logImages') ? undefined : LogImagesFromJSON(json['logImages']),
        'clearFloatSeries': !exists(json, 'clearFloatSeries') ? undefined : json['clearFloatSeries'],
        'clearStringSeries': !exists(json, 'clearStringSeries') ? undefined : json['clearStringSeries'],
        'clearImageSeries': !exists(json, 'clearImageSeries') ? undefined : json['clearImageSeries'],
        'configFloatSeries': !exists(json, 'configFloatSeries') ? undefined : ConfigFloatSeriesFromJSON(json['configFloatSeries']),
        'insertStrings': !exists(json, 'insertStrings') ? undefined : InsertStringsOperationFromJSON(json['insertStrings']),
        'removeStrings': !exists(json, 'removeStrings') ? undefined : RemoveStringsOperationFromJSON(json['removeStrings']),
        'clearStringSet': !exists(json, 'clearStringSet') ? undefined : json['clearStringSet'],
        'deleteFiles': !exists(json, 'deleteFiles') ? undefined : DeleteFilesOperationFromJSON(json['deleteFiles']),
        'deleteAttribute': !exists(json, 'deleteAttribute') ? undefined : json['deleteAttribute'],
    };
}

export function OperationToJSON(value?: Operation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'path': value.path,
        'assignComplex': AssignComplexOperationToJSON(value.assignComplex),
        'assignFloat': AssignFloatOperationToJSON(value.assignFloat),
        'assignInt': AssignIntOperationToJSON(value.assignInt),
        'assignBool': AssignBoolOperationToJSON(value.assignBool),
        'assignString': AssignStringOperationToJSON(value.assignString),
        'assignArtifact': AssignArtifactOperationToJSON(value.assignArtifact),
        'assignDatetime': AssignDatetimeOperationToJSON(value.assignDatetime),
        'logFloats': LogFloatsOperationToJSON(value.logFloats),
        'logStrings': LogStringsOperationToJSON(value.logStrings),
        'logImages': LogImagesToJSON(value.logImages),
        'clearFloatSeries': value.clearFloatSeries,
        'clearStringSeries': value.clearStringSeries,
        'clearImageSeries': value.clearImageSeries,
        'configFloatSeries': ConfigFloatSeriesToJSON(value.configFloatSeries),
        'insertStrings': InsertStringsOperationToJSON(value.insertStrings),
        'removeStrings': RemoveStringsOperationToJSON(value.removeStrings),
        'clearStringSet': value.clearStringSet,
        'deleteFiles': DeleteFilesOperationToJSON(value.deleteFiles),
        'deleteAttribute': value.deleteAttribute,
    };
}


