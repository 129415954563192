/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NotebookDTO,
    NotebookDTOFromJSON,
    NotebookDTOFromJSONTyped,
    NotebookDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface NotebookListDTO
 */
export interface NotebookListDTO {
    /**
     * The entries matching the given criteria.
     * @type {Array<NotebookDTO>}
     * @memberof NotebookListDTO
     */
    entries: Array<NotebookDTO>;
    /**
     * The total number of entries matching the given criteria.
     * @type {number}
     * @memberof NotebookListDTO
     */
    matchingItemCount: number;
    /**
     * The total number of entries.
     * @type {number}
     * @memberof NotebookListDTO
     */
    totalItemCount: number;
}

export function NotebookListDTOFromJSON(json: any): NotebookListDTO {
    return NotebookListDTOFromJSONTyped(json, false);
}

export function NotebookListDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotebookListDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entries': ((json['entries'] as Array<any>).map(NotebookDTOFromJSON)),
        'matchingItemCount': json['matchingItemCount'],
        'totalItemCount': json['totalItemCount'],
    };
}

export function NotebookListDTOToJSON(value?: NotebookListDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entries': ((value.entries as Array<any>).map(NotebookDTOToJSON)),
        'matchingItemCount': value.matchingItemCount,
        'totalItemCount': value.totalItemCount,
    };
}


