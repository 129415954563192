import { CustomIconDefinition } from '../types';

export const nepFile: CustomIconDefinition = {
  prefix: 'nep',
  iconName: 'file',
  icon: [
    660,
    660,
    [],
    'e135',
    'M510 412.5C510 422.1 502 430 492.1 430H168C158.1 430 150 422.1 150 412.5S158.1 395 168 395H492.1C502 395 510 402.9 510 412.5ZM292.5 295H167.5C157.9 295 150 302.9 150 312.5S157.9 330 167.5 330H292.5C302.1 330 310 322.1 310 312.5S302.2 295 292.5 295ZM492.1 495H168C158.1 495 150 502.9 150 512.5S158.1 530 168 530H492.1C502 530 510 522.1 510 512.5S502 495 492.1 495ZM492.1 595H168C158.1 595 150 602.8 150 612.5S158.1 630 168 630H492.1C502 630 510 622.2 510 612.5S502 595 492.1 595ZM492.1 695H168C158.1 695 150 702.8 150 712.5S158.1 730 168 730H492.1C502 730 510 722.2 510 712.5S502 695 492.1 695ZM660 297.4V900H0V100H455.3L660 297.4ZM595.4 286.7L466.4 162.3V286.7H595.4ZM428.7 323.3V136.7H37.7V863.3H622.4V323.3H428.7Z',
  ],
};
