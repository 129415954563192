import { LocalStorageCompatible } from './local-storage-compatible';
import { isLocalStorageV1 } from './local-storage-v1';
import { upgrade as upgradeFromPrevious } from './local-storage-v1.upgrade';
import { LocalStorageV2 } from './local-storage-v2';

export async function upgrade(raw: LocalStorageCompatible): Promise<LocalStorageV2> {
  const input = isLocalStorageV1(raw) ? raw : await upgradeFromPrevious(raw);
  const { data } = input;

  const { newUIInfoStatus, ...rest } = data;

  return {
    version: 2,
    data: rest,
  };
}
