export function splitTime(minutes: number): { h: number; m: number; s: number } {
  const h = Math.floor(minutes / 60);
  const m = Math.floor(minutes) % 60;
  const s = Math.round((minutes % 1) * 60); // we need to nice handle: (123 / 60) * 60 = 122.99999999999999
  return { h, m, s };
}

export function formatMonitoringExtended(minutes: number): string {
  const { h, m, s } = splitTime(minutes);
  return `${h.toString()}h ${m.toString().padStart(2, '0')}m ${s.toString().padStart(2, '0')}s`;
}

export function toMonthPeriod(date: Date) {
  return date
    .toDateString()
    .replace(/....(....).../, '$1')
    .toUpperCase();
}

export function formatMonitoringShort(minutes: number): string {
  const { h, m, s } = splitTime(minutes);

  if (minutes < 1) {
    return `${s}s`;
  }

  if (minutes < 60) {
    return `${m}m${s > 0 ? ` ${s}s` : ''}`;
  }

  return `${h}h${m > 0 ? ` ${m}m` : ''}`;
}
