/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ProjectRoleDTO,
    ProjectRoleDTOFromJSON,
    ProjectRoleDTOFromJSONTyped,
    ProjectRoleDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface ProjectInvitationUpdateDTO
 */
export interface ProjectInvitationUpdateDTO {
    /**
     * 
     * @type {ProjectRoleDTO}
     * @memberof ProjectInvitationUpdateDTO
     */
    roleGrant: ProjectRoleDTO;
}

export function ProjectInvitationUpdateDTOFromJSON(json: any): ProjectInvitationUpdateDTO {
    return ProjectInvitationUpdateDTOFromJSONTyped(json, false);
}

export function ProjectInvitationUpdateDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectInvitationUpdateDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'roleGrant': ProjectRoleDTOFromJSON(json['roleGrant']),
    };
}

export function ProjectInvitationUpdateDTOToJSON(value?: ProjectInvitationUpdateDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'roleGrant': ProjectRoleDTOToJSON(value.roleGrant),
    };
}


