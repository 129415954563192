export enum fetchStatus {
  NONE = 'none',
  PENDING = 'pending',
  SUCCESS = 'success',
  FAILED = 'failed',
  ABORTED = 'aborted',
}

export const isFetchStatusNone = (...args: (string | fetchStatus)[]) =>
  args.every((status) => status === fetchStatus.NONE);
export const isFetchStatusPending = (...args: (string | fetchStatus)[]) =>
  args.some((status) => status === fetchStatus.PENDING);
export const isFetchStatusSuccess = (...args: (string | fetchStatus)[]) =>
  args.every((status) => status === fetchStatus.SUCCESS);
export const isFetchStatusFailed = (...args: (string | fetchStatus)[]) =>
  args.some((status) => status === fetchStatus.FAILED);
export const isFetchStatusAborted = (...args: (string | fetchStatus)[]) =>
  args.some((status) => status === fetchStatus.ABORTED);

export const isFetchStatusCompleted = (...args: (string | fetchStatus)[]) => {
  return args.every((status) => status === fetchStatus.SUCCESS || status === fetchStatus.FAILED);
};

export const fetchStatusValues = Object.values(fetchStatus);
