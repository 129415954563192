import { LocalStorageCompatible } from './local-storage-compatible';
import { isLocalStorageV2 } from './local-storage-v2';
import { upgrade as upgradeFromPrevious } from './local-storage-v2.upgrade';
import { LocalStorageV3 } from './local-storage-v3';

export async function upgrade(raw: LocalStorageCompatible): Promise<LocalStorageV3> {
  const input = isLocalStorageV2(raw) ? raw : await upgradeFromPrevious(raw);
  const { data } = input;

  const { shownHelpByOrganization, ...rest } = data;

  return {
    version: 3,
    data: rest,
  };
}
