/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AttributeTypeDTO,
    AttributeTypeDTOFromJSON,
    AttributeTypeDTOFromJSONTyped,
    AttributeTypeDTOToJSON,
    TypeDefinitionDTO,
    TypeDefinitionDTOFromJSON,
    TypeDefinitionDTOFromJSONTyped,
    TypeDefinitionDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface ComplexAttributeDTO
 */
export interface ComplexAttributeDTO {
    /**
     * 
     * @type {string}
     * @memberof ComplexAttributeDTO
     */
    attributeName: string;
    /**
     * 
     * @type {AttributeTypeDTO}
     * @memberof ComplexAttributeDTO
     */
    attributeType: AttributeTypeDTO;
    /**
     * 
     * @type {string}
     * @memberof ComplexAttributeDTO
     */
    content: string;
    /**
     * 
     * @type {TypeDefinitionDTO}
     * @memberof ComplexAttributeDTO
     */
    typeDefinition: TypeDefinitionDTO;
}

export function ComplexAttributeDTOFromJSON(json: any): ComplexAttributeDTO {
    return ComplexAttributeDTOFromJSONTyped(json, false);
}

export function ComplexAttributeDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ComplexAttributeDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attributeName': json['attributeName'],
        'attributeType': AttributeTypeDTOFromJSON(json['attributeType']),
        'content': json['content'],
        'typeDefinition': TypeDefinitionDTOFromJSON(json['typeDefinition']),
    };
}

export function ComplexAttributeDTOToJSON(value?: ComplexAttributeDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attributeName': value.attributeName,
        'attributeType': AttributeTypeDTOToJSON(value.attributeType),
        'content': value.content,
        'typeDefinition': TypeDefinitionDTOToJSON(value.typeDefinition),
    };
}


