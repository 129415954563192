/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ArtifactsConfigDTO,
    ArtifactsConfigDTOFromJSON,
    ArtifactsConfigDTOFromJSONTyped,
    ArtifactsConfigDTOToJSON,
    ClientVersionsConfigDTO,
    ClientVersionsConfigDTOFromJSON,
    ClientVersionsConfigDTOFromJSONTyped,
    ClientVersionsConfigDTOToJSON,
    MultiPartUploadConfigDTO,
    MultiPartUploadConfigDTOFromJSON,
    MultiPartUploadConfigDTOFromJSONTyped,
    MultiPartUploadConfigDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface ClientConfig
 */
export interface ClientConfig {
    /**
     * 
     * @type {ArtifactsConfigDTO}
     * @memberof ClientConfig
     */
    artifacts?: ArtifactsConfigDTO;
    /**
     * 
     * @type {string}
     * @memberof ClientConfig
     */
    apiUrl: string;
    /**
     * 
     * @type {ClientVersionsConfigDTO}
     * @memberof ClientConfig
     */
    pyLibVersions: ClientVersionsConfigDTO;
    /**
     * 
     * @type {MultiPartUploadConfigDTO}
     * @memberof ClientConfig
     */
    multiPartUpload?: MultiPartUploadConfigDTO;
    /**
     * 
     * @type {string}
     * @memberof ClientConfig
     */
    applicationUrl: string;
    /**
     * 
     * @type {boolean}
     * @memberof ClientConfig
     */
    gzipUpload: boolean;
}

export function ClientConfigFromJSON(json: any): ClientConfig {
    return ClientConfigFromJSONTyped(json, false);
}

export function ClientConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClientConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'artifacts': !exists(json, 'artifacts') ? undefined : ArtifactsConfigDTOFromJSON(json['artifacts']),
        'apiUrl': json['apiUrl'],
        'pyLibVersions': ClientVersionsConfigDTOFromJSON(json['pyLibVersions']),
        'multiPartUpload': !exists(json, 'multiPartUpload') ? undefined : MultiPartUploadConfigDTOFromJSON(json['multiPartUpload']),
        'applicationUrl': json['applicationUrl'],
        'gzipUpload': json['gzipUpload'],
    };
}

export function ClientConfigToJSON(value?: ClientConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'artifacts': ArtifactsConfigDTOToJSON(value.artifacts),
        'apiUrl': value.apiUrl,
        'pyLibVersions': ClientVersionsConfigDTOToJSON(value.pyLibVersions),
        'multiPartUpload': MultiPartUploadConfigDTOToJSON(value.multiPartUpload),
        'applicationUrl': value.applicationUrl,
        'gzipUpload': value.gzipUpload,
    };
}


