export function updateInputPreservingCaretPosition(
  inputOrTextArea: HTMLInputElement | HTMLTextAreaElement,
  valueUpdateFn: (value: string) => string,
) {
  const end = inputOrTextArea.selectionEnd;
  const start = inputOrTextArea.selectionStart;
  const value = inputOrTextArea.value;

  inputOrTextArea.value = valueUpdateFn(value);
  inputOrTextArea.selectionStart = start;
  inputOrTextArea.selectionEnd = end;
}
