// Libs
import React from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line no-restricted-imports
import classNames from 'classnames';
import { noop } from 'lodash';


// Module
import './DragAction.less';


const propTypes = {
  className: PropTypes.string,
  column: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  onDragStart: PropTypes.func,
  onDragEnd: PropTypes.func,
  onDrag: PropTypes.func,
};

const defaultProps = {
  onDragStart: noop,
  onDragEnd: noop,
  onDrag: noop,
};


const DragAction = ({
  className,
  column,
  onDragStart,
  onDragEnd,
  onDrag,
}) => {
  const cssClasses = classNames(
    'n-table-cell-action',
    'n-action-drag',
    className,
  );

  return (
    <div
      className={cssClasses}
      draggable="true"
      onDragStart={(event) => onDragStart(event, column)}
      onDrag={(event) => onDrag(event, column)}
      onDragEnd={(event) => onDragEnd(event, column)}
    ></div>
  );
};

DragAction.propTypes = propTypes;
DragAction.defaultProps = defaultProps;


export default DragAction;
