/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AttributeTypeDTO,
    AttributeTypeDTOFromJSON,
    AttributeTypeDTOFromJSONTyped,
    AttributeTypeDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface QueryLeaderboardParamsFieldDTO
 */
export interface QueryLeaderboardParamsFieldDTO {
    /**
     * 
     * @type {string}
     * @memberof QueryLeaderboardParamsFieldDTO
     */
    aggregationMode?: QueryLeaderboardParamsFieldDTOAggregationModeEnum;
    /**
     * 
     * @type {string}
     * @memberof QueryLeaderboardParamsFieldDTO
     */
    name: string;
    /**
     * 
     * @type {AttributeTypeDTO}
     * @memberof QueryLeaderboardParamsFieldDTO
     */
    type: AttributeTypeDTO;
}

export function QueryLeaderboardParamsFieldDTOFromJSON(json: any): QueryLeaderboardParamsFieldDTO {
    return QueryLeaderboardParamsFieldDTOFromJSONTyped(json, false);
}

export function QueryLeaderboardParamsFieldDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): QueryLeaderboardParamsFieldDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'aggregationMode': !exists(json, 'aggregationMode') ? undefined : json['aggregationMode'],
        'name': json['name'],
        'type': AttributeTypeDTOFromJSON(json['type']),
    };
}

export function QueryLeaderboardParamsFieldDTOToJSON(value?: QueryLeaderboardParamsFieldDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'aggregationMode': value.aggregationMode,
        'name': value.name,
        'type': AttributeTypeDTOToJSON(value.type),
    };
}

/**
* @export
* @enum {string}
*/
export enum QueryLeaderboardParamsFieldDTOAggregationModeEnum {
    Last = 'last',
    Min = 'min',
    Max = 'max',
    Average = 'average',
    Variance = 'variance',
    Auto = 'auto'
}


