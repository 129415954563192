import { CustomIconDefinition } from '../types';

export const nepPencil: CustomIconDefinition = {
  prefix: 'nep',
  iconName: 'pencil',
  icon: [
    1000,
    1000,
    [],
    'e11a',
    'M777.6 68.6L166.9 679.2 114.8 939.7 375.3 887.6 986 277 777.6 68.6ZM902.5 277L806.2 373.3 681.2 248.3 777.5 152 902.5 277ZM346.2 833.2L190 864.5 221.2 708.3 639.6 290 764.6 415 346.2 833.2Z',
  ],
};
