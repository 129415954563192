import { authClient } from './auth-client';
import urlParse from 'url-parse';

/** @deprecated use `updateAndAppendAuthTokenToQuery` */
export function appendAuthTokenToQuery(url: string): string;
/** @deprecated use `updateAndAppendAuthTokenToQuery` */
export function appendAuthTokenToQuery(url: undefined): undefined;
/** @deprecated use `updateAndAppendAuthTokenToQuery` */
export function appendAuthTokenToQuery(url: null): null;

/** @deprecated use `updateAndAppendAuthTokenToQuery` */
export function appendAuthTokenToQuery(url: string | undefined | null): string | undefined | null {
  if (!authClient.authenticated || !url) {
    return url;
  }

  return appendQueryParams(url, { access_token: authClient.token });
}

function appendQueryParams(url: string, paramsObject: Record<string, any> | undefined): string {
  const parseQuery = true;

  const parsedUrl = urlParse(url, parseQuery);
  const result = parsedUrl.set('query', { ...parsedUrl.query, ...paramsObject });

  return result.toString();
}
