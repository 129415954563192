// Libs
import React, { HtmlHTMLAttributes } from 'react';

// core-ui
import { bemBlock, Text } from '@neptune/shared/venus-ui';

// Module
import './KeycloakTitle.less';

const block = bemBlock('keycloak-title');

export type KeycloakTitleProps = HtmlHTMLAttributes<HTMLHeadingElement>;

export const KeycloakTitle: React.FC<KeycloakTitleProps> = ({ className, children }) => {
  return (
    <Text
      component="h1"
      fontWeight="bold"
      size="xxl"
      color="text"
      className={block({ extra: className })}
    >
      {children}
    </Text>
  );
};
