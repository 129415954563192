// Libs
import React from 'react';
import PropTypes from 'prop-types';


// neptune-core-ui
// eslint-disable-next-line no-restricted-imports
import ncuiPropTypes from 'neptune-core-ui/helpers/prop-types';
// eslint-disable-next-line no-restricted-imports
import { bemBlock } from 'neptune-core-ui/modules/bem';
// eslint-disable-next-line no-restricted-imports
import { sanitizeDangerousUrl } from 'neptune-core-ui/modules/url';
// eslint-disable-next-line no-restricted-imports
import { getEventHandler } from 'neptune-core-ui/modules/events';


// Module
import './Link.less';

const propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  disabled: PropTypes.bool,
  external: PropTypes.bool,
  href: PropTypes.string,
  /**
   * Can be event handler function or object with two properties:
   *
   * - `handler`: event handler
   * - `payload`: object that will be passed to handler
   *
   * Event handler will be called with arguments:
   *
   * - `event`: [SyntheticEvent](https://facebook.github.io/react/docs/events.html)
   * - `params`: `object` (defaults to `{}` *empty object*)
   *   - `payload`: value of `onClick.payload`
   */
  onClick: ncuiPropTypes.eventHandler,
  sanitize: PropTypes.bool,
};

const defaultProps = {
  disabled: false,
  external: false,
  sanitize: false,
};

const block = bemBlock('n-link');


const Link = ({
  children,
  className,
  component,
  disabled,
  external,
  href,
  onClick,
  sanitize,
  title,
  ...props
}) => {
  let tag = 'span';
  const onClickHandler = getEventHandler(onClick);
  const ownProps = {
    className: block({
      extra: [
        {
          'clickable': onClickHandler && !disabled,
          'disabled': disabled,
        },
        className,
      ],
    }),
    onClick: onClickHandler,
  };

  ownProps.href = href && sanitize ? sanitizeDangerousUrl(href) : href;
  ownProps.title = title || ownProps.href;

  if (component) {
    tag = component;
    ownProps.external = external;
  } else if (href) {
    tag = 'a';
    if (external) {
      ownProps.target = '_blank';
      ownProps.rel = 'noopener noreferrer';
    }
  } else if (onClickHandler) {
    tag = 'button';
    if (disabled) {
      ownProps.disabled = true;
    }
  }

  return React.createElement(tag, {...props, ...ownProps}, children);
};

Link.propTypes = propTypes;
Link.defaultProps = defaultProps;


export default Link;
