/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AttributeTypeDTO,
    AttributeTypeDTOFromJSON,
    AttributeTypeDTOFromJSONTyped,
    AttributeTypeDTOToJSON,
    FloatSeriesAttributeConfigDTO,
    FloatSeriesAttributeConfigDTOFromJSON,
    FloatSeriesAttributeConfigDTOFromJSONTyped,
    FloatSeriesAttributeConfigDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface FloatSeriesAttributeDTO
 */
export interface FloatSeriesAttributeDTO {
    /**
     * 
     * @type {string}
     * @memberof FloatSeriesAttributeDTO
     */
    attributeName: string;
    /**
     * 
     * @type {AttributeTypeDTO}
     * @memberof FloatSeriesAttributeDTO
     */
    attributeType: AttributeTypeDTO;
    /**
     * 
     * @type {number}
     * @memberof FloatSeriesAttributeDTO
     */
    average?: number;
    /**
     * 
     * @type {FloatSeriesAttributeConfigDTO}
     * @memberof FloatSeriesAttributeDTO
     */
    config: FloatSeriesAttributeConfigDTO;
    /**
     * 
     * @type {number}
     * @memberof FloatSeriesAttributeDTO
     */
    last?: number;
    /**
     * 
     * @type {number}
     * @memberof FloatSeriesAttributeDTO
     */
    lastStep?: number;
    /**
     * 
     * @type {number}
     * @memberof FloatSeriesAttributeDTO
     */
    max?: number;
    /**
     * 
     * @type {number}
     * @memberof FloatSeriesAttributeDTO
     */
    min?: number;
    /**
     * 
     * @type {number}
     * @memberof FloatSeriesAttributeDTO
     */
    variance?: number;
}

export function FloatSeriesAttributeDTOFromJSON(json: any): FloatSeriesAttributeDTO {
    return FloatSeriesAttributeDTOFromJSONTyped(json, false);
}

export function FloatSeriesAttributeDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): FloatSeriesAttributeDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attributeName': json['attributeName'],
        'attributeType': AttributeTypeDTOFromJSON(json['attributeType']),
        'average': !exists(json, 'average') ? undefined : json['average'],
        'config': FloatSeriesAttributeConfigDTOFromJSON(json['config']),
        'last': !exists(json, 'last') ? undefined : json['last'],
        'lastStep': !exists(json, 'lastStep') ? undefined : json['lastStep'],
        'max': !exists(json, 'max') ? undefined : json['max'],
        'min': !exists(json, 'min') ? undefined : json['min'],
        'variance': !exists(json, 'variance') ? undefined : json['variance'],
    };
}

export function FloatSeriesAttributeDTOToJSON(value?: FloatSeriesAttributeDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attributeName': value.attributeName,
        'attributeType': AttributeTypeDTOToJSON(value.attributeType),
        'average': value.average,
        'config': FloatSeriesAttributeConfigDTOToJSON(value.config),
        'last': value.last,
        'lastStep': value.lastStep,
        'max': value.max,
        'min': value.min,
        'variance': value.variance,
    };
}


