/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RegisteredMemberInfoDTO,
    RegisteredMemberInfoDTOFromJSON,
    RegisteredMemberInfoDTOFromJSONTyped,
    RegisteredMemberInfoDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface OrganizationOwnerDTO
 */
export interface OrganizationOwnerDTO {
    /**
     * 
     * @type {RegisteredMemberInfoDTO}
     * @memberof OrganizationOwnerDTO
     */
    registeredMemberInfo: RegisteredMemberInfoDTO;
    /**
     * 
     * @type {string}
     * @memberof OrganizationOwnerDTO
     */
    email: string;
}

export function OrganizationOwnerDTOFromJSON(json: any): OrganizationOwnerDTO {
    return OrganizationOwnerDTOFromJSONTyped(json, false);
}

export function OrganizationOwnerDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationOwnerDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'registeredMemberInfo': RegisteredMemberInfoDTOFromJSON(json['registeredMemberInfo']),
        'email': json['email'],
    };
}

export function OrganizationOwnerDTOToJSON(value?: OrganizationOwnerDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'registeredMemberInfo': RegisteredMemberInfoDTOToJSON(value.registeredMemberInfo),
        'email': value.email,
    };
}


