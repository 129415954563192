// Libs
import React from 'react';
import PropTypes from 'prop-types';
import { isFunction } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


// neptune-core-ui
// eslint-disable-next-line no-restricted-imports
import ncuiPropTypes from 'neptune-core-ui/helpers/prop-types';
// eslint-disable-next-line no-restricted-imports
import { getEventHandler } from 'neptune-core-ui/modules/events';
// eslint-disable-next-line no-restricted-imports
import { bemBlock } from 'neptune-core-ui/modules/bem';
// eslint-disable-next-line no-restricted-imports
import IconLayered from 'neptune-core-ui/components/icon/IconLayered';


// Module
const GLYPHS = {
  'bell': { icon: 'bell' },
  'bold': { icon: 'bold' },
  'calendar': { icon: ['far', 'calendar-alt'] },
  'caret-down': { icon: 'caret-down' },
  'caret-right': { icon: 'caret-right' },
  'caret-square-down': { icon: 'caret-square-down' },
  'caret-square-down-o': { icon: ['far', 'caret-square-down'] },
  'caret-square-right': { icon: 'caret-square-right' },
  'caret-square-right-o': { icon: ['far', 'caret-square-right'] },
  'caret-up': { icon: 'caret-up' },
  'check-circle': { icon: 'check-circle' },
  'chevron-double-left': {
    render: (props) => (
      <IconLayered {...props}>
        <IconFA icon="chevron-left" transform="left-3 shrink-4" />
        <IconFA icon="chevron-left" transform="right-3 shrink-4" />
      </IconLayered>
    ),
  },
  'chevron-left': { icon: 'chevron-left' },
  'chevron-double-right': {
    render: (props) => (
      <IconLayered {...props}>
        <IconFA icon="chevron-right" transform="left-3 shrink-4" />
        <IconFA icon="chevron-right" transform="right-3 shrink-4" />
      </IconLayered>
    ),
  },
  'chevron-right': { icon: 'chevron-right' },
  'clipboard': { icon: ['far', 'clipboard'] },
  'clone': { icon: ['far', 'clone'] },
  'code': { icon: 'code' },
  'cog': { icon: 'cog' },
  'columns': { icon: 'columns' },
  'command-square': {
    render: (props) => (
      <IconLayered {...props}>
        <IconFA icon="square" />
        <IconFA
          icon="terminal"
          inverse
          transform="shrink-8"
        />
      </IconLayered>
    ),
  },
  'comment': { icon: ['far', 'comment'] },
  'comment-alt': { icon: ['far', 'comment-alt'] },
  'comments': { icon: ['far', 'comments'] },
  'compress': { icon: 'compress' },
  'credit-card': { icon: ['far', 'credit-card'] },
  'crown': { icon: 'crown' },
  'database': { icon: 'database' },
  'dot-line': {
    render: (props) => (
      <IconLayered {...props}>
        <IconFA icon={['far', 'window-minimize']} transform="up-6" />
        <IconFA icon="circle" transform="shrink-7" />
      </IconLayered>
    ),
  },
  'download': {
    render: (props) => (
      <IconLayered {...props}>
        <IconFA icon={['far', 'window-minimize']} />
        <IconFA icon="arrow-down" transform="shrink-2 up-2" />
      </IconLayered>
    ),
  },
  'ellipsis-h': { icon: 'ellipsis-h' },
  'exit': { icon: 'sign-out-alt' },
  'expand': { icon: 'expand' },
  'external-link': { icon: 'external-link-alt' },
  'exclamation-circle': { icon: 'exclamation-circle' },
  'exclamation-triangle': { icon: 'exclamation-triangle' },
  'file-alt': { icon: ['far', 'file-alt'] },
  'file-solid': { icon: ['fas', 'file-alt'] },
  'file-code': { icon: ['far', 'file-code'] },
  'file-export': { icon: 'file-export' },
  'file-import': { icon: 'file-import' },
  'github': { icon: ['fab', 'github'] },
  'globe-americas': { icon: 'globe-americas' },
  'grip-lines': { icon: 'grip-lines' },
  'heading': { icon: 'heading' },
  'image': { icon: ['far', 'image'] },
  'info-circle': { icon: 'info-circle' },
  'italic': { icon: 'italic' },
  'kaggle': { icon: ['fab', 'kaggle'] },
  'level-up': { icon: 'level-up-alt', flip: 'horizontal' },
  'link': { icon: 'link' },
  'linkedin': { icon: ['fab', 'linkedin'] },
  'list-alt': { icon: ['far', 'list-alt'] },
  'list-ol': { icon: 'list-ol' },
  'list-ul': { icon: 'list-ul' },
  'lock': { icon: 'lock' },
  'paragraph': { icon: 'paragraph' },
  'pen': { icon: 'pen' },
  'plus': { icon: 'plus' },
  'plus-square': { icon: ['far', 'plus-square'] },
  'python': { icon: ['fab', 'python'] },
  'question-circle': { icon: ['far', 'question-circle'] },
  'random': { icon: 'random' },
  'retweet': { icon: 'retweet' },
  'rocket': { icon: 'rocket' },
  'search': { icon: 'search' },
  'spinner': { icon: 'spinner' },
  'square-root-alt': { icon: 'square-root-alt' },
  'strikethrough': { icon: 'strikethrough' },
  'sliders-h': { icon: 'sliders-h' },
  'tag': { icon: 'tag' },
  'thumbtack': { icon: 'thumbtack'},
  'times': { icon: 'times' },
  'times-circle': { icon: 'times-circle' },
  'toggle-off': { icon: 'toggle-off' },
  'toggle-on': { icon: 'toggle-on' },
  'trash': { icon: ['far', 'trash-alt'] },
  'undo': { icon: 'undo-alt' },
  'upload': { icon: 'cloud-upload-alt' },
  'user': { icon: ['far', 'user'] },
  'users': { icon: 'users' },
};


export function getGlyph(name) {
  return GLYPHS[name] || {};
}

export function getGlyphNames() {
  return Object.keys(GLYPHS);
}


const propTypes = {
  border: PropTypes.bool,
  className: PropTypes.string,
  fixedWidth: PropTypes.bool,
  flip: PropTypes.oneOf(['horizontal', 'vertical', 'both']),
  /** Predefined icon shapes used in Neptune */
  glyph: PropTypes.oneOf(getGlyphNames()),
  /**
   * Define shapes using `icon` property of FontAwesomeIcon.
   * Using `glyph` is recommended and preferred over `icon`.
   */
  icon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  inverse: PropTypes.bool,
  listItem: PropTypes.bool,
  // HACK: for backward compatibility with Icon
  monospace: PropTypes.bool,
  /**
   * Can be event handler function or object with two properties:
   *
   * - `handler`: event handler
   * - `payload`: object that will be passed to handler
   *
   * Event handler will be called with arguments:
   *
   * - `event`: [SyntheticEvent](https://facebook.github.io/react/docs/events.html)
   * - `params`: `object` (defaults to `{}` *empty object*)
   *   - `payload`: value of `onClick.payload`
   */
  onClick: ncuiPropTypes.eventHandler,
  pull: PropTypes.oneOf(['right', 'left']),
  pulse: PropTypes.bool,
  rotation: PropTypes.oneOf([90, 180, 270]),
  // HACK: 'md' is not used by FontAwesomeIcon
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', '1x', '2x', '3x', '4x', '5x', '6x', '7x', '8x', '9x', '10x']),
  spin: PropTypes.bool,
  title: PropTypes.string,
  transform: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

const block = bemBlock('n-icon-fa');


class IconFA extends React.PureComponent {
  renderIcon(iconDefinition = {}) {
    const {
      className,
      fixedWidth,
      monospace,
      onClick,
      size,
      ...restProps
    } = this.props;

    const onClickHandler = getEventHandler(onClick);
    const ownProps = {
      className: block({
        extra: [
          { clickable: isFunction(onClickHandler) },
          className,
        ],
      }),
      onClick: onClickHandler,
      // TODO: pass size directly when Icon is no longer needed
      size: size !== 'md' ? size : null,
      fixedWidth: monospace || fixedWidth,
    };

    return (
      <FontAwesomeIcon
        {...ownProps}
        {...restProps}
        {...iconDefinition}
      />
    );
  }


  render() {
    const iconDefinition = getGlyph(this.props.glyph);

    if (isFunction(iconDefinition.render)) {
      return iconDefinition.render(this.props);
    }
    return this.renderIcon(iconDefinition);
  }
}

IconFA.propTypes = propTypes;


export default IconFA;
