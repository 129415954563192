export type Token = string | [string, 'e' | 's' | 'd' | 'i'];

/*
 * Levenshtein distance implementation with backtrace.
 * Returns a list of tokens and together with selected operation.
 *
 * Arguments:
 *  - str1: string - string to compare
 *  - str2: string - reference string
 *
 * Returns an array of tokens. A token is a string, or a 2-element array.
 *
 * 'e' - equals
 * 's' - substitution
 * 'd' - deletion
 * 'i' - insertion
 *
 */
export function diffStrings(str1: string, str2: string): Array<Token> {
  const len1 = str1.length;
  const len2 = str2.length;

  let d: number[]; // current line distance
  let dp: number[] = []; // previous line distance

  const t: Array<Array<'e' | 's' | 'd' | 'i'>> = [[]]; // backtrace

  for (let j = 0; j <= len2; j++) {
    dp[j] = j;
    t[0][j] = 'd';
  }

  for (let i = 1; i <= len1; i++) {
    d = [i];
    t[i] = ['i'];

    for (let j = 1; j <= len2; j++) {
      const eq = str1[i - 1] === str2[j - 1];
      const del = d[j - 1] + 1;
      const ins = dp[j] + 1;
      const sub = dp[j - 1] + (eq ? 0 : 1);

      d[j] = Math.min(del, ins, sub);

      if (sub === d[j]) {
        t[i][j] = eq ? 'e' : 's';
      } else if (ins === d[j]) {
        t[i][j] = 'i';
      } else {
        t[i][j] = 'd';
      }
    }

    dp = d;
  }

  // Reconstruct tokens from backtrace
  let i = len1,
    j = len2;
  const tokens: Array<Token> = [];

  while (i > 0 || j > 0) {
    const c = t[i][j];

    if (c === 'e') {
      tokens.unshift(str1[i - 1]);
    } else if (c === 'd') {
      tokens.unshift([str2[j - 1], 'd']);
    } else {
      tokens.unshift([str1[i - 1], c]);
    }

    if (c !== 'd') {
      i--;
    }

    if (c !== 'i') {
      j--;
    }
  }

  // Levenshtein distance for future reference.
  // const distance = d[len2];

  return tokens;
}
