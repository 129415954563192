// Libs
import React from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line no-restricted-imports
import classNames from 'classnames';

// neptune-core-ui
// eslint-disable-next-line no-restricted-imports
import ncuiPropTypes from 'neptune-core-ui/helpers/prop-types';
// eslint-disable-next-line no-restricted-imports
import { getEventHandler } from 'neptune-core-ui/modules/events';

// eslint-disable-next-line no-restricted-imports
import Icon from 'neptune-core-ui/components/icon';

// Module
import './SortAction.less';

const propTypes = {
  sortBy: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  sortOrder: PropTypes.oneOf(['none', 'asc', 'desc']),
  /**
   * Can be event handler function or object with two properties:
   * - `handler` - event handler function
   * - `payload` - object that will be passed to handler
   *
   * Event handler will be called with argument:
   * - `params` - object with the following properties:
   *   - `sortBy`
   *   - `sortOrder` - new sort order
   *   - `prevSortOrder` - previous sort order
   *   - `payload` - value of `onSortChange.payload`
   */
  onSortChange: ncuiPropTypes.eventHandler,
};

const defaultProps = {
  sortOrder: 'none',
};


const sortTransitions = {
  none: 'asc',
  asc: 'desc',
  desc: 'asc',
};


const SortAction = ({
  sortBy,
  sortOrder,
  onSortChange,
}) => {
  const sortChangeHandler = getEventHandler(onSortChange, {}, false);
  const cssClasses = classNames(
    'n-action-sort',
    { 'clickable' : onSortChange !== undefined },
    { 'n-action-sort--disabled' : onSortChange === undefined },
  );

  const onClick = (ev) => {
    ev.stopPropagation();
    return sortChangeHandler({
      sortBy,
      sortOrder: sortTransitions[sortOrder],
      prevSortOrder: sortOrder,
    });
  };


  const ascCssClasses = classNames(
    'n-action-sort__arrow',
    {'n-action-sort__arrow--selected': sortOrder === 'asc'},
  );

  const descCssClasses = classNames(
    'n-action-sort__arrow',
    {'n-action-sort__arrow--selected': sortOrder === 'desc'},
  );

  return (
    <Icon.Stack
      className={cssClasses}
      data-role="sort-action"
      data-selected={['asc', 'desc'].includes(sortOrder)}
      onClick={onSortChange ? onClick : undefined}
    >
      <Icon glyph="sort-up" className={ascCssClasses} />
      <Icon glyph="sort-down" className={descCssClasses} />
    </Icon.Stack>
  );
};

SortAction.propTypes = propTypes;
SortAction.defaultProps = defaultProps;


export default SortAction;
