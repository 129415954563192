// Libs
import React from 'react';

import { bemBlock } from '../../modules/bem';
import { Icon } from '../icon/Icon';
import { Link } from '../link/Link';

// Module
import './InfoIcon.less';
import { UITooltip, useTooltip, UseTooltipProps } from '../tooltip';

const block = bemBlock('n-InfoIcon');

export const DefaultTriggerElement = () => <Icon glyph="info-circle" color="dark-decorator" />;

type InfoIconProps = {
  /**
   * Allows to use custom trigger element.
   * `DefaultTriggerElement` can be reused in your custom trigger.
   */
  triggerElement?: React.ReactChild;
  className?: string;
  placement?: UseTooltipProps['placement'];
  'data-role'?: string;
};

export const InfoIcon: React.FC<InfoIconProps> = ({
  children,
  className,
  placement,
  triggerElement,
  'data-role': dataRole,
}) => {
  const tooltipProps = useTooltip({
    disabled: children == null,
    placement,
  });

  return (
    <span
      data-role={dataRole}
      className={block({ extra: className })}
      {...tooltipProps.triggerProps}
    >
      <Link className={block('trigger')} fontWeight="normal">
        {triggerElement || <DefaultTriggerElement />}
      </Link>
      {tooltipProps.isOpen &&
        tooltipProps.renderLayer(<UITooltip {...tooltipProps.layerProps}>{children}</UITooltip>)}
    </span>
  );
};
