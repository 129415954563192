/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AttributesHolderIdentifier,
    AttributesHolderIdentifierFromJSON,
    AttributesHolderIdentifierFromJSONTyped,
    AttributesHolderIdentifierToJSON,
} from './';

/**
 * 
 * @export
 * @interface TimeSeries
 */
export interface TimeSeries {
    /**
     * 
     * @type {string}
     * @memberof TimeSeries
     */
    attribute: string;
    /**
     * 
     * @type {AttributesHolderIdentifier}
     * @memberof TimeSeries
     */
    holder: AttributesHolderIdentifier;
    /**
     * 
     * @type {string}
     * @memberof TimeSeries
     */
    lineage?: TimeSeriesLineageEnum;
}

export function TimeSeriesFromJSON(json: any): TimeSeries {
    return TimeSeriesFromJSONTyped(json, false);
}

export function TimeSeriesFromJSONTyped(json: any, ignoreDiscriminator: boolean): TimeSeries {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attribute': json['attribute'],
        'holder': AttributesHolderIdentifierFromJSON(json['holder']),
        'lineage': !exists(json, 'lineage') ? undefined : json['lineage'],
    };
}

export function TimeSeriesToJSON(value?: TimeSeries | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attribute': value.attribute,
        'holder': AttributesHolderIdentifierToJSON(value.holder),
        'lineage': value.lineage,
    };
}

/**
* @export
* @enum {string}
*/
export enum TimeSeriesLineageEnum {
    FULL = 'FULL',
    NONE = 'NONE'
}


