import React from 'react';

type HorizontalTabsContextProps = {
  activeTabId: string;
  tabIdsInOrder: string[];
  onChange?: (tabName: string) => void;
};

export const HorizontalTabsContext = React.createContext<HorizontalTabsContextProps | undefined>(
  undefined,
);

export function useHorizontalTabsContext() {
  const context = React.useContext(HorizontalTabsContext);

  if (!context) {
    throw new Error('useHorizontalTabsContext must be used within a HorizontalTabs');
  }

  return context;
}
