// Venus UI
// component state class name helpers
// //////////////////////////////////////////////////////////////////////////////

// Libs
import { MouseEventHandler } from 'react';

// Module
export function clickableClassName(value?: boolean | MouseEventHandler) {
  return !!value ? 'n--clickable' : undefined;
}

export function disabledClassName(value?: boolean) {
  return value && 'n--disabled';
}

export function pressedClassName(value?: boolean) {
  return value && 'n--pressed';
}

export function selectedClassName(value?: boolean) {
  return value && 'n--selected';
}

export function expandedClassName(value?: boolean) {
  return value && 'n--expanded';
}
