/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SessionDTO
 */
export interface SessionDTO {
    /**
     * 
     * @type {string}
     * @memberof SessionDTO
     */
    sessionId: string;
}

export function SessionDTOFromJSON(json: any): SessionDTO {
    return SessionDTOFromJSONTyped(json, false);
}

export function SessionDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): SessionDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sessionId': json['sessionId'],
    };
}

export function SessionDTOToJSON(value?: SessionDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sessionId': value.sessionId,
    };
}


