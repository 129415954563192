// Libs
import React from 'react';
import { Button, Layout, Text } from '@neptune/shared/venus-ui';

// App
import { neptune } from 'analytics/neptune';
import { authClient } from '@neptune/shared/core-auth-domain';

// Module
type WrongSsoErrorPageProps = {
  ssoMethod: string;
};

export const WrongSsoErrorPage: React.FC<WrongSsoErrorPageProps> = ({ ssoMethod }) => {
  return (
    <Layout.Column height="100%" alignItems="center" justifyContent="center" spacedChildren="lg">
      <Text size="xl" fontWeight="semibold">
        Another login method required
      </Text>
      <Text align="center">
        <Text>
          Your organization’s policy requires you to log in using{' '}
          <Text fontWeight="semibold">{ssoMethod}</Text>.
        </Text>
        <br />
        <Text>Please log out and log in again.</Text>
      </Text>
      <Button
        size="lg"
        onClick={() => {
          neptune.logout();
          authClient.logout();
        }}
      >
        Back to login
      </Button>
    </Layout.Column>
  );
};
