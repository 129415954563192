// Libs
import React from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line no-restricted-imports
import classNames from 'classnames';
import {
  isArray,
  isEmpty,
} from 'lodash';


// neptune-core-ui
// eslint-disable-next-line no-restricted-imports
import ncuiPropTypes from 'neptune-core-ui/helpers/prop-types';
// eslint-disable-next-line no-restricted-imports
import { getValidProps } from 'neptune-core-ui/components/helpers';
// eslint-disable-next-line no-restricted-imports
import TableRow from 'neptune-core-ui/components/table/TableRow';
// eslint-disable-next-line no-restricted-imports
import TableCell from 'neptune-core-ui/components/table/TableCell';


// Module
import './TableFoot.less';


const propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  /**
   * Array of row definitions.
   *
   * Every row is an array of cell definitions (see [TableRow](#tablerow)).
   *
   * Cell definition is an object (see [TableCell](#tablecell)):
   * - `align`
   * - `className`
   * - `colSpan`
   * - `data`
   * - `dataKey`
   * - `header`
   * - `renderer`
   * - `renderParams`
   * - `rowSpan`
   * - `width`
   * - `onClick`
   */
  rows: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        align: PropTypes.oneOf(['left', 'center', 'right']),
        className: PropTypes.string,
        colSpan: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
        ]),
        data: PropTypes.oneOfType([
          PropTypes.number,
          PropTypes.string,
          PropTypes.object,
          PropTypes.array,
        ]),
        dataKey: PropTypes.string,
        header: PropTypes.bool,
        renderer: PropTypes.func,
        renderParams: PropTypes.any,
        rowSpan: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
        ]),
        width: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
        ]),
        onClick: ncuiPropTypes.eventHandler,
      }),
    ),
  ),
};


const TableFoot = ({
  children,
  className,
  rows,
}) => {

  const cssClasses = classNames(
    'n-table-foot',
    className,
  );

  const generatedContent = renderFooterRows(rows);

  return (
    <tfoot className={cssClasses}>
      {generatedContent}
      {children}
    </tfoot>
  );
};

TableFoot.propTypes = propTypes;


const validCellProps = [
  'align',
  'className',
  'colSpan',
  'data',
  'dataKey',
  'header',
  'renderer',
  'renderParams',
  'rowSpan',
  'width',
  'onClick',
];


function renderFooterRows(rows) {
  if (!isArray(rows) || isEmpty(rows)) {
    return null;
  }

  return rows.map((row, rowIndex) => (
    <TableRow key={rowIndex}>
    {
      row.map((cell, cellIndex) => (
        <TableCell
          key={cellIndex}
          {...getValidProps(cell, validCellProps)}
        />
      ))
    }
    </TableRow>
  ));
}


export default TableFoot;
