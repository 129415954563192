import { CustomIconDefinition } from '../types';

export const nepSourceCode: CustomIconDefinition = {
  prefix: 'nep',
  iconName: 'source-code',
  icon: [
    24,
    24,
    [],
    'e008',
    'm4.3 5.5q0 0 0 0.1v5.2c0 0.4-0.2 0.8-0.4 1.1l-0.2 0.1 0.2 0.1c0.2 0.3 0.4 0.7 0.4 1.1v5.2q0 0.1 0 0.1 0 0 0 0c0.1 0.1 0.4 0.2 0.9 0.2 0.9 0 1.6 0.6 1.6 1.4 0 0.8-0.7 1.5-1.6 1.5-0.9 0-1.9-0.2-2.7-0.7-0.8-0.6-1.3-1.5-1.3-2.5v-4.6l-0.7-0.8c-0.7-0.5-0.7-1.5 0-2l0.7-0.8v-4.6c0-1 0.5-1.9 1.3-2.5 0.8-0.5 1.8-0.7 2.7-0.7 0.9 0 1.6 0.7 1.6 1.5 0 0.8-0.7 1.4-1.6 1.4-0.5 0-0.8 0.1-0.9 0.2q0 0 0 0zm16.6 0c-0.1-0.1-0.4-0.2-0.9-0.2-0.9 0-1.6-0.6-1.6-1.4 0-0.8 0.7-1.5 1.6-1.5 0.9 0 1.9 0.2 2.7 0.7 0.8 0.6 1.3 1.5 1.3 2.5v4.6l0.7 0.8c0.7 0.5 0.7 1.5 0 2l-0.7 0.8v4.6c0 1-0.5 1.9-1.3 2.5-0.8 0.5-1.8 0.7-2.7 0.7-0.9 0-1.6-0.7-1.6-1.5 0-0.8 0.7-1.4 1.6-1.4 0.5 0 0.8-0.1 0.9-0.2q0 0 0 0 0 0 0-0.1v-5.2c0-0.4 0.2-0.8 0.4-1.1l0.2-0.1-0.2-0.1c-0.2-0.3-0.4-0.7-0.4-1.1v-5.2q0-0.1 0-0.1 0 0 0 0zm-9.4 11.8c-0.4 0.7-1.3 1-2 0.7-0.8-0.4-1.1-1.3-0.7-2l4.9-9.3c0.4-0.7 1.3-1 2-0.7 0.8 0.4 1.1 1.3 0.7 2zm9.4-11.8q0 0 0 0 0 0 0 0zm0 13q0 0 0 0z',
  ],
};
