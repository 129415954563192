/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ActiveProjectsTraitDTO,
    ActiveProjectsTraitDTOFromJSON,
    ActiveProjectsTraitDTOFromJSONTyped,
    ActiveProjectsTraitDTOToJSON,
    DashboardsLimitsTraitDTO,
    DashboardsLimitsTraitDTOFromJSON,
    DashboardsLimitsTraitDTOFromJSONTyped,
    DashboardsLimitsTraitDTOToJSON,
    ModelRegistryLimitsTraitDTO,
    ModelRegistryLimitsTraitDTOFromJSON,
    ModelRegistryLimitsTraitDTOFromJSONTyped,
    ModelRegistryLimitsTraitDTOToJSON,
    OperationsLimitTraitDTO,
    OperationsLimitTraitDTOFromJSON,
    OperationsLimitTraitDTOFromJSONTyped,
    OperationsLimitTraitDTOToJSON,
    PlanUpgradeTraitDTO,
    PlanUpgradeTraitDTOFromJSON,
    PlanUpgradeTraitDTOFromJSONTyped,
    PlanUpgradeTraitDTOToJSON,
    ProjectVisibilityRestrictedTraitDTO,
    ProjectVisibilityRestrictedTraitDTOFromJSON,
    ProjectVisibilityRestrictedTraitDTOFromJSONTyped,
    ProjectVisibilityRestrictedTraitDTOToJSON,
    ServiceAccountsTraitDTO,
    ServiceAccountsTraitDTOFromJSON,
    ServiceAccountsTraitDTOFromJSONTyped,
    ServiceAccountsTraitDTOToJSON,
    TableViewsLimitsTraitDTO,
    TableViewsLimitsTraitDTOFromJSON,
    TableViewsLimitsTraitDTOFromJSONTyped,
    TableViewsLimitsTraitDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface OrganizationTraitsSetDTO
 */
export interface OrganizationTraitsSetDTO {
    /**
     * 
     * @type {ModelRegistryLimitsTraitDTO}
     * @memberof OrganizationTraitsSetDTO
     */
    modelRegistryLimits?: ModelRegistryLimitsTraitDTO;
    /**
     * 
     * @type {object}
     * @memberof OrganizationTraitsSetDTO
     */
    uiWorkspaceSwitching?: object;
    /**
     * 
     * @type {PlanUpgradeTraitDTO}
     * @memberof OrganizationTraitsSetDTO
     */
    planUpgrade?: PlanUpgradeTraitDTO;
    /**
     * 
     * @type {TableViewsLimitsTraitDTO}
     * @memberof OrganizationTraitsSetDTO
     */
    tableViewsLimits?: TableViewsLimitsTraitDTO;
    /**
     * 
     * @type {ProjectVisibilityRestrictedTraitDTO}
     * @memberof OrganizationTraitsSetDTO
     */
    projectVisibilityRestricted?: ProjectVisibilityRestrictedTraitDTO;
    /**
     * 
     * @type {DashboardsLimitsTraitDTO}
     * @memberof OrganizationTraitsSetDTO
     */
    dashboardsLimits?: DashboardsLimitsTraitDTO;
    /**
     * 
     * @type {ActiveProjectsTraitDTO}
     * @memberof OrganizationTraitsSetDTO
     */
    activeProjects?: ActiveProjectsTraitDTO;
    /**
     * 
     * @type {OperationsLimitTraitDTO}
     * @memberof OrganizationTraitsSetDTO
     */
    operationLimit?: OperationsLimitTraitDTO;
    /**
     * 
     * @type {ServiceAccountsTraitDTO}
     * @memberof OrganizationTraitsSetDTO
     */
    serviceAccounts?: ServiceAccountsTraitDTO;
}

export function OrganizationTraitsSetDTOFromJSON(json: any): OrganizationTraitsSetDTO {
    return OrganizationTraitsSetDTOFromJSONTyped(json, false);
}

export function OrganizationTraitsSetDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationTraitsSetDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'modelRegistryLimits': !exists(json, 'modelRegistryLimits') ? undefined : ModelRegistryLimitsTraitDTOFromJSON(json['modelRegistryLimits']),
        'uiWorkspaceSwitching': !exists(json, 'uiWorkspaceSwitching') ? undefined : json['uiWorkspaceSwitching'],
        'planUpgrade': !exists(json, 'planUpgrade') ? undefined : PlanUpgradeTraitDTOFromJSON(json['planUpgrade']),
        'tableViewsLimits': !exists(json, 'tableViewsLimits') ? undefined : TableViewsLimitsTraitDTOFromJSON(json['tableViewsLimits']),
        'projectVisibilityRestricted': !exists(json, 'projectVisibilityRestricted') ? undefined : ProjectVisibilityRestrictedTraitDTOFromJSON(json['projectVisibilityRestricted']),
        'dashboardsLimits': !exists(json, 'dashboardsLimits') ? undefined : DashboardsLimitsTraitDTOFromJSON(json['dashboardsLimits']),
        'activeProjects': !exists(json, 'activeProjects') ? undefined : ActiveProjectsTraitDTOFromJSON(json['activeProjects']),
        'operationLimit': !exists(json, 'operationLimit') ? undefined : OperationsLimitTraitDTOFromJSON(json['operationLimit']),
        'serviceAccounts': !exists(json, 'serviceAccounts') ? undefined : ServiceAccountsTraitDTOFromJSON(json['serviceAccounts']),
    };
}

export function OrganizationTraitsSetDTOToJSON(value?: OrganizationTraitsSetDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'modelRegistryLimits': ModelRegistryLimitsTraitDTOToJSON(value.modelRegistryLimits),
        'uiWorkspaceSwitching': value.uiWorkspaceSwitching,
        'planUpgrade': PlanUpgradeTraitDTOToJSON(value.planUpgrade),
        'tableViewsLimits': TableViewsLimitsTraitDTOToJSON(value.tableViewsLimits),
        'projectVisibilityRestricted': ProjectVisibilityRestrictedTraitDTOToJSON(value.projectVisibilityRestricted),
        'dashboardsLimits': DashboardsLimitsTraitDTOToJSON(value.dashboardsLimits),
        'activeProjects': ActiveProjectsTraitDTOToJSON(value.activeProjects),
        'operationLimit': OperationsLimitTraitDTOToJSON(value.operationLimit),
        'serviceAccounts': ServiceAccountsTraitDTOToJSON(value.serviceAccounts),
    };
}


