import type { Middleware } from 'generated/backend-client';

type ProblemType = 'timeout' | 'connection_failed' | 'error';
export type NeptuneApiIssueContext = {
  problemType: ProblemType;
  errorCode: number;
  endpoint: string;
};

export type SwaggerClientConfigurationFragment = {
  responseInterceptor: (this: Response, req: Request) => Response;
};

/**
 * Supervise API calls. Create instance with proper logging engine.
 *
 * @example
 *   const supervisor = new Supervisor( (context: NeptuneApiIssueContext) => heap.track('API issues', context) );
 *   supervisor.track('error', '500', 'https://example.com');
 */
export class Supervisor {
  constructor(private trackCallback: (context: NeptuneApiIssueContext) => void) {}

  track(problemType: ProblemType, errorCode: number, endpoint: string) {
    this.trackCallback({ problemType, errorCode, endpoint });
  }

  asOpenApiMiddleware(): Middleware {
    return {
      post: async ({ response }) => this.trackResponse(response),
    };
  }

  asSwaggerClientConfiguration(): SwaggerClientConfigurationFragment {
    const trackResponse = this.trackResponse.bind(this);

    return { responseInterceptor };

    function responseInterceptor(this: Response) {
      return trackResponse(this);
    }
  }

  private trackResponse(response: Response): Response {
    if (response.status >= 500 || response.status < 100) {
      this.track('error', response.status, response.url);
    }

    return response;
  }
}
