/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum AttributeTypeDTO {
    Complex = 'complex',
    ExperimentState = 'experimentState',
    Float = 'float',
    Int = 'int',
    Bool = 'bool',
    String = 'string',
    File = 'file',
    Datetime = 'datetime',
    FloatSeries = 'floatSeries',
    StringSeries = 'stringSeries',
    ImageSeries = 'imageSeries',
    StringSet = 'stringSet',
    FileSet = 'fileSet',
    GitRef = 'gitRef',
    NotebookRef = 'notebookRef',
    Artifact = 'artifact'
}

export function AttributeTypeDTOFromJSON(json: any): AttributeTypeDTO {
    return AttributeTypeDTOFromJSONTyped(json, false);
}

export function AttributeTypeDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): AttributeTypeDTO {
    return json as AttributeTypeDTO;
}

export function AttributeTypeDTOToJSON(value?: AttributeTypeDTO | null): any {
    return value as any;
}

