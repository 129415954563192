/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AttributeStyleIdentifierDTO,
    AttributeStyleIdentifierDTOFromJSON,
    AttributeStyleIdentifierDTOFromJSONTyped,
    AttributeStyleIdentifierDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface SearchAttributesStyleSettingsParamsDTO
 */
export interface SearchAttributesStyleSettingsParamsDTO {
    /**
     * 
     * @type {Array<AttributeStyleIdentifierDTO>}
     * @memberof SearchAttributesStyleSettingsParamsDTO
     */
    attributes?: Array<AttributeStyleIdentifierDTO>;
    /**
     * 
     * @type {string}
     * @memberof SearchAttributesStyleSettingsParamsDTO
     */
    projectIdentifier: string;
}

export function SearchAttributesStyleSettingsParamsDTOFromJSON(json: any): SearchAttributesStyleSettingsParamsDTO {
    return SearchAttributesStyleSettingsParamsDTOFromJSONTyped(json, false);
}

export function SearchAttributesStyleSettingsParamsDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchAttributesStyleSettingsParamsDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attributes': !exists(json, 'attributes') ? undefined : ((json['attributes'] as Array<any>).map(AttributeStyleIdentifierDTOFromJSON)),
        'projectIdentifier': json['projectIdentifier'],
    };
}

export function SearchAttributesStyleSettingsParamsDTOToJSON(value?: SearchAttributesStyleSettingsParamsDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attributes': value.attributes === undefined ? undefined : ((value.attributes as Array<any>).map(AttributeStyleIdentifierDTOToJSON)),
        'projectIdentifier': value.projectIdentifier,
    };
}


