// Libs
import React from 'react';
import { bemBlock, Layout } from '@neptune/shared/venus-ui';

// Module
import { Testimonial } from './Testimonial';
import { testimonialsData } from './testimonialsData';
import { KeycloakAsideTitle } from 'components/keycloak/KeycloakAsideTitle';
import { KeycloakBenefits } from 'components/keycloak/KeycloakBenefits';
import './TeamTrialAside.less';

type TeamTrialAsideProps = {
  isAcademiaFlow?: boolean;
};

const block = bemBlock('team-trial-aside');

const defaultFlowBenefits = [
  'No credit card required',
  'Invite an unlimited number of users',
  'Check out collaboration features',
  'Get dedicated support during the trial',
];

const academiaFlowBenefits = [
  'For academic researchers, professors, students, and Kaggle competitors',
  'Invite an unlimited number of users',
  'Check out all team features',
];

export const TeamTrialAside: React.FC<TeamTrialAsideProps> = ({ isAcademiaFlow = false }) => {
  const personData = isAcademiaFlow ? testimonialsData['2'] : testimonialsData['1'];
  const header = isAcademiaFlow
    ? 'Free team workspace for academic use'
    : 'Start a 14-day free trial';

  return (
    <>
      <Layout.Fill />
      <Layout.Column className={block('benefits-section ')} spacedChildren="md">
        <KeycloakAsideTitle className={block('title')}>{header}</KeycloakAsideTitle>
        <KeycloakBenefits values={isAcademiaFlow ? academiaFlowBenefits : defaultFlowBenefits} />
      </Layout.Column>
      <Layout.Fill className={block('margin')} />
      <Testimonial
        name={personData.name}
        title={personData.title}
        photo={personData.photo}
        citation={personData.citation}
        data-role={isAcademiaFlow ? 'testimonial-2' : 'testimonial-1'}
      />
    </>
  );
};
