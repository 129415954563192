/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Alias
 */
export interface Alias {
    /**
     * 
     * @type {Array<string>}
     * @memberof Alias
     */
    channels: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Alias
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Alias
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Alias
     */
    projectId: string;
}

export function AliasFromJSON(json: any): Alias {
    return AliasFromJSONTyped(json, false);
}

export function AliasFromJSONTyped(json: any, ignoreDiscriminator: boolean): Alias {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'channels': json['channels'],
        'id': json['id'],
        'name': json['name'],
        'projectId': json['projectId'],
    };
}

export function AliasToJSON(value?: Alias | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'channels': value.channels,
        'id': value.id,
        'name': value.name,
        'projectId': value.projectId,
    };
}


