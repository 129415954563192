// Libs
import React from 'react';

import { bemBlock } from '../../modules/bem';
import { Button, ButtonProps } from '../button/Button';

// Module
import './AppHeaderButton.less';

const block = bemBlock('n-AppHeaderButton');

export type AppHeaderButtonProps<P extends object> = Omit<ButtonProps<P>, 'size' | 'variant'> & {
  /** Pass url property to `AppHeaderButton` so that component acts as `<a>` tag */
  url?: string;
};

/**
 * ## Import
 *
 * ```
 * import { AppHeader } from '@neptune/shared/venus-ui';
 * ```
 *
 * And use `<AppHeader.Button />`
 */
export function AppHeaderButton<P extends object>({
  className,
  url,
  ...passProps
}: AppHeaderButtonProps<P>): React.ReactElement {
  const ownProps = {
    className: block({ extra: className }),
    component: url ? 'a' : 'button',
    href: url ? url : undefined,
    'data-role': 'app-header-button',
  };

  return (
    <Button
      variant="secondary"
      size="lg"
      withPadding={['none', 'sm']}
      {...ownProps}
      {...passProps}
    />
  );
}
