// Libs
import { isFunction, isObject, noop } from 'lodash';

// Types
declare global {
  interface Window {
    heap?: HeapGlobal;
  }
}

type NoOp = typeof noop;

/**
 * This interface does not contain every property available in HEAP SDK.
 * We typed only those that are used as there are no official typings.
 * Feel free to add new ones.
 */
interface HeapGlobal {
  addUserProperties(properties: HeapUserProperties): void;
  identify(identifier: string): void;
  resetIdentity(): void;
  track(eventName: string, properties?: HeapEventProperties): void;
}

type HeapEventProperties = { [key: string]: string | number };
type HeapUserProperties = { [key: string]: string | number };

// Module

/**
 * This function returns original heap method or noop if heap or method does not exist.
 * It is used to not crash the app if 3rd party script was not loaded (ie. blocked by the user).
 */
function getSafeHeapMethod<P extends keyof HeapGlobal>(name: P): HeapGlobal[P] | NoOp {
  if (isObject(window.heap)) {
    const property = window.heap[name];

    if (isFunction(property)) {
      return property;
    }
  }

  return noop;
}

export const heap: HeapGlobal = {
  get addUserProperties() {
    return getSafeHeapMethod('addUserProperties');
  },
  get identify() {
    return getSafeHeapMethod('identify');
  },
  get resetIdentity() {
    return getSafeHeapMethod('resetIdentity');
  },
  get track() {
    return getSafeHeapMethod('track');
  },
};
