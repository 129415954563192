// Libs
import React, { LinkHTMLAttributes } from 'react';

import { createComponent, sanitizeDangerousUrl } from '../../modules/utils';

// Module
export type GenericLinkProps = LinkHTMLAttributes<HTMLLinkElement> & {
  /** `external` property is valid for `<a>` version only and will be ignored in other cases. */
  external?: boolean;
  /** Will try to convert `href` to safe URL. */
  sanitize?: boolean;
};

type GenericLinkOwnProps = {
  href?: string;
  target?: string;
  title?: string;
  rel?: string;
};

export function GenericLink({
  external,
  href,
  sanitize,
  title,
  ...passProps
}: GenericLinkProps): React.ReactElement {
  const calculatedHref = href && sanitize ? sanitizeDangerousUrl(href) : href;
  const ownProps: GenericLinkOwnProps = {
    title: title || href,
  };
  let tag = 'span';

  if (calculatedHref) {
    tag = 'a';
    ownProps.href = calculatedHref;

    if (external) {
      ownProps.target = '_blank';
      ownProps.rel = 'noopener noreferrer';
    }
  }

  return createComponent(tag, { ...ownProps, ...passProps });
}
