// Libs
import React from 'react';

// App
import { LayoutRow, LayoutRowProps } from '../layout-row/LayoutRow';
import { Text } from '../text/Text';
import { Icon } from '../icon/Icon';
import { bemBlock } from '../../modules/bem';
import { clickableClassName } from '../../modules/ui-state';

// Module
import './HelpLink.less';

const block = bemBlock('help-link');

type HelpLinkProps<P extends object> = LayoutRowProps<P> & {
  type?: 'external' | 'internal';
};

export function HelpLink<P extends object>({
  className,
  children,
  type = 'internal',
  onClick,
  ...restProps
}: HelpLinkProps<P>) {
  return (
    <LayoutRow
      className={block({ modifiers: { type }, extra: [className, clickableClassName(onClick)] })}
      span="auto"
      spacedChildren="sm"
      onClick={onClick}
      {...restProps}
    >
      <Icon
        className={block('icon')}
        glyph={type === 'internal' ? 'circle-question-mark' : 'circle-arrow-right'}
      />
      <Text size="xs">
        {type === 'internal' ? ' ' : ' Learn more about '}
        {children}
      </Text>
    </LayoutRow>
  );
}
