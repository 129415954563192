/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MultiPartUploadConfigDTO
 */
export interface MultiPartUploadConfigDTO {
    /**
     * 
     * @type {number}
     * @memberof MultiPartUploadConfigDTO
     */
    maxChunkSize: number;
    /**
     * 
     * @type {number}
     * @memberof MultiPartUploadConfigDTO
     */
    maxSinglePartSize: number;
    /**
     * 
     * @type {boolean}
     * @memberof MultiPartUploadConfigDTO
     */
    enabled: boolean;
    /**
     * 
     * @type {number}
     * @memberof MultiPartUploadConfigDTO
     */
    minChunkSize: number;
    /**
     * 
     * @type {number}
     * @memberof MultiPartUploadConfigDTO
     */
    maxChunkCount: number;
}

export function MultiPartUploadConfigDTOFromJSON(json: any): MultiPartUploadConfigDTO {
    return MultiPartUploadConfigDTOFromJSONTyped(json, false);
}

export function MultiPartUploadConfigDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): MultiPartUploadConfigDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'maxChunkSize': json['maxChunkSize'],
        'maxSinglePartSize': json['maxSinglePartSize'],
        'enabled': json['enabled'],
        'minChunkSize': json['minChunkSize'],
        'maxChunkCount': json['maxChunkCount'],
    };
}

export function MultiPartUploadConfigDTOToJSON(value?: MultiPartUploadConfigDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'maxChunkSize': value.maxChunkSize,
        'maxSinglePartSize': value.maxSinglePartSize,
        'enabled': value.enabled,
        'minChunkSize': value.minChunkSize,
        'maxChunkCount': value.maxChunkCount,
    };
}


