import { AvatarSource, avatarSourceFromApiToDomain } from './avatar-source';
import { backendClient, UserListItemDTO } from '@neptune/shared/core-apis-backend-domain';

export interface UserEntry {
  avatarSource: AvatarSource;
  lastName: string;
  firstName: string;
  username: string;
  avatarUrl: string;
}

export function userEntryFromApiToDomain(source: UserListItemDTO): UserEntry {
  return {
    avatarSource: avatarSourceFromApiToDomain(source.avatarSource),
    lastName: source.lastName,
    firstName: source.firstName,
    username: source.username,
    avatarUrl: source.avatarUrl,
  };
}

type ListUsersQuery = {
  organizationIdentifier?: string;
  projectIdentifier?: string;
  usernamePrefix?: string;
  limit?: number;
  offset?: number;
};

export async function listUsers({
  organizationIdentifier,
  projectIdentifier,
  usernamePrefix,
  limit,
  offset,
}: ListUsersQuery): Promise<UserEntry[]> {
  const result = await backendClient.listMembers({
    organizationIdentifier,
    projectIdentifier,
    usernamePrefix,
    limit,
    offset,
  });
  return result.entries.map(userEntryFromApiToDomain);
}

export async function fetchProjectUsernames(projectIdentifier: string): Promise<string[]> {
  const result = await listUsers({ projectIdentifier });
  return result.map(({ username }) => username);
}
