import { CustomIconDefinition } from '../types';

export const nepOk: CustomIconDefinition = {
  prefix: 'nep',
  iconName: 'ok',
  icon: [
    1000,
    1000,
    [],
    'e157',
    'M1 457.4L71.3 383.9 390.8 703.4 889.3 208.1 959.6 278.4 390.8 850.4 1 457.4Z',
  ],
};
