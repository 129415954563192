/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PlotMode
 */
export interface PlotMode {
    /**
     * 
     * @type {object}
     * @memberof PlotMode
     */
    line?: object;
    /**
     * 
     * @type {object}
     * @memberof PlotMode
     */
    scatter?: object;
}

export function PlotModeFromJSON(json: any): PlotMode {
    return PlotModeFromJSONTyped(json, false);
}

export function PlotModeFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlotMode {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'line': !exists(json, 'line') ? undefined : json['line'],
        'scatter': !exists(json, 'scatter') ? undefined : json['scatter'],
    };
}

export function PlotModeToJSON(value?: PlotMode | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'line': value.line,
        'scatter': value.scatter,
    };
}


