// Libs
import React from 'react';

// App
import { Text, TextProps } from '../text/Text';

type HeaderTitleVariant = 'large' | 'medium' | 'small';

type HeaderTitleProps = {
  variant?: HeaderTitleVariant;
} & TextProps<{}>;

const HEADER_TITLE_VARIANTS: Record<
  HeaderTitleVariant,
  Pick<HeaderTitleProps, 'size' | 'fontWeight'>
> = {
  large: {
    size: 'xxl',
    fontWeight: 'bold',
  },
  medium: {
    size: 'lg',
    fontWeight: 'bold',
  },
  small: {
    size: 'md',
    fontWeight: 'bold',
  },
};

export const HeaderTitle: React.FC<HeaderTitleProps> = ({ variant = 'large', ...restProps }) => {
  const calculatedProps: Pick<HeaderTitleProps, 'size' | 'fontWeight'> =
    HEADER_TITLE_VARIANTS[variant];
  return <Text {...calculatedProps} {...restProps} />;
};
