/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MonitoringTimeStatusDTO
 */
export interface MonitoringTimeStatusDTO {
    /**
     * 
     * @type {number}
     * @memberof MonitoringTimeStatusDTO
     */
    availableSeconds: number;
    /**
     * 
     * @type {number}
     * @memberof MonitoringTimeStatusDTO
     */
    nextTopUpSeconds: number;
    /**
     * 
     * @type {number}
     * @memberof MonitoringTimeStatusDTO
     */
    surplusSeconds: number;
    /**
     * 
     * @type {Date}
     * @memberof MonitoringTimeStatusDTO
     */
    nextTopUpDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof MonitoringTimeStatusDTO
     */
    last30DaysSeconds: number;
}

export function MonitoringTimeStatusDTOFromJSON(json: any): MonitoringTimeStatusDTO {
    return MonitoringTimeStatusDTOFromJSONTyped(json, false);
}

export function MonitoringTimeStatusDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonitoringTimeStatusDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'availableSeconds': json['availableSeconds'],
        'nextTopUpSeconds': json['nextTopUpSeconds'],
        'surplusSeconds': json['surplusSeconds'],
        'nextTopUpDate': !exists(json, 'nextTopUpDate') ? undefined : (new Date(json['nextTopUpDate'])),
        'last30DaysSeconds': json['last30DaysSeconds'],
    };
}

export function MonitoringTimeStatusDTOToJSON(value?: MonitoringTimeStatusDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'availableSeconds': value.availableSeconds,
        'nextTopUpSeconds': value.nextTopUpSeconds,
        'surplusSeconds': value.surplusSeconds,
        'nextTopUpDate': value.nextTopUpDate === undefined ? undefined : (value.nextTopUpDate.toISOString()),
        'last30DaysSeconds': value.last30DaysSeconds,
    };
}


