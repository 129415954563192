/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SeriesViewRequestDTO
 */
export interface SeriesViewRequestDTO {
    /**
     * 
     * @type {string}
     * @memberof SeriesViewRequestDTO
     */
    attributePath: string;
    /**
     * 
     * @type {number}
     * @memberof SeriesViewRequestDTO
     */
    firstPointIndex?: number;
    /**
     * 
     * @type {string}
     * @memberof SeriesViewRequestDTO
     */
    holderIdentifier: string;
    /**
     * 
     * @type {string}
     * @memberof SeriesViewRequestDTO
     */
    holderType: string;
    /**
     * 
     * @type {number}
     * @memberof SeriesViewRequestDTO
     */
    lastPointIndex?: number;
}

export function SeriesViewRequestDTOFromJSON(json: any): SeriesViewRequestDTO {
    return SeriesViewRequestDTOFromJSONTyped(json, false);
}

export function SeriesViewRequestDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): SeriesViewRequestDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attributePath': json['attributePath'],
        'firstPointIndex': !exists(json, 'firstPointIndex') ? undefined : json['firstPointIndex'],
        'holderIdentifier': json['holderIdentifier'],
        'holderType': json['holderType'],
        'lastPointIndex': !exists(json, 'lastPointIndex') ? undefined : json['lastPointIndex'],
    };
}

export function SeriesViewRequestDTOToJSON(value?: SeriesViewRequestDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attributePath': value.attributePath,
        'firstPointIndex': value.firstPointIndex,
        'holderIdentifier': value.holderIdentifier,
        'holderType': value.holderType,
        'lastPointIndex': value.lastPointIndex,
    };
}


