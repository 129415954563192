/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ProjectWithRoleDTO,
    ProjectWithRoleDTOFromJSON,
    ProjectWithRoleDTOFromJSONTyped,
    ProjectWithRoleDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface ProjectListDTO
 */
export interface ProjectListDTO {
    /**
     * 
     * @type {Array<ProjectWithRoleDTO>}
     * @memberof ProjectListDTO
     */
    entries: Array<ProjectWithRoleDTO>;
    /**
     * 
     * @type {number}
     * @memberof ProjectListDTO
     */
    matchingItemCount: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectListDTO
     */
    totalItemCount: number;
}

export function ProjectListDTOFromJSON(json: any): ProjectListDTO {
    return ProjectListDTOFromJSONTyped(json, false);
}

export function ProjectListDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectListDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entries': ((json['entries'] as Array<any>).map(ProjectWithRoleDTOFromJSON)),
        'matchingItemCount': json['matchingItemCount'],
        'totalItemCount': json['totalItemCount'],
    };
}

export function ProjectListDTOToJSON(value?: ProjectListDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entries': ((value.entries as Array<any>).map(ProjectWithRoleDTOToJSON)),
        'matchingItemCount': value.matchingItemCount,
        'totalItemCount': value.totalItemCount,
    };
}


