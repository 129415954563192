import React from 'react';

import { MiddleEllipsisRenderer } from './MiddleEllipsisRenderer';
import { MiddleEllipsisWithMatching } from './MiddleEllipsisWithMatching';
import { computePartitionIndex as generalComputePartitionIndex } from './compute-partition-index';
import { TextProps } from '@neptune/shared/venus-ui';
import { ComputePartitionIndex } from '../domain/compute-partition-index';

type MiddleEllipsisProps = {
  text: string;
  match?: string;
  expandable?: boolean;
  'data-role'?: string;
  computePartitionIndex?: ComputePartitionIndex;
} & Pick<
  TextProps<{}>,
  'size' | 'className' | 'fontFamily' | 'fontWeight' | 'lineHeight' | 'color'
>;

export const MiddleEllipsis = ({
  text,
  match,
  size,
  color,
  expandable,
  className,
  fontFamily,
  fontWeight,
  lineHeight,
  'data-role': dataRole,
  computePartitionIndex = generalComputePartitionIndex,
}: MiddleEllipsisProps) => {
  if (match === undefined || match === text) {
    const middleIdx = computePartitionIndex(text);
    const firstPart = text.substring(0, middleIdx);
    const secondPart = text.substring(middleIdx);

    return (
      <MiddleEllipsisRenderer
        text={text}
        color={color}
        expandable={expandable}
        firstPart={firstPart}
        secondPart={secondPart}
        size={size}
        fontFamily={fontFamily}
        fontWeight={fontWeight}
        data-role={dataRole}
        className={className}
        lineHeight={lineHeight}
      />
    );
  }

  return (
    <MiddleEllipsisWithMatching
      text={text}
      color={color}
      match={match}
      size={size}
      expandable={expandable}
      fontFamily={fontFamily}
      fontWeight={fontWeight}
      data-role={dataRole}
      className={className}
      lineHeight={lineHeight}
      computePartitionIndex={generalComputePartitionIndex}
    />
  );
};
