/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InvitationTypeEnumDTO,
    InvitationTypeEnumDTOFromJSON,
    InvitationTypeEnumDTOFromJSONTyped,
    InvitationTypeEnumDTOToJSON,
    ProjectRoleDTO,
    ProjectRoleDTOFromJSON,
    ProjectRoleDTOFromJSONTyped,
    ProjectRoleDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface NewProjectInvitationDTO
 */
export interface NewProjectInvitationDTO {
    /**
     * 
     * @type {string}
     * @memberof NewProjectInvitationDTO
     */
    projectIdentifier: string;
    /**
     * 
     * @type {string}
     * @memberof NewProjectInvitationDTO
     */
    invitee: string;
    /**
     * 
     * @type {InvitationTypeEnumDTO}
     * @memberof NewProjectInvitationDTO
     */
    invitationType: InvitationTypeEnumDTO;
    /**
     * 
     * @type {ProjectRoleDTO}
     * @memberof NewProjectInvitationDTO
     */
    roleGrant: ProjectRoleDTO;
}

export function NewProjectInvitationDTOFromJSON(json: any): NewProjectInvitationDTO {
    return NewProjectInvitationDTOFromJSONTyped(json, false);
}

export function NewProjectInvitationDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewProjectInvitationDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'projectIdentifier': json['projectIdentifier'],
        'invitee': json['invitee'],
        'invitationType': InvitationTypeEnumDTOFromJSON(json['invitationType']),
        'roleGrant': ProjectRoleDTOFromJSON(json['roleGrant']),
    };
}

export function NewProjectInvitationDTOToJSON(value?: NewProjectInvitationDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'projectIdentifier': value.projectIdentifier,
        'invitee': value.invitee,
        'invitationType': InvitationTypeEnumDTOToJSON(value.invitationType),
        'roleGrant': ProjectRoleDTOToJSON(value.roleGrant),
    };
}


