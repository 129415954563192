import { CustomIconDefinition } from '../types';

export const nepRoundFlask: CustomIconDefinition = {
  prefix: 'nep',
  iconName: 'round-flask',
  icon: [
    1000,
    1000,
    [],
    'e13d',
    'M616.4 352.8V176.5C639.4 163.8 654.8 145.8 654.8 120.4 654.8 61.5 574.8 40.5 500 40.5S345.2 61.5 345.2 120.4C345.2 145.9 360.6 163.9 383.6 176.5V352.8C264.2 400.2 185.2 515.4 185.2 645.2 185.2 818.8 326.4 960 500 960S814.8 818.8 814.8 645.2C814.7 515.4 735.8 400.3 616.4 352.8ZM499.9 83C561.9 83 612.2 99.8 612.2 120.4S561.9 157.8 499.9 157.8 387.6 141 387.6 120.4 437.9 83 499.9 83ZM499.9 906.4C355.9 906.4 238.7 789.2 238.7 645.2 238.7 532 311 432.3 418.7 397L437.2 391V194.6C457.3 198.4 478.8 200.3 500 200.3 521.3 200.3 542.7 198.4 562.9 194.6V391L581.3 397C688.9 432.2 761.2 532 761.2 645.2 761.2 789.3 644 906.4 499.9 906.4ZM696.5 673C696.5 672.8 696.7 672.5 696.7 672.3 696.7 634.4 609.9 603.8 502.8 603.8S309 634.4 309 672.3C309 672.4 309 672.4 309 672.5H309C322 768 403.8 841.6 502.9 841.6 601.8 841.6 683.4 768.2 696.7 673 696.7 673 696.5 673 696.5 673Z',
  ],
};
