// Libs
import React from 'react';
import PropTypes from 'prop-types';


// neptune-core-ui
// eslint-disable-next-line no-restricted-imports
import ncuiPropTypes from 'neptune-core-ui/helpers/prop-types';
// eslint-disable-next-line no-restricted-imports
import { getEventHandler } from 'neptune-core-ui/modules/events';
import { Icon } from '@neptune/shared/venus-ui';


const propTypes = {
  className: PropTypes.string,
  column: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  /**
   * Can be event handler function or object with two properties:
   * - `handler` - event handler
   * - `payload` - object that will be passed to handler
   *
   * Event handler will be called with arguments:
   * - `event` - [SyntheticEvent](https://facebook.github.io/react/docs/events.html)
   * - `params` - object
   *   - `column` - value of `column` property
   *   - `payload` - `onRemove.payload`
   */
  onRemove: ncuiPropTypes.eventHandler.isRequired,
  title: PropTypes.string,
};


const RemoveAction = ({
  className,
  column,
  onRemove,
  title,
}) => {
  const removeHandler = getEventHandler(onRemove, {}, false);
  const onClickHandler = (ev) => {
    ev.stopPropagation();
    return removeHandler({column});
  };

  return (
    <Icon
      className={className}
      glyph="circle-cancel"
      title={title ?? 'Hide column'}
      onClick={onClickHandler}
      data-role="remove-action"
      color="text-alt"
    />
  );
};

RemoveAction.propTypes = propTypes;


export default RemoveAction;
