/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InvitationTypeEnumDTO,
    InvitationTypeEnumDTOFromJSON,
    InvitationTypeEnumDTOFromJSONTyped,
    InvitationTypeEnumDTOToJSON,
    NewOrganizationInvitationProjectEntryDTO,
    NewOrganizationInvitationProjectEntryDTOFromJSON,
    NewOrganizationInvitationProjectEntryDTOFromJSONTyped,
    NewOrganizationInvitationProjectEntryDTOToJSON,
    OrganizationRoleDTO,
    OrganizationRoleDTOFromJSON,
    OrganizationRoleDTOFromJSONTyped,
    OrganizationRoleDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface NewOrganizationInvitationEntryDTO
 */
export interface NewOrganizationInvitationEntryDTO {
    /**
     * 
     * @type {OrganizationRoleDTO}
     * @memberof NewOrganizationInvitationEntryDTO
     */
    roleGrant: OrganizationRoleDTO;
    /**
     * 
     * @type {boolean}
     * @memberof NewOrganizationInvitationEntryDTO
     */
    addToAllProjects?: boolean;
    /**
     * 
     * @type {string}
     * @memberof NewOrganizationInvitationEntryDTO
     */
    invitee: string;
    /**
     * 
     * @type {Array<NewOrganizationInvitationProjectEntryDTO>}
     * @memberof NewOrganizationInvitationEntryDTO
     */
    addToProjects?: Array<NewOrganizationInvitationProjectEntryDTO>;
    /**
     * 
     * @type {InvitationTypeEnumDTO}
     * @memberof NewOrganizationInvitationEntryDTO
     */
    invitationType: InvitationTypeEnumDTO;
}

export function NewOrganizationInvitationEntryDTOFromJSON(json: any): NewOrganizationInvitationEntryDTO {
    return NewOrganizationInvitationEntryDTOFromJSONTyped(json, false);
}

export function NewOrganizationInvitationEntryDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewOrganizationInvitationEntryDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'roleGrant': OrganizationRoleDTOFromJSON(json['roleGrant']),
        'addToAllProjects': !exists(json, 'addToAllProjects') ? undefined : json['addToAllProjects'],
        'invitee': json['invitee'],
        'addToProjects': !exists(json, 'addToProjects') ? undefined : ((json['addToProjects'] as Array<any>).map(NewOrganizationInvitationProjectEntryDTOFromJSON)),
        'invitationType': InvitationTypeEnumDTOFromJSON(json['invitationType']),
    };
}

export function NewOrganizationInvitationEntryDTOToJSON(value?: NewOrganizationInvitationEntryDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'roleGrant': OrganizationRoleDTOToJSON(value.roleGrant),
        'addToAllProjects': value.addToAllProjects,
        'invitee': value.invitee,
        'addToProjects': value.addToProjects === undefined ? undefined : ((value.addToProjects as Array<any>).map(NewOrganizationInvitationProjectEntryDTOToJSON)),
        'invitationType': InvitationTypeEnumDTOToJSON(value.invitationType),
    };
}


