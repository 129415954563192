import { OrganizationTypeDTO } from '@neptune/shared/core-apis-backend-domain';

export enum OrganizationType {
  individual = 'individual',
  team = 'team',

  notSupported = 'notSupported',
}

export function organizationTypeFromApiToDomain(source?: OrganizationTypeDTO): OrganizationType {
  switch (source) {
    case OrganizationTypeDTO.Individual:
      return OrganizationType.individual;

    case OrganizationTypeDTO.Team:
      return OrganizationType.team;

    default:
      return OrganizationType.notSupported;
  }
}

export function organizationTypeFromDomainToApi(source?: OrganizationType): OrganizationTypeDTO {
  switch (source) {
    case OrganizationType.individual:
      return OrganizationTypeDTO.Individual;

    case OrganizationType.team:
      return OrganizationTypeDTO.Team;

    default:
      return OrganizationTypeDTO.Individual;
  }
}
