/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserProfileUpdateDTO
 */
export interface UserProfileUpdateDTO {
    /**
     * 
     * @type {string}
     * @memberof UserProfileUpdateDTO
     */
    biography?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserProfileUpdateDTO
     */
    hasLoggedToCli?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserProfileUpdateDTO
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileUpdateDTO
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileUpdateDTO
     */
    shortInfo?: string;
}

export function UserProfileUpdateDTOFromJSON(json: any): UserProfileUpdateDTO {
    return UserProfileUpdateDTOFromJSONTyped(json, false);
}

export function UserProfileUpdateDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserProfileUpdateDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'biography': !exists(json, 'biography') ? undefined : json['biography'],
        'hasLoggedToCli': !exists(json, 'hasLoggedToCli') ? undefined : json['hasLoggedToCli'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'shortInfo': !exists(json, 'shortInfo') ? undefined : json['shortInfo'],
    };
}

export function UserProfileUpdateDTOToJSON(value?: UserProfileUpdateDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'biography': value.biography,
        'hasLoggedToCli': value.hasLoggedToCli,
        'lastName': value.lastName,
        'firstName': value.firstName,
        'shortInfo': value.shortInfo,
    };
}


