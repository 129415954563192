/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NotebookWithNoContentDTO
 */
export interface NotebookWithNoContentDTO {
    /**
     * 
     * @type {Date}
     * @memberof NotebookWithNoContentDTO
     */
    creationTime: Date;
    /**
     * 
     * @type {string}
     * @memberof NotebookWithNoContentDTO
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof NotebookWithNoContentDTO
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof NotebookWithNoContentDTO
     */
    owner: string;
    /**
     * 
     * @type {string}
     * @memberof NotebookWithNoContentDTO
     */
    projectId: string;
}

export function NotebookWithNoContentDTOFromJSON(json: any): NotebookWithNoContentDTO {
    return NotebookWithNoContentDTOFromJSONTyped(json, false);
}

export function NotebookWithNoContentDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotebookWithNoContentDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'creationTime': (new Date(json['creationTime'])),
        'description': json['description'],
        'id': json['id'],
        'owner': json['owner'],
        'projectId': json['projectId'],
    };
}

export function NotebookWithNoContentDTOToJSON(value?: NotebookWithNoContentDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'creationTime': (value.creationTime.toISOString()),
        'description': value.description,
        'id': value.id,
        'owner': value.owner,
        'projectId': value.projectId,
    };
}


