/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AttributeFilterDTO,
    AttributeFilterDTOFromJSON,
    AttributeFilterDTOFromJSONTyped,
    AttributeFilterDTOToJSON,
    AttributeNameFilterDTO,
    AttributeNameFilterDTOFromJSON,
    AttributeNameFilterDTOFromJSONTyped,
    AttributeNameFilterDTOToJSON,
    NextPageDTO,
    NextPageDTOFromJSON,
    NextPageDTOFromJSONTyped,
    NextPageDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface QueryAttributeDefinitionsBodyDTO
 */
export interface QueryAttributeDefinitionsBodyDTO {
    /**
     * Filter by attribute (match any), if null no type or property value filtering is applied
     * @type {Array<AttributeFilterDTO>}
     * @memberof QueryAttributeDefinitionsBodyDTO
     */
    attributeFilter?: Array<AttributeFilterDTO>;
    /**
     * 
     * @type {AttributeNameFilterDTO}
     * @memberof QueryAttributeDefinitionsBodyDTO
     */
    attributeNameFilter?: AttributeNameFilterDTO;
    /**
     * Filter by attribute name with regex, if null no name filtering is applied; deprecated, use attributeNameFilter instead; if attributeNameFilter is set, this field is ignored
     * @type {string}
     * @memberof QueryAttributeDefinitionsBodyDTO
     */
    attributeNameRegex?: string;
    /**
     * Filter by experiment id, if null all experiments are considered
     * @type {Array<string>}
     * @memberof QueryAttributeDefinitionsBodyDTO
     */
    experimentIdsFilter?: Array<string>;
    /**
     * Filter by experiment name, if null all experiments are considered
     * @type {Array<string>}
     * @memberof QueryAttributeDefinitionsBodyDTO
     */
    experimentNamesFilter?: Array<string>;
    /**
     * 
     * @type {NextPageDTO}
     * @memberof QueryAttributeDefinitionsBodyDTO
     */
    nextPage?: NextPageDTO;
}

export function QueryAttributeDefinitionsBodyDTOFromJSON(json: any): QueryAttributeDefinitionsBodyDTO {
    return QueryAttributeDefinitionsBodyDTOFromJSONTyped(json, false);
}

export function QueryAttributeDefinitionsBodyDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): QueryAttributeDefinitionsBodyDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attributeFilter': !exists(json, 'attributeFilter') ? undefined : ((json['attributeFilter'] as Array<any>).map(AttributeFilterDTOFromJSON)),
        'attributeNameFilter': !exists(json, 'attributeNameFilter') ? undefined : AttributeNameFilterDTOFromJSON(json['attributeNameFilter']),
        'attributeNameRegex': !exists(json, 'attributeNameRegex') ? undefined : json['attributeNameRegex'],
        'experimentIdsFilter': !exists(json, 'experimentIdsFilter') ? undefined : json['experimentIdsFilter'],
        'experimentNamesFilter': !exists(json, 'experimentNamesFilter') ? undefined : json['experimentNamesFilter'],
        'nextPage': !exists(json, 'nextPage') ? undefined : NextPageDTOFromJSON(json['nextPage']),
    };
}

export function QueryAttributeDefinitionsBodyDTOToJSON(value?: QueryAttributeDefinitionsBodyDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attributeFilter': value.attributeFilter === undefined ? undefined : ((value.attributeFilter as Array<any>).map(AttributeFilterDTOToJSON)),
        'attributeNameFilter': AttributeNameFilterDTOToJSON(value.attributeNameFilter),
        'attributeNameRegex': value.attributeNameRegex,
        'experimentIdsFilter': value.experimentIdsFilter,
        'experimentNamesFilter': value.experimentNamesFilter,
        'nextPage': NextPageDTOToJSON(value.nextPage),
    };
}


