/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SimplePricingDTO
 */
export interface SimplePricingDTO {
    /**
     * 
     * @type {number}
     * @memberof SimplePricingDTO
     */
    currentValue: number;
    /**
     * 
     * @type {number}
     * @memberof SimplePricingDTO
     */
    unitPriceInCents: number;
}

export function SimplePricingDTOFromJSON(json: any): SimplePricingDTO {
    return SimplePricingDTOFromJSONTyped(json, false);
}

export function SimplePricingDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): SimplePricingDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'currentValue': json['currentValue'],
        'unitPriceInCents': json['unitPriceInCents'],
    };
}

export function SimplePricingDTOToJSON(value?: SimplePricingDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'currentValue': value.currentValue,
        'unitPriceInCents': value.unitPriceInCents,
    };
}


