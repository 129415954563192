import { isFunction } from 'lodash';

type Variant = string | ((value: number) => string);

export function getTextVariantByValue(
  value: number,
  pluralVariant: Variant,
  singularVariant: Variant,
  zeroVariant: Variant,
): string {
  const variant = (value === 0 && zeroVariant) || (value === 1 && singularVariant) || pluralVariant;

  if (isFunction(variant)) {
    return variant(value);
  }

  return variant;
}
