/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ProjectRoleDTO {
    Viewer = 'viewer',
    Member = 'member',
    Manager = 'manager'
}

export function ProjectRoleDTOFromJSON(json: any): ProjectRoleDTO {
    return ProjectRoleDTOFromJSONTyped(json, false);
}

export function ProjectRoleDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectRoleDTO {
    return json as ProjectRoleDTO;
}

export function ProjectRoleDTOToJSON(value?: ProjectRoleDTO | null): any {
    return value as any;
}

