import urlParse from 'url-parse';
import { fetchWithTimeout } from './fetch-with-timeout';

const supportsNetworkInterfaceStatus = 'onLine' in window.navigator;

export async function isReachable(url: string, timeout = 5000): Promise<boolean> {
  /**
   * Online/offline status API is not fully reliable.
   * It only checks status of network interface, not connection to the internet.
   * But if navigator.onLine returns false we are sure that there is no internet.
   */
  if (supportsNetworkInterfaceStatus && !window.navigator.onLine) {
    return false;
  }

  let res: Response | undefined;

  try {
    const parseQuery = true;
    const parsedUrl = urlParse(url, parseQuery);
    const uniqueUrl = parsedUrl.set('query', {
      ...parsedUrl.query,
      v: Date.now(),
    });

    res = await fetchWithTimeout(
      uniqueUrl.toString(),
      {
        method: 'HEAD',
      },
      timeout,
    );
  } catch (e) {}

  return !!res && res.status >= 200;
}
