import { authClient } from './auth-client';
import { updateAuthToken } from './update-auth-token';

export const updateAndAppendAuthTokenToHeaders = async (
  initHeaders?: Headers | [string, string][] | Record<string, string>,
): Promise<Headers> => {
  // Headers is global constructor, see https://developer.mozilla.org/en-US/docs/Web/API/Headers
  const headers = new Headers(initHeaders);

  if (authClient.authenticated) {
    const token = await updateAuthToken();
    headers.set('Authorization', `Bearer ${token}`);
  }

  return headers;
};
