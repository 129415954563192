// Libs
import React from 'react';

import { joinClassNames } from '../../modules/bem';
import * as layout from '../../modules/layout';
import { LayoutElement, LayoutElementProps } from '../layout-element/LayoutElement';

// Module
export type LayoutColumnProps<P extends object> = LayoutElementProps<P> & {
  alignItems?: layout.AlignItems;
  justifyContent?: layout.JustifyContent;
  spacedChildren?: layout.LayoutUnit;
  reversed?: boolean;
};

/**
 * ### Import
 *
 * ```
 * import { Layout } from '@neptune/shared/venus-ui';
 * ```
 *
 * And use `<Layout.Column />`.
 */
export function LayoutColumn<P extends object>({
  alignItems = 'stretch',
  className,
  justifyContent = 'start',
  reversed,
  spacedChildren,
  span = 'greedy',
  ...passProps
}: LayoutColumnProps<P>): React.ReactElement {
  const ownProps = {
    className: joinClassNames(
      className,
      layout.displayFlexClassName(),
      layout.flexDirectionClassName('column', reversed),
      layout.alignItemsClassName(alignItems),
      layout.justifyContentClassName(justifyContent),
      layout.spacedChildrenClassName(spacedChildren),
    ),
    span,
  };

  return <LayoutElement {...ownProps} {...passProps} />;
}
