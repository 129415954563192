// Libs
import React from 'react';

import { bemBlock } from '../../modules/bem';
import { Button, ButtonProps, ButtonSize } from '../button/Button';
import { Icon, IconProps } from '../icon/Icon';
import { LayoutElement } from '../layout-element/LayoutElement';
import { LayoutRow, LayoutRowProps } from '../layout-row/LayoutRow';
import { Text, TextProps } from '../text/Text';

// Module
import './DropdownToggle.less';

export interface DropdownToggleInterface {
  disabled?: boolean;
  elementRef?: React.Ref<any>;
  onCollapse?: React.ReactEventHandler;
  onExpand?: React.ReactEventHandler;
  onToggle?: React.ReactEventHandler;
  onKeyDown?: React.KeyboardEventHandler;
  pressed?: boolean;
}

interface LabelProps {
  label?: React.ReactNode;
}

export type DropdownToggleProps =
  // There is an interference between HTMLAttributes<Element> in LayoutRowProps
  // and HTMLAttributes<HTMLButtonElement> in ButtonProps, so we explicitly make
  // ButtonProps "win" to resolve the clash.
  Omit<LayoutRowProps<{}>, keyof ButtonProps<{}>> &
    ButtonProps<{}> &
    DropdownToggleInterface &
    LabelProps & {
      'data-role'?: string;
      size?: ButtonSize;
    };

const block = bemBlock('n-DropdownToggle');

export class DropdownToggle extends React.PureComponent<DropdownToggleProps> {
  public static defaultProps = {
    span: 'auto',
    'data-role': 'dropdown-toggle',
    size: 'lg',
    spacedChildren: 'sm',
    variant: 'secondary',
  };

  public static Icon = ({ pressed, ...props }: Partial<IconProps> & { pressed?: boolean }) => (
    <Icon {...props} glyph={pressed ? 'chevron-up' : 'chevron-down'} />
  );

  public static Label = ({ label }: LabelProps) => {
    const labelTitle = label && typeof label === 'string' ? label : undefined;

    return (
      <LayoutElement<TextProps<{}>> component={Text} span="greedy" ellipsis title={labelTitle}>
        {label}
      </LayoutElement>
    );
  };

  render() {
    const {
      className,
      children,
      onToggle,
      onExpand,
      onCollapse,
      pressed,
      label,
      variant,
      ...passProps
    } = this.props;
    const content = children ? (
      children
    ) : (
      <>
        <DropdownToggle.Label label={label} />
        <DropdownToggle.Icon pressed={pressed} />
      </>
    );

    return (
      <LayoutRow<ButtonProps<{}>>
        component={Button}
        className={block({
          extra: className,
        })}
        variant={variant}
        onClick={onToggle}
        alignItems="center"
        inline
        {...passProps}
      >
        {content}
      </LayoutRow>
    );
  }
}
