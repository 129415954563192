import { LocalStorageCompatible } from './local-storage-compatible';
import { isLocalStorageV6 } from './local-storage-v6';
import { upgrade as upgradeFromPrevious } from './local-storage-v6.upgrade';
import { LocalStorageV7 } from './local-storage-v7';

/*
 * NOTE: Create new migration files only when you need to remove or edit existing field. Do not create new migration file for adding new field.
 */
export async function upgrade(raw: LocalStorageCompatible): Promise<LocalStorageV7> {
  const input = isLocalStorageV6(raw) ? raw : await upgradeFromPrevious(raw);
  const { data } = input;

  const { chartsWithXLogScale, chartsWithYLogScale, ...rest } = data;

  const chartsAxisScales: LocalStorageV7['data']['chartsAxisScales'] = {};

  for (const chartId of chartsWithXLogScale ?? []) {
    (chartsAxisScales[chartId] = chartsAxisScales[chartId] ?? {}).x = 'logarithmic';
  }

  for (const chartId of chartsWithYLogScale ?? []) {
    (chartsAxisScales[chartId] = chartsAxisScales[chartId] ?? {}).y = 'logarithmic';
  }

  return {
    version: 7,
    data: {
      ...rest,
      chartsAxisScales,
    },
  };
}
