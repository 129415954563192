/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ArtifactAttributeDTO,
    ArtifactAttributeDTOFromJSON,
    ArtifactAttributeDTOFromJSONTyped,
    ArtifactAttributeDTOToJSON,
    AttributeTypeDTO,
    AttributeTypeDTOFromJSON,
    AttributeTypeDTOFromJSONTyped,
    AttributeTypeDTOToJSON,
    BoolAttributeDTO,
    BoolAttributeDTOFromJSON,
    BoolAttributeDTOFromJSONTyped,
    BoolAttributeDTOToJSON,
    ComplexAttributeDTO,
    ComplexAttributeDTOFromJSON,
    ComplexAttributeDTOFromJSONTyped,
    ComplexAttributeDTOToJSON,
    DatetimeAttributeDTO,
    DatetimeAttributeDTOFromJSON,
    DatetimeAttributeDTOFromJSONTyped,
    DatetimeAttributeDTOToJSON,
    ExperimentStateAttributeDTO,
    ExperimentStateAttributeDTOFromJSON,
    ExperimentStateAttributeDTOFromJSONTyped,
    ExperimentStateAttributeDTOToJSON,
    FileAttributeDTO,
    FileAttributeDTOFromJSON,
    FileAttributeDTOFromJSONTyped,
    FileAttributeDTOToJSON,
    FileSetAttributeDTO,
    FileSetAttributeDTOFromJSON,
    FileSetAttributeDTOFromJSONTyped,
    FileSetAttributeDTOToJSON,
    FloatAttributeDTO,
    FloatAttributeDTOFromJSON,
    FloatAttributeDTOFromJSONTyped,
    FloatAttributeDTOToJSON,
    FloatSeriesAttributeDTO,
    FloatSeriesAttributeDTOFromJSON,
    FloatSeriesAttributeDTOFromJSONTyped,
    FloatSeriesAttributeDTOToJSON,
    GitInfoDTO,
    GitInfoDTOFromJSON,
    GitInfoDTOFromJSONTyped,
    GitInfoDTOToJSON,
    ImageSeriesAttributeDTO,
    ImageSeriesAttributeDTOFromJSON,
    ImageSeriesAttributeDTOFromJSONTyped,
    ImageSeriesAttributeDTOToJSON,
    IntAttributeDTO,
    IntAttributeDTOFromJSON,
    IntAttributeDTOFromJSONTyped,
    IntAttributeDTOToJSON,
    NotebookRefAttributeDTO,
    NotebookRefAttributeDTOFromJSON,
    NotebookRefAttributeDTOFromJSONTyped,
    NotebookRefAttributeDTOToJSON,
    StringAttributeDTO,
    StringAttributeDTOFromJSON,
    StringAttributeDTOFromJSONTyped,
    StringAttributeDTOToJSON,
    StringSeriesAttributeDTO,
    StringSeriesAttributeDTOFromJSON,
    StringSeriesAttributeDTOFromJSONTyped,
    StringSeriesAttributeDTOToJSON,
    StringSetAttributeDTO,
    StringSetAttributeDTOFromJSON,
    StringSetAttributeDTOFromJSONTyped,
    StringSetAttributeDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface AttributeDTO
 */
export interface AttributeDTO {
    /**
     * 
     * @type {ArtifactAttributeDTO}
     * @memberof AttributeDTO
     */
    artifactProperties?: ArtifactAttributeDTO;
    /**
     * 
     * @type {BoolAttributeDTO}
     * @memberof AttributeDTO
     */
    boolProperties?: BoolAttributeDTO;
    /**
     * 
     * @type {ComplexAttributeDTO}
     * @memberof AttributeDTO
     */
    complexProperties?: ComplexAttributeDTO;
    /**
     * 
     * @type {DatetimeAttributeDTO}
     * @memberof AttributeDTO
     */
    datetimeProperties?: DatetimeAttributeDTO;
    /**
     * 
     * @type {ExperimentStateAttributeDTO}
     * @memberof AttributeDTO
     */
    experimentStateProperties?: ExperimentStateAttributeDTO;
    /**
     * 
     * @type {FileAttributeDTO}
     * @memberof AttributeDTO
     */
    fileProperties?: FileAttributeDTO;
    /**
     * 
     * @type {FileSetAttributeDTO}
     * @memberof AttributeDTO
     */
    fileSetProperties?: FileSetAttributeDTO;
    /**
     * 
     * @type {FloatAttributeDTO}
     * @memberof AttributeDTO
     */
    floatProperties?: FloatAttributeDTO;
    /**
     * 
     * @type {FloatSeriesAttributeDTO}
     * @memberof AttributeDTO
     */
    floatSeriesProperties?: FloatSeriesAttributeDTO;
    /**
     * 
     * @type {GitInfoDTO}
     * @memberof AttributeDTO
     */
    gitRefProperties?: GitInfoDTO;
    /**
     * 
     * @type {ImageSeriesAttributeDTO}
     * @memberof AttributeDTO
     */
    imageSeriesProperties?: ImageSeriesAttributeDTO;
    /**
     * 
     * @type {IntAttributeDTO}
     * @memberof AttributeDTO
     */
    intProperties?: IntAttributeDTO;
    /**
     * 
     * @type {string}
     * @memberof AttributeDTO
     */
    name: string;
    /**
     * 
     * @type {NotebookRefAttributeDTO}
     * @memberof AttributeDTO
     */
    notebookRefProperties?: NotebookRefAttributeDTO;
    /**
     * 
     * @type {StringAttributeDTO}
     * @memberof AttributeDTO
     */
    stringProperties?: StringAttributeDTO;
    /**
     * 
     * @type {StringSeriesAttributeDTO}
     * @memberof AttributeDTO
     */
    stringSeriesProperties?: StringSeriesAttributeDTO;
    /**
     * 
     * @type {StringSetAttributeDTO}
     * @memberof AttributeDTO
     */
    stringSetProperties?: StringSetAttributeDTO;
    /**
     * 
     * @type {AttributeTypeDTO}
     * @memberof AttributeDTO
     */
    type: AttributeTypeDTO;
}

export function AttributeDTOFromJSON(json: any): AttributeDTO {
    return AttributeDTOFromJSONTyped(json, false);
}

export function AttributeDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): AttributeDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'artifactProperties': !exists(json, 'artifactProperties') ? undefined : ArtifactAttributeDTOFromJSON(json['artifactProperties']),
        'boolProperties': !exists(json, 'boolProperties') ? undefined : BoolAttributeDTOFromJSON(json['boolProperties']),
        'complexProperties': !exists(json, 'complexProperties') ? undefined : ComplexAttributeDTOFromJSON(json['complexProperties']),
        'datetimeProperties': !exists(json, 'datetimeProperties') ? undefined : DatetimeAttributeDTOFromJSON(json['datetimeProperties']),
        'experimentStateProperties': !exists(json, 'experimentStateProperties') ? undefined : ExperimentStateAttributeDTOFromJSON(json['experimentStateProperties']),
        'fileProperties': !exists(json, 'fileProperties') ? undefined : FileAttributeDTOFromJSON(json['fileProperties']),
        'fileSetProperties': !exists(json, 'fileSetProperties') ? undefined : FileSetAttributeDTOFromJSON(json['fileSetProperties']),
        'floatProperties': !exists(json, 'floatProperties') ? undefined : FloatAttributeDTOFromJSON(json['floatProperties']),
        'floatSeriesProperties': !exists(json, 'floatSeriesProperties') ? undefined : FloatSeriesAttributeDTOFromJSON(json['floatSeriesProperties']),
        'gitRefProperties': !exists(json, 'gitRefProperties') ? undefined : GitInfoDTOFromJSON(json['gitRefProperties']),
        'imageSeriesProperties': !exists(json, 'imageSeriesProperties') ? undefined : ImageSeriesAttributeDTOFromJSON(json['imageSeriesProperties']),
        'intProperties': !exists(json, 'intProperties') ? undefined : IntAttributeDTOFromJSON(json['intProperties']),
        'name': json['name'],
        'notebookRefProperties': !exists(json, 'notebookRefProperties') ? undefined : NotebookRefAttributeDTOFromJSON(json['notebookRefProperties']),
        'stringProperties': !exists(json, 'stringProperties') ? undefined : StringAttributeDTOFromJSON(json['stringProperties']),
        'stringSeriesProperties': !exists(json, 'stringSeriesProperties') ? undefined : StringSeriesAttributeDTOFromJSON(json['stringSeriesProperties']),
        'stringSetProperties': !exists(json, 'stringSetProperties') ? undefined : StringSetAttributeDTOFromJSON(json['stringSetProperties']),
        'type': AttributeTypeDTOFromJSON(json['type']),
    };
}

export function AttributeDTOToJSON(value?: AttributeDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'artifactProperties': ArtifactAttributeDTOToJSON(value.artifactProperties),
        'boolProperties': BoolAttributeDTOToJSON(value.boolProperties),
        'complexProperties': ComplexAttributeDTOToJSON(value.complexProperties),
        'datetimeProperties': DatetimeAttributeDTOToJSON(value.datetimeProperties),
        'experimentStateProperties': ExperimentStateAttributeDTOToJSON(value.experimentStateProperties),
        'fileProperties': FileAttributeDTOToJSON(value.fileProperties),
        'fileSetProperties': FileSetAttributeDTOToJSON(value.fileSetProperties),
        'floatProperties': FloatAttributeDTOToJSON(value.floatProperties),
        'floatSeriesProperties': FloatSeriesAttributeDTOToJSON(value.floatSeriesProperties),
        'gitRefProperties': GitInfoDTOToJSON(value.gitRefProperties),
        'imageSeriesProperties': ImageSeriesAttributeDTOToJSON(value.imageSeriesProperties),
        'intProperties': IntAttributeDTOToJSON(value.intProperties),
        'name': value.name,
        'notebookRefProperties': NotebookRefAttributeDTOToJSON(value.notebookRefProperties),
        'stringProperties': StringAttributeDTOToJSON(value.stringProperties),
        'stringSeriesProperties': StringSeriesAttributeDTOToJSON(value.stringSeriesProperties),
        'stringSetProperties': StringSetAttributeDTOToJSON(value.stringSetProperties),
        'type': AttributeTypeDTOToJSON(value.type),
    };
}


