'use strict';

import { isObject } from 'lodash';
import urlParse from 'url-parse';
import baseConfig from './base';
import { customConfig } from './custom-config';

const dockerConfig = isObject(window.dockerConfig) ? compileDockerConfig(window.dockerConfig) : {};

const config = {
  appEnv: 'dist',
};

const featureFlags = {
  withAdminPanel: false,

  withRefactoredCharts: false,
  withRefactoredCharts2: false,
  withRefactoredCharts3: false,

  withRunGroupsV4: false,

  withSingleRunAutoFollow: false,
  withSectionsInReports: true,
  withSectionsInDashboards: false,
  withXAxisRangeSetting: true,
  withXAxisGlobalSettings: false,
  withNameSearchRegExp: true,
  withCompoundSearch: true,
  withCompoundSearchWithEditableCriterionItems: false,
  withReportsRunGroups: true,
  withCustomXExpression: true,
};

export default Object.freeze(
  Object.assign({}, baseConfig, config, featureFlags, dockerConfig, customConfig()),
);

function compileDockerConfig(config) {
  // in case of relative logoutURL, always convert to absolute as on-prems have problems when hosting from IPs
  const logoutURL = config.logoutURL
    ? urlParse(config.logoutURL).toString()
    : window.location.origin;

  const allowAddingNewLicense = !!config.allowAddingNewLicense;

  return Object.assign({}, config, {
    neptuneApiWSPort: config.neptuneApiPort,
    logoutURL,
    allowAddingNewLicense,
  });
}
