/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ConfigFloatSeries
 */
export interface ConfigFloatSeries {
    /**
     * 
     * @type {number}
     * @memberof ConfigFloatSeries
     */
    min?: number;
    /**
     * 
     * @type {number}
     * @memberof ConfigFloatSeries
     */
    max?: number;
    /**
     * 
     * @type {string}
     * @memberof ConfigFloatSeries
     */
    unit?: string;
}

export function ConfigFloatSeriesFromJSON(json: any): ConfigFloatSeries {
    return ConfigFloatSeriesFromJSONTyped(json, false);
}

export function ConfigFloatSeriesFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConfigFloatSeries {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'min': !exists(json, 'min') ? undefined : json['min'],
        'max': !exists(json, 'max') ? undefined : json['max'],
        'unit': !exists(json, 'unit') ? undefined : json['unit'],
    };
}

export function ConfigFloatSeriesToJSON(value?: ConfigFloatSeries | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'min': value.min,
        'max': value.max,
        'unit': value.unit,
    };
}


