import { CustomIconDefinition } from '../types';

export const nepFolder: CustomIconDefinition = {
  prefix: 'nep',
  iconName: 'folder-alt', // was: 'folder
  icon: [
    1000,
    1000,
    [],
    'e163',
    'M94.7 349.9V872.1H907V349.9H94.7ZM442.8 216.9H94.7V291.9H442.8V216.9Z',
  ],
};
