/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Backend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.8
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RegistrationSurveyDTO
 */
export interface RegistrationSurveyDTO {
    /**
     * 
     * @type {string}
     * @memberof RegistrationSurveyDTO
     */
    survey: string;
}

export function RegistrationSurveyDTOFromJSON(json: any): RegistrationSurveyDTO {
    return RegistrationSurveyDTOFromJSONTyped(json, false);
}

export function RegistrationSurveyDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): RegistrationSurveyDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'survey': json['survey'],
    };
}

export function RegistrationSurveyDTOToJSON(value?: RegistrationSurveyDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'survey': value.survey,
    };
}


