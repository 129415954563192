/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    WidgetAttributeDTO,
    WidgetAttributeDTOFromJSON,
    WidgetAttributeDTOFromJSONTyped,
    WidgetAttributeDTOToJSON,
} from './';

/**
 * 
 * @export
 * @interface WidgetDTO
 */
export interface WidgetDTO {
    /**
     * 
     * @type {Array<WidgetAttributeDTO>}
     * @memberof WidgetDTO
     */
    attributes?: Array<WidgetAttributeDTO>;
    /**
     * 
     * @type {Array<string>}
     * @memberof WidgetDTO
     */
    experimentShortIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof WidgetDTO
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof WidgetDTO
     */
    name?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof WidgetDTO
     */
    namespaces?: Array<string>;
    /**
     * 
     * @type {object}
     * @memberof WidgetDTO
     */
    options?: object;
    /**
     * 
     * @type {string}
     * @memberof WidgetDTO
     */
    type: WidgetDTOTypeEnum;
}

export function WidgetDTOFromJSON(json: any): WidgetDTO {
    return WidgetDTOFromJSONTyped(json, false);
}

export function WidgetDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): WidgetDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attributes': !exists(json, 'attributes') ? undefined : ((json['attributes'] as Array<any>).map(WidgetAttributeDTOFromJSON)),
        'experimentShortIds': !exists(json, 'experimentShortIds') ? undefined : json['experimentShortIds'],
        'id': json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'namespaces': !exists(json, 'namespaces') ? undefined : json['namespaces'],
        'options': !exists(json, 'options') ? undefined : json['options'],
        'type': json['type'],
    };
}

export function WidgetDTOToJSON(value?: WidgetDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attributes': value.attributes === undefined ? undefined : ((value.attributes as Array<any>).map(WidgetAttributeDTOToJSON)),
        'experimentShortIds': value.experimentShortIds,
        'id': value.id,
        'name': value.name,
        'namespaces': value.namespaces,
        'options': value.options,
        'type': value.type,
    };
}

/**
* @export
* @enum {string}
*/
export enum WidgetDTOTypeEnum {
    Chart = 'chart',
    ValueList = 'valueList',
    File = 'file',
    FileSet = 'fileSet',
    Image = 'image',
    ImageComparison = 'imageComparison',
    InteractiveTable = 'interactiveTable',
    Gallery = 'gallery',
    Notebook = 'notebook',
    ScatterPlot = 'scatterPlot',
    SingleValue = 'singleValue',
    Table = 'table',
    TextNode = 'textNode',
    Section = 'section'
}


